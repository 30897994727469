import React, { useEffect, useState } from 'react';
import FileSaver from 'file-saver';

import { Card } from '@components/Card';
import { AlbumCarousel } from '@components/AlbumCarosel';
import { Button } from '@components/Button';
import { PhotoDto, SubjectAlbumDto, UserDto } from '@dto';
import { useAppDispatch, useAppSelector } from '@store/hook';
import { open as modalOpen } from '@store/modal';

import * as albumApi from '@api/album';
import { selectUser } from '@store/user';
import { ROLE } from '@common';
import { TailSpin } from 'react-loader-spinner';

interface IProp {
  readonly album: SubjectAlbumDto | undefined;
  readonly onModeChange?: (mode: 'update' | 'create' | 'read') => void;
  readonly onRemoved: () => void;
  readonly onClose: () => void;
  readonly onUpdateMode: () => void;
}

export const AlbumDetail = ({ album: _album, onRemoved, onClose, onUpdateMode }: IProp) => {
  const [isFullOpen, setIsFullOpen] = useState<boolean>(false);
  const [album, setAlbum] = useState<SubjectAlbumDto>();
  const [images, setImages] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const { user } = useAppSelector(selectUser);
  const [imageIndex, setImageIndex] = useState<number | undefined>();

  useEffect(() => {
    setAlbum(_album);
    if (_album?.files) {
      const images: string[] = _album.files.map((value: PhotoDto): string => value.path || '');
      setImages(images);
    }
  }, [_album]);

  const handleClose = () => {
    onClose();
  };

  const handleModeChange = () => {
    onUpdateMode();
  };

  const handleDownload = async () => {
    try {
      setIsLoading(true);

      images.forEach((path: string) => {
        const filename: string = path.substring(path.lastIndexOf('/') + 1);
        FileSaver.saveAs(path, filename);
      });
    } catch (error: any) {
    } finally {
      setIsLoading(false);
    }
  };

  const handleRemove = () => {
    dispatch(
      modalOpen({
        type: 'confirm',
        message: '앨범을 삭제하시겠습니까?',
        onOk: removeProc,
      }),
    );
  };

  const removeProc = async () => {
    try {
      if (!album?.id) {
        throw new Error('삭제할 대상을 찾을 수 없습니다');
      }

      const { count } = await albumApi.removeById(album.id);

      if (count < 1) {
        throw new Error('삭제에 실패하였습니다');
      }

      onRemoved();
    } catch (error: any) { }
  };

  return (
    <div className="relative w-full h-full">
      <>
        {isLoading && (
          <div className="absolute w-full h-full bg-white/75 rounded-lg flex items-center justify-center">
            <TailSpin
              height="48"
              width="48"
              color="#ff9e18"
              ariaLabel="tail-spin-loading"
              radius="1"
              visible={true}
            />
          </div>
        )}
        {user && (
          <Card
            title={
              <div className="flex justify-between items-center">
                <span>앨범 상세보기</span>
                {(user.role?.id === ROLE.MEM_TEACHER || user.role?.id === ROLE.MEM_MANAGER) && (
                  <div className="flex">
                    <Button
                      plain
                      color="error"
                      className="hover:bg-error/20"
                      onClick={handleRemove}>
                      <span className="font-normal">삭제</span>
                    </Button>
                    <Button
                      plain
                      color="secondary"
                      className="hover:bg-secondary/20"
                      onClick={handleModeChange}>
                      <span className="font-normal">수정</span>
                    </Button>
                  </div>
                )}
              </div>
            }
            actions={
              <div className="w-full flex justify-between space-x-1 p-2">
                <div>
                  {user.role?.id === ROLE.MEM_PARENT && (
                    <Button
                      plain
                      color="primary"
                      className="hover:bg-primary/20"
                      onClick={handleDownload}>
                      활동사진 다운로드
                    </Button>
                  )}
                </div>
                <Button
                  plain
                  color="secondary"
                  className="hover:bg-secondary/20"
                  onClick={handleClose}>
                  확인
                </Button>
              </div>
            }>
            <>
              {album && (
                <>
                  <div className="flex space-x-2 mb-4 w-full">
                    <div className="grid grid-cols-2 w-full">
                      <div className="col-span-2 md:col-span-1">
                        <label className="label">
                          <span className="label-text">수업</span>
                        </label>
                        <div className="input flex items-center w-full text-sm text-gray-500">
                          <span>{album.course?.title}</span>
                        </div>
                      </div>
                      <div className="col-span-2 md:col-span-1">
                        <label className="label">
                          <span className="label-text">주제</span>
                        </label>
                        <div className="input flex items-center w-full text-sm text-gray-500">
                          <span>{album.subject.title}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="w-full overflow-x-hidden py-2 mb-4">
                    <label className="label">
                      <span className="label-text">활동사진</span>
                    </label>
                    <div className="border-y overflow-x-hidden py-2 h-48">
                      <div className="h-full flex space-x-4 overflow-x-auto">
                        {images.map((value, index) => (
                          <div className="h-full min-w-[45%] md:min-w-[25%] w-1/2 md:w-1/4 rounded-lg" key={index}>
                            <img
                              src={value}
                              className="w-full h-full rounded-lg object-cover"
                              alt={`image_${index}`}
                              onClick={() => {
                                setIsFullOpen(true);
                                setImageIndex(index);
                              }}
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="w-full mb-4">
                    <label className="label">
                      <span className="label-text">설명</span>
                    </label>
                    <div className="input input-bordered w-full py-3 text-sm min-h-[86px] overflow-y-auto leading-6">
                      {album.content}
                    </div>
                  </div>
                  <div className="w-full">
                    <label className="label">
                      <span className="label-text">참여한 아이들</span>
                    </label>
                    <div className="input flex items-center w-full text-sm text-gray-500">
                      {album.users.map((user: UserDto) => `#${user.userName || ''}`).join(' ')}
                    </div>
                  </div>
                </>
              )}
            </>
          </Card>
        )}
        {album && images.length > 0 && (
          <AlbumCarousel
            isOpen={isFullOpen}
            title={album?.subject.title || ''}
            setIsOpen={setIsFullOpen}
            images={images}
            startIndex={imageIndex}
          />
        )}
      </>
    </div>
  );
};
