import tw, { styled } from 'twin.macro';

export const Card = styled.div(tw`card bg-white shadow rounded-lg`);
export const CardBody = styled.div(tw`card-body py-4`);
export const CardTitle = styled.h2(tw`card-title text-base`);
export const CardSubTitle = styled.div(tw`text-gray-400 text-xs font-light -mt-2`);
export const CardAction = styled.div(tw`card-actions`);
export const CardTitleBtn = styled.button(tw`
  btn
  btn-xs
  btn-secondary
  rounded-full
  font-light
  text-white
  ml-auto
  hover:ring-2
  hover:ring-offset-2
  focus:ring-2
  focus:ring-offset-2`);
