import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ChevronUpIcon, ChevronLeftIcon } from '@heroicons/react/20/solid';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';

import { useAppDispatch } from '@store/hook';
import { open as modalOpen } from '@store/modal';
import { open as loadingOpen, close as loadingClose } from '@store/loading';

import * as courseApi from '@api/course';
import * as subjectApi from '@api/subject';
import { CopyCourseDto, CourseDto, SubjectDto } from '@dto';
import { Button, CourseSelector, SubjectDetail, SubjectList } from '@components';

export const CourseDetail = () => {
  const { id: _id } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [data, setData] = useState<CourseDto>();
  const [isUpdate, setIsUpdate] = useState<boolean>(false);
  const [isOpenSelector, setIsOpenSelector] = useState<boolean>(false);
  const [copyCourse, setCopyCourse] = useState<CopyCourseDto>();
  const [copySubjects, setCopySubjects] = useState<SubjectDto[]>([]);
  const [subjectId, setSubjectId] = useState<number>();
  const [subjects, setSubjects] = useState<SubjectDto[]>([]);
  const [selectSubjects, setSelectSubjects] = useState<Set<number>>(new Set());

  const schema = yup.object().shape({
    title: yup.string().required('커리큘럼의 이름을 입력해주세요.'),
    content: yup.string().required('커리큘럼의 설명을 입력해주세요.'),
    refCourseId: yup.number(),
    refCourseTitle: yup.string(),
  });

  const {
    register,
    handleSubmit: onSubmit,
    formState: { errors },
    reset,
    setValue,
    getValues,
  } = useForm({
    defaultValues: {
      title: '',
      content: '',
      refCourseId: 0,
      refCourseTitle: '',
    },
    resolver: yupResolver(schema),
  });

  const fetchData = useCallback(async () => {
    try {
      if (!_id) {
        return;
      }

      dispatch(loadingOpen());
      const data: CopyCourseDto = await courseApi.itemById(_id);

      if (!data.refCourse) {
        throw new Error('데이터가 올바르지 않습니다.');
      }

      setValue('title', data.title);
      setValue('content', data.content);
      setValue('refCourseId', data.refCourse.id as number);
      setValue('refCourseTitle', data.refCourse.title as string);

      const newCopySubjects: SubjectDto[] = [...data.subjects]
        .sort((a, b) => (a.order || 0) - (b.order || 0))
        .map((subject, index) => {
          if (subject.order) {
            subject.order = index + 1;
          }
          return subject;
        });

      setCopySubjects(newCopySubjects);
      setCopyCourse(data);

      setData(data);
    } catch (error: any) {
      dispatch(
        modalOpen({
          type: 'alert',
          isOpen: true,
          title: '오류',
          message: error.message
            ? error.message
            : `오류가 발생하였습니다. [오류코드 ${error.status}]`,
          onOk () {
            navigate('/director/course');
          },
        }),
      );
    } finally {
      dispatch(loadingClose());
    }
    // eslint-disable-next-line
  }, [_id]);

  const fetchSubjects = useCallback(
    async () => {
      try {
        if (!isUpdate) {
          return;
        }
        dispatch(loadingOpen());

        if (!data?.refCourse) {
          throw new Error('데이터가 올바르지 않습니다.');
        }

        const subjectData = await subjectApi.list({
          page: 1,
          limit: 100,
          search: { course: { id: data.refCourse.id as number } },
        });

        const newSubjects: SubjectDto[] = [...subjectData.data]
          .sort((a, b) => (a.order || 0) - (b.order || 0))
          .map((subject, index) => {
            if (subject.order) {
              subject.order = index + 1;
            }
            return subject;
          });
        setSubjects(newSubjects);
      } catch (error: any) {
        dispatch(
          modalOpen({
            type: 'alert',
            isOpen: true,
            title: '오류',
            message: error.message
              ? error.message
              : `오류가 발생하였습니다. [오류코드 ${error.status}]`,
            onOk () {
              navigate('/director/course');
            },
          }),
        );
      } finally {
        dispatch(loadingClose());
      }
    },
    // eslint-disable-next-line
    [isUpdate],
  );

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    if (data) {
      fetchSubjects();
    }
  }, [data, fetchSubjects]);

  const handleReset = () => {
    reset({
      title: copyCourse?.title,
      content: copyCourse?.content,
      refCourseId: data?.refCourse?.id as number,
      refCourseTitle: data?.refCourse?.title as string,
    });

    if (copyCourse) {
      const newCopySubjects: SubjectDto[] = [...copyCourse.subjects]
        .sort((a, b) => (a.order || 0) - (b.order || 0))
        .map((subject, index) => {
          if (subject.order) {
            subject.order = index + 1;
          }
          return subject;
        });
      setCopySubjects(newCopySubjects);
    }

    setSubjects([]);
    setSelectSubjects(new Set());
    setSubjectId(undefined);
    setIsUpdate(false);
  };

  const handleSubmit = (data: any) => {
    dispatch(
      modalOpen({
        isOpen: true,
        type: 'confirm',
        message: '수정하시겠습니까?',
        onOk: async () => {
          try {
            if (copySubjects.length < 1) {
              dispatch(
                modalOpen({
                  isOpen: true,
                  type: 'alert',
                  message: '선택된 강의가 없습니다.',
                }),
              );
              return;
            }

            dispatch(loadingOpen());

            if (!copyCourse) {
              throw new Error('대상 커리큘럼을 찾을 수 없습니다');
            }

            const refCourseId: number =
              data.refCourseId === 0 ? copyCourse.refCourse.id : data.refCourseId;

            const c: CopyCourseDto = {
              refCourse: { id: refCourseId },
              title: data.title,
              content: data.content,
              subjects: [...copySubjects],
              org: copyCourse.org,
            };

            const response = await courseApi.update(copyCourse.id as number, c);
            if (!response) {
              throw new Error('수정에 실패하였습니다');
            }

            toast.info('수정에 성공하였습니다');
            fetchData();
            setSubjectId(undefined);
            setIsUpdate(false);
          } catch (error) {
            console.log(error);
          } finally {
            dispatch(loadingClose());
          }
        },
      }),
    );
  };

  const handleToggleMode = () => {
    setSelectSubjects(new Set());
    setSubjectId(undefined);
    setIsUpdate(!isUpdate);
  };

  // 순서 변경을 한다.
  const handleChangeOrder = (subjects: SubjectDto[]) => {
    const newList: SubjectDto[] = [...subjects].map((subject: SubjectDto, index: number) => {
      subject.order = index + 1;
      return subject;
    });
    setCopySubjects(newList);
  };

  // 커리큘럼을 복사한다.
  const handleCopy = () => {
    let order: number = 0;

    if (copySubjects.length > 0) {
      order = copySubjects[copySubjects.length - 1].order || 0;
    }
    // 복사는 이름으로 중복 제거를 한다.
    const newList: SubjectDto[] = [...subjects]
      .filter((subject: SubjectDto) => selectSubjects.has(subject.id))
      .filter((subject: SubjectDto) => {
        return !copySubjects.find(({ title }: SubjectDto) => title === subject.title);
      })
      .map((subject: SubjectDto, index: number) => {
        subject.order = order + index + 1;
        return {
          ...subject,
        };
      });
    setCopySubjects([...copySubjects, ...newList]);
    setSelectSubjects(new Set());
  };

  const handleSelectItem = (ids: Set<number>) => {
    const iterator = ids.values();
    setSubjectId(iterator.next().value);
  };

  // 제공 커리큘럼이 선택이 될 때 value값을 변경한다.
  const handleSelectedCourse = async (id: number, title: string, content: string) => {
    const { data } = await subjectApi.list({ page: 1, limit: 100, search: { course: { id } } });
    setSubjects([...data]);
    // 동시에 모든 값을 넣는다.
    setCopySubjects([...data]);
    // setSelectSubjects(new Set());
    setValue('refCourseId', id);
    setValue('refCourseTitle', title);
    setValue('title', title);
    setValue('content', content);
  };

  return (
    <>
      <div className="w-full lg:h-[calc(100vh-112px)]">
        <h3 className="mb-4 text-3xl font-semibold tracking-wider">커리큘럼</h3>
        <form
          onSubmit={onSubmit(handleSubmit)}
          className="w-full h-[calc(100%-52px)] bg-white p-4 rounded-lg shadow-lg">
          {/* Buttons */}
          <div className="flex items-center justify-between mb-4">
            <Button
              outlined
              color="primary"
              className="hover:!text-white hover:!font-light"
              onClick={() => {
                navigate('/director/course');
              }}>
              목록으로
            </Button>
            <div className="flex space-x-2">
              {!isUpdate && (
                <>
                  {/* <Button color="light" outlined onClick={handleReset}>
                    다운로드
                  </Button> */}
                  <Button color="secondary" onClick={handleToggleMode}>
                    <span className="text-white font-light">수정</span>
                  </Button>
                </>
              )}
              {isUpdate && (
                <>
                  <Button
                    color="error"
                    className="bg-danger border-danger hover:bg-danger/80 hover:border-danger/80 focus-visible:outline-danger"
                    type="reset"
                    onClick={handleReset}>
                    <span className="text-white font-light">취소</span>
                  </Button>
                  <Button color="secondary" className="text-white hover:!text-white" type="submit">
                    <span className="text-white font-light">수정</span>
                  </Button>
                </>
              )}
            </div>
          </div>
          <div className="grid grid-cols-12 gap-4">
            {/* TITLE, CONTENT */}
            <div className={`col-span-12 xl:col-span-3 ${isUpdate ? 'xl:mt-7' : 'xl:mt-14'}`}>
              {/* TITLE */}
              {!isUpdate && <h4 className="mb-4 text-lg font-semibold">{copyCourse?.title}</h4>}
              {isUpdate && (
                <div className="mb-4 form-control">
                  <label className="label">
                    <span className={`label-text ${errors.title && 'text-error'}`}>이름</span>
                  </label>
                  <input
                    type="text"
                    className={`text-sm input input-bordered ${errors.title && 'input-error'}`}
                    placeholder="커리큘럼 이름을 입력해주세요"
                    {...register('title')}
                  />
                  <span className="text-xs mt-2 text-danger tracking-wide">
                    {errors.title?.message}
                  </span>
                </div>
              )}
              {/* 복사된 강좌 수 */}
              <div className="px-2 mb-4 text-sm">총 {copySubjects.length}강</div>
              {/* 설명 */}
              {!isUpdate && (
                <div className="form-control">
                  <div className="mb-4 text-sm textarea textarea-bordered leading-6 tracking-wide text-gray-500 whitespace-pre-wrap h-64 max-h-64 overflow-y-auto">
                    {copyCourse?.content}
                  </div>
                </div>
              )}
              {isUpdate && (
                <div className="form-control">
                  <label className="label">
                    <span className={`label-text ${errors.content && 'text-error'}`}>설명</span>
                  </label>
                  <textarea
                    className={`h-full py-2 text-sm resize-none textarea textarea-bordered ${errors.content && 'textarea-error'
                      }`}
                    rows={12}
                    placeholder="설명을 입력해주세요"
                    {...register('content')}
                  />
                  <span className="text-xs mt-2 text-danger tracking-wide">
                    {errors.content?.message}
                  </span>
                </div>
              )}
            </div>
            {/* 복사된 강좌 목록 */}
            <div className="col-span-12 xl:col-span-4 xl:h-[calc(100vh-300px)] xl:max-h-[calc(100vh-300px)] lg:mt-[4rem]">
              <div
                className={`relative overflow-x-hidden overflow-y-auto h-[calc(100vh-312px)] lg:h-[calc(100vh-312px)] rounded-lg border
                `}>
                <SubjectList
                  data={copySubjects}
                  useDnD={isUpdate}
                  header={isUpdate}
                  useRemove={isUpdate}
                  onChange={handleChangeOrder}
                  selectType={isUpdate ? 'multi' : 'single'}
                  onSelect={handleSelectItem}
                />
              </div>
            </div>
            {/* 복사 버튼 (업데이트 모드일 때만 동작한다.) */}
            {isUpdate && (
              <div className="col-span-12 xl:col-span-1 xl:h-[calc(100vh-300px)] xl:max-h-[calc(100vh-300px)]">
                <div className="flex items-center justify-center w-full h-full xl:mt-12">
                  <Button
                    color="primary"
                    className="text-white hover:!text-white"
                    onClick={handleCopy}>
                    <ChevronUpIcon className="w-6 h-6 -ml-1 fill-white xl:hidden" />
                    <ChevronLeftIcon className="hidden w-6 h-6 -ml-1 fill-white xl:block" />
                    <span>복사</span>
                  </Button>
                </div>
              </div>
            )}
            <div
              className={`col-span-12 xl:h-[calc(100vh-300px)] xl:max-h-[calc(100vh-300px)] ${!isUpdate ? 'xl:col-span-5 lg:mt-[4rem]' : 'xl:col-span-4'
                }`}>
              {!isUpdate && (
                <>
                  {!subjectId && (
                    <div className="flex items-center justify-center w-full h-full">
                      <p className="text-sm text-gray-500">확인하실 회차를 선택해주세요</p>
                    </div>
                  )}
                  {subjectId && <SubjectDetail id={subjectId} />}
                </>
              )}
              {/* 원본 목록 */}
              {isUpdate && (
                <>
                  <div className="mb-4 form-control">
                    <input
                      type="text"
                      className="text-sm cursor-pointer select select-bordered font-normal"
                      placeholder="커리큘럼을 선택해주세요"
                      readOnly
                      {...register('refCourseTitle')}
                    // onClick={() => setIsOpenSelector(true)}
                    />
                  </div>
                  <div className="relative overflow-auto h-[calc(100vh-312px)] lg:h-[calc(100vh-312px)] rounded-lg border">
                    <SubjectList
                      data={subjects}
                      header
                      selectType={'multi'}
                      onChange={(data) => {
                        setSubjects([...data]);
                        setSelectSubjects(new Set());
                      }}
                      onSelect={(data) => {
                        setSelectSubjects(new Set(data));
                      }}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </form>
      </div>
      <CourseSelector
        mode="creamo"
        isOpen={isOpenSelector}
        setIsOpen={setIsOpenSelector}
        onSelect={handleSelectedCourse}
        defaultId={getValues('refCourseId')}
      />
    </>
  );
};;;
