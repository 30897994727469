import { createBrowserRouter } from 'react-router-dom';
import {
  Signin,
  Signup,
  Agreement,
  FindPassword,
  Layout,
  Dashboard,
  Album,
  Notice,
  CourseList,
  CourseCreate,
  CourseDetail,
  Attendance,
  Survey,
  TeacherList,
  Org,
  Account,
  Lecture,
  Preschool,
  RatingCreateList,
  Invite,
  Child,
  ParentList,
  Error,
  ChangePassword,
  SelectOrg,
} from '@pages';
import { Rating } from '@pages/parent/Rating';
import App from './App';
import { RatingList } from '@pages/Rating/List';
import { NotFound } from '@pages';

export const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    children: [
      {
        path: 'signin',
        element: <Signin />,
      },
      {
        path: 'signup/:hash',
        element: <Signup />,
      },
      {
        path: 'agreement/:hash',
        element: <Agreement />,
      },
      {
        path: 'findPassword',
        element: <FindPassword />,
      },
      {
        path: 'changepassword/:hash',
        element: <ChangePassword />,
      },
      {
        path: 'selectorg',
        element: <SelectOrg></SelectOrg>,
      },
      {
        path: 'parents',
        element: <Layout />,
        children: [
          {
            path: '',
            element: <Dashboard />,
          },
          {
            path: 'rating',
            element: <Rating />,
          },
          {
            path: 'album',
            element: <Album />,
          },
          {
            path: 'notice',
            element: <Notice />,
          },
          {
            path: 'survey',
            element: <Survey />,
          },
          {
            path: 'account',
            element: <Account />,
          },
        ],
      },
      {
        path: 'teacher',
        element: <Layout />,
        children: [
          {
            path: '',
            element: <Dashboard />,
          },
          {
            path: 'attendance',
            element: <Attendance />,
          },
          {
            path: 'ratingcreate',
            element: <RatingCreateList />,
          },
          {
            path: 'rating',
            element: <RatingList />,
          },
          {
            path: 'album',
            element: <Album />,
          },
          {
            path: 'notice',
            element: <Notice />,
          },
          {
            path: 'survey',
            element: <Survey />,
          },
          {
            path: 'account',
            element: <Account />,
          }, {
            path: 'lecture',
            element: <Lecture />,
          }, {
            path: 'child',
            element: <Child />,
          },
        ],
      },
      {
        path: '/director',
        element: <Layout />,
        children: [
          {
            path: '',
            element: <Dashboard />,
          },
          {
            path: 'course',
            children: [
              {
                path: '',
                element: <CourseList />,
              },
              {
                path: 'create',
                element: <CourseCreate />,
              },
              {
                path: ':id',
                element: <CourseDetail />,
              },
            ],
          },
          {
            path: 'teacher',
            element: <TeacherList />,
          },
          {
            path: 'org',
            children: [
              {
                path: '',
                element: <Org />,
              },
            ],
          },
          {
            path: 'album',
            element: <Album />,
          },
          {
            path: 'lecture',
            element: <Lecture />,
          },
          {
            path: 'preschool',
            element: <Preschool />,
          },
          {
            path: 'notice',
            element: <Notice />,
          },
          {
            path: 'survey',
            element: <Survey />,
          },
          {
            path: 'invite',
            element: <Invite />,
          },
          {
            path: 'rating',
            element: <RatingList />,
          },
          {
            path: 'child',
            element: <Child />,
          },
          {
            path: 'parent',
            element: <ParentList />,
          },
          {
            path: 'account',
            element: <Account />,
          },
        ],
      },
    ],
  },
  {
    path: 'error',
    element: <Error />,
  },
  {
    path: '*',
    element: <NotFound />,
  },
]);
