import React from 'react';
import { useEffect } from 'react';
import { TailSpin } from 'react-loader-spinner';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Modal } from '@components';
import { useAppDispatch, useAppSelector } from '@store/hook';
import { selectUser, setUser } from '@store/user';
import { selectLoading } from '@store/loading';
import { me } from '@api/auth';
import { ROLE, TOKEN_KEY } from '@common';
import { UserDto } from '@dto';

function App (): JSX.Element {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const loadingSelector = useAppSelector(selectLoading);
  const { user } = useAppSelector(selectUser);

  useEffect(() => {
    async function fetchData () {
      try {
        const strToken: string | null = localStorage.getItem(TOKEN_KEY);

        if (!strToken) {
          throw new Error('인증이 필요합니다');
        }

        let u: UserDto | undefined;

        if (!user) {
          // store에 저장된 user가 없다면 me에 저장시킨다.
          const user = await me();
          u = { ...user };
          dispatch(setUser({ ...user }));
        } else {
          u = { ...user };
        }
        // console.log('App.tsx fetchData', u);
        const id: number | undefined = u?.role?.id;

        if (!id) {
          throw new Error('접근 권한이 없습니다.');
        }

        if (/selectorg/.test(pathname.slice(1))) {
          // if (id === ROLE.MEM_ADMIN || id === ROLE.MEM_PARENT) {
          return;
          // }
        }

        switch (id) {
          case ROLE.OP_MANAGER:
          case ROLE.OP_ADMIN:
          case ROLE.MEM_MANAGER:
            if (!/director/.test(pathname.slice(1))) {
              navigate('/director');
            }
            break;
          case ROLE.MEM_TEACHER:
            if (!/teacher/.test(pathname.slice(1))) {
              navigate('/teacher');
            }
            break;
          case ROLE.MEM_PARENT:
            if (!/parents/.test(pathname.slice(1))) {
              navigate('/parents');
            }
            break;
          default:
            navigate('/error');
        }
      } catch (error: any) {
        console.log('ERROR!!', error);
        navigate(`/signin?e=${Math.random().toString(36).substring(2, 12)}`);
      }
    }

    if (
      !/\/signin/.test(pathname) &&
      !/\/signup/.test(pathname) &&
      !/\/agreement/.test(pathname) &&
      !/\/findpassword/.test(pathname) &&
      !/\/changepassword/.test(pathname)
    ) {
      fetchData();
    }
  }, [pathname, dispatch, navigate, user]);

  return (
    <>
      <div className="w-full h-[100vh] max-h-screen overflow-y-auto">
        <Outlet></Outlet>
      </div>
      <Modal />
      {/* LOADING */}
      {loadingSelector.isLoading && (
        <div className="relative z-[2020]">
          <div className="fixed inset-0 bg-white/50" />
          <TailSpin
            height="80"
            width="80"
            color="#ff9e18"
            ariaLabel="tail-spin-loading"
            radius="1"
            wrapperClass="fixed inset-0 flex items-center justify-center"
            visible={true}
          />
        </div>
      )}
      {/* Toast */}
      <ToastContainer position="top-right" theme="dark" />
    </>
  );
}

export default App;
