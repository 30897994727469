import React, { useEffect, useState } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { EllipsisVerticalIcon, ChevronDoubleRightIcon } from '@heroicons/react/20/solid';

interface SubjectItemProp {
  id: number;
  title: string;
  useDnD: boolean;
  selected: boolean;
  index: number;
  onSelect?: (id: number) => void;
}

export const SubjectItem: React.FC<SubjectItemProp> = ({
  id,
  title,
  index,
  useDnD,
  selected,
  onSelect,
}) => {
  const [isSelected, setIsSelected] = useState<boolean>(selected);
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  useEffect(() => {
    setIsSelected(selected);
  }, [selected]);

  const handleClick = () => {
    if (onSelect) {
      onSelect(id);
    }
  };

  return (
    <div
      style={style}
      className={`group h-16 flex border-b px-4 text-sm items-center justify-between bg-white cursor-pointer hover:bg-primary/75 hover:text-white ${isSelected && 'text-white bg-primary/75'
        }`}
      onClick={handleClick}>
      <div className="flex items-center">
        {useDnD && (
          <span ref={setNodeRef} {...listeners} {...attributes}>
            <EllipsisVerticalIcon
              className={`w-6 h-4 mr-2 group-hover:fill-white ${isSelected ? 'fill-white' : 'fill-gray-500'
                }`}
            />
          </span>
        )}
        <span className="truncate w-96">{title} [{index + 1}]</span>
      </div>
      <ChevronDoubleRightIcon
        className={`w-6 h-4 mr-2 group-hover:fill-white ${isSelected ? 'fill-white' : 'fill-gray-500'
          }`}
      />
    </div>
  );
};
