import axiosInstance from './index';
import { SearchDto, CopyCourseDto, CourseDto, CreateCourseOrgDto } from '@dto';

/**
 * 커리큘럼의 목록을 가져온다
 * @param search
 * @returns
 */
export const list = (
  search: SearchDto,
): Promise<{
  count: number;
  hasNextPage: boolean;
  totalPage: number;
  data: CourseDto[];
}> => axiosInstance.post('/course/list', search);

/**
 * ID에 대한 커리큘럼을 가져온다.
 * @param id
 * @returns
 */
export const itemById = async (id: number | string) => {
  const response: CopyCourseDto = await axiosInstance.get(`/course/${id}`);
  return response;
};

/**
 * 새로운 커리큘럼을 복사하여 생성한다.
 * @param course
 * @returns
 */
export const copy = async (course: CopyCourseDto) => {
  const response = await axiosInstance.post('/course/copy', course);

  return response;
};

/**
 * 생성했던 커리큘럼을 업데이트 한다.
 * @param course
 */
export const update = async (id: number | string, course: CopyCourseDto) => {
  const response = await axiosInstance.patch(`/course/${id}`, course);
  return response;
};

/**
 * 조직에서 사용할 수 있는 코스 목록
 * @param search
 * @returns
 */
export const listByOrg = (search: SearchDto): Promise<{ count: number; data: [] }> =>
  axiosInstance.post('/course/list', search);

/**
 * 새로운 수업을 만든다.
 * @param courseOrg
 * @returns
 */
export const newClass = (courseOrg: CreateCourseOrgDto): Promise<any> =>
  axiosInstance.post('/course/newClass', courseOrg);

/**
 * 코스 정보를 삭제한다
 * @param id
 * @returns
 */
export const removeById = (id: number): Promise<any> =>
  axiosInstance.delete(`/course/${id}`);

/**
 * 코스 정보를 수정한다.
 * @param id
 * @param course
 * @returns
 */
export const updateById = (id: number, course: CourseDto): Promise<any> =>
  axiosInstance.patch(`/course/${id}`, course);

/**
 * course의 상세를 불러온다
 * @param id
 * @returns
 */
export const getById = (id: number): Promise<CourseDto> => axiosInstance.get(`/course/${id}`);