import React, { useState, useEffect, useCallback } from 'react';
import { Dialog } from '@headlessui/react';
import { TailSpin } from 'react-loader-spinner';
import { format } from 'date-fns';

import { Card } from '../Card';
import { Button } from '../Button';
import { Chip } from '../Chip';
import { ItemGroupDto, SurveyDto } from '@dto/SurveyDto';

import * as voteApi from '@api/vote';

import { useAppDispatch } from '@store/hook';
import { open as modalOpen } from '@store/modal';
import { toast } from 'react-toastify';

interface SurveyItemProp extends ItemGroupDto {
  onItemChange: (id: number, value: number) => void;
  voteYn?: string;
}
/**
 * 아이템
 * 선택지들....
 * @param
 * @returns
 */
const SurveyItem: React.FC<SurveyItemProp> = ({
  id,
  content,
  items,
  submitItem: _submitItem,
  voteYn,
  onItemChange,
}: SurveyItemProp) => {
  const [checkedId, setCheckedId] = useState<number | undefined>();

  const handleChange = (e: any) => {
    onItemChange(e.target.name, e.target.value);
  };

  useEffect(() => {
    if (!voteYn) {
      setCheckedId(_submitItem?.id);
    } else {
      setCheckedId(_submitItem?.item?.id);
    }
  }, [_submitItem, voteYn]);

  return (
    <div>
      <h5 className="text-sm">{content}</h5>
      {items?.map((item) => (
        <div className="flex items-center px-4 pt-2 space-x-2" key={item.id}>
          <input
            type="radio"
            className="form-control radio radio-secondary radio-sm"
            name={`${id}`}
            id={`q_${id}_${item.id}`}
            value={item.id}
            onChange={handleChange}
            disabled={voteYn === 'Y' ? true : false}
            checked={item.id === checkedId}
          />
          <label className="text-sm cursor-pointer" htmlFor={`q_${id}_${item.id}`}>
            {item.content}
          </label>
        </div>
      ))}
    </div>
  );
};

interface SurveyDetailProp {
  readonly id: number;
  readonly isOpen: boolean;
  readonly setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  readonly onClose?: () => void;
  readonly onSubmit: () => void;
}

/**
 *
 * 전체
 * @param param0
 * @returns
 */
export const SurveyDetail: React.FC<SurveyDetailProp> = ({
  id,
  isOpen,
  setIsOpen,
  onClose,
  onSubmit,
}) => {
  const [data, setData] = useState<ItemGroupDto[]>();
  const [obj, setObj] = useState<SurveyDto | undefined>();
  const [startDate, setStart] = useState<string | undefined>();
  const [endDate, setEnd] = useState<string | undefined>();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const dispatch = useAppDispatch();

  const fetchData = useCallback(
    async () => {
      try {
        if (!isOpen) {
          return;
        }

        setIsLoading(true);

        if (id < 1) {
          throw new Error('대상이 잘못되었습니다');
        }
        const response: any = await voteApi.detail(id);

        setObj(response);
        setData(response.itemGroups);

        setStart(format(new Date(response?.startDate || '1970-01-01'), 'yyyy-MM-dd'));
        setEnd(format(new Date(response?.endDate || '2030-12-31'), 'yyyy-MM-dd'));
      } catch (error: any) {
        dispatch(
          modalOpen({
            type: 'alert',
            isOpen: true,
            title: '오류',
            message: error.message
              ? error.message
              : `오류가 발생하였습니다. [오류코드 ${error.status}]`,
            onOk() {
              setIsOpen(false);
            },
          }),
        );
      } finally {
        setIsLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [id, isOpen],
  );

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleClose = () => {
    if (onClose) {
      onClose();
    }
    setIsOpen(false);
  };

  const onItemChange = (selGroup: number, selItem: number) => {
    if (!obj) {
      return;
    }

    const newObj = Object.assign({}, obj);
    const itemGroups: ItemGroupDto[] = newObj.itemGroups.map((group: ItemGroupDto) => {
      if (group.id === +selGroup) {
        group.submitItem = { id: +selItem };
      }
      return group;
    }) as ItemGroupDto[];

    newObj.itemGroups = [...itemGroups];
    setObj(newObj);
  };

  /**
   * 완료
   */
  const handleSubmit = async () => {
    dispatch(
      modalOpen({
        type: 'confirm',
        message: '설문에 참여하시겠습니까?',
        onOk: submitProc,
      }),
    );
  };

  const submitProc = async () => {
    let valid: boolean = true;
    obj?.itemGroups.forEach((group) => {
      if (!group.submitItem) {
        valid = false;
      }
    });
    if (!valid || !obj) {
      dispatch(
        modalOpen({
          type: 'alert',
          message: '모든 설문이 입력되지 않았습니다',
        }),
      );
      return;
    } else {
      try {
        setIsLoading(true);
        await voteApi.submitItems(obj);
        toast.info('설문조사가 완료되었습니다');
        setIsOpen(false);
        onSubmit();
      } catch (error: any) {
        dispatch(
          modalOpen({
            type: 'alert',
            isOpen: true,
            title: '오류',
            message: error.message
              ? error.message
              : `오류가 발생하였습니다. [오류코드 ${error.status}]`,
            onOk() {
              setIsOpen(false);
            },
          }),
        );
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <Dialog open={isOpen} onClose={() => {}} className="relative z-[1010]">
      {/* BACKDROP */}
      <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
      <div className="fixed inset-0 flex items-center justify-center min-h-full px-4">
        <Dialog.Panel className="relative w-full shadow lg:max-w-2xl xl:max-w-4xl">
          {isLoading && (
            <div className="absolute w-full h-full bg-white/75 rounded-lg flex items-center justify-center">
              <TailSpin
                height="48"
                width="48"
                color="#ff9e18"
                ariaLabel="tail-spin-loading"
                radius="1"
                visible={true}
              />
            </div>
          )}
          <Card
            title={
              <>
                <Chip label className={`${obj?.voteYn === 'Y' ? 'bg-blue-500' : 'bg-green-500'}`}>
                  {obj?.voteYn === 'Y' ? '참여완료' : '참여하기'}
                </Chip>
                <div>{obj?.title}</div>
              </>
            }
            subTitle={
              <span className="text-xs">{'설문조사 기간 : ' + startDate + ' ~ ' + endDate}</span>
            }
            actions={
              <div>
                <Button plain color="light" onClick={handleClose}>
                  닫기
                </Button>
                {obj?.voteYn !== 'Y' && (
                  <Button plain color="secondary" onClick={handleSubmit}>
                    제출하기
                  </Button>
                )}
              </div>
            }>
            <>
              <div className="px-4 py-4 mt-2 space-y-8 overflow-y-auto h-80 border-y">
                <div className="whitespace-pre-wrap w-full">{obj?.content}</div>
                {data?.map((value) => (
                  <SurveyItem
                    id={value.id}
                    onItemChange={onItemChange}
                    content={value.content}
                    items={value.items}
                    submitItem={value.submitItem}
                    key={value.id}
                    voteYn={obj?.voteYn}
                  />
                ))}
              </div>
            </>
          </Card>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
};
