import React, { useCallback, useEffect, useState } from 'react';
import { format } from 'date-fns';

import * as subjectApi from '@api/subject';

import { RatingGraph } from '@components/common';
import { ChildInfo } from '@components/parent';
import { SubjectSubmitDto } from '@dto/SubjectSubmitDto';
import { RatingAlbum } from '@components/RatingAlbum';
import { SubjectSubmitEvalItemDto } from '@dto/SubjectSubmitEvalItemDto';
import { useAppDispatch } from '@store/hook';
import { open as modalOpen } from '@store/modal';

export interface RatingDetailProp {
  submitId?: number | undefined;
  onLoading?: (isLoading: boolean) => void;
}

export const RatingDetail: React.FC<RatingDetailProp> = ({
  submitId,
  onLoading,
}: RatingDetailProp) => {
  const [submit, setSubmit] = useState<SubjectSubmitDto>();
  const [submitVals, setSubmitVals] = useState<any>([]);
  const [maxValue, setMaxValue] = useState<number | 'auto' | undefined>('auto');

  const dispatch = useAppDispatch();

  const fetchData = useCallback(
    async () => {
      try {
        if (!submitId) {
          return;
        }

        // setLoading(true);
        // if (onLoading) {
        //   onLoading(true);
        // }

        const submit = await subjectApi.getSubmitById(submitId + '');

        if (!submit) {
          return;
        }

        setSubmit(submit);
        setSubmitVal([...submit.evalSubmits]);
      } catch (error: any) {
        dispatch(
          modalOpen({
            type: 'alert',
            isOpen: true,
            title: '오류',
            message: error.message
              ? error.message
              : `오류가 발생하였습니다. [오류코드 ${error.status}]`,
          }),
        );
      } finally {
        // setLoading(false);
        if (onLoading) {
          onLoading(false);
        }
      }
    },
    // eslint-disable-next-line
    [submitId],
  );

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const setSubmitVal = (evalSubmits: SubjectSubmitEvalItemDto[]) => {
    const evalMaxs: number[] | undefined = [...evalSubmits].map(
      (item: SubjectSubmitEvalItemDto) => item.eval.evalMax || 1,
    );

    if (evalMaxs) {
      const maxValue: number = Math.max(...evalMaxs);
      setMaxValue(maxValue);
    } else {
      setMaxValue('auto');
    }

    const newList: any = [...evalSubmits].map((evalItem: SubjectSubmitEvalItemDto) => {
      return {
        점수: Number(evalItem.submitVal),
        제목: evalItem.eval.title || '',
      };
    });
    setSubmitVals(newList);
  };

  return (
    <div className="relative w-full grid grid-cols-12 gap-4 px-4 lg:mt-32 h-full">
      {submit && (
        <>
          <div className="col-span-12 lg:col-span-6">
            <RatingAlbum
              isEdit={false}
              label={`${submit?.targetUser.userName}이(가) 만든 작품`}
              files={submit.files}
              onChange={() => { }}
            />
          </div>
          <div className="col-span-12 lg:col-span-6 mt-6 lg:mt-0">
            <label className="label">
              <span className="label-text font-medium">평가 점수</span>
            </label>
            <div className="w-full h-80 mx-auto my-auto">
              <RatingGraph data={submitVals} keys={['점수']} indexBy={'제목'} maxValue={maxValue} />
            </div>
          </div>
          <div className="col-span-12 mt-8">
            <label className="label">
              <span className="label-text font-medium">선생님의 한마디</span>
            </label>
            {submit.content.trim().length === 0 && (
              <p className="text-sm text-gray-500 ml-1 textarea textarea-bordered h-28">
                등록된 선생님의 한마디가 없습니다.
              </p>
            )}
            <div className="mb-4 last:mb-0">
              <ChildInfo
                name={submit.adminUser.userName as string}
                orgName={format(new Date(submit.createdAt || ''), 'yyyy.MM.dd')}
                profile={submit.adminUser.photo?.path}
              />
              <p className="text-sm text-gray-500 leading-7 textarea textarea-bordered h-28">
                {submit.content}
              </p>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
