import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { CourseAttendance, ChildAttendance, CourseSelect } from '@components';
import { CourseDto, SearchDto, SubjectDto, UserDto } from '@dto';
import { COURSE_TYPE, USER_STATE } from '@common';

import * as courseApi from '@api/course';

import { useAppDispatch } from '@store/hook';
import { open as modalOpen } from '@store/modal';
import { open as loadingOpen, close as loadingClose } from '@store/loading';

export const Attendance = () => {
  const [courseId, setCourseId] = useState<number | undefined>();
  const [members, setMembers] = useState<UserDto[]>([]);
  const [subjects, setSubjects] = useState<SubjectDto[]>([]);
  const [subject, setSubject] = useState<SubjectDto | undefined>();
  const [usedQuery, setUsedQuery] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const cId = searchParams.get('cId');

  const handleChangeCourse = (id: number, members?: UserDto[], subjects?: SubjectDto[]) => {
    setCourseId(id);

    if (members) {
      const newMembers: UserDto[] = [...members].filter(
        (member: UserDto) => member.status === USER_STATE.ACTIVATE,
      );
      setMembers(newMembers);
    } else {
      setMembers([]);
    }

    if (subjects) {
      const newSubjects: SubjectDto[] = [...subjects].sort(
        (a, b) => (a.order || 0) - (b.order || 0),
      );
      setSubjects(newSubjects);

      const id = searchParams.get('id');
      if (id && !usedQuery) {
        const subject = newSubjects.find((sd: SubjectDto) => sd.id === Number(id));
        setSubject(subject);
        setUsedQuery(true);
      } else {
        setSubject(undefined);
      }
    } else {
      setSubjects([]);
      setSubject(undefined);
    }
  };

  const handleChangeSubject = (id: number) => {
    const subject: SubjectDto | undefined = subjects.find(
      (subject: SubjectDto) => subject.id === id,
    );

    if (subject) {
      setSubject({ ...subject });
    }
  };

  const handleCompleted = async () => {
    try {
      dispatch(loadingOpen());
      const search: SearchDto = {
        page: 1,
        limit: 100,
        search: {
          type: COURSE_TYPE.SUBJECT,
          id: courseId,
        },
      };

      const { data } = await courseApi.list(search);
      const course: CourseDto | undefined = data.find(
        (course: CourseDto) => course.id === courseId,
      );

      if (course) {
        if (course.subjects) {
          setSubjects([...course.subjects]);
        }

        const sd: SubjectDto | undefined = course.subjects?.find(
          (s: SubjectDto) => s.id === subject?.id,
        );

        if (sd) {
          setSubject({ ...sd });
        }
      }
      toast.info(`출석 체크를 완료하였습니다`);
    } catch (error: any) {
      console.log(error)
      dispatch(
        modalOpen({
          type: 'alert',
          isOpen: true,
          title: '오류',
          message: error.message
            ? error.message
            : `오류가 발생하였습니다. [오류코드 ${error.status}]`,
        }),
      );
    } finally {
      dispatch(loadingClose());
    }
  };

  return (
    <div className="w-full">
      <h3 className="text-3xl font-semibold tracking-wider mb-4">출석부</h3>
      <div className="grid grid-cols-2 gap-4 bg-white p-4 rounded-lg shadow-lg">
        <div className="col-span-2 lg:col-span-1">
          <div className="flex space-x-4 mb-4">
            {/* 수업 선택 */}
            <CourseSelect
              value={courseId}
              onChange={handleChangeCourse}
              isTeacher
              className="mt-3"
              selectClassName="min-h-[2.25rem] h-[2.25rem] max-h-[2.25rem]"
              defaultValue={cId === null ? undefined : Number(cId)}
            />
          </div>
          {/* 강좌 선택 */}
          <CourseAttendance subject={subject} subjects={subjects} onSelect={handleChangeSubject} />
        </div>
        <div className="col-span-2 lg:col-span-1">
          {/* 출석부 */}
          <ChildAttendance subject={subject} members={members} onCompleted={handleCompleted} />
        </div>
      </div>
    </div>
  );
};
