import React from 'react';

export interface CardProp {
  readonly id?: string | null;
  readonly className?: string | null;
  readonly title?: JSX.Element | JSX.Element[] | string | null;
  readonly subTitle?: JSX.Element | JSX.Element[] | string | null;
  readonly children?: JSX.Element | JSX.Element[] | string | null;
  readonly actions?: JSX.Element | JSX.Element[] | null;
}

export const Card: React.FC<CardProp> = ({
  id,
  className,
  title,
  subTitle,
  children,
  actions,
}: CardProp) => {
  return (
    <div className={`w-full rounded-xl bg-white shadow-lg ${className}`}>
      <div className="p-4 text-xl tracking-[0.0125em] font-medium">{title}</div>
      {subTitle && (
        <div className="px-4 pb-4 -mt-4 text-sm text-gray-500 tracking-[0.007em] leading-5">
          {subTitle}
        </div>
      )}
      <div className="px-4 pb-4 text-sm text-gray-500 leading-5 tracking-[0.007em]">{children}</div>
      {actions && <div className="flex items-center p-2 justify-end">{actions}</div>}
    </div>
  );
};
