import React, { useCallback, useEffect, useState } from 'react';
import { Dialog } from '@headlessui/react';
import * as courseApi from '@api/course';
import { ChevronRightIcon } from '@heroicons/react/24/solid';
import { Card, Button, SubjectDetailModal } from '@components';
import { CourseDto } from '@dto/CourseDto';
import { COURSE_TYPE } from '@common/code';
import { SubjectDto } from '@dto/Subject';
import { SearchDto } from '@dto';

interface CourseSelectorProp {
  readonly mode: 'custom' | 'creamo';
  readonly isOpen: boolean;
  readonly setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  readonly defaultId?: number;
  readonly onSelect?: (id: number, title: string, content: string, subjects?: SubjectDto[]) => void;
  readonly onCancel?: () => void;
}

interface CourseItemProp {
  readonly id: number;
  readonly title: string;
  readonly subjectCount?: number;
  readonly onClick: (id: number) => void;
  readonly selected?: boolean;
}

interface SubjectItemProp {
  readonly id: number;
  readonly title: string;
  readonly onClick: (id: number) => void;
}

/**
 * 커리큘럼 목록 아이템
 * @param
 * @returns
 */
const CourseItem: React.FC<CourseItemProp> = ({
  id,
  title,
  subjectCount = 0,
  onClick,
  selected,
}: CourseItemProp) => {
  return (
    <div
      className={`h-16 w-full px-4 border-b flex items-center justify-between border-slate-300 last:border-transparent
      hover:bg-primary/75 hover:text-white cursor-pointer ${selected && 'bg-primary/75 text-white'
        }`}
      onClick={() => onClick(id)}>
      <div className="w-[calc(100%-1rem)] py-2">
        <div className="w-full truncate text-sm mb-1">{title}</div>
        <div className="w-full truncate text-xs">{subjectCount}강</div>
      </div>
      <ChevronRightIcon className="w-4 h-4" />
    </div>
  );
};

/**
 * 선택한 커리큘럼의 목록 아이템
 * @returns
 */
const SubjectItem: React.FC<SubjectItemProp> = ({ id, title, onClick }: SubjectItemProp) => {
  const handleClick = () => {
    onClick(id);
  };

  return (
    <>
      <div
        className={`h-12 w-full px-4 border-b flex items-center justify-between border-slate-300 last:border-transparent cursor-pointer`}
        onClick={handleClick}>
        <div className="w-full truncate py-2 text-sm">{title}</div>
      </div>
    </>
  );
};

export const CourseSelector: React.FC<CourseSelectorProp> = ({
  mode,
  isOpen,
  setIsOpen,
  defaultId = -1,
  onSelect,
  onCancel,
}: CourseSelectorProp) => {
  const [itemId, setItemId] = useState<number>(defaultId);
  const [subjectId, setSubjectId] = useState<number>(-1);
  const [subjectOpen, setSubjectOpen] = useState<boolean>(false);
  const [courseList, setCourseList] = useState<CourseDto[]>([]);
  const [subjectList, setSubjectList] = useState<SubjectDto[]>([]);
  const [content, setContent] = useState<string>('');

  const fetchData = useCallback(async () => {
    try {
      const search: SearchDto = {
        page: 1,
        limit: 100,
      };

      search.search = {
        type: mode === 'creamo' ? COURSE_TYPE.MANAGER : COURSE_TYPE.COMPANY,
      };

      const { data } = await courseApi.list(search);
      setCourseList([...data]);
    } catch (error) {
    } finally {
    }
  }, [mode]);

  useEffect(() => {
    if (isOpen) {
      fetchData();
    } else {
      setCourseList([]);
    }
  }, [isOpen, fetchData]);

  useEffect(() => {
    setItemId(defaultId);
  }, [defaultId]);

  /**
   * 아이템 선택
   */
  const handleSelectItem = (id: number) => {
    setItemId(id);
    const course = courseList.find((value) => value.id === id);
    if (course) {
      setContent(course.content || '');

      if (course.subjects) {
        const newSubjects = [...course.subjects].sort((a, b) => (a.order || 0) - (b.order || 0));
        setSubjectList(newSubjects);
      } else {
        setSubjectList([]);
      }
    }
  };

  const handleSelectSubject = (id: number) => {
    setSubjectId(id);
    setSubjectOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleSubmit = () => {
    if (onSelect) {
      const course = courseList.find((value) => value.id === itemId);
      onSelect(itemId, course?.title || '', course?.content || '', course?.subjects || []);
    }
    setIsOpen(false);
  };

  return (
    <>
      <Dialog as="div" className="relative z-[1010]" open={isOpen} onClose={() => { }}>
        {/* BACKDROP */}
        <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
        <div className="fixed inset-0 flex min-h-full items-center justify-center">
          <Dialog.Panel className="w-full md:w-[45rem]">
            <Card
              title={<>{mode === 'creamo' && 'CRAEMO'} 커리큘럼 선택</>}
              actions={
                <>
                  <Button plain onClick={handleClose}>
                    취소
                  </Button>
                  <Button plain color="secondary" onClick={handleSubmit}>
                    확인
                  </Button>
                </>
              }>
              <div className="flex flex-col space-y-4 md:flex-row md:space-x-4 md:space-y-0 mb-4">
                <div className="h-64 md:h-80 flex flex-col overflow-y-auto w-full border border-slate-300 rounded-lg">
                  {courseList.map((course, index) => (
                    <CourseItem
                      key={index}
                      id={course.id || -1}
                      title={course.title || ''}
                      subjectCount={course.subjects?.length}
                      onClick={handleSelectItem}
                      selected={course.id === itemId}
                    />
                  ))}
                </div>
                <div className="h-64 md:h-80 flex flex-col overflow-y-auto w-full border border-slate-300 rounded-lg">
                  {itemId > -1 && (
                    <>
                      <div className="h-24 min-h-[6rem] overflow-y-auto p-4 border-b border-slate-300 text-sm whitespace-pre-wrap">
                        {content}
                      </div>
                      <div className="h-[100%-6rem] overflow-y-auto">
                        {subjectList.map((subject) => (
                          <SubjectItem
                            key={subject.id}
                            id={subject.id}
                            title={subject.title as string}
                            onClick={handleSelectSubject}
                          />
                        ))}
                      </div>
                    </>
                  )}
                  {itemId < 0 && (
                    <div className="mx-auto my-auto text-gray-400 tracking-wide">
                      커리큘럼을 선택해주세요
                    </div>
                  )}
                </div>
              </div>
            </Card>
          </Dialog.Panel>
        </div>
      </Dialog>
      <SubjectDetailModal id={subjectId} isOpen={subjectOpen} setIsOpen={setSubjectOpen} />
    </>
  );
};
