import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import * as orgApi from '@api/org';
import * as userApi from '@api/user';

import { ClassList, ChildProfile, ChildAdd, Button } from '@components';
import { PencilIcon, PlusIcon, XMarkIcon } from '@heroicons/react/20/solid';
import { ORG, ORG_STATE, USER_STATE } from '@common';
import { OrgDto, OrgUserDto, UserDto } from '@dto';
import { useAppDispatch } from '@store/hook';
import { open as modalOpen } from '@store/modal';
import { open as loadingOpen, close as loadingClose } from '@store/loading';
import { groupBy } from 'lodash';

export const Org: React.FC = () => {
  const [isChildOpen, setIsChildOpen] = useState<boolean>(false);
  const [isAddOpen, setIsAddOpen] = useState<boolean>(false);
  const [showNewInput, setShowNewInput] = useState<boolean>(false);
  const [data, setData] = useState<OrgDto[]>([]);
  const [selectedOrgId, setSelectedOrgId] = useState<number | undefined>();
  const [selectedOrgName, setSelectedOrgName] = useState<string | undefined>();
  const [members, setMembers] = useState<UserDto[]>([]);
  const [selectedUserId, setSelectedUserId] = useState<number>();
  const dispatch = useAppDispatch();

  const newRef = useRef<HTMLFormElement>(null);

  const fetchData = useCallback(async () => {
    try {
      dispatch(loadingOpen());
      const { count, data } = await orgApi.list({
        page: 1,
        limit: 100,
        search: {
          type: ORG.CLASS,
          status: ORG_STATE.USED,
        },
      });

      // 반이 없다면 아래의 학생과 선생님을 가져오는 걸 하지 않는다.
      if (count === 0) {
        return;
      }

      // TODO Member를 뒤져보고 30이 아닌 원생은 쳐낸다.
      const newData: OrgDto[] = [...data].map((org: OrgDto) => {
        const newOrg: OrgDto = {
          ...org,
        };
        if (org.members) {
          const newMembers: UserDto[] = [...org.members].filter(
            (member: UserDto) => member.status === USER_STATE.ACTIVATE,
          );
          newOrg.members = newMembers;
        }

        return newOrg;
      });

      setData([...newData]);
    } catch (error: any) {
      dispatch(
        modalOpen({
          type: 'alert',
          title: '오류',
          message: error.message
            ? error.message
            : `오류가 발생하였습니다. [오류코드 ${error.status}]`,
        }),
      );
    } finally {
      dispatch(loadingClose());
    }
  }, [dispatch]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const scheme = yup.object().shape({
    orgName: yup.string().required('이름을 입력해주세요'),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<{ orgName: string }>({
    resolver: yupResolver(scheme),
  });

  const handleShowInput = () => {
    toggleShowNewInput(true);
  };

  /**
   * 기존 input flag를 state 관리 대신에 style을 직접 지정,
   * scroll까지 가능하도록 변경
   * @param show
   */
  const toggleShowNewInput = (show: boolean) => {
    setShowNewInput(show);
    if (newRef.current) {
      newRef.current.style.display = show ? 'block' : 'none';
      if (show) {
        newRef.current.scrollIntoView(false);
      }
    }
  };

  const handleChildDetail = (id: number) => {
    setSelectedUserId(id);
    setIsChildOpen(true);
  };

  /**
   * 반 등록이 된다.
   * @param data
   */
  const onSubmit = async (org: any) => {
    // TODO 등록 API가 수정이 된 것을 확인 후 나머지 처리 만든다.
    try {
      const result = await orgApi.create(org.orgName, ORG.CLASS);
      result.members = [];
      setData([...data, result]);
      reset();
      toggleShowNewInput(false);
    } catch (error: any) {
      dispatch(
        modalOpen({
          type: 'alert',
          isOpen: true,
          title: '오류',
          message: error.message
            ? error.message
            : `오류가 발생하였습니다. [오류코드: ${error.status}]`,
        }),
      );
      reset();
    } finally {
    }
  };

  /**
   * 원생 추가 모달을 띄운다
   * @param id
   */
  const handleClickAdd = (id: number) => {
    const org: OrgDto = data.find((org: OrgDto) => org.id === id) as OrgDto;
    const members: UserDto[] = org.members ? [...org.members] : [];

    setSelectedOrgId(id);
    setSelectedOrgName(org.title);
    setMembers(members);
    setIsAddOpen(true);
  };

  /**
   * ORG 삭제가 되었다면, 리스트를 업데이트 하지 않고
   * @param id
   */
  const handleRemovedOrg = (orgId: number) => {
    const newList: OrgDto[] = [...data].filter((org: OrgDto) => org.id !== orgId);
    setData([...newList]);
  };

  /**
   * 이동을 변경한다
   * @param orgUserDto
   */
  const handleMoveEnd = async (orgUserDto: OrgUserDto) => {
    try {
      const result = await userApi.joinUserOrg(orgUserDto);
      if (result.length < 1) {
        throw new Error('원생을 이동시킬 수 없습니다');
      }

      const allMembers: UserDto[] = (
        [...data].map((value: OrgDto) => value.members).flat() as UserDto[]
      ).map((user: UserDto) => {
        const hasUser = orgUserDto.users.filter((v: UserDto) => v.id === user.id);
        if (hasUser.length > 0) {
          hasUser[0].srcOrg = {
            id: orgUserDto.org.id,
          };
          user = Object.assign({}, user, hasUser[0]);
        }
        return { ...user };
      }) as UserDto[];

      const group = groupBy(allMembers, (value: UserDto) => value.srcOrg?.id);
      const keys: string[] = Object.keys(group);
      const newData: OrgDto[] = [...data].map((org: OrgDto) => {
        if (keys.includes(org.id + '')) {
          org.members = [...group[org.id + '']];
        } else {
          org.members = [];
        }

        return { ...org };
      });

      setData([...newData]);
      // orgUserDto
    } catch (error: any) {
      dispatch(
        modalOpen({
          type: 'alert',
          isOpen: true,
          title: '오류',
          message: error.message
            ? error.message
            : `오류가 발생하였습니다 [오류코드 : ${error.status}]`,
        }),
      );
    }
  };

  /**
   * 선택된 원생을 떠나게 한다
   * @param orgUserDto
   */
  const handleLeave = async (orgUserDto: OrgUserDto) => {
    try {
      const result = await userApi.leaveUserOrg(orgUserDto);
      if (result.length < 1) {
        throw new Error('원생을 이동시킬 수 없습니다');
      }

      const orgIndex: number = data.findIndex((org: OrgDto) => org.id === orgUserDto.org.id);
      const org: OrgDto = [...data][orgIndex];
      const userIds: number[] = orgUserDto.users.map((user: UserDto) => user.id as number);
      const newMembers: UserDto[] | undefined = org.members?.filter((user: UserDto) => {
        return !userIds.includes(user.id as number);
      });

      const newData = [...data];

      newData[orgIndex].members = [...(newMembers as UserDto[])];
      setData(newData);
    } catch (error: any) {
      dispatch(
        modalOpen({
          type: 'alert',
          isOpen: true,
          title: '오류',
          message: error.message
            ? error.message
            : `오류가 발생하였습니다 [오류코드 : ${error.status}]`,
        }),
      );
    }
  };

  const handleJoinUserOrg = async (users: UserDto[], orgId: number) => {
    try {
      dispatch(loadingOpen());
      const orgUser: OrgUserDto = {
        org: {
          id: orgId,
        },
        users,
      };

      const result = await userApi.joinUserOrg(orgUser);
      if (result.length < 1) {
        throw new Error('원생을 이동시킬 수 없습니다');
      }
      fetchData();
    } catch (error: any) {
      dispatch(
        modalOpen({
          type: 'alert',
          isOpen: true,
          title: '오류',
          message: error.message
            ? error.message
            : `오류가 발생하였습니다 [오류코드 : ${error.status}]`,
        }),
      );
    } finally {
      dispatch(loadingClose());
    }
  };

  const handleCancelNewOrg = () => {
    toggleShowNewInput(false);
    reset();
  };

  return (
    <>
      <div className="w-full h-[calc(100vh-112px)]">
        <h3 className="text-3xl font-semibold tracking-wider mb-4">반 관리</h3>
        <div className="flex flex-col bg-white w-full h-[calc(100%-52px)] overflow-y-hidden rounded-lg shadow-xl p-4">
          {/* 반 추가 버튼 */}
          <div className="flex items-center justify-end mb-4">
            <Button color="primary" onClick={handleShowInput}>
              <PlusIcon className="w-4 h-6 fill-white" />
              <span className="ml-1 text-sm text-white">반 추가</span>
            </Button>
          </div>
          <div className="w-full h-[calc(100%-52px)] overflow-x-auto overflow-y-hidden">
            <div
              className={[
                'flex-nowrap bg-transparent h-full space-x-4',
                (data.length === 0 && !showNewInput) ? 'flex fexl-col items-center justify-center border rounded-lg' : 'inline-flex'
              ].join(' ')}>
              {(data.length === 0 && !showNewInput) && (
                <div>
                  <p className="text-sm text-gray-500 mb-4">
                    등록된 반이 없습니다. 반 추가를 해주세요.
                  </p>
                  <Button
                    color="primary"
                    onClick={handleShowInput}
                    >
                    <PlusIcon className="w-4 h-6 fill-white" />
                    <span className="ml-1 text-sm text-white">반 추가</span>
                  </Button>
                </div>
              )}
              {data.map((value) => {
                return (
                  <ClassList
                    key={value.id}
                    {...value}
                    onClickChild={handleChildDetail}
                    onClickAdd={handleClickAdd}
                    onRemoved={handleRemovedOrg}
                    onMoveEnd={handleMoveEnd}
                    onLeave={handleLeave}
                  />
                );
              })}
              {/* BUTTON 반 추가 */}
              {/* 반 생성 팝업 */}
              <form ref={newRef} style={{ display: 'none' }} onSubmit={handleSubmit(onSubmit)}>
                <div className="inline-block box-border bg-gray-200 border border-gray-300 rounded-md w-80 whitespace-nowrap py-2">
                  <div className={`px-2 pb-2`}>
                    <input
                      type="text"
                      className={`w-full min-h-[24px] py-2 rounded border border-secondary text-sm font-medium mr-1 focus-visible:outline-secondary px-1 invalid:border-error ${
                        errors.orgName && 'border-error focus-visible:outline-error'
                      }`}
                      {...register('orgName')}
                      placeholder="반 이름을 입력해주세요"
                    />
                    <div className="text-error text-xs mt-1 tracking-wider">
                      {errors.orgName && errors.orgName.message}
                    </div>
                  </div>
                  <div className="px-2 flex">
                    <button
                      type="submit"
                      className="inline-flex bg-primary min-w-[3rem] rounded-md p-2 items-center
                  hover:bg-opacity-80 focus-visible:outline-primary focus-visible:outline-2 focus-visible:bg-opacity-80 mr-2">
                      <PencilIcon className="w-3 h-3 fill-white mr-1" />
                      <span className="text-sm text-white pr-1">반 추가</span>
                    </button>
                    <button
                      type="reset"
                      className="inline-flex bg-error min-w-[3rem] rounded-md p-2 items-center
                  hover:bg-opacity-80 focus-visible:outline-error focus-visible:outline-2 focus-visible:bg-opacity-80"
                      onClick={handleCancelNewOrg}>
                      <XMarkIcon className="w-4 h-4 fill-white mr-1" />
                      <span className="text-sm text-white pr-1">취소</span>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <ChildProfile
        userId={selectedUserId as number}
        isOpen={isChildOpen}
        setIsOpen={setIsChildOpen}
        onRemoved={() => {
          fetchData();
        }}
        onUpdated={() => {
          fetchData();
        }}
      />
      <ChildAdd
        orgId={selectedOrgId}
        orgName={selectedOrgName}
        isOpen={isAddOpen}
        setIsOpen={setIsAddOpen}
        value={members}
        onSelected={handleJoinUserOrg}
      />
    </>
  );
};;
