/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Button } from '../Button';
import { CourseStatusLabel } from '@common';
export interface CourseItemProp {
  readonly id: number;
  readonly title: string;
  readonly status: string;
  readonly subjectCount?: number | undefined;
  readonly onClick: (id: number) => void;
}

export const CourseItem: React.FC<CourseItemProp> = ({
  id,
  title,
  status: statusCode,
  subjectCount = 0,

  onClick,
}: CourseItemProp) => {
  const handleClick = () => {
    onClick(id);
  };
  const [statusStr, setStatusStr] = useState<string>();

  useEffect(() => {
    const idx = CourseStatusLabel.find((opt: any) => opt.value === statusCode)
    if (idx) {
      setStatusStr(idx.label)
    }

  }, [statusCode])


  return (
    <tr>
      <td className="border-b text-black/80 text-sm p-4 leading-normal truncate max-w-xs lg:indent-12">
        {title}
      </td>
      <td className="border-b text-black/80 text-sm p-4 leading-normal text-center">
        {subjectCount}강
      </td>

      <td className="border-b p-4 text-center">
        <Button
          outlined
          color="primary"
          size="sm"
          className="text-xs font-normal group"
          onClick={handleClick}>
          <span className="group-hover:text-white">상세보기</span>
        </Button>
      </td>
    </tr>
  );
};
