import React from 'react';
import { Dialog } from '@headlessui/react';
import { Card, Button } from '@components';
import { useAppDispatch, useAppSelector } from '@store/hook';
import { selectModal, close, ok } from '@store/modal';

export const Modal: React.FC = () => {
  const modal = useAppSelector(selectModal);
  const dispatch = useAppDispatch();

  const handleCancel = () => {
    dispatch(close());
  };

  const handleOk = () => {
    dispatch(ok());
  };

  return (
    <Dialog className="relative z-[3001]" open={modal.isOpen} onClose={() => {}}>
      <div className="fixed inset-0 bg-black/50" aria-hidden="true" />
      <div className="fixed inset-0 flex items-center justify-center">
        <Dialog.Panel className="w-full md:max-w-md bg-white rounded-lg">
          <Card
            title={modal.title ? modal.title : modal.type === 'alert' ? '알림' : '확인'}
            actions={
              <>
                {modal.type === 'confirm' && (
                  <Button plain className="font-normal" onClick={handleCancel}>
                    {modal.cancelTitle ? modal.cancelTitle : '취소'}
                  </Button>
                )}
                <Button plain color="secondary" className="font-normal" onClick={handleOk}>
                  {modal.okTitle ? modal.okTitle : '확인'}
                </Button>
              </>
            }>
            <>
              <p>{modal.message}</p>
            </>
          </Card>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
};
