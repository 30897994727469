import React, { useEffect, useState } from 'react';
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { Button, SubjectItem } from '@components';
import { SubjectDto } from '@dto/Subject';

interface SubjectListProp {
  /**
   * dnd를 사용하는지 여부
   */
  readonly useDnD?: boolean;
  /**
   * header를 보여줄지 여부
   */
  readonly header?: boolean;
  /**
   * 선택 삭제를 지원할지 여부
   */
  readonly useRemove?: boolean;
  /**
   * 선택 타입을 지정한다. (멀티 - 여러개 선택, 싱글 - 1건 선택)
   */
  readonly selectType?: 'multi' | 'single';
  readonly data: Array<SubjectDto>;
  readonly selectData?: Set<number>;
  readonly onChange?: (data: SubjectDto[]) => void;
  readonly onSelect?: (data: Set<number>) => void;
  readonly selectClear?: boolean;
}

export const SubjectList = ({
  useDnD = false,
  header = false,
  useRemove = false,
  selectType = 'single',
  data,
  selectData,
  onChange,
  onSelect,
  selectClear = false,
}: SubjectListProp) => {
  const [items, setItems] = useState<SubjectDto[]>(data);
  const [checkedAll, setCheckedAll] = useState<boolean>(false);
  const [selectItems, setSelectItems] = useState<Set<number>>(new Set());

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  useEffect(() => {
    setSelectItems(new Set());
    setCheckedAll(false);
    setItems(data);
  }, [data]);

  useEffect(() => {
    if (selectData) {
      setSelectItems(new Set(selectData));
      if (selectData.size === 0) {
        setCheckedAll(false);
      } else {
        setCheckedAll(selectData.size === items.length);
      }
    }
  }, [selectData, selectItems.size, items.length]);

  const handleDragEnd = (event: any) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      const oldIndex = items.findIndex((value) => value.id === active.id);
      const newIndex = items.findIndex((value) => value.id === over.id);
      const newList = arrayMove(items, oldIndex, newIndex);
      setItems([...newList]);
      if (onChange) {
        onChange(newList);
      }
    }
  };

  const handleSelectItem = (id: number) => {
    if (selectType === 'multi') {
      if (selectItems.has(id)) {
        selectItems.delete(id);
      } else {
        selectItems.add(id);
      }
      setSelectItems(new Set(selectItems));
      setCheckedAll(selectItems.size === items.length);
    } else {
      selectItems.clear();
      selectItems.add(id);
      setSelectItems(new Set(selectItems));
    }

    if (onSelect) {
      onSelect(selectItems);
    }
  };

  const handleCheckAll = (e: any) => {
    setCheckedAll(e.target.checked);
    const newSet: Set<number> = e.target.checked ? new Set(items.map((v) => v.id)) : new Set();
    setSelectItems(newSet);

    if (onSelect) {
      onSelect(newSet);
    }
  };

  /**
   * 삭제
   */
  const handleRemove = () => {
    const newItems = [...items]
      .filter((v) => !selectItems.has(v.id))
      .map((subject: SubjectDto, index: number) => {
        if (subject.order) {
          subject.order = index + 1;
        }
        return subject;
      });

    setItems(newItems);
    setSelectItems(new Set());
    setCheckedAll(false);
    if (onChange) {
      onChange(newItems);
    }
  };

  return (
    <>
      {header && (
        <div className="flex sticky top-0 items-center justify-between p-4 border-b bg-white">
          <label className="label justify-start">
            <input
              type="checkbox"
              className="checkbox checkbox-primary checkbox-sm mr-2"
              checked={checkedAll}
              onChange={handleCheckAll}
            />
            <span className="text-sm">전체 선택</span>
          </label>
          {useRemove && (
            <Button color="error" outlined size="sm" className="font-normal" onClick={handleRemove}>
              선택삭제
            </Button>
          )}
        </div>
      )}
      {items.length === 0 && (
        <div className="bg-white h-[calc(100%-69px)] flex flex-col justify-center">
          <div className="text-center text-sm text-gray-500 block">데이터가 없습니다.</div>
        </div>
      )}
      {items.length > 0 && (
        <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
          <SortableContext items={items} strategy={verticalListSortingStrategy}>
            {

              items.map((value, index) => {
                return (
                  <SubjectItem
                    key={value.id}
                    id={value.id}
                    index={index}
                    title={value.title as string}
                    selected={selectItems.has(value.id)}
                    useDnD={useDnD}
                    onSelect={handleSelectItem}
                  />
                );
              })
            }
          </SortableContext>
        </DndContext>
      )}
    </>
  );
};
