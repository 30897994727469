import React, { useState, useEffect, useCallback } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Button } from '@components';
import { ClassSelect } from '@components/ClassSelect';
import { ChildItem } from '@components';
import { RatingSubjectModal } from '@components/RatingSubjectModal';
import { SearchDto, UserDto } from '@dto';

import * as userApi from '@api/user';
import { useAppDispatch, useAppSelector } from '@store/hook';
import { selectUser } from '@store/user';
import { open as modalOpen } from '@store/modal';
import { ROLE } from '@common';
import { TailSpin } from 'react-loader-spinner';
import { exportEval } from '../../../api/evals';

export const RatingList = () => {
  const [children, setChildren] = useState<UserDto[]>([]);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [userId, setUserId] = useState<number>();
  const [search, setSearch] = useState<SearchDto>({
    page: 1,
    limit: 20,
    search: {},
  });
  const { user } = useAppSelector(selectUser);
  const [keyword, setKeyword] = useState<string>('');
  const [orgId, setOrgId] = useState<number | undefined>();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  /**
   * 데이터를 불러온다
   */
  const fetchData = useCallback(
    async () => {
      try {
        // dispatch(loadingOpen());
        if (!hasMore) {
          return;
        }
        if (!user) {
          return;
        }
        const newSearch: SearchDto = { ...search };
        // 원장의 경우
        if (user.role?.id === ROLE.MEM_MANAGER || user.role?.id === ROLE.OP_MANAGER || user.role?.id === ROLE.OP_ADMIN) {
          search.search.role = {
            id: ROLE.MEM_CHILD,
          };
          const { data, hasNextPage } = await userApi.list(search);
          setChildren([...children, ...data]);
          setHasMore(hasNextPage);
        } else if (user.role?.id === ROLE.MEM_TEACHER) {
          // 선생님의 경우
          const { data, hasNextPage } = await userApi.getChildrenByTeacher(search);
          setChildren([...children, ...data]);
          setHasMore(hasNextPage);
        } else {
          console.log('unkown user', user.role)
          throw Error('unkown user Role')
        }
        newSearch.page += 1;
        setSearch({ ...newSearch });
      } catch (error: any) {
        dispatch(
          modalOpen({
            type: 'alert',
            isOpen: true,
            title: '오류',
            message: error.message
              ? error.message
              : `오류가 발생하였습니다. [오류코드 ${error.status}]`,
          }),
        );
      } finally {
        // dispatch(loadingClose());
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [children, hasMore, search, user],
  );

  useEffect((): any => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchData]);

  const handleClick = (id: number) => {
    setIsOpen(true);
    setUserId(id);
  };

  const handleChangeOrg = (id: number) => {
    setOrgId(id);

    const newSearch: SearchDto = { ...search };
    newSearch.page = 1;
    newSearch.search.class = {
      id,
    };
    setChildren([]);
    setSearch({ ...newSearch });
    setHasMore(true);
  };

  const handleChangeKeyword = (e: any) => {
    setKeyword(e.target.value);
  };
  const handleDownload = () => {
    try {
      setLoading(true);

      exportEval(orgId)

    } catch (error: any) {
      dispatch(
        modalOpen({
          type: 'alert',
          isOpen: true,
          title: '오류',
          message: error.message
            ? error.message
            : `오류가 발생하였습니다. [오류코드 ${error.status}]`,
        }),
      );
    } finally {
      setLoading(false);
    }
  };
  const handleClickFilter = () => {
    const newSearch: SearchDto = { ...search };
    newSearch.page = 1;
    newSearch.search = {};

    if (keyword && keyword.trim().length > 0) {
      newSearch.search.userName = keyword;
    }

    if (orgId) {
      newSearch.search.class = {
        id: orgId,
      };
    }
    setChildren([]);
    setSearch({ ...newSearch });
    setHasMore(true);
  };

  const handleResetFilter = () => {
    const newSearch: SearchDto = { ...search };
    newSearch.page = 1;
    newSearch.search = {};
    setOrgId(undefined);
    setKeyword('');
    setChildren([]);
    setSearch({ ...newSearch });
    setHasMore(true);
  };

  const getChildClassTitle = (child: UserDto): string => {
    if (child.class && child.class.length > 0) {
      return child.class[0].title || '';
    }
    return '';
  };

  return (
    <>
      {loading && (
        <div className=" absolute w-[calc(100vw-430px)] h-[calc(100vh-112px)] flex items-center justify-center bg-white/75">
          <TailSpin
            height="48"
            width="48"
            color="#ff9e18"
            ariaLabel="tail-spin-loading"
            radius="1"
            visible={true}
          />
        </div>
      )}{
        <div className="w-full h-[calc(100vh-112px)]">

          <h3 className="text-3xl font-semibold tracking-wider mb-4">평가보기</h3>
          <div className="flex flex-col bg-white w-full h-[calc(100%-52px)] overflow-y-hidden rounded-lg shadow-xl p-4">

            <div className="grid grid-cols-12 gap-4 mb-4">
              <div className="col-span-12 lg:col-span-3">
                <ClassSelect
                  value={orgId}
                  onChange={handleChangeOrg}
                  selectClassName="min-h-[2.25rem] h-[2.25rem] max-h-[2.25rem]"
                />
              </div>
              <div className="col-span-12 lg:col-span-3">
                <div className="form-control">
                  <label className="label">
                    <span className="label-text">원생</span>
                  </label>
                  <input
                    type="text"
                    className="input input-bordered text-sm h-[2.25rem]"
                    placeholder="이름을 입력해주세요"
                    value={keyword}
                    onChange={handleChangeKeyword}
                  />
                </div>
              </div>
              <div className="col-span-12 lg:col-span-3 flex items-end mb-1">
                <Button color="secondary" className="mr-2" onClick={handleClickFilter}>
                  <span className="text-white font-light">검색</span>
                </Button>
                <Button
                  color="error"
                  className="bg-danger border-danger hover:bg-danger/80 hover:border-danger/80 focus-visible:outline-danger"
                  onClick={handleResetFilter}>
                  <span className="text-white font-light">초기화</span>
                </Button>
                <div className=' space-between'></div>

              </div>
              <div className="hidden lg:block  lg:col-span-3  text-end  mb-1">
                <label className="label">
                  <span className="label-text">&nbsp;</span>
                </label>
                <Button
                  color="secondary"
                  className="secondary"
                  onClick={handleDownload}>
                  <span className="text-white font-light"> 평가결과 내보내기</span>
                </Button>
              </div>
            </div>
            <div
              className="w-full h-[calc(100%-100px)] max-h-[calc(100%-100px)] overflow-auto bg-gray-200 rounded-lg"
              id="ratingList">
              <InfiniteScroll
                dataLength={children.length}
                next={fetchData}
                hasMore={hasMore}
                loader={
                  <h4 className="w-full col-span-1 md:col-span-2 lg:col-span-4 4xl:grid-cols-6 text-center">
                    불러오는 중
                  </h4>
                }
                scrollableTarget="ratingList"
                className="w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 4xl:grid-cols-6 gap-8 p-4 auto-rows-auto">
                {children.map((child: UserDto, index) => (
                  <ChildItem
                    key={index}
                    id={child?.id as number}
                    profile={child.photo?.path}
                    name={child.userName as string}
                    org={getChildClassTitle(child)}
                    onClick={handleClick}
                  />
                ))}
              </InfiniteScroll>
            </div>
          </div>
        </div >
      }
      <RatingSubjectModal id={userId} isOpen={isOpen} setIsOpen={setIsOpen} />

    </>
  );
};;
