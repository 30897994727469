import React, { useState, useEffect } from 'react';
import MultiCarousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { XMarkIcon } from '@heroicons/react/20/solid';
import { PhotoDto } from '@dto';

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

interface CarouselProps {
  images: PhotoDto[];
  className?: string;
  isRemove?: boolean;
  onRemove?: (images: PhotoDto[]) => void;
}

export const Carousel: React.FC<CarouselProps> = ({
  images: _images,
  className,
  isRemove,
  onRemove,
}) => {
  const [images, setImages] = useState<PhotoDto[]>([]);

  useEffect(() => {
    if (_images !== undefined) {
      setImages([..._images]);
    }
  }, [_images]);

  const handleRemove = (index: number) => {
    const newList = [...images].filter((_, idx) => idx !== index);
    setImages(newList);
    if (onRemove) {
      onRemove([...newList]);
    }
  };

  return (
    <MultiCarousel
      swipeable={true}
      responsive={responsive}
      showDots={true}
      infinite={true}
      autoPlay={false}
      draggable={false}
      renderDotsOutside={false}
      containerClass={`h-full ${className} rounded-xl bg-transparents`}
      sliderClass="h-full"
      itemClass="w-full h-full rounded-xl">
      {images.map((src, index) => (
        <div key={index} className="relative w-full h-full rounded-xl">
          <img
            src={src.path}
            alt={`활동사진-${index + 1}`}
            className="object-contain w-full h-full rounded-xl"
          />
          {isRemove && (
            <button
              className="absolute top-4 right-4"
              onClick={() => {
                handleRemove(index);
              }}>
              <XMarkIcon className="w-8 h-8 fill-gray-500" />
            </button>
          )}
        </div>
      ))}
    </MultiCarousel>
  );
};
