import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, ChangePasswdModal, Profile } from '@components';

import * as userApi from '@api/user';

import { useAppDispatch, useAppSelector } from '@store/hook';
import { selectUser, setUser } from '@store/user';
import { open as modalOpen } from '@store/modal';
import { open as loadingOpen, close as loadingClose } from '@store/loading';
import { PhotoDto, UserDto } from '@dto';
import { TelInput } from '@components/common/TelInput';

export const Account = () => {
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [isPasswordOpen, setIsPasswordOpen] = useState<boolean>(false);
  const [photo, setPhoto] = useState<PhotoDto | undefined>();
  const dispatch = useAppDispatch();
  const { user } = useAppSelector(selectUser);

  const scheme = yup.object().shape({
    email: yup.string().email('올바른 이메일 형식이 아닙니다').required('이메일을 입력해주세요'),
    org: yup.string().required('유치원을 입력해주세요'),
    userName: yup.string().required('이름을 입력해주세요'),
    telno: yup
      .string()
      .required('전화번호를 입력해주세요.')
      .matches(/^01([0|1|6|7|8|9])-([0-9]{3,4})-([0-9]{4})$/, '전화번호 양식에 맞게 입력해주세요.'),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm({
    defaultValues: {
      email: '',
      org: '',
      userName: '',
      telno: '',
    },
    resolver: yupResolver(scheme),
  });

  useEffect(() => {
    if (!user) {
      return;
    }
    dispatch(loadingOpen());
    setValue('email', user.email as string);
    setValue('org', user.org?.title as string);
    setValue('userName', user.userName as string);
    setValue('telno', user.telno as string);
    setPhoto(user.photo);
    dispatch(loadingClose());
  }, [user, setValue, dispatch]);

  /**
   * 저장
   * @param data
   */
  const onSubmit = async (data: any) => {
    dispatch(
      modalOpen({
        type: 'confirm',
        message: '계정 정보를 수정하시겠습니까?',
        onOk: () => {
          updateProc(data);
        },
      }),
    );
  };

  /**
   * 업데이트 처리
   * @param data
   */
  const updateProc = async (data: any) => {
    try {
      dispatch(loadingOpen());
      if (!user) {
        throw new Error('계정 정보를 찾을 수 없습니다');
      }

      const newUser: UserDto = {
        ...user,
        userName: data.userName,
        telno: data.telno,
      };

      if (photo) {
        newUser.photo = { ...photo };
      } else {
        newUser.photo = undefined;
      }

      console.log('update Proc!!');

      const result = await userApi.updateById(newUser.id as number, newUser);

      if (!result) {
        throw new Error('수정에 실패하였습니다');
      }

      dispatch(setUser(newUser));
      setIsEdit(false);
    } catch (error: any) {
      dispatch(
        modalOpen({
          type: 'alert',
          title: '오류',
          message: error.message
            ? error.message
            : `오류가 발생하였습니다. [오류코드 ${error.status}]`,
        }),
      );
    } finally {
      dispatch(loadingClose());
    }
  };

  const handleReset = () => {
    reset({
      userName: user?.userName as string,
      telno: user?.telno as string,
    });
    setPhoto(user?.photo);
    setIsEdit(false);
  };

  const handleChangePhoto = (photo?: PhotoDto) => {
    setPhoto(photo);
  };

  return (
    <>
      <div className="w-full flex flex-col">
        <h3 className="text-3xl font-semibold tracking-wider mb-8">계정 관리</h3>
        <div className="w-full md:max-w-md lg:max-w-lg xl:max-w-xl mx-auto">
          <form onSubmit={handleSubmit(onSubmit)}>
            <Profile photo={photo} isEdit={isEdit} onChange={handleChangePhoto} />
            <div className="form-control mb-4">
              <label className="label">
                <span className="label-text">이메일</span>
              </label>
              <input
                type="text"
                className="input input-bordered text-sm"
                disabled
                {...register('email')}
              />
            </div>
            <div className="form-control mb-4">
              <label className="label">
                <span className="label-text">유치원</span>
              </label>
              <input
                type="text"
                className="input input-bordered text-sm"
                disabled
                {...register('org')}
              />
            </div>
            <div className="form-control mb-4">
              <label className="label">
                <span className={`label-text ${errors.userName && 'text-error'}`}>이름</span>
              </label>
              <input
                type="text"
                className={`input input-bordered text-sm ${errors.userName && 'input-error'}`}
                disabled={!isEdit}
                {...register('userName')}
              />
              <span className="text-xs mt-2 text-danger tracking-wide">
                {errors.userName && errors.userName.message}
              </span>
            </div>
            <div className="form-control mb-8">
              <label className="label">
                <span className={`label-text ${errors.telno && 'text-error'}`}>전화번호</span>
              </label>
              <TelInput
                register={register('telno')}
                disabled={!isEdit}
                className={`${errors.telno && 'input-error'}`}
                />
              <span className="text-xs mt-2 text-danger tracking-wide">
                {errors.telno && errors.telno.message}
              </span>
            </div>
            <div className={`flex ${isEdit ? 'justify-end space-x-2' : 'justify-between'}`}>
              {!isEdit && (
                <>
                  <Button color="primary" onClick={() => setIsPasswordOpen(true)}>
                    <span className="text-white font-light">비밀번호 변경</span>
                  </Button>
                  <Button color="secondary" onClick={() => setIsEdit(true)}>
                    <span className="text-white font-light">수정</span>
                  </Button>
                </>
              )}
              {isEdit && (
                <>
                  <Button
                    color="error"
                    className="bg-danger border-danger hover:bg-danger/80 hover:border-danger/80 focus-visible:outline-danger"
                    onClick={handleReset}>
                    <span className="text-white font-light">취소</span>
                  </Button>
                  <Button type="submit" color="secondary" className="text-white font-normal">
                    <span className="text-white font-light">수정</span>
                  </Button>
                </>
              )}
            </div>
          </form>
        </div>
      </div>
      <ChangePasswdModal isOpen={isPasswordOpen} setIsOpen={setIsPasswordOpen} />
    </>
  );
};
