import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './config';

import { AgreeYnDto } from '@dto/AgreeYnDto';

export interface AgreeState {
  agreeYnList: AgreeYnDto[];
}

const initialState: AgreeState = {
  agreeYnList: [],
};

export const agreeSlice = createSlice({
  name: 'agree',
  initialState,
  reducers: {
    setAgree: (state, action: PayloadAction<AgreeYnDto[] | []>) => {
      state.agreeYnList = [...action.payload];
    },
    clearAgree: (state) => {
      state.agreeYnList = [];
    },
  },
});

export const selectAgree = (state: RootState) => state.agree;
export const { setAgree, clearAgree } = agreeSlice.actions;
export default agreeSlice.reducer;
