import React, { useCallback, useEffect, useState } from 'react';
import { Dialog } from '@headlessui/react';
import { Button, Card, CourseSelect } from '@components';
import { RatingModal } from '@components/RatingModal';
import { ChildInfo } from '@components/parent';
import { RatingGraph } from '@components/common';

import * as subjectApi from '@api/subject';
import * as userApi from '@api/user';
import { UserDto, SubjectDto } from '@dto';
import { useAppDispatch } from '@store/hook';
import { open as modalOpen } from '@store/modal';
import { TailSpin } from 'react-loader-spinner';
import { EvalSumDto } from '@dto/EvalSumDto';
import { SubjectSubmitEvalItemDto } from '@dto/SubjectSubmitEvalItemDto';
import { SubjectSubmitDto } from '@dto/SubjectSubmitDto';
import { groupBy } from 'lodash';

interface RatingModalProp {
  id: number | undefined;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

interface ItemProp {
  id: number;
  title: string;
  disable: boolean;
  onClick?: (id: number) => void;
}

const Item: React.FC<ItemProp> = ({ id, title, disable, onClick }) => {
  const handleClick = () => {
    if (onClick) {
      onClick(id);
    }
  };

  return (
    <div className="col-span-1 h-24 px-4 flex justify-center items-center border rounded-lg bg-white">
      <div className="text-center">
        <div className="text-sm mb-2">{title}</div>
        <Button
          color="primary"
          size="sm"
          disabled={disable}
          className="text-sm text-white font-normal"
          onClick={handleClick}>
          보기
        </Button>
      </div>
    </div>
  );
};

export const RatingSubjectModal: React.FC<RatingModalProp> = ({ id, isOpen, setIsOpen }) => {
  // eslint-disable-next-line
  const [user, setUser] = useState<UserDto>();
  const [classId, setClassId] = useState<number | undefined>();
  const [classTitle, setClassTitle] = useState<string>('');
  const [courseId, setCourseId] = useState<number | undefined>();
  const [subjects, setSubjects] = useState<SubjectDto[]>();
  const [subjectId, setSubjectId] = useState<number>();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [evalAvgs, setEvalAvgs] = useState<any>([]);
  const [evalAvgMaxValue, setEvalAvgMaxValue] = useState<number | 'auto' | undefined>('auto');

  const dispatch = useAppDispatch();

  const fetchData = useCallback(
    async () => {
      try {
        setIsLoading(true);
        if (!id) {
          throw new Error('잘못된 요청입니다');
        }

        const user = await userApi.itemById(id as number);
        setUser({ ...user });
        if (user.class && user.class.length > 0) {
          setClassId(user.class[0].id);
          setClassTitle(user.class[0].title || '');
        }
      } catch (error: any) {
        dispatch(
          modalOpen({
            type: 'alert',
            isOpen: true,
            title: '오류',
            message: error.message
              ? error.message
              : `오류가 발생하였습니다. [오류코드 ${error.status}]`,
            onOk () {
              setIsOpen(false);
            },
          }),
        );
      } finally {
        setIsLoading(false);
      }
    },
    // eslint-disable-next-line
    [id],
  );

  const reset = useCallback(() => {
    setUser(undefined);
    setClassId(undefined);
    setClassTitle('');
    setSubjects(undefined);
    setSubjectId(undefined);
    setCourseId(undefined);
  }, []);

  useEffect(() => {
    if (isOpen) {
      fetchData();
    } else {
      reset();
    }
  }, [isOpen, fetchData, reset]);

  const handleChangeCourse = async (
    id: number,
    members?: UserDto[],
    subjects?: SubjectDto[],
    evalSums?: EvalSumDto[],
  ) => {
    setCourseId(id);
    try {
      setIsLoading(true);
      if (!subjects) {
        throw new Error('수업 데이터가 존재하지 않습니다');
      }

      if (id > 0 && user) {
        const { data } = await subjectApi.submitList({
          page: 1,
          limit: subjects.length,
          search: {
            subject: {
              course: {
                id,
              },
            },
            targetUser: {
              id: user.id,
            },
          },
        });

        if (data.length > 0) {

          const r: SubjectSubmitEvalItemDto[][] = data.map((subjectSubmit: SubjectSubmitDto) => [
            ...subjectSubmit.evalSubmits,
          ]);

          const submitEvalList: SubjectSubmitEvalItemDto[] = (
            [] as SubjectSubmitEvalItemDto[]
          ).concat(...r);

          // 출석부의 주제번호만 찾아 낸다.
          const s1 = data.map((subjectSubmit: SubjectSubmitDto) => [
            subjectSubmit.evalSubmits[0].eval.subject?.id
          ]) as any;
          // 2차배열을 1차배열로
          const s2 = [].concat(...s1);
          // 주제에 출석여부를 넣는다.
          subjects.forEach((s: SubjectDto) => {
            const idx = s2.findIndex((el: any) => (s.id === el))
            s.userData = idx >= 0 ? true : false
          })

          const maxValues: number[] | undefined = submitEvalList.map(
            (v: SubjectSubmitEvalItemDto) => v.eval.evalMax || 1,
          );

          if (maxValues) {
            const maxValue: number = Math.max(...maxValues);
            setEvalAvgMaxValue(maxValue);
          } else {
            setEvalAvgMaxValue('auto');
          }

          const evalItems: any = groupBy(
            submitEvalList.map((value: SubjectSubmitEvalItemDto) => ({
              submitVal: Number(value.submitVal),
              title: value.eval.title || '',
            })),
            'title',
          );

          const newList: any = [];

          // 평가 값.
          for (const key in evalItems) {
            const count: number = evalItems[key].length;
            const sum: number = evalItems[key].reduce((acc: number, current: any) => {
              return acc + current.submitVal;
            }, 0);
            const avg: number = Number((sum / count).toFixed(1));
            newList.push({
              평가항목: key,
              평균점수: avg,
              '총 점수': sum,
              '참여 주제수': count,
            });
          }

          setEvalAvgs([...newList]);
        } else {
          setEvalAvgs([]);
        }
      }

      setSubjects([...subjects]);
    } catch (error: any) {
      dispatch(
        modalOpen({
          type: 'alert',
          isOpen: true,
          title: '오류',
          message: error.message
            ? error.message
            : `오류가 발생하였습니다. [오류코드 ${error.status}]`,
          onOk () {
            setIsOpen(false);
          },
        }),
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubjectClick = async (id: number) => {
    try {
      setIsLoading(true);
      if (!user) {
        throw new Error('원생의 정보를 찾을 수 없습니다');
      }

      const { data } = await subjectApi.submitList({
        page: 1,
        limit: 1,
        search: {
          subject: { id },
          targetUser: { id: user.id },
        },
      });

      if (data.length < 1) {
        dispatch(
          modalOpen({
            type: 'alert',
            message: '평가가 완료되지 않았습니다',
          }),
        );
        return;
      }

      setSubjectId(data[0].id);
      setIsModalOpen(true);
    } catch (error: any) {
      dispatch(
        modalOpen({
          type: 'alert',
          isOpen: true,
          title: '오류',
          message: error.message
            ? error.message
            : `오류가 발생하였습니다. [오류코드 ${error.status}]`,
          onOk () {
            setIsOpen(false);
          },
        }),
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Dialog className="relative z-[1001]" open={isOpen} onClose={() => []}>
        <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
        {/* container */}
        <div className="fixed flex inset-0 w-full items-center justify-center overflow-y-auto p-4">
          <Dialog.Panel className="relative w-full max-w-full lg:max-w-2xl xl:max-w-4xl rounded-lg bg-white shadow">
            {isLoading && (
              <div className="absolute w-full h-full bg-white/75 rounded-xl flex items-center justify-center">
                <TailSpin
                  height="48"
                  width="48"
                  color="#ff9e18"
                  ariaLabel="tail-spin-loading"
                  radius="1"
                  visible={true}
                  wrapperClass={'mx-auto my-auto'}
                />
              </div>
            )}
            <Card
              title={
                <div className="flex items-center justify-between">
                  <ChildInfo
                    name={user?.userName as string}
                    orgName={classTitle}
                    profile={user?.photo?.path}
                  />
                </div>
              }
              actions={
                <Button color="secondary" plain onClick={() => setIsOpen(false)}>
                  확인
                </Button>
              }>
              <div className="w-full h-full lg:h-[420px]">
                <div className="grid grid-cols-12 gap-4 lg:h-full">
                  <div className="col-span-12 lg:col-span-5 h-full flex items-center justify-center">
                    {!courseId && (
                      <p className="text-center">
                        수업을 선택하시면 수업의 평균 점수를 보실 수 있습니다
                      </p>
                    )}
                    {courseId && (
                      <>
                        {evalAvgs.length === 0 && (
                          <p className="text-center">평가된 수업이 없습니다</p>
                        )}
                        {evalAvgs.length > 0 && (
                          <div className="lg:w-[320px] lg:h-[320px]">
                            <RatingGraph
                              data={evalAvgs}
                              keys={['평균점수']}
                              indexBy={'평가항목'}
                              maxValue={evalAvgMaxValue}
                            />
                          </div>
                        )}
                      </>
                    )}
                  </div>
                  <div className="col-span-12 lg:col-span-7">
                    <div className="pb-4">
                      <CourseSelect
                        requiredClassId
                        classId={classId}
                        value={courseId}
                        onChange={handleChangeCourse}
                      />
                    </div>
                    <div className="grid grid-cols-2 gap-4 max-h-[20rem] overflow-y-auto auto-rows-auto">
                      {(!subjects || subjects?.length === 0) && (
                        <p className="col-span-2 text-center">수업을 선택해주세요</p>
                      )}
                      {subjects?.map((value) => {
                        return (
                          <Item
                            key={value.id}
                            id={value.id}
                            disable={!value.userData} // 출석부를 먼저 가지고 와야 할까?
                            title={value.title || ''}
                            onClick={handleSubjectClick}
                          />
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </Card>
          </Dialog.Panel>
        </div>
      </Dialog>
      <RatingModal
        id={subjectId + ''}
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        onUpdated={() => {
          fetchData();
        }}
      />
    </>
  );
};
