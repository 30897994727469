import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import DaumPostcodeEmbed from 'react-daum-postcode';

import * as orgApi from '@api/org';

import { Button, Card } from '@components';
import { Dialog } from '@headlessui/react';
import { useAppDispatch, useAppSelector } from '@store/hook';
import { selectUser } from '@store/user';
import { open as modalOpen } from '@store/modal';
import { open as loadingOpen, close as loadingClose } from '@store/loading';
import { OrgDto } from '@dto';
import { toast } from 'react-toastify';
import { TelInput } from '@components/common/TelInput';

export const Preschool = () => {
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [zipCodeOpen, setZipCodeOpen] = useState<boolean>(false);
  const [org, setOrg] = useState<OrgDto | undefined>();
  const { user } = useAppSelector(selectUser);
  const dispatch = useAppDispatch();

  const fetchData = useCallback(
    async () => {
      try {
        dispatch(loadingOpen());

        if (!user) {
          return;
        }

        if (!user.org) {
          throw new Error('유치원 정보를 불러오지 못했습니다.');
        }

        const org = await orgApi.getById(user.org.id as number);

        if (!org) {
          throw new Error('');
        }

        setOrg(org);

        setValue('title', org.title as string);

        if (org.profile) {
          setValue('postCode', org.profile.postCode);
          setValue('address', org.profile.address);
          setValue('detailAddress', org.profile.detailAddress);
          setValue('telno', org.profile.telno);
        }
      } catch (error: any) {
        dispatch(
          modalOpen({
            type: 'alert',
            isOpen: true,
            title: '오류',
            message: error.message
              ? error.message
              : `오류가 발생하였습니다. [오류코드 ${error.status}]`,
          }),
        );
      } finally {
        dispatch(loadingClose());
      }
    },
    // eslint-disable-next-line
    [user],
  );

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const scheme = yup.object().shape({
    title: yup.string().required('유치원 이름을 입력해주세요'),
    address: yup.string().required('주소를 입력해주세요'),
    detailAddress: yup.string().required('상세주소를 입력해주세요'),
    postCode: yup.string().required('우편번호를 입력해주세요'),
    telno: yup
      .string()
      .required('전화번호를 입력해주세요')
      .matches(/^01([0|1|6|7|8|9])-([0-9]{3,4})-([0-9]{4})$/, '전화번호 양식에 맞게 입력해주세요.'),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    defaultValues: {
      title: '',
      address: '',
      detailAddress: '',
      postCode: '',
      telno: '',
    },
    resolver: yupResolver(scheme),
  });

  const handleZipCodeComplete = (data: any) => {
    let fullAddress = data.address;
    let extraAddress = '';

    if (data.addressType === 'R') {
      if (data.bname !== '') {
        extraAddress += data.bname;
      }
      if (data.buildingName !== '') {
        extraAddress += extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== '' ? ` (${extraAddress})` : '';
    }

    setValue('address', fullAddress);
    setValue('postCode', data.zonecode);
    setZipCodeOpen(false);
  };

  const updateProc = async (data: any) => {
    try {
      console.log(data);
      dispatch(loadingOpen());
      if (!org) {
        throw new Error('수정할 대상을 찾을 수 없습니다');
      }

      const newOrg: OrgDto = {
        id: org.id,
        title: data.title,
        profile: {
          postCode: data.postCode,
          address: data.address,
          detailAddress: data.detailAddress,
          telno: data.telno,
        },
      };

      const reuslt = await orgApi.updateById(newOrg);

      if (!reuslt) {
        throw new Error('수정에 실패하였습니다');
      }

      fetchData();
      setIsEdit(false);
      toast.info('어린이집 정보를 수정하였습니다');
    } catch (error: any) {
      dispatch(
        modalOpen({
          type: 'alert',
          isOpen: true,
          title: '오류',
          message: error.message
            ? error.message
            : `오류가 발생하였습니다. [오류코드 ${error.status}]`,
        }),
      );
    } finally {
      dispatch(loadingClose());
    }
  }

  const onSubmit = (data: any) => {
    dispatch(modalOpen({
      type: 'confirm',
      message: '어린이집 정보를 수정하시겠습니까?',
      onOk() {
        updateProc(data);
      }
    }));
  };

  const handleReset = () => {
    const resetData: any = {
      title: org?.title,
    };

    if (org?.profile) {
      resetData.postCode = org.profile.postCode;
      resetData.address = org.profile.address;
      resetData.detailAddress = org.profile.detailAddress;
      resetData.telno = org.profile.telno;
    }

    reset(resetData);
    setIsEdit(false);
  };

  return (
    <>
      <div className="w-full flex flex-col">
        <h3 className="text-3xl font-semibold tracking-wider mb-4">어린이집 관리</h3>
        <div className="w-full md:max-w-md lg:max-w-lg xl:max-w-xl mx-auto bg-white rounded-lg shadow-lg p-4">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="form-control mb-4">
              <label className="label">
                <span className={`label-text ${errors.title && 'text-error'}`}>유치원 이름</span>
              </label>
              <input
                type="text"
                className={`input input-bordered text-sm ${errors.title && 'input-error'}`}
                disabled={!isEdit}
                {...register('title')}
              />
              {errors.title && (
                <span className="text-xs mt-2 text-danger tracking-wide">
                  {errors.title.message}
                </span>
              )}
            </div>
            <div className="form-control mb-4">
              <label className="label">
                <span
                  className={`label-text ${
                    errors.postCode && errors.address && errors.detailAddress && 'text-error'
                  }`}>
                  주소
                </span>
              </label>
              <input
                type="text"
                className={`input input-bordered text-sm ${
                  errors.postCode ? 'input-error' : 'mb-2'
                }`}
                disabled={!isEdit}
                readOnly
                {...register('postCode')}
                onClick={() => {
                  setZipCodeOpen(true);
                }}
              />
              {errors.postCode && (
                <span className="text-xs mt-2 text-danger tracking-wide">
                  {errors.postCode.message}
                </span>
              )}
              <input
                type="text"
                className={`input input-bordered text-sm ${
                  errors.address ? 'input-error' : 'mb-2'
                }`}
                disabled={!isEdit}
                readOnly
                {...register('address')}
                onClick={() => {
                  setZipCodeOpen(true);
                }}
              />
              {errors.address && (
                <span className="text-xs mt-2 text-danger tracking-wide">
                  {errors.address.message}
                </span>
              )}
              <input
                type="text"
                className={`input input-bordered text-sm ${errors.detailAddress && 'input-error'}`}
                disabled={!isEdit}
                {...register('detailAddress')}
              />
              {errors.detailAddress && (
                <span className="text-xs mt-2 text-danger tracking-wide">
                  {errors.detailAddress.message}
                </span>
              )}
            </div>
            <div className="form-control mb-4">
              <label className="label">
                <span className={`label-text ${errors.telno && 'text-error'}`}>전화번호</span>
              </label>
              <TelInput
                register={register('telno')}
                className={`${errors.telno && 'input-error'}`}
                disabled={!isEdit}
                />
              {errors.telno && (
                <span className="text-xs mt-2 text-danger tracking-wide">
                  {errors.telno.message}
                </span>
              )}
            </div>
            <div className={`flex ${isEdit ? 'justify-end space-x-2' : 'justify-between'}`}>
              {!isEdit && (
                <>
                  <Button
                    color="secondary"
                    outlined
                    className="hover:!text-white hover:!font-light"
                    plain
                    onClick={() => setIsEdit(true)}>
                    <span className="">수정</span>
                  </Button>
                </>
              )}
              {isEdit && (
                <>
                  <Button
                    color="error"
                    className="bg-danger border-danger hover:bg-danger/80 hover:border-danger/80 focus-visible:outline-danger"
                    onClick={handleReset}>
                    <span className="text-white font-light">취소</span>
                  </Button>
                  <Button type="submit" color="secondary" className="text-white font-normal">
                    <span className="text-white font-light">수정</span>
                  </Button>
                </>
              )}
            </div>
          </form>
        </div>
      </div>
      <Dialog className="relative z-[1010]" open={zipCodeOpen} onClose={() => {}}>
        {/* BACKDROP */}
        <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
        <div className="fixed inset-0 flex min-h-full items-center justify-center">
          <Dialog.Panel className="w-full lg:max-w-lg">
            <Card
              title={'주소 찾기'}
              actions={
                <Button
                  plain
                  className="font-normal text-sm"
                  onClick={() => {
                    setZipCodeOpen(false);
                  }}>
                  닫기
                </Button>
              }>
              <DaumPostcodeEmbed style={{ height: 500 }} onComplete={handleZipCodeComplete} />
            </Card>
          </Dialog.Panel>
        </div>
      </Dialog>
    </>
  );
};
