import React, { useCallback, useEffect, useState } from 'react';
import { SurveyItem } from '@components/SurveyItem';
import { SurveyDetail } from '@components/SurveyDetail';
import { Pagination } from '@components/Pagination';
import * as voteApi from '@api/vote';
import { SurveyDto } from '../../dto/SurveyDto';
import { SearchDto } from '../../dto';
import { format, isAfter, isBefore } from 'date-fns';
import { useAppDispatch } from '@store/hook';
import { open as modalOpen } from '@store/modal';
interface SurveyListProp {
  readonly header?: boolean;
  readonly rows?: number;
  readonly pagination?: boolean;
  readonly useDashboard?: boolean;
}

export const SurveyList: React.FC<SurveyListProp> = ({
  header = true,
  rows = 10,
  pagination = true,
  useDashboard = false,
}: SurveyListProp) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [id, setId] = useState<number>(-1);
  const [activePage, setActivePage] = useState<number>(1);

  const [totalCount, setTotalCount] = useState<number>(0);
  const [list, setList] = useState<SurveyDto[]>([]);

  const dispatch = useAppDispatch();

  const fetchData = useCallback(
    async () => {
      try {
        const search: SearchDto = {
          page: activePage,
          limit: rows,
          search: {},
        };

        if (useDashboard) {
          search.search.submitYn = 'N';
          search.search.baseDate = format(new Date(), 'yyyy-MM-dd');
        }

        const response: any = await voteApi.list(search);

        setTotalCount(response.count);
        // console.log('data', response.data)
        // response.data.foreach((v: any) => {
        //   console.log(v)
        // })

        for (let i = 0; i < response.data.length; i++) {
          const d1 = response.data[i];
          if (d1.itemGroups?.length === d1.submitItems?.length) {
            response.data[i].voteYn = 'Y';
          } else {
            response.data[i].voteYn = 'N';
          }
        }
        setList([...response.data]);
      } catch (error) {
        console.log('error in survey', error);
      } finally {
      }
    },
    // eslint-disable-next-line
    [activePage, rows],
  );

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleRowClick = (id: number) => {
    const survey: SurveyDto | undefined = list.find((survey: SurveyDto) => survey.id === id);

    if (!survey) {
      // TODO 오류 알럿을 띄운다.
      return false;
    }

    if (survey.voteYn === 'N') {
      if (
        survey.startDate &&
        survey.endDate &&
        (isBefore(new Date(), new Date(survey.startDate)) ||
          isAfter(new Date(), new Date(survey.endDate)))
      ) {
        dispatch(
          modalOpen({
            type: 'alert',
            isOpen: true,
            title: '오류',
            message: '설문조사의 참여 기간이 아니기 때문에 설문조사에 참여하실 수 없습니다',
          }),
        );
        return;
      }
    }

    setId(id);
    setIsOpen(true);
  };

  const handlePageChange = (pageNumber: number) => {
    setActivePage(pageNumber);
  };

  const handleSubmit = () => {
    fetchData();
  };

  return (
    <>
      <table className="table w-full mb-4 border-y">
        <colgroup className={`hidden lg:table-column-group`}>
          <col width={'*'} />
          {header && <col width={'200px'} />}
          <col width={'150px'} />
        </colgroup>
        <colgroup className={`lg:hidden`}>
          <col width={'*'} />
        </colgroup>
        {header && (
          <thead className="hidden lg:table-header-group">
            <tr>
              <th className="h-12 text-sm text-black/60 border-b bg-slate-50 font-medium">제목</th>
              <th className="h-12 text-sm text-black/60 border-b bg-slate-50 font-medium">기간</th>
              <th className="h-12 text-sm text-black/60 border-b bg-slate-50 font-medium">
                작성날짜
              </th>
            </tr>
          </thead>
        )}
        <tbody className="bg-white">
          {list.length === 0 && (
            <tr>
              <td className="h-[280px] text-center text-sm text-gray-500" colSpan={3}>
                참여할 설문조사가 없습니다
              </td>
            </tr>
          )}
          {list.map((value, index) => (
            <SurveyItem
              id={value.id || 0}
              header={header}
              voteYn={value.voteYn}
              title={value.title || ''}
              startDate={value?.startDate || '1970-01-01'}
              endDate={value?.endDate || '2030-12-31'}
              createdAt={value.createdAt || ''}
              onClick={handleRowClick}
              key={index}
            />
          ))}
        </tbody>
      </table>
      {pagination && (
        <Pagination
          activePage={activePage}
          setActivePage={setActivePage}
          itemsCountPerPage={rows}
          totalItemsCount={totalCount}
          pageRangeDisplayed={5}
          onChange={handlePageChange}
        />
      )}
      <SurveyDetail id={id} isOpen={isOpen} setIsOpen={setIsOpen} onSubmit={handleSubmit} />
    </>
  );
};
