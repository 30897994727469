import React from 'react';
import { NoticeList } from '@components';

export const Notice = () => {
  return (
    <div className="w-full h-full">
      <h3 className="text-3xl font-semibold tracking-wider mb-4">공지사항</h3>
      <div className="w-full bg-white p-4 rounded-lg shadow-lg">
        <NoticeList />
      </div>
    </div>
  );
};
