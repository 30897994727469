import React, { useEffect, useState } from 'react';
import { Dialog } from '@headlessui/react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { Card, Button } from '@components';

interface IProp {
  readonly isOpen: boolean;
  readonly setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  readonly onOpen?: () => void;
  readonly onClose?: () => void;
}

interface ErrorAlert {
  show: boolean;
  message: string;
}

export const ChangePasswdModal: React.FC<IProp> = ({ isOpen, setIsOpen, onClose }: IProp) => {
  const [error, setError] = useState<ErrorAlert>({ show: false, message: '' });

  const schema = yup.object().shape({
    password: yup
      .string()
      .min(6, '비밀번호를 6자 이상 입력해주세요')
      .max(16)
      .required('현재 비밀번호를 입력해주세요'),
    newPassword: yup
      .string()
      .min(6, '새로운 비밀번호를 6자 이상 입력해주세요')
      .max(16)
      .notOneOf(
        [yup.ref('password'), null],
        '기존 비밀번호와 동일한 비밀번호는 사용해서는 안됩니다.',
      )
      .required('새로운 비밀번호를 입력해주세요'),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref('newPassword'), null], '새로운 비밀번호와 동일해야 합니다.')
      .min(6, '비밀번호 확인을 6자 이상 입력해주세요')
      .max(16)
      .required('비밀번호 확인을 입력해주세요.'),
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    defaultValues: {
      password: '',
      newPassword: '',
      confirmPassword: '',
    },
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (!isOpen) {
      reset({
        password: '',
        newPassword: '',
        confirmPassword: '',
      });
    }
  }, [isOpen, reset]);

  const handleClose = () => {
    setIsOpen(false);
    if (onClose) {
      onClose();
    }
  };

  const onSubmit = (data: any) => {
    if (data.password === '12345678') {
      setError({
        show: true,
        message: '현재 사용하시는 비밀번호와 동일하지 않습니다.',
      });
      reset();
    } else {
      setIsOpen(false);
    }
  };

  return (
    <Dialog as="div" className="relative z-[1000]" open={isOpen} onClose={() => {}}>
      <div className="fixed inset-0 bg-black bg-opacity-25" aria-hidden="true" />
      <div className="fixed inset-0 flex min-h-full items-center justify-center px-4">
        <Dialog.Panel className="w-full md:max-w-md lg:max-w-2xl bg-white shadow rounded-lg">
          <form onSubmit={handleSubmit(onSubmit)}>
            <Card
              title={'비밀번호 변경'}
              actions={
                <>
                  <Button plain className="text-gray-500" onClick={handleClose}>
                    취소
                  </Button>
                  <Button plain color="secondary" type="submit">
                    비밀번호 변경
                  </Button>
                </>
              }>
              <>
                {error.show && (
                  <div className="px-4 pb-4">
                    <div className="alert alert-error rounded-lg text-white">{error.message}</div>
                  </div>
                )}
                <div className="px-4 pb-4 form-control">
                  <label className="label">
                    <span className={`label-text ${errors.password && 'text-error'}`}>
                      현재 비밀번호
                    </span>
                  </label>
                  <input
                    type="password"
                    className={`input input-bordered text-sm ${errors.password && 'input-error'}`}
                    {...register('password')}
                  />
                  <span className="text-xs mt-2 text-danger tracking-wide">
                    {errors.password && errors.password.message}
                  </span>
                </div>
                <div className="px-4 pb-4 form-control">
                  <label className="label">
                    <span className={`label-text ${errors.newPassword && 'text-error'}`}>
                      비밀번호
                    </span>
                  </label>
                  <input
                    type="password"
                    className={`input input-bordered text-sm ${
                      errors.newPassword && 'input-error'
                    }`}
                    {...register('newPassword')}
                  />
                  <span className="text-xs mt-2 text-danger tracking-wide">
                    {errors.newPassword && errors.newPassword.message}
                  </span>
                </div>
                <div className="px-4 pb-4 form-control">
                  <label className="label">
                    <span className={`label-text ${errors.confirmPassword && 'text-error'}`}>
                      비밀번호 확인
                    </span>
                  </label>
                  <input
                    type="password"
                    className={`input input-bordered text-sm ${
                      errors.confirmPassword && 'input-error'
                    }`}
                    {...register('confirmPassword')}
                  />
                  <span className="text-xs mt-2 text-danger tracking-wide">
                    {errors.confirmPassword && errors.confirmPassword.message}
                  </span>
                </div>
              </>
            </Card>
          </form>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
};
