import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { PageHeader } from '@components';
import { forgotPassword } from '@api/auth';
import { useAppDispatch } from '@store/hook';
import { open as modalOpen } from '@store/modal';

export const FindPassword = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<{ show: boolean; message: string }>({
    show: false,
    message: '',
  });

  const scheme = yup.object().shape({
    email: yup
      .string()
      .email('올바른 이메일 형식이 아닙니다.')
      .required('아이디(이메일)을 입력해주세요.'),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    defaultValues: {
      email: '',
    },
    resolver: yupResolver(scheme),
  });

  const onSubmit = async (data: any) => {
    try {
      setLoading(true);
      setError({ show: false, message: '' });
      await forgotPassword(data.email);
      // TODO MODAL...

      dispatch(
        modalOpen({
          type: 'alert',
          message: '이메일 발송이 완료되었습니다.',
          isOpen: true,
          onOk: () => {
            navigate('/signin');
          },
        }),
      );
    } catch (error: any) {
      if (error.status === 422) {
        setError({
          show: true,
          message: '등록된 이메일이 아닙니다',
        });
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="w-full h-screen flex flex-col items-center overflow-y-auto">
      <PageHeader auth prev={'/signin'} />
      <div className="container flex items-center">
        <div className="w-full max-w-md pt-8 lg:pt-24 pb-24 mx-auto px-4">
          <h3 className="mb-4 text-3xl font-semibold tracking-wider">비밀번호 찾기</h3>
          {error.show && (
            <div className="alert alert-error text-sm text-white mb-4 font-light tracking-wider">
              {error.message}
            </div>
          )}
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-6">
              <div className="form-control w-full mb-4">
                <label className="label">
                  <span className={`label-text text-sm ${errors.email && 'text-error'}`}>
                    아이디 (이메일)
                  </span>
                </label>
                <input
                  type="email"
                  placeholder="아이디(이메일)을 입력해주세요"
                  className={`input input-bordered w-full text-sm ${errors.email && 'input-error'}`}
                  {...register('email')}
                  disabled={loading}
                />
                {errors.email && (
                  <div className="mt-1 text-sm text-error">{errors.email.message}</div>
                )}
              </div>
            </div>
            <div className="pb-6">
              <button
                type="submit"
                className={`btn btn-secondary w-full font-normal text-white ${
                  loading && 'loading'
                }`}
                disabled={loading}>
                {!loading && '임시 비밀번호 발급'}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
