import React, { useState, useEffect } from 'react';
import { ResponsiveRadar } from '@nivo/radar';

interface IProps {
  readonly data: any;
  readonly keys: Array<string>;
  readonly indexBy: string;
  readonly maxValue?: number | 'auto';
}

export const RatingGraph = ({
  data: _data,
  keys: _keys,
  indexBy: _indexBy,
  maxValue: _maxValue = 'auto',
}: IProps) => {
  const [data, setData] = useState<any>([]);
  const [keys, setKeys] = useState<string[]>([]);
  const [indexBy, setIndexBy] = useState<string>('');
  const [maxValue, setMaxValue] = useState<number | 'auto' | undefined>('auto');

  useEffect(() => {
    setData([..._data]);
  }, [_data]);

  useEffect(() => {
    setKeys(_keys);
  }, [_keys]);

  useEffect(() => {
    setIndexBy(_indexBy);
  }, [_indexBy]);

  useEffect(() => {
    setMaxValue(_maxValue);
  }, [_maxValue]);

  return (
    <>
      {data.length > 0 && (
        <ResponsiveRadar
          data={data}
          keys={keys}
          indexBy={indexBy}
          valueFormat=">-.2f"
          gridShape="linear"
          margin={{ top: 70, right: 80, bottom: 40, left: 80 }}
          borderColor={{ from: 'color' }}
          gridLabelOffset={12}
          dotSize={10}
          dotColor={{ theme: 'background' }}
          dotBorderWidth={2}
          colors={{ scheme: 'nivo' }}
          blendMode="multiply"
          motionConfig="wobbly"
          maxValue={maxValue}
          legends={[
            {
              anchor: 'top-left',
              direction: 'column',
              translateX: -50,
              translateY: -40,
              itemWidth: 80,
              itemHeight: 20,
              itemTextColor: '#999',
              symbolSize: 12,
              symbolShape: 'circle',
              effects: [
                {
                  on: 'hover',
                  style: {
                    itemTextColor: '#000',
                  },
                },
              ],
            },
          ]}
        />
      )}
    </>
  );
};
