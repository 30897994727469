import React from 'react';
import { createGlobalStyle } from 'styled-components';
import tw, { GlobalStyles as BaseStyles } from 'twin.macro';

const CustomStyles = createGlobalStyle`
  html,
  body,
  #root {
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    overflow: hidden;
  }

  body {
    background-color: var(--addi-background-color);
    color: var(--addi-font-color);
    font-size: var(--addi-font-size);
    ${tw`antialiased`}
  }

  .react-daterange-picker__wrapper {
    border: 0;
  }

  .react-calendar {
    ${tw`border`}
    ${tw`border-gray-300`}
    ${tw`p-2`}
  }

  .react-calendar__tile--rangeStart {
    ${tw`rounded-l-lg`}
  }
  .react-calendar__tile--rangeEnd {
    ${tw`rounded-r-lg`}
  }

  .react-date-picker--disabled {
    course: not-allowed;
    --tw-border-opacity: 1;
    border-color: hsl(var(--b2, var(--b1)) / var(--tw-border-opacity));
    --tw-bg-opacity: 1;
    background-color: hsl(var(--b2, var(--b1)) / var(--tw-bg-opacity));
    --tw-text-opacity: 0.2;
  }

  .rangeslider {
    box-shadow: none;
    margin: 0;
  }

  .rangeslider-horizontal {
    height: 8px;
  }

  .rangeslider-horizontal .rangeslider__handle {
    width: 16px;
    height: 16px;
    border-radius: 16px;
  }

  .rangeslider .rangeslider__handle {
    box-shadow: none;
  }

  .rangeslider-horizontal .rangeslider__handle:after {
    display: none;
  }

  .rangeslider-horizontal .rangeslider__fill {
    background-color: ${tw`bg-primary`};
  }

  .rangeslider, .rangeslider .rangeslider__fill {
    box-shadow: none;
  }

  .react-date-picker__wrapper {
    border: none !important;
  }

  .carousel-root {
    width: 100%;
  }

  .carousel-root > .carousel.carousel-slider {
    height: 100%;
  }

  .carousel .slider-wrapper {
    height: 100%;
  }

  .carousel .slider-wrapper .slider {
    height: 100%;
  }

  .carousel .slider-wrapper .slider .slide {
    height: 100%;
  }

  .carousel .slide img {
    width: auto;
  }

  .album-select > div {
    height: auto;
  }
`;

const GlobalStyles = () => (
  <>
    <BaseStyles />
    <CustomStyles />
  </>
);

export default GlobalStyles;
