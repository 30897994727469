import React, { useCallback, useEffect, useState } from 'react';

import { QueueListIcon } from '@heroicons/react/24/outline';

import { Button, Pagination, InviteCreate } from '@components';
import { InviteDetail } from '@components/InviteDetail';
import * as userApi from '@api/user';
import * as authApi from '@api/auth';
import { ROLE, USER_STATE } from '@common';
import { UserDto } from '@dto';
import { useAppDispatch } from '@store/hook';
import { open as modalOpen } from '@store/modal';
import { open as loadingOpen, close as loadingClose } from '@store/loading';
import { toast } from 'react-toastify';
import { ChildTabs } from './child.tab';

export const Invite = () => {
  const [userId, setUserId] = useState<number>();
  const [tabId, setTabId] = useState<string>(USER_STATE.CONFIRM);
  const rows: number = 10;
  const [list, setList] = useState<UserDto[]>([]);
  const [activePage, setActivePage] = useState<number>(1);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [create, setCreate] = useState<boolean>(false);
  const [detail, setDetail] = useState<boolean>(false);
  const Tabs = [
    { id: USER_STATE.CONFIRM, name: '승인' },
    { id: USER_STATE.WAIT, name: '초대' },
  ];
  const dispatch = useAppDispatch();

  const fetchData = useCallback(async () => {
    try {
      const result: any = await userApi.list({
        page: activePage,
        limit: rows,
        search: {
          status: tabId,
        },
      });

      setTotalPage(result.count);
      setList([...result.data]);
    } catch (error) {
    } finally {
    }
  }, [activePage, tabId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleChangeTabs = (id: number | string) => {
    setTabId(id as string);
    handlePageChange(1);
  };

  const handlePageChange = (pageNumber: number) => {
    setActivePage(pageNumber);
  };

  /**
   * 초대를 한다
   */
  const handleInvite = () => {
    setCreate(true);
  };

  /**
   * 등록 완료가 되었을 시 자동으로 업데이트 한다
   */
  const handleAddComplete = () => {
    handlePageChange(1);
    fetchData();
  };

  /**
   * 메일을 재전송한다.
   * @param email
   */
  const handleResend = (email: string | undefined) => {
    dispatch(
      modalOpen({
        isOpen: true,
        type: 'confirm',
        message: `${email}에 초대메일을 재전송 하시겠습니까?`,
        onOk: async () => {
          try {
            dispatch(loadingOpen());
            if (!email) {
              throw new Error('발송할 대상이 없습니다');
            }
            await authApi.resend(email);
            toast.info('재전송을 하였습니다');
          } catch (error: any) {
            if (typeof error === 'string') {
              toast.error(error);
            } else {
              toast.error(`오류가 발생하였습니다 오류 코드 [${error.status}]`);
            }
          } finally {
            dispatch(loadingClose());
          }
        },
      }),
    );
  };

  /**
   * 회원가입 승인
   * @param id
   * @param userName
   */
  const handleApprove = (id: number | undefined, userName: string | undefined = '') => {
    dispatch(
      modalOpen({
        isOpen: true,
        type: 'confirm',
        message: `${userName}의 회원가입을 승인하시겠습니까?`,
        onOk: async () => {
          try {
            dispatch(loadingOpen());
            if (!id) {
              throw new Error('회원가입 승인할 대상을 찾지 못했습니다');
            }
            await userApi.approveUser(id);
            toast.info('회원가입을 승인하였습니다');
            fetchData();
          } catch (error: any) {
            if (error.message) {
              toast.error(error.message);
            } else {
              toast.error(`오류가 발생하였습니다 오류 코드 [${error.status}]`);
            }
          } finally {
            dispatch(loadingClose());
          }
        },
      }),
    );
  };

  /**
   * 회원가입 거절
   * @param id
   * @param userName
   */
  const handleDeny = (id: number | undefined, userName: string | undefined = '') => {
    dispatch(
      modalOpen({
        isOpen: true,
        type: 'confirm',
        message: `${userName}의 회원가입을 거절하시겠습니까?`,
        onOk: async () => {
          try {
            dispatch(loadingOpen());
            if (!id) {
              throw new Error('회원가입 거절할 대상을 찾지 못했습니다');
            }
            await userApi.denyUser(id);
            toast.info('회원가입을 거절하였습니다');
            fetchData();
          } catch (error: any) {
            if (error.message) {
              toast.error(error.message);
            } else {
              toast.error(`오류가 발생하였습니다 오류 코드 [${error.status}]`);
            }
          } finally {
            dispatch(loadingClose());
          }
        },
      }),
    );
  };

  return (
    <>
      <div className="w-full">
        <h3 className="text-3xl font-semibold tracking-wider mb-4">승인/초대</h3>
        <div className="flex flex-col bg-white w-full rounded-lg shadow-xl p-4 mb-4">
          <div className="flex items-center justify-between mb-4">
            <ChildTabs children={Tabs} onChange={handleChangeTabs}></ChildTabs>
            {tabId === USER_STATE.WAIT && (
              <Button color="primary" onClick={handleInvite}>
                <span className="text-sm text-white font-normal hover:text-white">초대하기</span>
              </Button>
            )}
          </div>
          <div className="w-full h-12 mb-4 px-2">
            {tabId === USER_STATE.CONFIRM && (
              <div className="text-lg font-medium tracking-wider">승인 대기 중인 멤버</div>
            )}
            {tabId === USER_STATE.WAIT && (
              <div className="text-lg font-medium tracking-wider">초대 중인 멤버</div>
            )}
          </div>
          <div className="w-full overflow-x-auto">
            <table className="table w-full mb-4 border-y">
              <colgroup>
                <col width={'20%'} />
                <col width={'20%'} />
                <col width={'15%'} />
                <col width={'*'} />
                <col width={'15%'} />
              </colgroup>
              <thead>
                <tr>
                  <th className="h-12 text-sm text-black/60 border-b bg-slate-50 font-medium lg:indent-12 !relative">
                    이메일
                  </th>
                  <th className="h-12 text-sm text-black/60 border-b bg-slate-50 font-medium lg:indent-12">
                    이름
                  </th>
                  <th className="h-12 text-sm text-black/60 border-b bg-slate-50 font-medium lg:indent-12">
                    역할
                  </th>
                  {tabId === USER_STATE.CONFIRM && (
                    <th className="h-12 text-sm text-black/60 border-b bg-slate-50 font-medium text-center">
                      승인여부
                    </th>
                  )}
                  <th
                    className="h-12 text-sm text-black/60 border-b bg-slate-50 font-medium"
                    colSpan={2}></th>
                </tr>
              </thead>
              <tbody>
                {list.length === 0 && (
                  <tr>
                    <td className="py-8 text-sm text-center" colSpan={5}>
                      <div className="flex items-center justify-center mb-4">
                        <QueueListIcon className="w-24 h-24 text-gray-400" />
                      </div>
                      <p className="text-gray-400">데이터가 없습니다</p>
                    </td>
                  </tr>
                )}
                {list.map((value: UserDto, index: number) => (
                  <tr key={index}>
                    <td className="text-sm lg:indent-12">{value.email}</td>
                    <td className="text-sm lg:indent-12">{value.userName}</td>
                    <td className="text-sm lg:indent-12">
                      {value.roleId === ROLE.MEM_TEACHER && '선생님'}
                      {value.roleId === ROLE.MEM_PARENT && '학부모'}
                    </td>
                    {tabId === USER_STATE.CONFIRM && (
                      <td className="text-sm text-center space-x-2">
                        <Button
                          color="primary"
                          size="sm"
                          onClick={() => {
                            handleApprove(value.id, value.userName);
                          }}>
                          <span className="text-white text-xs font-light">승인</span>
                        </Button>
                        <Button
                          color="error"
                          size="sm"
                          className="bg-danger border-danger hover:bg-danger/80 hover:border-danger/80 focus-visible:outline-danger"
                          onClick={() => {
                            handleDeny(value.id, value.userName);
                          }}>
                          <span className="text-white text-xs font-light">거부</span>
                        </Button>
                      </td>
                    )}
                    {tabId === USER_STATE.CONFIRM && (
                      <td className="text-sm text-center">
                        <Button
                          color="primary"
                          size="sm"
                          className="font-normal hover:!text-white text-xs"
                          outlined
                          onClick={() => {
                            setUserId(value.id);
                            setDetail(true);
                          }}>
                          상세보기
                        </Button>
                      </td>
                    )}
                    {tabId === USER_STATE.WAIT && (
                      <td className="text-sm text-center" colSpan={2}>
                        <Button
                          color="primary"
                          size="sm"
                          outlined
                          className="mr-2 hover:!text-white"
                          onClick={() => {
                            handleResend(value.email);
                          }}>
                          <span className="text-xs">다시초대</span>
                        </Button>
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
            {totalPage > 0 && (
              <Pagination
                activePage={activePage}
                setActivePage={setActivePage}
                itemsCountPerPage={rows}
                totalItemsCount={totalPage}
                pageRangeDisplayed={5}
                onChange={handlePageChange}
              />
            )}
          </div>
        </div>
      </div>
      <InviteCreate isOpen={create} setIsOpen={setCreate} onAddComplete={handleAddComplete} />
      <InviteDetail isOpen={detail} setIsOpen={setDetail} id={userId} />
    </>
  );
};
