import React, { useEffect, useState } from 'react';
import { Dialog } from '@headlessui/react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Card, DatePicker, Profile } from '@components';
import { ClassSelect } from '@components/ClassSelect';
import * as userApi from '@api/user';
import { StudentDto } from '@dto/StudentDto';
import { useAppDispatch } from '@store/hook';
import { open as modalOpen } from '@store/modal';

import { ParentSelect } from '@components';
import { toast } from 'react-toastify';
import { TailSpin } from 'react-loader-spinner';
import { PhotoDto } from '@dto';
import { format } from 'date-fns';

interface ChildCreateProp {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onCreated: () => void;
}

export const ChildCreate: React.FC<ChildCreateProp> = ({ isOpen, setIsOpen, onCreated }) => {
  const [photo, setPhoto] = useState<PhotoDto>();
  const [orgId, setOrgId] = useState<number | undefined>(-1);
  const [parentSelect, setParentSelect] = useState<boolean>(false);
  const [parentId, setParentId] = useState<number>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  const scheme = yup.object().shape({
    userName: yup.string().required('이름을 입력해주세요'),
    birthDay: yup.date().required('생년월일을 입력해주세요'),
    orgId: yup.number(),
    // 부모없이 원생을 등록할수 있게 한다.
    // parentId: yup.number().min(1, '부모님을 선택해주세요').required('부모님을 선택해주세요'),
    parentName: yup.string(),
  });

  const {
    register,
    handleSubmit: onSubmit,
    reset,
    formState: { errors },
    setValue,
    control,
  } = useForm({
    mode: 'onBlur',
    defaultValues: {
      userName: '',
      birthDay: new Date(),
      orgId: -1,
      parentId: -1,
      parentName: '',
    },
    resolver: yupResolver(scheme),
  });

  useEffect(() => {
    if (!isOpen) {
      reset();
      setOrgId(-1);
      setPhoto(undefined);
      setParentId(undefined);
    }
  }, [isOpen, setOrgId, reset]);

  const handlePhotoUpload = (file: PhotoDto | undefined) => {
    setPhoto(file);
  };

  const handleReset = () => {
    reset();
    setPhoto(undefined);
    setIsOpen(false);
  };

  const handleSubmit = async (data: any) => {
    try {
      setIsLoading(true);
      const student: StudentDto = {
        userName: data.userName,
        parent: {
          id: data.parentId,
        },
        class: {
          id: data.orgId,
        },
        birthDay: format(data.birthDay, 'y-MM-dd'),
      };

      if (photo) {
        student.photo = {
          id: photo.id,
        };
      }

      await userApi.createStudent(student);
      toast.info('원생을 등록하였습니다');
      onCreated();
      setIsOpen(false);
    } catch (error: any) {
      dispatch(
        modalOpen({
          type: 'alert',
          isOpen: true,
          message: `오류가 발생하였습니다 [오류 코드 : ${error.status}]`,
          onOk: () => {
            console.log(error);
            setIsOpen(false);
          },
        }),
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleChangeParent = (id: number, userName: string) => {
    setParentId(id);
    setValue('parentId', id);
    setValue('parentName', userName);
  };

  return (
    <>
      <Dialog className="relative z-[1001]" open={isOpen} onClose={() => { }}>
        <div className="fixed inset-0 bg-black/75" aria-hidden="true" />
        <div className="fixed inset-0 flex items-center justify-center">
          <Dialog.Panel className="w-full md:max-w-md bg-white rounded-lg">
            <form onSubmit={onSubmit(handleSubmit)} className="w-full">
              <Card
                title={'원생 등록'}
                actions={
                  <>
                    <Button plain className="font-normal" onClick={handleReset}>
                      취소
                    </Button>
                    <Button type="submit" plain color="secondary" className="font-normal">
                      등록
                    </Button>
                  </>
                }>
                <div className="relative w-full">
                  {isLoading && (
                    <div className="w-full h-full absolute bg-white flex items-center justify-center">
                      <TailSpin
                        height="80"
                        width="80"
                        color="#ff9e18"
                        ariaLabel="tail-spin-loading"
                        radius="1"
                        visible={true}
                      />
                    </div>
                  )}
                  <div className="form-control mb-4 items-center">
                    <Profile photo={photo} onChange={handlePhotoUpload} isEdit={true} />
                  </div>
                  <div className="form-control mb-4">
                    <label className="label">
                      <span className={`label-text ${errors.parentId && 'text-error'}`}>
                        부모님
                      </span>
                    </label>
                    <input
                      className={`select select-bordered font-normal text-sm cursor-pointer ${errors.parentId && 'select-error'
                        }`}
                      onClick={() => setParentSelect(true)}
                      placeholder="부모님을 선택해주세요"
                      readOnly
                      {...register('parentName')}
                    />
                    <span className="mt-1 text-xs text-error">
                      {errors.parentId && errors.parentId.message}
                    </span>
                  </div>
                  <div className="form-control mb-4">
                    <label className="label">
                      <span className={`label-text ${errors.userName && 'text-error'}`}>이름</span>
                    </label>
                    <input
                      type="text"
                      className={`input input-bordered text-sm ${errors.userName && 'input-error'}`}
                      placeholder="이름을 입력해주세요"
                      {...register('userName')}
                    />
                    <span className="mt-1 text-xs text-error">
                      {errors.userName && errors.userName.message}
                    </span>
                  </div>
                  <div className="form-control">
                    <label className="label">
                      <span className={`label-text ${errors.birthDay && 'text-error'}`}>
                        생년월일
                      </span>
                    </label>
                    <DatePicker
                      control={control}
                      name={'birthDay'}
                      className={`${errors.birthDay && 'input-error'}`}
                      maxDate={new Date()}
                    />
                    <span className="mt-1 text-xs text-error">
                      {errors.birthDay && errors.birthDay.message}
                    </span>
                  </div>
                  <ClassSelect
                    value={orgId}
                    onChange={(id: number) => {
                      setOrgId(id);
                      setValue('orgId', id);
                    }}
                    className="mb-4"
                    error={errors.orgId}
                  />
                </div>
              </Card>
            </form>
          </Dialog.Panel>
        </div>
      </Dialog>
      <ParentSelect
        isOpen={parentSelect}
        setIsOpen={setParentSelect}
        value={parentId}
        onChange={handleChangeParent}
      />
    </>
  );
};
