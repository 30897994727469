import React from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';
import '@assets/tailwind.css';
import GlobalStyles from './styles/GlobalStyles';
// import App from './App';
import { Provider } from 'react-redux';
import { store } from './store/config';
import reportWebVitals from './reportWebVitals';
import { router } from './router';

/**
 * PRODUCTION 모드일 시 console에 대한 이벤트 없앰
 */
if (process.env.NODE_ENV === 'production') {
  console.log = () => {};
  console.error = () => {};
  console.debug = () => {};
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <GlobalStyles />
      <RouterProvider router={router} />
    </Provider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
