import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { Dialog } from '@headlessui/react';
import { PhotoDto, SearchDto, UserDto } from '@dto';
import { Button, Card, ClassSelect } from '@components';
import { debounce } from 'lodash';

import * as userApi from '@api/user';
import {
  ChevronDoubleDownIcon,
  ChevronDoubleRightIcon,
  ChevronDownIcon,
  ChevronRightIcon,
  UserCircleIcon,
  XMarkIcon,
} from '@heroicons/react/20/solid';
import { TailSpin } from 'react-loader-spinner';
import { useAppDispatch } from '@store/hook';
import { open as modalOpen } from '@store/modal';
import { USER_STATE } from '@common';

interface ChildItemProps {
  readonly id: number;
  readonly userName: string;
  readonly photo: PhotoDto | undefined;
  readonly orgName: string | undefined;
  readonly selected?: boolean;
  readonly onClick: (id: number) => void;
  readonly onRemove?: (id: number) => void;
}

type ChildSelectorProp = {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  /**
   * 선택된 아이들
   */
  members?: UserDto[];
  /**
   * 변경된 아이들을 내려준다
   */
  onChange: (members: UserDto[]) => void;
  isEdit?: boolean;
};

const ChildItem: React.FC<ChildItemProps> = ({
  id,
  userName,
  photo,
  orgName,
  selected = false,
  onClick,
  onRemove,
}) => {
  const [isSelected, setIsSelected] = useState<boolean>(selected);

  useEffect(() => {
    setIsSelected(selected);
  }, [selected]);

  /**
   * 아이템 선택
   */
  const handleClick = () => {
    onClick(id);
  };

  const handleRemove = (e: any) => {
    e.stopPropagation();
    if (onRemove) {
      onRemove(id);
    }
  };

  return (
    <div
      className={`w-full h-16 min-h-[4rem] flex items-center overflow-x-hidden cursor-pointer ${
        isSelected && 'bg-primary/75'
      }`}
      onClick={handleClick}>
      <div className="ml-2">
        <div className="w-12 h-12 rounded-full">
          {photo && (
            <img
              src={photo.path}
              alt={`profile_${id}`}
              className="w-[38.4px] h-[38.4px] rounded-full mx-auto my-auto"
            />
          )}
          {!photo && <UserCircleIcon className="w-full h-full fill-gray-400" />}
        </div>
      </div>
      <div className="ml-4">
        <div className={`text-sm ${isSelected && 'text-white'}`}>{userName}</div>
        <div className={`text-xs mt-1 truncate ${isSelected ? 'text-white' : 'text-gray-400'}`}>
          {orgName === '' ? '\u00A0' : orgName}
        </div>
      </div>
      {onRemove && (
        <div className="ml-auto mr-2">
          <button className="btn btn-ghost btn-circle btn-sm" onClick={handleRemove}>
            <XMarkIcon className="w-6 h-6 fill-gray-500" />
          </button>
        </div>
      )}
    </div>
  );
};

export const ChildSelector: React.FC<ChildSelectorProp> = ({
  isOpen,
  setIsOpen,
  members: _members,
  onChange,
  isEdit = false,
}) => {
  const [classId, setClassId] = useState<number | undefined>();
  const [childName, setChildName] = useState<string>('');
  const [allMembers, setAllMembers] = useState<UserDto[]>([]);
  const [members, setMembers] = useState<UserDto[]>([]);
  const [data, setData] = useState<UserDto[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [childIds, setChildIds] = useState<Set<number>>(new Set());

  const dispatch = useAppDispatch();

  const fetchData = useCallback(
    async (userName: string) => {
      try {
        if (!isOpen) {
          return;
        }
        setIsLoading(true);

        const search: SearchDto = {
          page: 1,
          limit: 1000,
          search: {
            status: USER_STATE.ACTIVATE,
          },
        };

        if (userName.trim().length > 0) {
          search.search.userName = userName;
        }

        if (classId && classId > 0) {
          search.search.class = {
            id: classId,
          };
        }

        const { data } = await userApi.childrenList(search);

        setData([...data]);
        if (userName.trim().length < 1 && (!classId || classId < 0)) {
          setAllMembers([...data]);
        }
      } catch (error: any) {
        dispatch(
          modalOpen({
            type: 'alert',
            isOpen: true,
            title: '오류',
            message: error.message
              ? error.message
              : `오류가 발생하였습니다. [오류코드 ${error.status}]`,
            onOk() {
              setIsOpen(false);
            },
          }),
        );
      } finally {
        setIsLoading(false);
      }
    },
    // eslint-disable-next-line
    [classId, isOpen],
  );

  const debounceFetchData = useMemo(
    () =>
      debounce((userName: string) => {
        fetchData(userName);
      }, 200),
    [fetchData],
  );

  useEffect(() => {
    fetchData('');
  }, [fetchData]);

  useEffect(() => {
    if (_members) {
      setMembers([..._members]);
    }
  }, [_members]);

  const handleOk = () => {
    onChange(members);
    setIsOpen(false);
  };

  const handleCancel = () => {
    setIsOpen(false);
  };

  const handleClassId = (id: number) => {
    setClassId(id);
    setChildIds(new Set());
  };

  const handleSelectItem = (id: number) => {
    if (childIds.has(id)) {
      childIds.delete(id);
    } else {
      childIds.add(id);
    }
    setChildIds(new Set(childIds));
  };

  const handleChangeUserName = (e: any) => {
    setChildName(e.target.value);
    debounceFetchData(e.target.value);
  };

  const handleSelectAdd = () => {
    if (childIds.size < 1) {
      return;
    }

    const arrChildIds: number[] = Array.from(childIds).filter(
      (id: number) => !members.find((u: UserDto) => u.id === id),
    );
    const newMembers: UserDto[] = [...data].filter((u: UserDto) =>
      arrChildIds.find((id: number) => id === u.id),
    );
    const sortMembers: UserDto[] = [...members, ...newMembers].sort(
      (a: UserDto, b: UserDto) => (b.id as number) - (a.id as number),
    );
    setMembers(sortMembers);
    setChildIds(new Set());
  };

  const handleAllAdd = () => {
    setMembers([...allMembers]);
    setChildIds(new Set());
  };

  const handleRemoveItem = (id: number) => {
    const newMembers: UserDto[] = [...members].filter((member: UserDto) => member.id !== id);
    setMembers(newMembers);
  };

  return (
    <Dialog open={isOpen} onClose={() => false} className="relative z-[1010]">
      <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
      <div className="fixed inset-0 flex items-center justify-center min-h-full">
        <Dialog.Panel className="w-full lg:max-w-2xl xl:max-w-4xl">
          <Card
            title={!isEdit ? '참여 원생 추가' : '참여 원생 변경'}
            actions={
              <>
                <Button plain className="text-gray-500 hover:bg-gray-500/20" onClick={handleCancel}>
                  취소
                </Button>
                <Button
                  plain
                  color="secondary"
                  className="hover:bg-secondary/20"
                  onClick={handleOk}>
                  확인
                </Button>
              </>
            }>
            <div className="grid grid-cols-12 gap-4 px-4 pt-4">
              {/* L */}
              <div className="col-span-12 lg:col-span-5">
                <ClassSelect
                  value={classId}
                  onChange={handleClassId}
                  defaultDisabled={false}
                  hideLabel
                />
                <div className="w-full border border-[hsl(0,0%,20%)] border-opacity-20 rounded-lg">
                  <div className="h-12 border-b border-[hsl(0,0%,20%)] border-opacity-20 flex">
                    <input
                      type="text"
                      className="input h-full text-sm w-full focus-visible:outline-none"
                      placeholder="검색하실 원생을 입력해주세요"
                      value={childName}
                      onChange={handleChangeUserName}
                    />
                  </div>
                  <div className="w-full h-48 lg:h-80 overflow-y-auto divide-y relative flex flex-col">
                    {isLoading && (
                      <div className="absolute flex w-full h-full bg-white/50">
                        <TailSpin
                          height="48"
                          width="48"
                          color="#ff9e18"
                          ariaLabel="tail-spin-loading"
                          radius="1"
                          visible={true}
                          wrapperClass={'mx-auto my-auto'}
                        />
                      </div>
                    )}
                    {data.length === 0 && (
                      <p className="text-gray-500 text-center my-auto">등록된 원생이 없습니다</p>
                    )}
                    {data.map((member: UserDto) => (
                      <ChildItem
                        key={member.id}
                        orgName={
                          (member?.class && member?.class.length > 0 && member.class[0].title) || ''
                        }
                        photo={member.photo}
                        userName={member.userName as string}
                        id={member.id as number}
                        onClick={handleSelectItem}
                        selected={childIds.has(member.id as number)}
                      />
                    ))}
                  </div>
                </div>
              </div>
              {/* C */}
              <div className="col-span-12 lg:col-span-2">
                <div className="h-full lg:mt-[52px] flex lg:flex-col items-center justify-center space-x-4 md:space-x-0 md:space-y-4">
                  <Button outlined color="primary" className="group" onClick={handleSelectAdd}>
                    <ChevronRightIcon className="w-6 h-6 hidden md:block group-hover:text-white" />
                    <ChevronDownIcon className="w-6 h-6 md:hidden group-hover:text-white" />
                  </Button>
                  <Button outlined color="primary" className="group" onClick={handleAllAdd}>
                    <ChevronDoubleRightIcon className="w-6 h-6 hidden md:block group-hover:text-white" />
                    <ChevronDoubleDownIcon className="w-6 h-6 md:hidden group-hover:text-white" />
                  </Button>
                </div>
              </div>
              {/* R */}
              <div className="col-span-12 lg:col-span-5">
                <label className="label lg:mt-4">
                  <span className="label-text">선택된 원생 목록</span>
                </label>
                <div className="w-full border border-[hsl(0,0%,20%)] border-opacity-20 rounded-lg h-60 lg:h-[369px] overflow-y-auto divide-y flex flex-col">
                  {members.length < 1 && (
                    <p className="text-gray-500 text-sm my-auto text-center">원생을 선택해주세요</p>
                  )}
                  {members.map((member: UserDto) => (
                    <ChildItem
                      key={member.id}
                      orgName={
                        (member?.class && member?.class.length > 0 && member.class[0].title) || ''
                      }
                      photo={member.photo}
                      userName={member.userName as string}
                      id={member.id as number}
                      onClick={() => {}}
                      onRemove={handleRemoveItem}
                    />
                  ))}
                </div>
              </div>
            </div>
          </Card>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
};
