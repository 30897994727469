import axiosInstance from '@api';
import { SearchDto } from '@dto';

/**
 * 공지사항 목록을 가져온다.
 * @param search
 * @returns
 */
export const list = (
  search: SearchDto,
): Promise<{
  count: number;
  totalPage: number;
  hasNextPPage: boolean;
  data: any[];
}> => axiosInstance.post('/notice/list', search);

/**
 * 상세 항목을 가져온다.
 * @param id
 * @returns
 */
export const detail = (id: number | string) => axiosInstance.get(`/notice/${id}`);

/**
 * 읽기 여부를 보낸디
 * @param id
 * @returns
 */
export const read = (id: number | string) => axiosInstance.get(`/notice/read/${id}`);
