import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { XMarkIcon } from '@heroicons/react/20/solid';
import Select from 'react-select';

import * as fileApi from '@api/file';
import * as albumApi from '@api/album';
import * as subjectApi from '@api/subject';

import { Button, Card, CourseSelect } from '@components';
import { PhotoDto, SubjectAlbumDto, SubjectDto, UserDto } from '@dto';
import { FILE_TYPE } from '@common';
import { useAppDispatch } from '@store/hook';
import { open as modalOpen } from '@store/modal';
import { TailSpin } from 'react-loader-spinner';

interface UserOption {
  readonly value: number;
  readonly label: string;
}

interface IProp {
  readonly album?: SubjectAlbumDto | undefined;
  readonly onSave: (album?: SubjectAlbumDto) => void;
  readonly onClose: () => void;
}

interface IThumbnail {
  readonly photo: PhotoDto;
  readonly index: number;
  readonly onRemove: (index: number) => void;
}

const Thumbnail: React.FC<IThumbnail> = ({ photo, index, onRemove }) => {
  const [src, setSrc] = useState<string>('');

  useEffect(() => {
    if (photo) {
      setSrc(photo.path || '');
    }
  }, [photo]);

  const handleRemove = (e: any) => {
    e.preventDefault();
    console.log('handleRemove');
    if (onRemove) {
      onRemove(index);
    }
  };

  return (
    <div className={`min-w-[45%] md:min-w-[25%] w-1/2 md:w-1/4 h-full rounded-lg relative border border-slate-200`}>
      <button
        className="btn btn-ghost btn-sm focus:border-transparent absolute right-1 top-1 px-1"
        onClick={handleRemove}>
        <XMarkIcon className="w-6 h-6 fill-gray-500" />
      </button>
      <img src={src} className="w-full h-full rounded-lg object-cover" alt={`활동사진`} />
    </div>
  );
};

export const AlbumForm = ({ album: _album, onSave, onClose }: IProp) => {
  const [album, setAlbum] = useState<SubjectAlbumDto | undefined>();
  const [courseId, setCourseId] = useState<number | undefined>();
  const [files, setFiles] = useState<PhotoDto[]>([]);
  const [subjects, setSubjects] = useState<SubjectDto[]>([]);
  const [members, setMembers] = useState<UserOption[]>([]);
  const [users, setUsers] = useState<UserOption[]>([]);
  const [subjectId, setSubjectId] = useState<number | undefined>();
  const [content, setContent] = useState<string>('');
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const dispatch = useAppDispatch();

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop: async (fs: File[]) => {
      try {
        const uploadAll: Promise<PhotoDto>[] = fs.map((f: File) => {
          const formData = new FormData();
          formData.append('file', f);
          formData.append('filetype', FILE_TYPE.NORMAL);
          return fileApi.upload(formData);
        });

        const results: PhotoDto[] = await Promise.all(uploadAll);
        setFiles([...files, ...results]);
      } catch (error: any) {
      } finally {
      }
    },
    noClick: files.length > 0,
    accept: {
      'image/*': ['.png', '.gif', '.jpeg', '.jpg'],
    },
  });

  const fetchSubjectData = useCallback(
    async () => {
      try {
        if (!_album) {
          return;
        }

        if (!isEdit) {
          return;
        }

        setIsLoading(true);

        const subject: SubjectDto = await subjectApi.itemById(_album?.subject.id);

        if (!subject) {
          return;
        }
        setAlbum({ ..._album });
        setFiles([..._album.files]);
        setContent(_album.content);
        const newUsers: UserOption[] = [..._album.users].map(
          (user: UserDto): UserOption => ({
            value: user.id as number,
            label: user.userName as string,
          }),
        );
        setSubjectId(_album.subject.id);
        setUsers(newUsers);

        if (subject.members) {
          const newMembers: UserOption[] = [...subject.members].map(
            (user: UserDto): UserOption => ({
              value: user.id as number,
              label: user.userName as string,
            }),
          );
          setMembers([{ label: '모든 원생 선택', value: -1 }, ...newMembers]);
        }
      } catch (error: any) {
        dispatch(
          modalOpen({
            type: 'alert',
            isOpen: true,
            title: '오류',
            message: error.message
              ? error.message
              : `오류가 발생하였습니다. [오류코드 ${error.status}]`,
            onOk() {
              onClose();
            },
          }),
        );
      } finally {
        setIsLoading(false);
      }
    },
    // eslint-disable-next-line
    [_album, isEdit],
  );

  useEffect(() => {
    fetchSubjectData();

    if (_album) {
      setIsEdit(true);
    } else {
      setIsEdit(false);
    }

    console.log('album', _album);
  }, [fetchSubjectData, _album]);

  const handleClose = () => {
    onClose();
  };

  /**
   * 썸네일을 없앤다
   * @param index
   */
  const handleThumbnailRemove = (index: number) => {
    const newFiles: PhotoDto[] = [...files].filter((_, i: number) => index !== i);
    setFiles([...newFiles]);
  };

  const handleChangeCourse = (id: number, members?: UserDto[], subjects?: SubjectDto[]) => {
    setCourseId(id);

    setSubjects(subjects ? [...subjects] : []);

    if (members) {
      const newMembers: UserOption[] = [...members].map(
        (value: UserDto): UserOption => ({
          label: value.userName as string,
          value: value.id as number,
        }),
      );
      setMembers([{ label: '모든 원생 선택', value: -1 }, ...newMembers]);
    } else {
      setMembers([]);
    }
  };

  const handleChangeSubject = (e: any) => {
    setSubjectId(Number(e.target.value));
  };

  const handleChangeContent = (e: any) => {
    setContent(e.target.value);
  };

  const handleSubmit = () => {
    if (isEdit) {
      dispatch(
        modalOpen({
          type: 'confirm',
          message: '앨범을 수정하시겠습니까?',
          onOk: updateProc,
        }),
      );
    } else {
      createProc();
    }
  };

  /**
   * 등록
   */
  const createProc = async () => {
    try {
      setIsLoading(true);
      if (!subjectId) {
        throw new Error('주제를 선택해주세요');
      }

      if (!files || files.length === 0) {
        throw new Error('활동사진은 한 장 이상 올리셔야 합니다');
      }

      if (!users || users.length === 0) {
        throw new Error('원생을 적어도 한 명을 선택하셔야 합니다');
      }

      const params: SubjectAlbumDto = {
        subject: {
          id: subjectId as number,
        },
        content: content,
        files: [...files],
        users: [],
      };

      params.users = [...users].map(({ value, label }) => ({ id: value }));
      console.log(params);
      const result = await albumApi.create(params);

      if (!result) {
        throw new Error('앨범 입력에 실패하였습니다');
      }

      onSave();
    } catch (error: any) {
      dispatch(
        modalOpen({
          type: 'alert',
          isOpen: true,
          title: '오류',
          message: error.message
            ? error.message
            : `오류가 발생하였습니다. [오류코드 ${error.status}]`,
        }),
      );
    } finally {
      setIsLoading(false);
    }
  };

  /**
   * 수정
   */
  const updateProc = async () => {
    try {
      setIsLoading(true);
      if (!subjectId) {
        throw new Error('주제를 선택해주세요');
      }

      if (!files || files.length === 0) {
        throw new Error('활동사진은 한 장 이상 올리셔야 합니다');
      }

      if (!users || users.length === 0) {
        throw new Error('원생을 적어도 한 명을 선택하셔야 합니다');
      }

      const params: SubjectAlbumDto = { ...(album as SubjectAlbumDto) };
      params.content = content;
      params.files = [...files];
      params.users = [...users].map(({ value }) => ({ id: value }));
      const result: SubjectAlbumDto = await albumApi.update(params.id as number, params);

      if (!result) {
        throw new Error('앨범 수정에 실패하였습니다');
      }

      onSave(result);
    } catch (error: any) {
      dispatch(
        modalOpen({
          type: 'alert',
          isOpen: true,
          title: '오류',
          message: error.message
            ? error.message
            : `오류가 발생하였습니다. [오류코드 ${error.status}]`,
        }),
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleChangeSelect = (selected: any) => {
    if (selected.find((option: any) => option.value === -1)) {
      setUsers([...members.slice(1)]);
    } else {
      setUsers([...selected]);
    }
  };

  const userSelectStyle = useMemo(
    () => ({
      control: (provided: any) => ({
        ...provided,
        height: '48px',
        paddingLeft: '0.5rem',
        borderRadius: '0.5rem',
        borderColor: 'hsl(0,0%,20%/20%)',
      }),
    }),
    [],
  );

  return (
    <div className={`relative w-full h-full ${!album ? 'p-4' : ''}`}>
      {isLoading && (
        <div className="absolute w-full h-full flex items-center justify-center bg-white/75 rounded-lg z-10">
          <TailSpin
            height="48"
            width="48"
            color="#ff9e18"
            ariaLabel="tail-spin-loading"
            radius="1"
            visible={true}
          />
        </div>
      )}
      <Card
        title={`앨범 ${album ? '수정' : '추가'}`}
        actions={
          <>
            <Button plain className="text-gray-500" onClick={handleClose}>
              취소
            </Button>
            <Button plain color="secondary" type="submit" onClick={handleSubmit}>
              저장
            </Button>
          </>
        }>
        <>
          <div className="grid grid-cols-2 gap-4">
            {!isEdit && (
              <>
                <CourseSelect
                  value={courseId}
                  onChange={handleChangeCourse}
                  className="col-span-2 lg:col-span-1"
                />
                <div className="form-control col-span-2 lg:col-span-1">
                  <label className="label">
                    <span className="label-text">주제</span>
                  </label>
                  <select
                    className="select select-bordered font-normal"
                    value={subjectId}
                    defaultValue={undefined}
                    onChange={handleChangeSubject}
                    disabled={subjects.length === 0}>
                    <option>주제를 선택해주세요</option>
                    {subjects.map((subject: SubjectDto) => (
                      <option key={subject.id} value={subject.id}>
                        {subject.title}
                      </option>
                    ))}
                  </select>
                </div>
              </>
            )}
            {isEdit && (
              <>
                <div className="form-control col-span-2 md:col-span-1">
                  <label className="label">
                    <span className="label-text">수업</span>
                  </label>
                  <div className="input flex items-center w-full text-sm text-gray-500">
                    <span>{album?.course?.title}</span>
                  </div>
                </div>
                <div className="form-control col-span-2 md:col-span-1">
                  <label className="label">
                    <span className="label-text">주제</span>
                  </label>
                  <div className="input flex items-center w-full text-sm text-gray-500">
                    <span>{album?.subject.title}</span>
                  </div>
                </div>
              </>
            )}
          </div>
          <div className="w-full overflow-x-hidden py-2 mb-4">
            <label className="label flex justify-between">
              <span className="label-text">활동사진</span>
              {files.length > 0 && (
                <button
                  className="btn btn-primary btn-sm text-white font-light"
                  onClick={() => {
                    open();
                  }}>
                  활동사진 올리기
                </button>
              )}
            </label>
            <div className="w-full border-y overflow-x-hidden py-2 h-48 relative">
              <div
                {...getRootProps({
                  className: `w-full h-full space-x-4 flex overflow-x-auto ${
                    files.length < 1 && 'flex-col'
                  }`,
                })}>
                <input {...getInputProps()} />
                {files.length > 0 &&
                  files.map((value: PhotoDto, index: number) => (
                    <Thumbnail
                      key={index}
                      index={index}
                      photo={value}
                      onRemove={handleThumbnailRemove}
                    />
                  ))}
                {files.length < 1 && (
                  <p className="text-sm text-gray-500 text-center my-auto">
                    드래그 앤 드롭 또는 클릭하여 활동사진을 올려주세요
                  </p>
                )}
              </div>
            </div>
          </div>
          <div className="form-control">
            <label className="label">
              <span className="label-text">설명</span>
            </label>
            <textarea
              className="textarea textarea-bordered text-sm resize-none"
              rows={3}
              placeholder="설명을 입력해주세요"
              value={content}
              onChange={handleChangeContent}
            />
          </div>
          <div className="form-control">
            <label className="label">
              <span className="label-text">원생</span>
            </label>
            <Select
              isMulti
              value={users}
              name="users"
              options={members}
              isDisabled={members.length === 0}
              placeholder={'원생을 선택해주세요'}
              className="text-sm h-auto album-select"
              noOptionsMessage={() => '데이터가 없습니다'}
              onChange={handleChangeSelect}
              styles={userSelectStyle}
            />
          </div>
        </>
      </Card>
    </div>
  );
};;
