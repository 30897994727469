import React, { useCallback, useEffect, useState } from 'react';

import { Dialog } from '@headlessui/react';

import * as subjectApi from '@api/subject';

import { useAppDispatch, } from '@store/hook';
import { open as modalOpen } from '@store/modal';

import { Button, Card, } from '@components';
import { SubjectDto } from '@dto';
import { SubjectEval } from '@dto/SubjectEvalDto';

interface RatingModalProp {
  id: string | number;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const RatingInfoModal: React.FC<RatingModalProp> = ({ id, isOpen, setIsOpen }) => {

  const dispatch = useAppDispatch();
  const [evals, setEvals] = useState<SubjectEval[]>([])

  const groupBy = () => {

  }

  /**
   * 데이터를 불러온다
   */
  const fetchData = useCallback(async () => {
    try {
      if (!id) {
        throw new Error('잘못된 요청입니다');
      }

      const submit: SubjectDto | undefined = await subjectApi.itemById(id);

      if (!submit) {
        throw new Error('데이터를 불러오지 못했습니다');
      }
      if (submit.evals)
        setEvals(submit.evals)


    } catch (error: any) {
      dispatch(
        modalOpen({
          type: 'alert',
          isOpen: true,
          title: '오류',
          message: error.message
            ? error.message
            : `오류가 발생하였습니다. [오류코드 ${error.status}]`,
        }),
      );
    } finally {
    }
  }, [id, dispatch]);

  useEffect(() => {
    if (isOpen) {
      fetchData();
    }
  }, [isOpen, fetchData]);

  useEffect(() => {
    groupBy()
  }, [evals])

  return (
    <Dialog className="relative z-[1002]" open={isOpen} onClose={() => []}>
      <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
      {/* container */}
      <div className="fixed flex inset-0 w-full max-h-full items-center justify-center overflow-y-auto">
        <Dialog.Panel className="w-full max-w-full max-h-full lg:max-w-2xl xl:max-w-4xl">
          <div className="relative w-full h-full p-4">
            <Card
              title={
                <div className="flex items-center justify-between">
                  평가기준
                </div>
              }
              actions={
                <>
                  <Button color="secondary" plain onClick={() => setIsOpen(false)}>
                    확인
                  </Button>

                </>
              }>
              <>
                <div className="w-full p-2 ">
                  {evals.map((ev: SubjectEval, idx: number) => {
                    return <div className='w-full flex' key={`rating-${idx}`}>
                      {/* <div className='w-[100px]'>{ev.groupName}</div> */}
                      <div>
                        <div className='flex  w-full mb-4'>
                          <div className='w-[140px]'>
                            {ev.title}
                          </div>
                          <div >
                            <pre className='font-sans'>{ev.content}</pre>
                          </div></div>
                      </div>
                    </div>

                  })
                  }


                </div>
              </>
            </Card>
          </div>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
};
