import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { UserCircleIcon, XMarkIcon } from '@heroicons/react/20/solid';

import * as fileApi from '@api/file';

import { Button } from '@components';
import { FILE_TYPE } from '@common';
import { TailSpin } from 'react-loader-spinner';
import { PhotoDto } from '@dto';

interface ProfileProp {
  photo?: PhotoDto;
  isEdit?: boolean;
  onChange: (photo: PhotoDto | undefined) => void;
}

export const Profile: React.FC<ProfileProp> = ({
  isEdit: _isEdit = false,
  photo: _photo,
  onChange,
}) => {
  const [photo, setPhoto] = useState<PhotoDto>();
  const [loading, setLoading] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);

  const { open } = useDropzone({
    multiple: false,
    accept: {
      'image/*': ['.png', '.gif', '.jpeg', '.jpg'],
    },
    onDrop: async (files: File[]) => {
      try {
        setLoading(true);
        const formData = new FormData();
        formData.append('file', files[0]);
        formData.append('filetype', FILE_TYPE.PROFILE);
        const result: PhotoDto = await fileApi.upload(formData);
        setPhoto(result);
        onChange(result);
      } catch (error) {}
    },
  });

  useEffect(() => {
    setPhoto(_photo);
  }, [_photo]);

  useEffect(() => {
    setIsEdit(_isEdit);
  }, [_isEdit]);

  const handleRemove = () => {
    setPhoto(undefined);
    onChange(undefined);
  };

  const onErrorImgLoad = () => {
    setPhoto(undefined);
    onChange(undefined);
  };

  return (
    <div className={`w-full flex flex-col items-center justify-center`}>
      <div className="relative p-2 bg-transparent rounded-xl mb-3">
        {loading && (
          <div className="absolute top-2 left-2 flex items-center justify-center w-36 h-36 rounded-full bg-white/50">
            <TailSpin width="3rem" height="3rem" color="#ff9e18" />
          </div>
        )}
        {photo && (
          <>
            <img
              src={photo.path}
              alt="profile"
              className="w-36 h-36 rounded-full"
              onLoad={() => setLoading(false)}
              onError={onErrorImgLoad}
            />
            {isEdit && (
              <button onClick={handleRemove} className="absolute top-0 right-0" disabled={loading}>
                <XMarkIcon className="w-8 h-8" />
              </button>
            )}
          </>
        )}
        {!photo && <UserCircleIcon className="w-36 h-36 fill-slate-400/75 rounded-full" />}
      </div>
      {isEdit && (
        <Button color="primary" outlined onClick={open} disabled={loading}>
          업로드
        </Button>
      )}
    </div>
  );
};
