import React from 'react';
import { useLocation } from 'react-router-dom';
import { Dashboard as Parent } from '@components/parent';
import { Dashboard as Teacher } from '@components/teacher';
import { Dashboard as Director } from '@components/director';

export const Dashboard = () => {
  const location = useLocation();


  if (location.pathname === '/parents') {
    return <Parent />;
  } else if (location.pathname === '/teacher') {
    return <Teacher />;
  } else {
    return <Director />;
  }
};
