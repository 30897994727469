import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { PageHeader } from '@components';
import { confirmInfo, emailConfirm } from '@api/auth';
import { useAppDispatch, useAppSelector } from '@store/hook';
import { open as loadingOpen, close as loadingClose } from '@store/loading';
import { clearAgree, selectAgree } from '@store/agree';
import { open as modalOpen } from '@store/modal';
import { AgreeYnDto } from '@dto/AgreeYnDto';
import { ConfirmDto } from '@dto/ConfirmDto';
import { format } from 'date-fns';
import { UserDto } from '@dto';


export const Signup: React.FC = () => {
  const navigate = useNavigate();
  const [alert, setAlert] = useState(false);
  const [result, setResult] = useState<UserDto>({} as UserDto)
  const [success, setSuccess] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const { agreeYnList } = useAppSelector(selectAgree);
  const { hash } = useParams();

  // TODO 스토어에 Agreement에 대한 데이터가 존재하는지 파악 후 넘긴다.

  const agreeClear = useCallback(() => {
    dispatch(clearAgree());
  }, [dispatch]);

  const navigateAgreement = useCallback(
    () => {
      if (!agreeYnList || agreeYnList.length === 0) {
        navigate(`/agreement/${hash}`);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [hash],
  );

  useEffect(() => {
    navigateAgreement();

    return () => {
      agreeClear();
    };
  }, [navigateAgreement, agreeClear]);

  const fetchData = useCallback(async () => {
    try {
      const { result } = await confirmInfo(hash as string);

      if (!result) {
        throw new Error('데이터를 불러오는데 실패하였습니다');
      }
      setResult(result)
      setValue('email', result.email as string);
      setValue('name', result.userName as string);
      setValue('phone', result.telno as string);
      setValue('org', result.org?.title as string);
      setValue('orgId', result.org?.id as number);
    } catch (error: any) {
      dispatch(
        modalOpen({
          type: 'alert',
          isOpen: true,
          title: '오류',
          message: `${error.message ? error.message : `오류가 발생하였습니다. [오류코드 : ${error.status}]`
            }`,
          onOk: () => {
            navigate('/signin');
          },
        }),
      );
    }
    // eslint-disable-next-line
  }, [hash]);

  useEffect(() => {
    if (hash) {
      fetchData();
    }
  }, [fetchData, hash]);

  const scheme = yup.object().shape({
    email: yup
      .string()
      .email('올바른 이메일 형식이 아닙니다.')
      .required('아이디(이메일)을 입력해주세요.'),
    password: yup
      .string()
      .min(6, '비밀번호는 최소 6글자 이상을 입력하셔야합니다.')
      .required('비밀번호를 입력해주세요.'),
    confirmPassword: yup
      .string()
      .min(6, '비밀번호 확인은 최소 6글자 이상을 입력하셔야합니다.')
      .required('비밀번호 확인을 입력해주세요.'),
    name: yup.string().required('이름을 입력해주세요.'),
    // birthDay: yup.date().required('생년월일을 입력해주세요.'),
    // phone: yup
    //   .string()
    //   .required('전화번호를 입력해주세요.')
    //   .matches(/^01([0|1|6|7|8|9])-([0-9]{3,4})-([0-9]{4})$/, '전화번호 양식에 맞게 입력해주세요.'),
    org: yup.string().required('어린이집을 선택해주세요.'),
    orgId: yup.number(),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    // trigger, 
  } = useForm({
    mode: 'onBlur',
    defaultValues: {
      email: '',
      password: '',
      confirmPassword: '',
      name: '',
      birthDay: new Date(),
      phone: '',
      org: '',
      orgId: -1,
    },
    resolver: yupResolver(scheme),
  });

  const onSubmit = async (data: any) => {
    try {
      dispatch(loadingOpen());
      const confirm: ConfirmDto = {
        email: data.email,
        password: data.password,
        userName: data.name,
        telno: data.phone,
        birthDay: format(data.birthDay, 'y-MM-dd'),
        hash: hash as string,
        agreement: agreeYnList.map((value: AgreeYnDto) => ({
          post: { id: value.id },
          agreeYn: value.agreeYn,
        })),
      };
      await emailConfirm(confirm);
      setSuccess(true);
    } catch (error) {
      setAlert(true);
    } finally {
      dispatch(loadingClose());
    }
  };

  return (
    <>
      <div className="w-full h-screen flex flex-col items-center overflow-y-auto">
        <PageHeader auth prev={'/agreement'} />
        <div className="container flex items-center">
          {!success && (
            <form
              className="w-full md:w-[28rem] lg:w-full pt-8 lg:pt-24 pb-24 mx-auto px-4   gap-8"
              onSubmit={handleSubmit(onSubmit)}>
              <h3 className={`text-3xl font-semibold tracking-wider col-span-12`}>회원가입</h3>
              {alert && (
                <div className="alert alert-error col-span-12 text-sm mb-4">
                  <span className="text-white">Error Message</span>
                </div>
              )}
              <div className="col-span-12 lg:col-span-4">
                <h4 className="mb-4 text-xl font-semibold tracking-wider">기본정보</h4>
                <div className="form-control w-full mb-4">
                  <label className="label">
                    <span className={`label-text text-sm ${errors.email && 'text-error'}`}>
                      [필수] 아이디 (이메일)
                    </span>
                  </label>
                  <input
                    type="email"
                    placeholder="아이디(이메일)을 입력해주세요"
                    className={`input input-bordered w-full text-sm ${errors.email && 'input-error'
                      }`}
                    {...register('email')}
                    readOnly
                  />
                  {errors.email && (
                    <div className="mt-1 ml-2 text-sm text-error">{errors.email.message}</div>
                  )}
                </div>
                <div className="form-control w-full mb-4">
                  <label className="label">
                    <span className={`label-text text-sm ${errors.email && 'text-error'}`}>
                      [필수] 이름
                    </span>
                  </label>
                  <input
                    type="text"
                    placeholder="이름을 입력해주세요"
                    className={`input input-bordered w-full text-sm ${errors.name && 'input-error'
                      }`}
                    {...register('name')}
                  />
                  {errors.name && (
                    <div className="mt-1 ml-2 text-sm text-error">{errors.name.message}</div>
                  )}
                </div>
                <div className="form-control w-full mb-2">
                  <label className="label">
                    <span className={`label-text text-sm ${errors.password && 'text-error'}`}>
                      [필수] 비밀번호
                    </span>
                  </label>
                  <input
                    type="password"
                    placeholder="비밀번호를 입력해주세요"
                    className={`input input-bordered w-full text-sm ${errors.password && 'input-error'
                      }`}
                    {...register('password')}
                  />
                  {errors.password && (
                    <div className="mt-1 ml-2 text-sm text-error">{errors.password.message}</div>
                  )}
                </div>
                <div className="form-control w-full mb-4">
                  <input
                    type="password"
                    placeholder="비밀번호 확인을 입력해주세요"
                    className={`input input-bordered w-full text-sm ${errors.confirmPassword && 'input-error'
                      }`}
                    {...register('confirmPassword')}
                  />
                  {errors.confirmPassword && (
                    <div className="mt-1 ml-2 text-sm text-error">
                      {errors.confirmPassword.message}
                    </div>
                  )}
                </div>
              </div>
              {/* 개인정보 수집하지 않는다. */}
              {/* <div className="col-span-12 lg:col-span-4">
                <h4 className="mb-4 text-xl font-semibold tracking-wider">개인정보</h4>
                <div className="form-control w-full mb-4">
                  <label className="label">
                    <span className={`label-text text-sm ${errors.email && 'text-error'}`}>
                      [필수] 이름
                    </span>
                  </label>
                  <input
                    type="text"
                    placeholder="이름을 입력해주세요"
                    className={`input input-bordered w-full text-sm ${errors.name && 'input-error'
                      }`}
                    {...register('name')}
                  />
                  {errors.name && (
                    <div className="mt-1 ml-2 text-sm text-error">{errors.name.message}</div>
                  )}
                </div>
                <div className="form-control w-full mb-4">
                  <label className="label">
                    <span className={`label-text text-sm ${errors.birthDay && 'text-error'}`}>
                      [필수] 생년월일
                    </span>
                  </label>
                  <DatePicker
                    control={control}
                    name={'birthDay'}
                    className={`${errors.birthDay && 'input-error'}`}
                    maxDate={new Date()}
                  />
                  {errors.birthDay && (
                    <div className="mt-1 ml-2 text-sm text-error">{errors.birthDay.message}</div>
                  )}
                </div>
                <div className="form-control w-full mb-4">
                  <label className="label">
                    <span className={`label-text text-sm ${errors.phone && 'text-error'}`}>
                      [필수] 전화번호
                    </span>
                  </label>
                  <TelInput
                    placeholder="전화번호를 입력해주세요"
                    className={`${errors.phone && 'input-error'}`}
                    register={register('phone')}
                  />
                  {errors.phone && (
                    <div className="mt-1 ml-2 text-sm text-error">{errors.phone.message}</div>
                  )}
                </div>
              </div> */}
              <div className="col-span-12 lg:col-span-4">
                <h4 className="mb-4 text-xl font-semibold tracking-wider">어린이집 정보</h4>
                <div className="form-control w-full mb-4">
                  <label className="label">
                    <span className={`label-text text-sm ${errors.org && 'text-error'}`}>
                      어린이집
                    </span>
                  </label>
                  <input
                    type="text"
                    placeholder="어린이집을 선택해주세요"
                    className={`input input-bordered w-full text-sm ${errors.org && 'input-error'
                      } cursor-pointer`}
                    {...register('org')}
                    readOnly
                  />
                  {errors.org && (
                    <div className="mt-1 ml-2 text-sm text-error">{errors.org.message}</div>
                  )}
                </div>
              </div>
              <div className="col-span-12 md:col-start-4 md:col-span-6 md:col-offset lg:col-start-5 lg:col-span-4 text-center">
                <button className="btn btn-secondary w-full lg:w-10/12 xl:w-8/12 text-white text-sm font-light tracking-wider">
                  회원가입
                </button>
              </div>
            </form>
          )}
          {success && (
            <div className="w-full md:w-[28rem] pt-8 lg:pt-24 pb-24 mx-auto px-4">
              <h3 className={`text-3xl font-semibold tracking-wider mb-4`}>회원가입</h3>
              <h4 className={`text-2xl font-semibold tracking-wider mb-6`}>
                가입이 완료되었습니다.
              </h4>
              {result.org?.id !== 1 &&
                <p className={`mb-6 text-gray-500 tracking-wide leading-7`}>
                  선택하신 어린이집의 원장님의 승인이 있어야 이용이 가능하시며, 승인이 완료되면
                  등록하신 이메일로 알려드립니다.
                </p>
              }
              <button
                type="submit"
                className="btn btn-secondary w-full text-white text-sm font-light tracking-wider"
                onClick={() => {
                  navigate('/signin');
                }}>
                로그인
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
