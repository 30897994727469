import React, { useCallback, useEffect, useState } from 'react';
import * as subjectApi from '@api/subject';
import { Dialog } from '@headlessui/react';
import { TailSpin } from 'react-loader-spinner';
import FileSaver from 'file-saver';
import Linkify from 'react-linkify';

import { ROLE, FILE_TYPE } from '@common';
import { FileDto } from '@dto/FileDto';
import { SubjectDto } from '@dto/Subject';

import { useAppDispatch, useAppSelector } from '@store/hook';
import { selectUser } from '@store/user';
import { open as modalOpen } from '@store/modal';
import { SubjectEval } from '@dto/SubjectEvalDto';

interface IProp {
  /**
   * 아이디 입력 (필수)
   */
  readonly id: number;
  /**
   * 페이지에서 사용하는 것인지 모달에서 보여지는 것인지 확인
   */
  readonly mode?: 'modal' | 'page';
  /**
   * 모드가 modal일 경우 닫기 버튼 누를 시 동작 이벤트 콜백
   */
  readonly onClose?: () => void;
}

export const SubjectDetail: React.FC<IProp> = ({ id, mode = 'page', onClose }: IProp) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<SubjectDto>();

  const dispatch = useAppDispatch();
  const { user } = useAppSelector(selectUser);

  const fetchData = useCallback(
    async () => {
      try {
        setLoading(true);
        const response: SubjectDto = await subjectApi.itemById(id);
        setData(response);
      } catch (error: any) {
        dispatch(
          modalOpen({
            type: 'alert',
            isOpen: true,
            title: '오류',
            message: error.message
              ? error.message
              : `오류가 발생하였습니다. [오류코드 ${error.status}]`,
          }),
        );
      } finally {
        setLoading(false);
      }
    },
    // eslint-disable-next-line
    [id],
  );

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleClose = () => {
    if (onClose) {
      onClose();
    }
  };

  /**
   * 파일 다운로드
   * @param type
   */
  const handleDownload = async (type: FILE_TYPE) => {
    try {
      setLoading(true);
      if (!data?.files || data.files.length === 0) {
        throw new Error('파일을 찾을 수 없습니다');
      }

      const file: FileDto | undefined = data.files.find((f: FileDto) => f.type === type);

      if (!file) {
        throw new Error('파일을 찾을 수 없습니다');
      }

      const header: string = type === FILE_TYPE.PLAN ? '교안' : '교재';
      const filename: string = `${header}_${file.filename}`;

      FileSaver.saveAs(file.path as string, filename);
    } catch (error: any) {
      dispatch(
        modalOpen({
          type: 'alert',
          isOpen: true,
          title: '오류',
          message: error.message
            ? error.message
            : `오류가 발생하였습니다. [오류코드 ${error.status}]`,
        }),
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      className={`relative w-full flex flex-col p-4 ${mode === 'page' ? '' : 'shadow rounded-lg bg-white lg:p-6 md:w-[45rem]'
        }`}>
      {loading && (
        <div
          className={`absolute w-full h-full flex items-center justify-center bg-white/75 rounded-lg -m-4 z-10
            ${mode === 'page' ? '' : 'lg:-m-6'}
          `}>
          <TailSpin
            height="48"
            width="48"
            color="#ff9e18"
            ariaLabel="tail-spin-loading"
            radius="1"
            visible={true}
          />
        </div>
      )}
      {/* 제목 */}
      {/* TODO 선생님 접속일 때는 DOWNLOAD 버튼을 붙여야 한다. */}
      <div className="flex items-center justify-between mb-4 tracking-wide w-full">
        <>
          <h3 className="font-semibold tracking-wide">{data?.title}</h3>
          {(user?.role?.id === ROLE.MEM_MANAGER || user?.role?.id === ROLE.MEM_TEACHER) && (
            <div
              className={`dropdown dropdown-end dropdown-hover`}
            >
              <label tabIndex={0} className="m-1 text-primary text-sm disabled">다운로드</label>
              <ul tabIndex={0} className="dropdown-content menu p-2 shadow bg-base-100 rounded-box w-52">
                <li
                  role="menuitem"
                  onClick={() => handleDownload(FILE_TYPE.PLAN)}
                  className="font-normal text-sm py-2 px-4 hover:bg-gray-300 rounded-lg cursor-pointer">
                  교안 다운로드
                </li>
                <li
                  role="menuitem"
                  onClick={() => handleDownload(FILE_TYPE.TEXTBOOK)}
                  className="font-normal text-sm py-2 px-4 hover:bg-gray-300 rounded-lg cursor-pointer">
                  교재 다운로드
                </li>
              </ul>
            </div>
          )}
        </>
      </div>
      {/* 이미지 2장 */}
      {/* TODO 캐러셀 이미지로 변경 (2장 이상이 올 경우도 생각해야 함) */}
      <div className="flex space-x-4 mb-4 w-full overflow-x-auto">
        {data?.image_url && (
          <div className="w-1/2 h-96 rounded-xl bg-slate-200 mx-auto">
            <img src={data.image_url} alt={`강의 이미지`} className="w-full h-full rounded-xl" />
          </div>
        )}
      </div>
      <div
        className={`mb-4 text-sm text-gray-500 leading-relaxed whitespace-pre-wrap ${mode === 'modal' && 'h-32 overflow-y-auto'
          }`}>
        <Linkify
          componentDecorator={
            (decoratedHref: string, decoratedText: string, key: number) => (
              <a
                key={key}
                href={decoratedHref}
                target="_blank"
                rel="noopener noreferrer"
              >
                {decoratedText}
              </a>
            )
          }
        >
          {data?.content || ''}
        </Linkify>

        <div className='mt-4'>
          <div>평가요소</div>

          {data?.evals?.map((data: SubjectEval, idx) => {
            return (<div>
              * {data.title} [ {data.groupName}]
              <pre className='font-sans ml-4'>
                {data.content}
              </pre>
            </div>)
          })}
        </div>
      </div>
      {/* 닫기 버튼 */}
      {mode !== 'page' && (
        <div className="flex justify-end w-full">
          <button
            className="btn btn-ghost btn-sm text-sm font-normal"
            onClick={handleClose}
            autoFocus={false}>
            확인
          </button>
        </div>
      )}
    </div>
  );
};

interface IModalProp {
  readonly id: number;
  readonly isOpen: boolean;
  readonly setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  readonly onClose?: () => void;
}

export const SubjectDetailModal: React.FC<IModalProp> = ({ id, isOpen, setIsOpen, onClose }) => {
  const handleClose = () => {
    setIsOpen(false);
    if (onClose) {
      onClose();
    }
  };

  return (
    <Dialog className="relative z-[1010]" open={isOpen} onClose={() => { }}>
      {/* backdrop */}
      <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
      {/* container */}
      <div className="fixed flex inset-0 w-full max-h-full items-center justify-center overflow-y-auto">
        <Dialog.Panel className="w-full max-w-full max-h-full lg:max-w-2xl xl:max-w-4xl p-4">
          <SubjectDetail id={id} mode={'modal'} onClose={handleClose} />
        </Dialog.Panel>
      </div>
    </Dialog>
  );
};
