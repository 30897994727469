import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { PageHeader } from '@components';
import { agreeList } from '@api/agree';
import { AgreeDto } from '@dto/AgreeDto';

import * as authApi from '@api/auth';

import { useAppDispatch } from '@store/hook';
import { setAgree, clearAgree } from '@store/agree';
import { open as modalOpen } from '@store/modal';
import { open as loadingOpen, close as loadingClose } from '@store/loading';
import { AgreeYnDto } from '@dto/AgreeYnDto';

interface AgreeItemProp {
  readonly agreement: AgreeDto;
  readonly checked: boolean;
  readonly valid?: boolean;
  readonly onChange: (id: number, checked: boolean) => void;
}

/**
 * 동의사항 아이템
 * @param
 * @returns
 */
const AgreeItem = ({ agreement, checked, onChange, valid = false }: AgreeItemProp) => {
  const [bChecked, setChecked] = useState(false);

  useEffect(() => {
    setChecked(checked);
  }, [checked]);

  const onChecked = (event: any) => {
    setChecked(event.target.checked);
    onChange(agreement.id, event.target.checked);
  };

  const isValid = (): boolean => {
    return valid && agreement.required === 'Y';
  };

  return (
    <div className="mb-4">
      <h3 className={`label tracking-wider ${agreement.required === 'Y' && 'font-medium'}`}>
        <span className={`label-text ${isValid() && 'text-error'}`}>
          {agreement.required === 'Y' ? '[필수]' : '[선택]'}
          {agreement.title}
        </span>
      </h3>
      <div className="h-64 overflow-y-auto border border-gray-400 rounded-lg bg-white mb-2 px-4 py-2 leading-7 text-sm font-normal">
        {agreement.content}
      </div>
      <div className="form-control flex-row align-center w-full">
        <input
          type="checkbox"
          className="checkbox checkbox-primary"
          id={`agreement-${agreement.id}`}
          onChange={onChecked}
          checked={bChecked}
        />
        <label className="label py-0 ml-2 cursor-pointer" htmlFor={`agreement-${agreement.id}`}>
          <span className={`label-text ${isValid() && 'text-error'}`}>위 약관에 동의합니다.</span>
        </label>
      </div>
    </div>
  );
};

export const Agreement = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(false);
  const [agreementList, setAgreementList] = useState<Array<AgreeDto>>([]);
  const [checkedItems, setCheckedItems] = useState(new Set());
  const [isAllChecked, setIsAllChecked] = useState<boolean>(false);
  const [allCheckedRequire, setAllCheckedRequire] = useState<boolean>(true);
  const { hash } = useParams();

  const homeRef = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();

  // HASH값 체크를 한다.
  useEffect(
    () => {
      async function fetchData() {
        try {
          dispatch(loadingOpen());
          if (!hash) {
            throw new Error('데이터가 올바르지 않습니다');
          }
          const data = await authApi.confirmInfo(hash);
          if (data.result === null) {
            throw new Error('데이터가 올바르지 않습니다');
          }
        } catch (error: any) {
          dispatch(clearAgree());
          dispatch(
            modalOpen({
              type: 'alert',
              isOpen: true,
              title: '오류',
              message: `${
                error.message ? error.message : `오류가 발생하였습니다 오류 코드[${error.status}]`
              }`,
              onOk: () => {
                navigate('/signin');
              },
            }),
          );
        } finally {
          dispatch(loadingClose());
        }
      }

      fetchData();
    },
    // eslint-disable-next-line
    [hash],
  );

  /**
   * 약관 목록을 가져온다
   */
  useEffect(() => {
    async function fetchData() {
      const data = await agreeList();
      setAgreementList(data);
      setLoading(false);
    }

    if (agreementList.length === 0) {
      setLoading(true);
      fetchData();
    }
  }, [agreementList.length]);

  /**
   * 한개 선택
   * @param id
   * @param isChecked
   */
  const onCheckedItem = (id: number, isChecked: boolean) => {
    if (isChecked) {
      checkedItems.add(id);
      setCheckedItems(checkedItems);
    } else if (!isChecked && checkedItems.has(id)) {
      checkedItems.delete(id);
      setCheckedItems(checkedItems);
    }
    setIsAllChecked(checkedItems.size === agreementList.length);
  };

  /**
   * 전체 선택
   * @param isChecked
   */
  const onAllCheckedItem = (isChecked: boolean) => {
    if (isChecked) {
      setCheckedItems(new Set(agreementList.map(({ id }) => id)));
      setIsAllChecked(true);
    } else {
      checkedItems.clear();
      setCheckedItems(checkedItems);
      setIsAllChecked(false);
    }
  };

  /**
   * 다음 페이지로 이동
   */
  const onNext = () => {
    let isAllCheckedRequire: boolean = true;

    for (let i = 0; i < agreementList.length; i++) {
      if (agreementList[i].required === 'Y' && !checkedItems.has(agreementList[i].id)) {
        isAllCheckedRequire = false;
        break;
      }
    }

    setAllCheckedRequire(isAllCheckedRequire);

    if (isAllCheckedRequire) {
      const agreeYnList: AgreeYnDto[] = [...agreementList].map((value: AgreeDto) => {
        const agreeYn: AgreeYnDto = {
          id: value.id,
          agreeYn: checkedItems.has(value.id) ? 'Y' : 'N',
        };

        return agreeYn;
      });

      dispatch(setAgree([...agreeYnList]));
      navigate(`/signup/${hash}`);
    } else {
      // 현재 페이지 그대로 유지
      homeRef?.current?.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className="w-full h-screen flex flex-col items-center overflow-y-auto">
      <PageHeader auth prev="/signin" />
      <div className="container flex items-center">
        <div className="w-full max-w-md pt-8 lg:pt-24 pb-24 mx-auto px-4" ref={homeRef}>
          <h3 className="mb-4 text-3xl font-semibold tracking-wider">약관</h3>
          {!allCheckedRequire && (
            <div className="alert alert-error mb-4">
              <div>
                <span className="font-light text-sm text-white tracking-wider">
                  필수 약관에 대해서 체크를 하셔야합니다.
                </span>
              </div>
            </div>
          )}
          {agreementList.map((agreement: AgreeDto, index: number) => {
            return (
              <AgreeItem
                key={index}
                agreement={agreement}
                onChange={onCheckedItem}
                checked={checkedItems.has(agreement.id)}
                valid={!allCheckedRequire}
              />
            );
          })}
          <div className="form-control flex-row align-center w-full mb-4">
            <input
              type="checkbox"
              className="checkbox checkbox-primary"
              id="all"
              checked={isAllChecked}
              onChange={(e) => {
                onAllCheckedItem(e.target.checked);
              }}
              disabled={loading}
            />
            <label className="label py-0 ml-2 cursor-pointer" htmlFor="all">
              <span className="label-text">전체 약관에 동의합니다.</span>
            </label>
          </div>
          <button
            type="submit"
            className={`btn btn-secondary w-full font-normal text-white mb-4 ${
              loading && 'loading'
            }`}
            disabled={loading}
            onClick={onNext}>
            {!loading && '다음'}
          </button>
        </div>
      </div>
    </div>
  );
};
