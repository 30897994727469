import React from 'react';
import { UserCircleIcon } from '@heroicons/react/20/solid';

interface IProp {
  readonly profile?: string;
  readonly name: string;
  readonly orgName: string;
}

export const ChildInfo = ({ name, orgName, profile }: IProp) => {
  return (
    <div className="h-16 flex px-4 items-center">
      <div className="-ml-4 mr-2">
        {profile && <img src={profile} alt="profile" className="w-12 h-12 rounded-full" />}
        {!profile && <UserCircleIcon className="w-12 h-12 fill-gray-400" />}
      </div>
      <div>
        <div className="text-sm mb-1 font-medium">{name}</div>
        <div className="text-xs text-gray-400">{orgName}</div>
      </div>
    </div>
  );
};
