import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import FileSaver from 'file-saver';

import { Card, CardBody, CardSubTitle, CardTitle, CardTitleBtn } from '@components/common';
import { NoticeList, SubjectDetailModal, SurveyList } from '@components';
import { DashDto } from '@dto/DashDto';

import * as dashApi from '@api/dash';
import { SubjectDto } from '../../../dto/Subject';
import { CourseDto } from '../../../dto';

import { FILE_TYPE } from '@common';
import { FileDto } from '@dto/FileDto';
import { useAppDispatch } from '@store/hook';
import { open as modalOpen } from '@store/modal';
import { TailSpin } from 'react-loader-spinner';

interface IListItemProp {
  itemId: number;
  readonly title: string;
  readonly subTitle: string;
  onClick: (itemId: number) => void;
}

const ListItem = ({ itemId, title, subTitle, onClick }: IListItemProp) => {
  const handleClick = () => {
    onClick(itemId);
  };

  return (
    <div
      className="min-h-16 max-h-24 px-4 flex flex-col border-b border-slate-200 justify-center cursor-pointer group hover:bg-primary/70"
      onClick={handleClick}>
      <div className="mb-1 text-sm group-hover:text-white">{title}</div>
      <div className="text-gray-500 text-xs group-hover:text-white">{subTitle}</div>
    </div>
  );
};

export const Dashboard = () => {
  const [compSubject, setCompSubject] = useState<SubjectDto[]>();
  const [attendSubject, setAttendSubject] = useState<SubjectDto[]>();
  // const [subjectList, setSubjectList] = useState<SubjectDto[]>()
  const [course, setCourse] = useState<CourseDto>();
  const [courseList, setCourseList] = useState<CourseDto[]>([]);
  const [info, setInfo] = useState<DashDto>();
  const [subjectId, setSubjectId] = useState<number>(-1);
  const [isOpenSubject, setIsOpenSubject] = useState<boolean>(false);
  const [isDownloadLoading, setIsDownloadLoading] = useState<boolean>(false);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const fetchData = useCallback(async () => {
    try {
      const response: any = await dashApi.dash();
      setInfo(response.result);
      const courseList = response?.result.courseList;
      let subList = new Array<SubjectDto>();
      if (courseList && courseList.length > 0) {
        setCourse(courseList[0]);
        setCourseList([...courseList]);
      }
      courseList.forEach((el: CourseDto) => {
        // console.log(el);
        el.subjects?.forEach((s: SubjectDto) => {
          s.org = el.class;
          s.course = el;
          subList.push(s);
        });
      });

      const newSubList = [...subList]
        .filter((a) => {
          const day = new Date();
          day.setDate(day.getDate() + (7 - day.getDay()));
          return new Date(a.startDate as string).getTime() <= day.getTime();
        })
        .sort(
          (a, b) =>
            new Date(a.startDate as string).getTime() - new Date(b.startDate as string).getTime(),
        );

      // 미출석 리스트
      const atList = [...newSubList].filter((a) => a.attendCnt !== a.memberCnt);

      const compList = [...newSubList]
        .filter((a) => {
          return !(a.submitComplete === 'Y' || (a.memberCnt || 0) === ((a.submitCnt || 0) + (a.attendNotCnt || 0)));
        })
      setAttendSubject(atList);
      setCompSubject(compList);
      // setSubjectList(subList)
    } catch (error) {
      console.log('error in Dashboard teacher', error);
    } finally {
    }
  }, []);
  useEffect(() => {
    fetchData();
  }, [fetchData]);
  const selectCourse = (index: number) => {
    setCourse(info?.courseList[index]);
  };

  const getPercent = () => {
    if (!course) {
      return 0;
    }
    const c = info?.courseList?.find((a) => {
      return a.id === course.id;
    });
    const slist = c?.subjects?.filter((a) => {
      return a.submitComplete === 'Y' || (a.memberCnt || 0) === ((a.submitCnt || 0) + (a.attendNotCnt || 0));
    });
    const xx = c?.subjects?.length || 0;
    const yy = slist?.length || 0;

    if (xx === 0 || yy === 0) {
      return 0;
    }

    return Math.round((yy / xx) * 100);
  };

  const handleSubject = (id: number) => {
    setSubjectId(id);
    setIsOpenSubject(true);
  };

  /**
   * 파일 다운로드
   * @param type
   */
  const handleDownload = async (type: FILE_TYPE) => {
    try {
      setIsDownloadLoading(true);
      if (!course?.subjects) {
        throw new Error('선택된 주제가 없습니다');
      }

      const f: FileDto[] = course.subjects
        .filter((subject: SubjectDto) => subject.files)
        .map((subject: SubjectDto) => {
          if (!subject.files) {
            return undefined;
          } else {
            return subject.files.find((file: FileDto) => file.type === type);
          }
        })
        .filter((value: FileDto | undefined) => value) as FileDto[];

      f.forEach((file: FileDto) => {
        FileSaver.saveAs(file.path as string, file.filename);
      });
    } catch (error: any) {
      dispatch(
        modalOpen({
          type: 'alert',
          isOpen: true,
          title: '오류',
          message: error.message
            ? error.message
            : `오류가 발생하였습니다. [오류코드 ${error.status}]`,
        }),
      );
    } finally {
      setIsDownloadLoading(false);
    }
  };

  /**
   * 출석부로 이동시킨다
   * @param id
   */
  const handleClickAttend = (id: number) => {
    const course: CourseDto | undefined = courseList.find(
      (c: CourseDto) => (c.subjects?.findIndex((sd: SubjectDto) => sd.id === id) as number) > -1,
    );

    if (!course) {
      return;
    }

    navigate(`attendance?cId=${course.id as number}&id=${id}`);
  };

  const handleClickRating = (id: number) => {
    navigate(`ratingcreate?id=${id}`);
  };

  const subjectSubTitle = (value: SubjectDto) => {
    let subTitle: string = value.startDate || '';
    if (value.course) {
      let course: string = value.course.title as string;

      if (value.course.alias) {
        course += ` [${value.course.alias}]`;
      }

      subTitle = `${course} · ${subTitle}`;
    }
    return subTitle;
  };

  return (
    <>
      <div className="grid grid-cols-12 gap-4">
        <div className="col-span-12 lg:col-span-6">
          <Card>
            <CardBody>
              <CardTitle>
                <span>평가 미완료 항목</span>
                <CardTitleBtn
                  onClick={() => {
                    navigate('ratingcreate');
                  }}>
                  평가하기 바로가기
                </CardTitleBtn>
              </CardTitle>
              <CardSubTitle>{` `}</CardSubTitle>
              <div
                className={`h-72 max-h-72 my-2 overflow-y-auto ${(!compSubject || compSubject.length === 0) && 'flex flex-col'
                  }`}>
                {(!compSubject || compSubject.length === 0) && (
                  <p className="text-gray-500 text-sm text-center my-auto">
                    평가하실 항목이 없습니다
                  </p>
                )}
                {compSubject?.map((value, index) => {
                  return (
                    <ListItem
                      itemId={value.id}
                      key={`item-${index}`}
                      title={value?.title || ''}
                      subTitle={subjectSubTitle(value)}
                      onClick={handleClickRating}
                    />
                  );
                })}
              </div>
            </CardBody>
          </Card>
        </div>
        <div className="col-span-12 lg:col-span-6">
          <Card>
            <CardBody>
              <CardTitle>
                <span>출결 미완료 항목</span>
                <CardTitleBtn
                  onClick={() => {
                    navigate('attendance');
                  }}>
                  출석부 바로가기
                </CardTitleBtn>
              </CardTitle>
              <CardSubTitle>{` `}</CardSubTitle>
              <div
                className={`h-72 max-h-72 my-2 overflow-y-auto ${(!attendSubject || attendSubject.length === 0) && 'flex flex-col'
                  }`}>
                {(!attendSubject || attendSubject.length === 0) && (
                  <p className="text-gray-500 text-sm text-center my-auto">
                    출석 체크하실 항목이 없습니다
                  </p>
                )}
                {attendSubject?.map((value, index) => {
                  return (
                    <ListItem
                      key={`item-${index}`}
                      itemId={value.id}
                      title={value?.title || ''}
                      subTitle={subjectSubTitle(value)}
                      onClick={handleClickAttend}
                    />
                  );
                })}
              </div>
            </CardBody>
          </Card>
        </div>
        <div className="col-span-12">
          {/* 커리큘럼 정보 선생님별로 다르다 */}
          <Card>
            <CardBody className="relative">
              {isDownloadLoading && (
                <div className="absolute w-full h-full flex items-center justify-center bg-white/50 -ml-8 -mt-4">
                  <TailSpin
                    height="48"
                    width="48"
                    color="#ff9e18"
                    ariaLabel="tail-spin-loading"
                    radius="1"
                    visible={true}
                  />
                </div>
              )}
              {
                false &&

                <CardTitle>
                  <div className="ml-auto space-x-2">
                    <button
                      className="btn btn-outline btn-primary btn-sm font-normal ml-auto hover:font-normal hover:ring-2 hover:ring-offset-2 focus:ring-2 focus:ring-offset-2 hover:!bg-primary hover:!text-white"
                      onClick={() => handleDownload(FILE_TYPE.PLAN)}>
                      <span className="text-xs">교안 전체 다운로드</span>
                    </button>
                    <button
                      className="btn btn-outline btn-primary btn-sm font-normal ml-auto hover:font-normal hover:ring-2 hover:ring-offset-2 focus:ring-2 focus:ring-offset-2 hover:!bg-primary hover:!text-white"
                      onClick={() => handleDownload(FILE_TYPE.TEXTBOOK)}>
                      <span className="text-xs">교재 전체 다운로드</span>
                    </button>
                  </div>
                </CardTitle>
              }
              <>
                {info && info?.courseList.length > 0 && (
                  <>
                    <div className="grid grid-cols-12 gap-4">
                      <div className="col-span-12 md:col-span-5 lg:col-span-4">
                        <label className="label">
                          <span className="label-text">커리큘럼</span>
                        </label>
                        <select
                          className="select select-bordered w-full font-normal"
                          onChange={(a) => {
                            selectCourse(Number(a.target.value));
                          }}>
                          {info?.courseList.map((v, index) => (
                            <option value={index} key={`option - ${v.id} `}>
                              {v.title} {v.alias && `- ${v.alias} `}[{v.startDate}]
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="col-span-12 md:col-start-6 md:col-span-7 lg:col-start-7">
                        <label className="label">
                          <span className="label-text">수업 진행률</span>
                        </label>
                        <div className="flex relative h-12 items-center justify-center w-full">
                          <progress
                            className="progress progress-success h-10 w-full"
                            value={getPercent()}
                            max="100"></progress>
                          <div className="absolute text-white text-sm font-light">
                            {getPercent()}%
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="h-72 max-h-72 my-2 overflow-y-auto">
                      <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-6 gap-4 mx-2">
                        {course?.subjects?.map((obj, index: number) => {
                          return (
                            <div
                              key={`item-${index}`}
                              className={`group border rounded-lg text-center text-sm font-normal py-2 px-2 hover:cursor-pointer hover:bg-secondary/90 hover:border-secondary
                              ${obj.submitComplete === 'Y' || (obj.memberCnt || 0) === ((obj.submitCnt || 0) + (obj.attendNotCnt || 0))
                                  ? 'bg-secondary border-secondary text-white'
                                  : 'border-slate-300 bg-white'
                                }`}
                              onClick={() => {
                                handleSubject(obj.id);
                              }}>
                              <p className="group-hover:text-white w-full truncate">{obj.title}</p>
                              <p className="group-hover:text-white text-xs text-gray-500">
                                [{obj.startDate}]
                              </p>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </>
                )}
                {(!info || info?.courseList.length === 0) && (
                  <div className="text-sm text-gray-500 h-72 flex justify-center items-center">
                    담당 중인 수업이 없습니다
                  </div>
                )}
              </>
            </CardBody>
          </Card>
        </div>
        <div className="col-span-12 lg:col-span-6">
          <Card className="h-[361px]">
            <CardBody>
              <CardTitle>공지사항</CardTitle>
              <div className="max-h-[305px] overflow-y-auto">
                <NoticeList header={false} pagination={false} rows={5} />
              </div>
            </CardBody>
          </Card>
        </div>
        <div className="col-span-12 lg:col-span-6">
          <Card className="h-[361px]">
            <CardBody>
              <CardTitle>설문조사</CardTitle>
              <div className="max-h-[305px] overflow-y-auto">
                <SurveyList header={false} pagination={false} rows={5} useDashboard={true} />
              </div>
            </CardBody>
          </Card>
        </div>
      </div>
      <SubjectDetailModal id={subjectId} isOpen={isOpenSubject} setIsOpen={setIsOpenSubject} />
    </>
  );
};
