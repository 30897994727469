import axiosInstance from '@api';
import { ROLE, USER_STATE } from '@common';
import { OrgUserDto, SearchDto, UserDto } from '@dto';
import { StudentDto } from '@dto/StudentDto';

/**
 * 사용자 목록을 가져온다.
 * @param search
 */
export const list = (
  search: SearchDto,
): Promise<{
  count: number;
  data: UserDto[];
  hasNextPage: boolean;
}> => axiosInstance.post('/user/list', search);

/**
 * 선생님 목록을 가져온다
 * @param page
 * @param limit
 */
export const teacherList = async (page: number = 1, limit: number = 10) => {
  const search: SearchDto = {
    page,
    limit,
    search: {
      role: {
        id: ROLE.MEM_TEACHER,
      },
      status: USER_STATE.ACTIVATE,
    },
  };

  const response: { count: number; data: UserDto[] } = await axiosInstance.post(
    '/user/list',
    search,
  );
  return response;
};

/**
 * 아이 목록을 가져온다
 * @param search
 * @returns
 */
export const childrenList = (
  search: SearchDto,
): Promise<{ count: number; data: UserDto[]; hasNextPage: boolean; totalPage: number }> => {
  const s: SearchDto = Object.assign({}, search);
  s.search.role = {
    id: ROLE.MEM_CHILD,
  };
  return axiosInstance.post('/user/list', s);
};

export const itemById = (id: number | string): Promise<UserDto> => axiosInstance.get(`/user/${id}`);

/**
 * 사용자 업데이트
 * @param id
 * @param user
 * @returns
 */
export const updateById = (id: number | string, user: UserDto): Promise<UserDto> =>
  axiosInstance.patch(`/user/${id}`, user);

/**
 * 학부모 리스트 가져오기
 * @param search
 */
export const parentList = (
  search: SearchDto,
): Promise<{
  count: number;
  data: UserDto[];
  hasNextPage: false;
  totalPage: 1;
}> => {
  const s: SearchDto = Object.assign({}, search);
  s.search.role = {
    id: ROLE.MEM_PARENT,
  };

  return axiosInstance.post('/user/list', s);
};

export const createStudent = (student: StudentDto): Promise<any> =>
  axiosInstance.post('/user/createStudent', student);

/**
 * 회원가입 승인
 * @param id
 * @returns
 */
export const approveUser = (id: number): Promise<any> =>
  axiosInstance.get(`/user/aproveUser/${id}`);

/**
 * 회원가입 거절
 * @param id
 * @returns
 */
export const denyUser = (id: number): Promise<any> => axiosInstance.get(`/user/denyUser/${id}`);

/**
 * Org에 속하는 유저를 가져온다.
 * @param orgId
 * @returns
 */
export const listByOrg = (
  orgId: number,
  role: ROLE,
): Promise<{ orgId: number; users: UserDto[] }> =>
  new Promise(async (resolve, reject) => {
    try {
      const { data: users } = await list({
        page: 1,
        limit: 100,
        search: { status: USER_STATE.ACTIVATE, org: { id: orgId }, role: { id: role } },
      });
      resolve({
        orgId,
        users,
      });
    } catch (error: any) {
      reject(error);
    }
  });

/**
 * 사용자를 해당 조직에 할당한다. org가 없을시 이전 조직에 탈퇴되지 않음. (여러반에 운영이 가능해짐)
 * @param orgUserDto
 * @returns
 */
export const joinUserOrg = (orgUserDto: OrgUserDto | OrgUserDto[]): Promise<any> =>
  axiosInstance.post('/user/joinUserOrg', orgUserDto);

/**
 * 사용자를 해당 조직에서 제거한다.
 * @param orgUserDto
 * @returns
 */
export const leaveUserOrg = (orgUserDto: OrgUserDto | OrgUserDto[]): Promise<any> =>
  axiosInstance.post('/user/leaveUserOrg', orgUserDto);

/**
 * 반에 소속되지 않은 이용자 목록
 * @param search
 * @returns
 */
export const orphanList = (
  search: SearchDto,
): Promise<{
  count: number;
  data: UserDto[];
}> => axiosInstance.post('/user/orphanList', search);

export const getChildrenByTeacher = (
  search: SearchDto,
): Promise<{ count: number; data: UserDto[]; hasNextPage: boolean; totalPage: number }> =>
  axiosInstance.post('/user/myuser', search);


export const removeUser = (id: number): Promise<any> => axiosInstance.delete(`/user/${id}`);