import React from 'react';
import { UserCircleIcon, CheckIcon } from '@heroicons/react/20/solid';
import { useAppDispatch } from '@store/hook';
import { open as modalOpen } from '@store/modal';

export interface ChildItemProp {
  readonly id: number;
  readonly org: string;
  readonly profile?: string;
  readonly name: string;
  readonly gradeComplete?: boolean;
  readonly loading?: boolean;
  readonly onClick?: (id: number, isComplete?: boolean) => void;
  readonly checked?: boolean;
}

export const ChildItem: React.FC<ChildItemProp> = ({
  id,
  name,
  org,
  profile,
  gradeComplete = false,
  loading = false,
  checked = true,
  onClick,
}: ChildItemProp) => {
  const dispatch = useAppDispatch();

  /**
   * 해당 원생을 클릭 시 ID를 전달한다.
   */
  const onClickChild = () => {
    if (!checked) {
      dispatch(
        modalOpen({
          type: 'alert',
          message: '결석한 학생입니다. 결석한 원생은 평가할 수 없습니다.',
        }),
      );
      return;
    }

    if (onClick) {
      onClick(id, gradeComplete);
    }
  };

  return (
    <div
      className={`
      w-full bg-white rounded-lg shadow cursor-pointer hover:outline hover:outline-primary relative
      `}
      onClick={onClickChild}>
      {gradeComplete && <CheckIcon className="w-8 h-8 absolute top-2 right-2 fill-secondary" />}
      <div className="flex justify-center items-center p-4">
        {profile && <img src={profile} className="w-40 h-40 rounded-full" alt="profile" />}
        {!profile && <UserCircleIcon className="w-40 h-40 fill-gray-400" />}
      </div>
      <h3 className="text-center px-4 pb-4 font-medium truncate">{name}</h3>
      <p className="text-center pb-4 px-4 text-sm text-gray-500/75">{org}</p>
    </div>
  );
};
