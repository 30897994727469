import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { Dialog } from '@headlessui/react';

import { ROLE } from '@common';
import { AlbumForm } from '@components/AlbumForm';
import { useAppDispatch, useAppSelector } from '@store/hook';
import { selectUser } from '@store/user';
import { open as modalOpen } from '@store/modal';

interface AlbumCreateModalProp {
  readonly isOpen: boolean;
  readonly setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  readonly onCreated: () => void;
}

export const AlbumCreateModal: React.FC<AlbumCreateModalProp> = ({
  isOpen,
  setIsOpen,
  onCreated,
}) => {
  const { user } = useAppSelector(selectUser);
  const dispatch = useAppDispatch();

  useEffect(
    () => {
      try {
        if (!isOpen) {
          return;
        }

        if (!user) {
          throw new Error('접속자 정보를 찾지 못했습니다');
        }

        if (user.role?.id !== ROLE.MEM_TEACHER) {
          throw new Error('접근 권한이 없습니다');
        }
      } catch (error: any) {
        dispatch(
          modalOpen({
            type: 'alert',
            isOpen: true,
            title: '오류',
            message: error.message
              ? error.message
              : `오류가 발생하였습니다. [오류코드 ${error.status}]`,
          }),
        );
      }
    },
    // eslint-disable-next-line
    [isOpen, user],
  );

  const handleSave = () => {
    toast.info('앨범을 저장하였습니다');
    setIsOpen(false);
    onCreated();
  };

  return (
    <Dialog className="relative z-[1001]" open={isOpen} onClose={() => []}>
      <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
      {/* container */}
      <div className="fixed flex inset-0 w-full max-h-full items-center justify-center overflow-y-auto">
        <Dialog.Panel className="w-full max-w-full max-h-full lg:max-w-2xl xl:max-w-4xl">
          <AlbumForm onSave={handleSave} onClose={() => setIsOpen(false)} />
        </Dialog.Panel>
      </div>
    </Dialog>
  );
};
