import React from 'react';

interface IProp {
  readonly className?: string;
  readonly theme?: 'primary' | 'secondary' | 'success' | 'error' | 'warning';
  readonly max: number;
  readonly value: number;
  readonly textType?: 'percentage' | 'value';
}

export const Progress = ({
  className,
  theme = 'primary',
  max,
  value,
  textType = 'percentage',
}: IProp) => {
  return (
    <div className={`relative h-10 w-full flex justify-center items-center ${className}`}>
      <progress className={`progress progress-${theme} h-9 w-full`} max={max} value={value} />
      <div className="absolute text-white text-sm font-light">
        {textType === 'percentage' ? `${value}%` : `${value} / ${max}`}
      </div>
    </div>
  );
};
