import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { debounce } from 'lodash';
import { Dialog } from '@headlessui/react';

import * as userApi from '@api/user';

import { Card, Button } from '@components';
import { PhotoDto, SearchDto, UserDto } from '@dto';
import { ROLE } from '@common';
import { UserCircleIcon } from '@heroicons/react/20/solid';
import { TailSpin } from 'react-loader-spinner';

interface TeacherItemProp {
  readonly id: number;
  readonly userName: string;
  readonly photo?: PhotoDto;
  // readonly classes: OrgDto[];
  readonly onClick: (teacher: UserDto) => void;
  readonly selected?: boolean;
}

export const TeacherItem: React.FC<TeacherItemProp> = ({
  id,
  userName,
  photo,
  // classes,
  onClick,
  selected = false,
}: TeacherItemProp) => {
  const handleClick = () => {
    if (onClick) {
      onClick({ id, userName });
    }
  };

  // const getClasses = (): string => {
  //   if (classes.length > 0) {
  //     return classes.map((org: OrgDto) => org.title).join(', ');
  //   } else {
  //     return '\u00A0';
  //   }
  // };

  return (
    <div
      className={`group min-h-[4rem] px-4 flex cursor-pointer hover:bg-primary/75 ${selected && 'bg-primary/75'
        }`}
      onClick={handleClick}>
      <div className={`mr-2 flex items-center`}>
        <div className="w-12 h-12 rounded-full flex items-center">
          {photo && (
            <img
              src={photo.path}
              alt={`profile_${id}`}
              className="w-[38.4px] h-[38.4px] rounded-full mx-auto my-auto"
            />
          )}
          {!photo && (
            <UserCircleIcon
              className={`w-full h-full group-hover:fill-white ${selected ? 'fill-white' : 'fill-gray-400'
                }`}
            />
          )}
        </div>
      </div>
      <div className="flex my-auto">
        <div className={`text-sm mb-1 group-hover:text-white ${selected && 'text-white'}`}>
          {userName}
        </div>
        {/* <div
          className={`text-xs group-hover:text-white/75 ${selected ? 'text-white/75' : 'text-gray-500/75'
            }`}>
          {getClasses()}
        </div> */}
      </div>
    </div>
  );
};

interface TeacherSelectorProp {
  readonly className?: string;
  readonly isOpen: boolean;
  readonly setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  readonly value?: UserDto | undefined;
  readonly onChange: (teacher: UserDto) => void;
}

export const TeacherSelector: React.FC<TeacherSelectorProp> = ({
  className: _className,
  isOpen,
  setIsOpen,
  value = undefined,
  onChange,
}) => {
  const [userName, setUserName] = useState<string>('');
  const [teacherList, setTeacherList] = useState<UserDto[]>([]);
  const [teacher, setTeacher] = useState<UserDto>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const fetchData = useCallback(async () => {
    try {
      setIsLoading(true);
      const search: SearchDto = {
        page: 1,
        limit: 100,
        search: {
          role: { id: ROLE.MEM_TEACHER },
        },
      };

      if (userName.trim().length > 0) {
        search.search.userName = userName;
      }

      const { data } = await userApi.list(search);
      setTeacherList([...data]);
    } catch (error: any) {
    } finally {
      setIsLoading(false);
    }
  }, [userName]);

  useEffect(() => {
    if (isOpen) {
      fetchData();
    }
  }, [isOpen, fetchData]);

  useEffect(() => {
    if (value) {
      setTeacher({ ...value });
    }
  }, [value]);

  const handleUserName = (e: any) => {
    console.log(e.target.value);
    setUserName(e.target.value);
  };

  const debounceHandleInput = useMemo(() => debounce(handleUserName, 500), []);

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleSelectTeacher = (teacher: UserDto) => {
    setTeacher({ ...teacher });
    onChange({ ...teacher });
    setIsOpen(false);
  };

  return (
    <>
      <Dialog
        as="div"
        className="relative z-[1010]"
        open={isOpen}
        onClose={() => {
          return false;
        }}>
        <div className="fixed inset-0 bg-black/75" aria-hidden="true" />
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex items-center justify-center min-h-full">
            <Dialog.Panel className="w-full lg:max-w-lg">
              <Card
                title={'선생님 선택'}
                actions={
                  <Button plain className="font-normal" onClick={handleClose}>
                    취소
                  </Button>
                }>
                <div className="pb-4 form-control">
                  <input
                    type="text"
                    className="text-sm input input-bordered"
                    placeholder="검색할 선생님 이름을 입력해주세요"
                    onChange={debounceHandleInput}
                  />
                </div>
                <div className="relative flex flex-col overflow-y-auto border divide-y rounded-lg h-80">
                  {isLoading && (
                    <div className="absolute bg-white/75 w-full h-full flex items-center justify-center">
                      <TailSpin
                        height="64"
                        width="64"
                        color="#ff9e18"
                        ariaLabel="tail-spin-loading"
                        radius="1"
                        visible={true}
                      />
                    </div>
                  )}
                  {teacherList.length < 1 && (
                    <div className="m-auto text-sm text-gray-500">등록된 선생님이 없습니다</div>
                  )}
                  {!isLoading &&
                    teacherList.map((user: UserDto, index: number) => (
                      <TeacherItem
                        key={index}
                        id={user.id as number}
                        userName={user.userName as string}
                        photo={user.photo}
                        // classes={user.class as OrgDto[]}
                        onClick={handleSelectTeacher}
                        selected={user.id === teacher?.id}
                      />
                    ))}
                </div>
              </Card>
            </Dialog.Panel>
          </div>
        </div>
      </Dialog>
    </>
  );
};
