import React, { useCallback, useEffect, useState } from 'react';
import { Button, Pagination, ParentDetail } from '@components';
import { SearchDto, UserDto } from '@dto';
import * as userApi from '@api/user';
import { USER_STATE } from '@common';
import { useAppDispatch } from '@store/hook';
import { open as modalOpen } from '@store/modal';
import { open as loadingOpen, close as loadingClose } from '@store/loading';
import { toast } from 'react-toastify';

export const ParentList = () => {
  const rows: number = 10;
  const dispatch = useAppDispatch();

  const [totalCount, setTotalCount] = useState<number>(0);
  const [list, setList] = useState<UserDto[]>([]);
  const [activePage, setActivePage] = useState<number>(1);
  const [profile, setProfile] = useState<boolean>(false);
  const [userName, setUserName] = useState<string>('');
  const [childName, setChildName] = useState<string>('');
  const [userId, setUserId] = useState<number>();
  const [search, setSearch] = useState<SearchDto>({
    page: 1,
    limit: rows,
    search: {
      status: USER_STATE.ACTIVATE,
    },
  });

  const fetchData = useCallback(async () => {
    try {
      const { count, data } = await userApi.parentList(search);

      setTotalCount(count);
      setList([...data]);
    } catch (error: any) {
    } finally {
    }
  }, [search]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleSearch = () => {
    setActivePage(1);
    const newSearch: SearchDto = {
      page: 1,
      limit: rows,
      search: {
        status: USER_STATE.ACTIVATE,
      },
    };

    if (userName && userName.trim().length > 0) {
      newSearch.search.userName = userName;
    }

    if (childName && childName.trim().length > 0) {
      newSearch.search.childs = {
        userName: childName,
      };
    }

    setSearch(newSearch);
  };

  const handleReset = () => {
    setActivePage(1);
    setUserName('');
    setChildName('');

    const newSearch = {
      page: 1,
      limit: rows,
      search: {
        status: USER_STATE.ACTIVATE,
      },
    };

    setSearch(newSearch);
  };

  /**
   * 페이지 변경 이벤트
   * @param pageNumber
   */
  const handlePageChange = (pageNumber: number) => {
    setActivePage(pageNumber);
    const newSearch: SearchDto = {
      ...search,
    };

    newSearch.page = pageNumber;
    setSearch(newSearch);
  };

  const handleUserUpdated = () => {
    const newSearch: SearchDto = {
      page: 1,
      limit: rows,
      search: {
        status: USER_STATE.ACTIVATE,
      },
    };

    setSearch(newSearch);
  };

  /**
   * 삭제
   * @param id
   */
  const handleRemove = (id: number) => {
    dispatch(
      modalOpen({
        type: 'confirm',
        message: '해당 학부모님을 삭제하시겠습니까?',
        onOk: async () => {
          try {
            dispatch(loadingOpen());
            await userApi.removeUser(id);
            toast.info('삭제에 성공하였습니다');
            fetchData();
          } catch (error: any) {
            dispatch(
              modalOpen({
                type: 'alert',
                isOpen: true,
                title: '오류',
                message: error.message
                  ? error.message
                  : `오류가 발생하였습니다. [오류코드 ${error.status}]`,
              }),
            );
          } finally {
            dispatch(loadingClose());
          }
        },
      }),
    );
  };

  return (
    <>
      <div className="w-full h-[calc(100vh-112px)]">
        <h3 className="text-3xl font-semibold tracking-wider mb-4">학부모 관리</h3>
        <div className="flex flex-col bg-white w-full overflow-y-hidden rounded-lg shadow-xl p-4">
          {/* FILTER */}
          <div className="grid grid-cols-12 mb-4 w-full gap-4">
            <div className="col-span-3">
              <div className="form-control">
                <label className="label">
                  <span className="label-text">부모님 이름</span>
                </label>
                <input
                  type="text"
                  className="input input-bordered text-sm h-[2.25rem]"
                  placeholder="부모님 이름을 입력해주세요"
                  value={userName}
                  onChange={(e) => setUserName(e.target.value)}
                />
              </div>
            </div>
            <div className="col-span-3">
              <div className="form-control">
                <label className="label">
                  <span className="label-text">원생 이름</span>
                </label>
                <input
                  type="text"
                  className="input input-bordered text-sm h-[2.25rem]"
                  placeholder="원생 이름을 입력해주세요"
                  value={childName}
                  onChange={(e) => setChildName(e.target.value)}
                />
              </div>
            </div>
            <div className="col-span-3 flex items-end space-x-2 mb-1">
              <Button color="secondary" onClick={handleSearch}>
                <span className="text-white font-light">검색</span>
              </Button>
              <Button
                color="error"
                className="bg-danger border-danger hover:bg-danger/80 hover:border-danger/80 focus-visible:outline-danger"
                onClick={handleReset}>
                <span className="text-white font-light">초기화</span>
              </Button>
            </div>
          </div>
          <div className="w-full">
            <table className="table w-full mb-4 border-y">
              <colgroup>
                <col width={'20%'} />
                <col width={'20%'} />
                <col width={'20%'} />
                <col width={'*'} />
                <col width={'15%'} />
              </colgroup>
              <thead>
                <tr>
                  <th className="h-12 text-sm text-black/60 border-b bg-slate-50 font-medium lg:indent-12">
                    이름
                  </th>
                  <th className="h-12 text-sm text-black/60 border-b bg-slate-50 font-medium lg:indent-12">
                    이메일
                  </th>
                  <th className="h-12 text-sm text-black/60 border-b bg-slate-50 font-medium lg:indent-12">
                    전화번호
                  </th>
                  <th className="h-12 text-sm text-black/60 border-b bg-slate-50 font-medium text-center">
                    원생
                  </th>
                  <th className="h-12 text-sm text-black/60 border-b bg-slate-50 font-medium"></th>
                </tr>
              </thead>
              <tbody>
                {list.length === 0 && (
                  <tr>
                    <td colSpan={5} className="h-80">
                      <p className="m-auto text-sm text-center text-gray-500">
                        데이터를 찾을 수 없습니다.
                      </p>
                    </td>
                  </tr>
                )}
                {list.map((value, index) => {
                  return (
                    <tr key={index}>
                      <td className="text-sm lg:indent-12 p-4">{value.userName}</td>
                      <td className="text-sm lg:indent-12 p-4">{value.email}</td>
                      <td className="text-sm lg:indent-12 p-4">{value.telno}</td>
                      <td className="text-sm p-4">
                        {!value.childs && '-'}
                        {value.childs && value.childs.length > 0 && (
                          <div className="flex flex-col items-center justify-center space-y-2">
                            {value.childs.slice(0, 2).map((v, index) => (
                              <span key={index} className="text-xs">
                                {v.userName}
                              </span>
                            ))}
                            {value.childs.length > 2 && (
                              <span className="text-xs">외 {value.childs.length - 2}명</span>
                            )}
                          </div>
                        )}
                      </td>
                      <td className="text-sm text-center p-4">
                        <Button
                          color="primary"
                          size="sm"
                          className="mr-2 text-xs hover:!text-white"
                          outlined
                          onClick={() => {
                            setUserId(value.id);
                            setProfile(true);
                          }}>
                          상세보기
                        </Button>
                        {value.childs && value.childs.length < 1 && (
                          <Button
                            color="error"
                            size="sm"
                            className="text-xs hover:!text-white"
                            outlined
                            onClick={() => {
                              handleRemove(value.id as number);
                            }}>
                            삭제
                          </Button>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            {totalCount > 0 && (
              <Pagination
                activePage={activePage}
                setActivePage={setActivePage}
                itemsCountPerPage={rows}
                totalItemsCount={totalCount}
                pageRangeDisplayed={5}
                onChange={handlePageChange}
              />
            )}
          </div>
        </div>
      </div>
      <ParentDetail
        isOpen={profile}
        setIsOpen={setProfile}
        userId={userId}
        onUpdated={handleUserUpdated}
      />
    </>
  );
};
