import React from 'react';
import { useLocation, Outlet } from 'react-router-dom';

import { PageHeader } from '@components';
import { Sidebar } from '@components/Sidebar';

export const Layout = () => {
  const { pathname } = useLocation();

  // 회원가입, 인증은 SUBPAGE로 변환
  if (/\/signup/g.test(pathname) || /\/agreement/g.test(pathname)) {
    return (
      <div className="w-full">
        <PageHeader auth />
        <Outlet></Outlet>
      </div>
    );
  } else {
    return (
      <div className="flex flex-col w-full">
        <PageHeader />
        <div className="overflow-hidden">
          <div className="hidden lg:block fixed z-10 inset-0 top-16 right-auto left-0 overflow-y-auto bg-white shadow">
            <Sidebar />
          </div>
          <div className="w-full lg:pl-64 overflow-x-auto">
            <main className="container mx-auto p-6">
              <Outlet />
            </main>
          </div>
        </div>
      </div>
    );
  }
};
