import { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';

import { PhotoDto } from '@dto/PhotoDto';
import * as fileApi from '@api/file';
import { FILE_TYPE } from '@common';

import { Carousel } from '@components/common';
import { Button } from '@components';

interface RatingAlbumProps {
  label?: string;
  isEdit?: boolean;
  files: PhotoDto[];
  onChange: (files: PhotoDto[]) => void;
}

export const RatingAlbum: React.FC<RatingAlbumProps> = ({
  label,
  isEdit: _isEdit = false,
  files: _files = [],
  onChange,
}) => {
  const [files, setFiles] = useState<PhotoDto[]>([]);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop: async (fs: File[]) => {
      try {
        setLoading(true);
        const uploadAll: Promise<PhotoDto>[] = fs.map((f: File) => {
          const formData = new FormData();
          formData.append('file', f);
          formData.append('filetype', FILE_TYPE.NORMAL);
          return fileApi.upload(formData);
        });

        const results: PhotoDto[] = await Promise.all(uploadAll);
        setFiles([...files, ...results]);
        onChange([...files, ...results]);
      } catch (error: any) {
      } finally {
        setLoading(false);
      }
    },
    noClick: files.length > 0,
    accept: {
      'image/*': ['.png', '.gif', '.jpeg', '.jpg'],
    },
  });

  useEffect(() => {
    setIsEdit(_isEdit);
  }, [_isEdit]);

  useEffect(() => {
    setFiles([..._files]);
  }, [_files]);

  const handleRemoveImage = (images: PhotoDto[]) => {
    setFiles([...images]);
    onChange([...images]);
  };

  return (
    <div className="w-full">
      <label className="label">
        <span className="font-medium label-text">{label}</span>
        {isEdit && files.length > 0 && (
          <Button color="secondary" outlined size="sm" disabled={loading} onClick={() => open()}>
            <span className="font-normal text-xs">파일 업로드</span>
          </Button>
        )}
      </label>
      {/* 파일이 존재하지 않을 경우 드래그 존을 만들어서 파일 업로드를 하도록 한다. */}
      <div className="w-full mx-auto border h-80 rounded-xl relative">
        {isEdit && files.length === 0 && (
          <>
            <div
              {...getRootProps({
                className: `w-full h-full space-x-4 flex overflow-x-auto ${
                  files.length < 1 && 'flex-col'
                }`,
              })}>
              <input {...getInputProps()} />
              <p className="my-auto text-sm text-center text-gray-500">
                업로드할 이미지를 드래그앤 드랍으로 올려주세요.
              </p>
            </div>
          </>
        )}
        {/* 파일이 업로드 될 시에는 파일을 보여주는 Carousel을 보여준다 */}
        {files.length > 0 && (
          <Carousel images={[...files]} isRemove={isEdit} onRemove={handleRemoveImage} />
        )}
      </div>
    </div>
  );
};
