import React, { useCallback, useEffect, useState } from 'react';
import { Dialog } from '@headlessui/react';

import * as userApi from '@api/user';

import { useAppDispatch } from '@store/hook';
import { open as modalOpen } from '@store/modal';

import { Card, Button } from '@components';
import { ROLE } from '@common';
import { UserDto } from '@dto';
import { TailSpin } from 'react-loader-spinner';

interface InviteDetailProp {
  id: number | undefined;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const InviteDetail: React.FC<InviteDetailProp> = ({ id, isOpen, setIsOpen }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [data, setData] = useState<UserDto>();
  const dispatch = useAppDispatch();

  const fetchData = useCallback(async () => {
    try {
      setIsLoading(true);
      if (!id) {
        throw new Error('대상을 찾을 수 없습니다');
      }

      const data = await userApi.itemById(id);
      setData({ ...data });
    } catch (error: any) {
      dispatch(
        modalOpen({
          isOpen: true,
          type: 'alert',
          title: '오류',
          message: error.message
            ? error.message
            : `오류가 발생하였습니다 [오류코드: ${error.status}]`,
          onOk: () => {
            setIsOpen(false);
          },
        }),
      );
    } finally {
      setIsLoading(false);
    }
    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    if (isOpen) {
      fetchData();
    } else {
      setData(undefined);
    }
  }, [fetchData, isOpen]);

  return (
    <Dialog className="relative z-[1001]" open={isOpen} onClose={() => { }}>
      <div className="fixed inset-0 bg-black/75" aria-hidden="true" />
      <div className="fixed inset-0 flex items-center justify-center">
        <Dialog.Panel className="w-full md:max-w-md bg-white rounded-lg">
          <Card
            title={'상세보기'}
            actions={
              <>
                <Button
                  plain
                  color="secondary"
                  className="font-normal"
                  onClick={() => {
                    setIsOpen(false);
                  }}>
                  확인
                </Button>
              </>
            }>
            <div className="relative w-full">
              {isLoading && (
                <div className="w-full h-full absolute bg-white/50 flex items-center justify-center">
                  <TailSpin
                    height="80"
                    width="80"
                    color="#ff9e18"
                    ariaLabel="tail-spin-loading"
                    radius="1"
                    visible={true}
                  />
                </div>
              )}
              <div className="px-4 pb-4 form-control">
                <label className="label">
                  <span className={`label-text`}>이름</span>
                </label>
                <input
                  type="text"
                  className={`input input-bordered text-sm`}
                  value={data?.userName}
                  readOnly
                />
              </div>
              <div className="px-4 pb-4 form-control">
                <label className="label">
                  <span className={`label-text`}>이메일</span>
                </label>
                <input
                  type="text"
                  className={`input input-bordered text-sm`}
                  value={data?.email}
                  readOnly
                />
              </div>
              <div className="px-4 pb-4 form-control">
                <label className="label">
                  <span className={`label-text`}>생년월일</span>
                </label>
                <input
                  type="text"
                  className={`input input-bordered text-sm`}
                  value={data?.birthDay}
                  readOnly
                />
              </div>
              <div className="px-4 pb-4 form-control">
                <label className="label">
                  <span className={`label-text`}>전화번호</span>
                </label>
                <input
                  type="text"
                  className={`input input-bordered text-sm`}
                  value={data?.telno}
                  // placeholder="이름을 입력해주세요"
                  readOnly
                />
              </div>
              <div className="px-4 pb-4 form-control">
                <label className="label">
                  <span className={`label-text`}>역할</span>
                </label>
                <div className="flex space-x-2">
                  <label className="label">
                    <input
                      type="radio"
                      className="radio radio-sm mr-2"
                      value={ROLE.MEM_TEACHER}
                      checked={data?.role?.id === ROLE.MEM_TEACHER}
                      onChange={() => false}
                    />
                    <span className={`label-text`}>선생님</span>
                  </label>
                  <label className="label">
                    <input
                      type="radio"
                      className="radio radio-sm mr-2"
                      value={ROLE.MEM_PARENT}
                      checked={data?.role?.id === ROLE.MEM_PARENT}
                      onChange={() => false}
                    />
                    <span className={`label-text`}>부모님</span>
                  </label>
                </div>
              </div>
            </div>
          </Card>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
};
