import React, { useRef } from 'react';
import { Dialog } from '@headlessui/react';
import { XMarkIcon, ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';

interface IProp {
  readonly images: string[];
  readonly title: string;
  readonly startIndex?: number;
  readonly isOpen: boolean;
  readonly setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const AlbumCarousel: React.FC<IProp> = ({
  images,
  title,
  isOpen,
  setIsOpen,
  startIndex = 0,
}) => {
  const carouselRef = useRef<Carousel | null>(null);

  const handleClick = (e: any) => {
    if (e.target.classList.contains('active-image')) {
      return;
    }
    setIsOpen(false);
  };

  /**
   * 이전 페이지
   */
  const handlePrevPage = () => {
    carouselRef.current?.decrement();
  };

  /**
   * 다음 페이지
   */
  const handleNextPage = () => {
    carouselRef.current?.increment();
  };

  return (
    <Dialog
      className="relative z-[1001]"
      open={isOpen}
      onClose={() => {
        return false;
      }}>
      <div className="fixed inset-0 bg-black/75" aria-hidden="true" />
      {/* container */}
      <div className="fixed inset-0 flex items-center justify-center">
        <Dialog.Panel className="w-full h-full relative">
          <div className="sticky flex top-0 h-16 px-4 bg-white w-full items-center justify-between shadow">
            <h3>{title}</h3>
            <button
              className="btn btn-ghost focus-visible:outline-primary"
              tabIndex={0}
              aria-label="Close Full Screen"
              onClick={() => {
                setIsOpen(false);
              }}>
              <XMarkIcon className="w-6 h-6 fill-gray-500" />
            </button>
          </div>
          <Carousel
            ref={carouselRef}
            className={`relative w-full ${
              images.length > 1 ? 'h-[calc(100%-8rem)]' : 'h-[calc(100%-4rem)]'
            }`}
            selectedItem={startIndex}
            showThumbs={false}
            showArrows={false}>
            {images.map((src: string, index: number) => (
              <div
                className="w-full h-full flex items-center justify-center p-4"
                key={`image-${index}`}
                onClick={handleClick}>
                <img
                  src={src}
                  alt={`활동사진-${index + 1}`}
                  className="max-h-full rounded-lg object-contain active-image !pointer-events-auto"
                />
              </div>
            ))}
          </Carousel>
          {images.length > 1 && (
            <div className="sticky flex bottom-0 w-full h-16 bg-white items-center justify-center">
              <div className="w-96 flex justify-between">
                <button
                  className="btn btn-ghost focus-visible:outline-primary"
                  onClick={handlePrevPage}
                  tabIndex={0}
                  aria-label="Previous Image">
                  <ChevronLeftIcon className="w-6 h-6 fill-gray-500" />
                </button>
                <button
                  className="btn btn-ghost focus-visible:outline-primary"
                  onClick={handleNextPage}
                  tabIndex={0}
                  aria-label="Next Image">
                  <ChevronRightIcon className="w-6 h-6 fill-gray-500" />
                </button>
              </div>
            </div>
          )}
        </Dialog.Panel>
      </div>
    </Dialog>
  );
};
