import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Dialog } from '@headlessui/react';
import {
  ChevronRightIcon,
  ChevronDoubleRightIcon,
  ChevronDownIcon,
  ChevronDoubleDownIcon,
  XMarkIcon,
  UserCircleIcon,
} from '@heroicons/react/20/solid';
import { debounce } from 'lodash';
import { OrgDto, PhotoDto, SearchDto, UserDto } from '@dto';
import { ROLE } from '@common';
import * as userApi from '@api/user';
import { Button, Card } from '@components';
import { TailSpin } from 'react-loader-spinner';

interface ChildAddProps {
  readonly orgId: number | undefined;
  readonly orgName: string | undefined;
  readonly isOpen: boolean;
  readonly setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  readonly value: UserDto[];
  readonly onSelected: (users: UserDto[], targetOrgId: number) => void;
}

interface ChildItemProps {
  readonly id: number;
  readonly userName: string;
  readonly photo: PhotoDto | undefined;
  readonly orgName: string | undefined;
  readonly selected?: boolean;
  readonly onClick: (id: number) => void;
  readonly onRemove?: (id: number) => void;
}

const ChildItem: React.FC<ChildItemProps> = ({
  id,
  userName,
  photo,
  orgName,
  selected = false,
  onClick,
  onRemove,
}) => {
  const [isSelected, setIsSelected] = useState<boolean>(selected);

  useEffect(() => {
    setIsSelected(selected);
  }, [selected]);

  /**
   * 아이템 선택
   */
  const handleClick = () => {
    onClick(id);
  };

  const handleRemove = (e: any) => {
    e.stopPropagation();
    if (onRemove) {
      onRemove(id);
    }
  };

  return (
    <div
      className={`w-full h-16 min-h-[4rem] flex items-center overflow-x-hidden cursor-pointer ${isSelected && 'bg-primary/75'
        }`}
      onClick={handleClick}>
      <div className="ml-2">
        <div className="w-12 h-12 rounded-full">
          {photo && (
            <img
              src={photo.path}
              alt={`profile_${id}`}
              className="w-[38.4px] h-[38.4px] rounded-full mx-auto my-auto"
            />
          )}
          {!photo && <UserCircleIcon className="w-full h-full fill-gray-400" />}
        </div>
      </div>
      <div className="ml-4">
        <div className={`text-sm ${isSelected && 'text-white'}`}>{userName}</div>
        <div className={`text-xs mt-1 truncate ${isSelected ? 'text-white' : 'text-gray-400'}`}>
          {orgName === '' ? '\u00A0' : orgName}
        </div>
      </div>
      {onRemove && (
        <div className="ml-auto mr-2">
          <button className="btn btn-ghost btn-circle btn-sm" onClick={handleRemove}>
            <XMarkIcon className="w-6 h-6 fill-gray-500" />
          </button>
        </div>
      )}
    </div>
  );
};

export const ChildAdd: React.FC<ChildAddProps> = ({
  orgId,
  orgName,
  value: _value,
  isOpen,
  setIsOpen,
  onSelected,
}) => {
  const [items, setItems] = useState<UserDto[]>([]);
  // 선택 유저
  const [selectedItems, setSelectedItems] = useState<UserDto[]>([]);
  // 대상 유저
  const [members, setMembers] = useState<UserDto[]>([..._value]);
  const [keyword, setKeyword] = useState<string>('');
  const [noOrg, setNoOrg] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const fetchData = useCallback(
    async (userName: string = keyword) => {
      console.log('fetchData');
      try {
        setIsLoading(true);
        if (!orgId) {
          throw new Error('대상이 존재하지 않습니다');
        }

        const search: SearchDto = { page: 1, limit: 100 };

        if (userName.trim().length > 0) {
          search.search = {
            userName,
          };
        }

        if (noOrg) {
          const { data } = await userApi.orphanList(search);
          setItems([...data]);
        } else {
          search.search = {
            ...search.search,
            role: { id: ROLE.MEM_CHILD },
          };

          const { data } = await userApi.childrenList(search);
          setItems([...data]);
        }
      } catch (error: any) {
      } finally {
        setIsLoading(false);
      }
    },
    // eslint-disable-next-line
    [orgId, noOrg],
  );

  useEffect(() => {
    if (isOpen) {
      fetchData();
    } else {
      setMembers([]);
      setSelectedItems([]);
      setKeyword('');
    }
    // eslint-disable-next-line
  }, [isOpen, _value, fetchData]);

  const debounceFetchData = useMemo(
    () =>
      debounce((userName: string) => {
        fetchData(userName);
      }, 200),
    [fetchData],
  );

  const handleCheckedNoOrg = (e: any) => {
    setNoOrg(e.target.checked);
  };

  const handleAdd = () => {
    onSelected(members, orgId as number);
    setIsOpen(false);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  /**
   * 선택한 아이템 전부 보낸다.
   */
  const handleSelectAdd = () => {
    const itemIds: number[] = [...members].map((value) => value.id) as number[];
    const newMembers: UserDto[] = [...selectedItems]
      .filter((user: UserDto) => !itemIds.includes(user.id as number))
      .map((user: UserDto) => {
        const newUser: UserDto = { ...user };
        if (user.class && user.class.length > 0) {
          newUser.srcOrg = { id: user.class[0].id };
        }

        return newUser;
      });
    setMembers([...members, ...newMembers]);
    setSelectedItems([]);
  };

  /**
   * 전체 넣어준다
   */
  const handleAllAdd = () => {
    const itemIds: number[] = [...members].map((value) => value.id) as number[];
    const newMembers: UserDto[] = [...items]
      .filter((user: UserDto) => !itemIds.includes(user.id as number))
      .map((user: UserDto) => {
        const newUser: UserDto = { ...user };
        if (user.class && user.class.length > 0) {
          newUser.srcOrg = { id: user.class[0].id };
        }

        return newUser;
      });

    setMembers([...members, ...newMembers]);
    setSelectedItems([]);
  };

  /**
   * 아이템 선택을 토글한다
   * @param id
   */
  const toggleItem = (id: number) => {
    const index: number = selectedItems.findIndex((user: UserDto) => user.id === id);
    if (index < 0) {
      // ADD
      const user: UserDto = items.find((user: UserDto) => user.id === id) as UserDto;
      setSelectedItems([...selectedItems, user]);
    } else {
      // REMOVE
      const newList: UserDto[] = [...selectedItems].filter((user: UserDto) => user.id !== id);
      setSelectedItems([...newList]);
    }
  };

  /**
   * 현재 아이템이 선택이 되었는지 확인한다.
   * @param id
   * @returns
   */
  const isSelected = (id: number): boolean => {
    const items: UserDto[] = selectedItems.filter((user: UserDto) => user.id === id);
    return items.length > 0;
  };

  const handleMemberRemove = (id: number) => {
    const newList: UserDto[] = [...members].filter((user: UserDto) => user.id !== id);
    setMembers(newList);
  };

  return (
    <Dialog className="relative z-[1001]" open={isOpen} onClose={() => { }}>
      <div className="fixed inset-0 bg-black/75" aria-hidden="true" />
      {/* container */}
      <div className="fixed inset-0 flex items-center justify-center">
        <Dialog.Panel className="w-full lg:max-w-2xl xl:max-w-4xl">
          <Card
            title={`${orgName}의 원생 추가`}
            actions={
              <>
                <Button plain className="text-gray-500 font-normal" onClick={handleClose}>
                  취소
                </Button>
                <Button plain color="secondary" className="font-normal" onClick={handleAdd}>
                  추가
                </Button>
              </>
            }>
            <div className="w-full grid grid-cols-12 gap-4 md:gap-0">
              {/* LEFT */}
              <div className="col-span-12 md:col-span-5">
                <div className="form-control mb-2">
                  <input
                    type="text"
                    className="input input-bordered w-full text-sm rounded-lg tracking-wider"
                    placeholder="찾으실 원생 이름을 입력해주세요"
                    value={keyword}
                    onChange={(e) => {
                      setKeyword(e.target.value);
                      debounceFetchData(e.target.value);
                    }}
                  />
                </div>
                <div className="form-control mb-2">
                  <label className="label justify-end">
                    <input
                      type="checkbox"
                      className="checkbox checkbox-primary checkbox-sm mr-2"
                      checked={noOrg}
                      onChange={handleCheckedNoOrg}
                    />
                    <span className="label-text">반 배정 안된 원생만</span>
                  </label>
                </div>
                <div className="w-full flex flex-col items-center rounded-lg input input-bordered h-80 overflow-y-auto divide-y px-0">
                  {isLoading && (
                    <TailSpin
                      height="48"
                      width="48"
                      color="#ff9e18"
                      ariaLabel="tail-spin-loading"
                      radius="1"
                      visible={true}
                      wrapperClass={'mx-auto my-auto'}
                    />
                  )}
                  {!isLoading && items.length === 0 && (
                    <div className="mx-auto my-auto text-sm font-normal text-center">
                      <p className="mb-2">선택할 원생이 없습니다.</p>
                      <p>원생관리에서 원생을 등록해주세요.</p>
                    </div>
                  )}
                  {!isLoading &&
                    items.map((user: UserDto, index: number) => (
                      <ChildItem
                        key={index}
                        id={user.id as number}
                        userName={user.userName as string}
                        photo={user.photo}
                        orgName={
                          user.class && user.class.map((org: OrgDto) => org.title || '').join(', ')
                        }
                        selected={isSelected(user.id as number)}
                        onClick={toggleItem}
                      />
                    ))}
                </div>
              </div>
              {/* CENTER */}
              <div className="col-span-12 md:col-span-2 flex md:flex-col justify-center items-center space-x-4 md:space-x-0 md:space-y-4">
                <Button outlined color="primary" className="md:mt-14" onClick={handleSelectAdd}>
                  <ChevronRightIcon className="w-6 h-6 hidden md:block" />
                  <ChevronDownIcon className="w-6 h-6 md:hidden" />
                </Button>
                <Button outlined color="primary" onClick={handleAllAdd}>
                  <ChevronDoubleRightIcon className="w-6 h-6 hidden md:block" />
                  <ChevronDoubleDownIcon className="w-6 h-6 md:hidden" />
                </Button>
              </div>
              {/* RIGHT */}
              <div className="col-span-12 md:col-span-5 md:flex md:flex-col md:items-end">
                <div className="w-full flex flex-col rounded-lg input input-bordered h-80 overflow-y-auto divide-y px-0 mt-auto">
                  {members.length < 1 && (
                    <p className="text-center my-auto text-sm text-gray-500">
                      원생을 선택하여 추가해주세요
                    </p>
                  )}
                  {members.map((user: UserDto, index: number) => (
                    <ChildItem
                      key={index}
                      id={user.id as number}
                      userName={user.userName as string}
                      photo={user.photo}
                      orgName={undefined}
                      selected={false}
                      onClick={() => { }}
                      onRemove={handleMemberRemove}
                    />
                  ))}
                </div>
              </div>
            </div>
          </Card>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
};
