import React from 'react';
import { SurveyList } from '@components';

export const Survey = () => {
  return (
    <div className="w-full h-full">
      <h3 className="text-3xl font-semibold tracking-wider mb-4">설문조사</h3>
      <div className="w-full bg-white p-4 rounded-lg shadow-lg">
        <SurveyList />
      </div>
    </div>
  );
};
