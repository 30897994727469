import axiosInstance from '@api';
import { AgreeDto } from '@dto/AgreeDto';

/**
 *
 * @param page
 * @param limit
 * @returns
 */
export const agreeList = async (page: number = 1, limit: number = 20) => {
  const { data } = await axiosInstance.post('/agree/list', { page, limit, search: {} });
  return data as AgreeDto[];
};
