import { AttendDto, SubjectAttendDto } from '@dto';
import { SearchDto } from '@dto/SearchDto';
import { SubjectDto } from '@dto/Subject';
import { SubjectSubmitDto } from '@dto/SubjectSubmitDto';
import axiosInstance from './index';

/**
 * 과정의 상세를 보여준다.
 * @param id
 * @returns
 */
export const itemById = (id: number | string): Promise<SubjectDto> => axiosInstance.get(`/subject/${id}`);

/**
 * 과정의 목록을 가져온다
 * @param search
 * @returns
 */
export const list = async (
  search: SearchDto,
): Promise<{
  count: number;
  data: SubjectDto[];
}> => await axiosInstance.post(`/subject/list`, search);

/**
 * 출석한 아이들 정보 가져오기
 * @param search
 * @returns
 */
export const attendList = (search: SearchDto): Promise<{ count: number; data: AttendDto[] }> =>
  axiosInstance.post(`/subject/attend/list`, search);

export const attendBulk = (subjectAttend: SubjectAttendDto): Promise<SubjectAttendDto[]> =>
  axiosInstance.post('/subject/attend/bulk', subjectAttend);

/**
 * 평가 제출 목록
 * @param search
 * @returns
 */
export const submitList = (
  search: SearchDto,
): Promise<{
  data: SubjectSubmitDto[];
  count: number;
  totalPage: number;
  hasNextPage: boolean;
}> => axiosInstance.post('/subject/submit/list', search);

/**
 * 평가 제출 등록 및 수정
 * 수정할 때에는 id가 포함이 되어야 한다.
 * @param subjectSubmit - SubjectSubmitDto
 * @returns
 */
export const submit = (subjectSubmit: SubjectSubmitDto): Promise<SubjectSubmitDto> =>
  axiosInstance.post('/subject/submit', subjectSubmit);

/**
 * 평가 제출 상세
 * @param id - string
 * @returns
 */
export const getSubmitById = (id: string): Promise<SubjectSubmitDto> =>
  axiosInstance.get(`/subject/submit/${id}`);

export const downloadEvals = (id: string): Promise<Blob> =>
  axiosInstance.get(`/subject/eval/`)