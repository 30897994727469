import React, { useState } from 'react';
import { UserDto } from '../../../dto';

interface IProp {
  readonly children: Array<UserDto>;
  readonly onChange?: (id: number | undefined) => void;
}

export const ChildTabs = ({ children, onChange }: IProp) => {
  const [activeId, setActiveId] = useState(children[0].id);

  const onClickChild = (id: number | undefined) => {
    if (id === undefined) {
      return
    }
    setActiveId(id);
    if (onChange) {
      onChange(id);
    }
  };

  return (
    <div className="tabs tabs-boxed tab-md bg-transparent">
      {children.map((value, index) => (
        <div
          className={`tab rounded ${activeId === value.id ? 'bg-secondary text-white' : 'text-secondary'
            }`}
          key={`child-${index}`}
          onClick={() => onClickChild(value.id)}>
          {value.userName}
        </div>
      ))}
    </div>
  );
};
