import axiosInstance from '@api';
import { SearchDto } from '@dto';
import { SurveyDto } from '../dto/SurveyDto';

/**
 * 목록을 가져온다.
 * @param search
 * @returns
 */
export const list = (search: SearchDto) => axiosInstance.post('/vote/list', search);

/**
 * 아이템을 가져온다
 * @param id
 * @returns
 */
export const detail = (id: number | string) => axiosInstance.get(`/vote/${id}`);


/**
 * 설문조사를 제출한다.
 * @param id
 * @returns
 */
export const submitItems = (data: SurveyDto) => axiosInstance.post(`/vote/item/submit`, data);
export const read = (id: number | string) => axiosInstance.get(`/vote/read/${id}`);