import React, { useState, useEffect, useCallback } from 'react';
import { FieldError } from 'react-hook-form';

import * as courseApi from '@api/course';

import { useAppSelector, useAppDispatch } from '@store/hook';
import { selectUser } from '@store/user';
import { open as modalOpen } from '@store/modal';

import { COURSE_TYPE, ROLE } from '@common';
import { SearchDto, CourseDto, UserDto, SubjectDto } from '@dto';
import { EvalSumDto } from '@dto/EvalSumDto';

interface CourseSelectProp {
  readonly value?: number | undefined;
  readonly onChange: (
    id: number,
    members?: UserDto[],
    subjects?: SubjectDto[],
    evalSums?: EvalSumDto[],
  ) => void;
  /**
   * 반 정보에 따라 course가 달라지는 지에 대한 확인
   */
  readonly requiredClassId?: boolean;
  /**
   * 반 아이디
   */
  readonly classId?: number | undefined;
  /**
   * 커리큘럼 타입
   */
  readonly type?: COURSE_TYPE;
  readonly disabled?: boolean;
  readonly hideLabel?: boolean;
  readonly className?: string;
  readonly isTeacher?: boolean;
  readonly isParent?: boolean;
  readonly childId?: number;
  readonly error?: FieldError | undefined;
  readonly selectClassName?: string;
  readonly defaultValue?: number;
}

export const CourseSelect: React.FC<CourseSelectProp> = ({
  value: _value = -1,
  onChange,
  classId,
  requiredClassId = false,
  type = COURSE_TYPE.SUBJECT,
  disabled: _disabled = false,
  hideLabel = false,
  className = '',
  isTeacher = false,
  isParent = false,
  childId: _childId,
  error: _error,
  selectClassName,
  defaultValue,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<CourseDto[]>([]);
  const [error, setError] = useState<FieldError | undefined>();
  const [value, setValue] = useState<number | undefined>(_value);
  const [disabled, setDisabled] = useState<boolean>(_disabled);
  const [childId, setChildId] = useState<number | undefined>();

  const { user } = useAppSelector(selectUser);
  const dispatch = useAppDispatch();

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      // 사용자 정보가 필요하기 때문에 STORE에 주입이 되었는지 확인 후
      // 동작하도록 한다.
      if (!user) {
        return;
      }

      // requiredClassId가 true이면서 classId가 없을 경우
      // 더 이상 처리 안되도록 한다.
      if (requiredClassId && !classId) {
        return;
      }

      const search: SearchDto = {
        page: 1,
        limit: 100,
        search: {
          type,
        },
      };

      // 선생님일 경우 search에 user id를 넣어준다
      const orgs = user.orgs?.length || 1
      if (isTeacher && user.role && user.role.id === ROLE.MEM_TEACHER && orgs < 2) {
        search.search.user = {
          id: user.id as number,
        };
      }

      if (classId) {
        search.search.class = {
          id: classId,
        };
      }

      if (isParent) {
        if (childId) {
          search.search.members = {
            id: _childId,
          };
        } else {
          return;
        }
      }

      const { data } = await courseApi.list(search);
      setData([...data]);

      if (defaultValue) {
        if (onChange) {
          const course: CourseDto = [...data].find(
            (c: CourseDto) => c.id === defaultValue,
          ) as CourseDto;
          onChange(defaultValue, course?.members, course.subjects, course.evalSums);
        }
      }
    } catch (error: any) {
      dispatch(
        modalOpen({
          type: 'alert',
          isOpen: true,
          title: '오류',
          message: error.message
            ? error.message
            : `오류가 발생하였습니다. [오류코드 ${error.status}]`,
        }),
      );
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line
  }, [type, classId, requiredClassId, user, isTeacher, isParent, childId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    if (_disabled !== disabled) {
      setDisabled(_disabled);
    }
  }, [_disabled, disabled]);

  useEffect(() => {
    setValue(_value);
  }, [_value]);

  useEffect(() => {
    setError(_error);
  }, [_error, setError]);

  useEffect(() => {
    setChildId(_childId);
  }, [_childId]);

  const handleChange = (e: any) => {
    const value: number = Number(e.target.value);

    if (!isNaN(value)) {
      setValue(value);
      if (onChange) {
        const course: CourseDto = [...data].find((c: CourseDto) => c.id === value) as CourseDto;
        onChange(value, course?.members, course.subjects, course.evalSums);
      }
    }
  };

  /**
   * type에 따라 표현되는 라벨이 달라진다
   * @returns
   */
  const label = (): string => {
    if (type === COURSE_TYPE.MANAGER) {
      return 'CREAMO 커리큘럼';
    } else if (type === COURSE_TYPE.COMPANY) {
      return '커리큘럼';
    } else {
      return '수업';
    }
  };

  return (
    <div className={`form-control w-full ${className}`}>
      {!hideLabel && (
        <label className="label">
          <span className="label-text">{label()}</span>
        </label>
      )}
      <select
        className={`select select-bordered ${loading && 'loading'} text-sm font-normal w-full ${selectClassName ? selectClassName : ''
          }`}
        disabled={disabled}
        value={value}
        onChange={handleChange}>
        <option value={-1} disabled>
          커리큘럼을 선택해주세요
        </option>
        {data.map((value, index) => (
          <option value={value.id} key={index}>
            {user?.role?.id !== ROLE.MEM_PARENT && (
              <>
                {value.title} {value.alias && `[${value.alias}]`}
              </>
            )}
            {user?.role?.id === ROLE.MEM_PARENT && <>{value.title}</>}
          </option>
        ))}
      </select>
      <span className="mt-1 text-xs text-error">{error?.message}</span>
    </div>
  );
};
