import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './config';

export interface ModalState {
  type: 'confirm' | 'alert';
  isOpen?: boolean;
  title?: string | undefined;
  message?: string | undefined;
  okTitle?: string | undefined;
  cancelTitle?: string | undefined;
  onOk?: () => void;
  onCancel?: () => void;
}

const initialState: ModalState = {
  isOpen: false,
  type: 'alert',
  title: '',
  message: '',
  okTitle: '확인',
  cancelTitle: '취소',
};

export const modalSlice = createSlice({
  name: 'Modal',
  initialState,
  reducers: {
    open: (state, action: PayloadAction<ModalState>) => {
      const newState = { ...action.payload, isOpen: true };
      return newState;
    },

    close: (state) => {
      return initialState;
    },

    ok: (state) => {
      return initialState;
    },
  },
});

export const selectModal = (state: RootState) => state.modal;
export const { open, close, ok } = modalSlice.actions;
export default modalSlice.reducer;
