import React from 'react';
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
} from '@heroicons/react/20/solid';
import ReactPagination from 'react-js-pagination';

export interface PaginationProp {
  readonly activePage: number;
  readonly setActivePage: React.Dispatch<React.SetStateAction<number>>;
  readonly itemsCountPerPage?: number;
  readonly totalItemsCount?: number;
  readonly pageRangeDisplayed?: number;
  readonly onChange?: (pageNumber: number) => void;
}

export const Pagination: React.FC<PaginationProp> = ({
  activePage,
  setActivePage,
  itemsCountPerPage = 10,
  totalItemsCount = 0,
  pageRangeDisplayed = 5,
  onChange,
}: PaginationProp) => {
  const handlePageChange = (pageNumber: number) => {
    setActivePage(pageNumber);
    if (onChange) {
      onChange(pageNumber);
    }
  };

  return (
    <ReactPagination
      activePage={activePage}
      itemsCountPerPage={itemsCountPerPage}
      totalItemsCount={totalItemsCount}
      pageRangeDisplayed={pageRangeDisplayed}
      onChange={handlePageChange}
      firstPageText={<ChevronDoubleLeftIcon className="w-4 h-4 fill-gray-500" />}
      prevPageText={<ChevronLeftIcon className="w-4 h-4 fill-gray-500" />}
      nextPageText={<ChevronRightIcon className="w-4 h-4 fill-gray-500" />}
      lastPageText={<ChevronDoubleRightIcon className="w-4 h-4 fill-gray-500" />}
      innerClass={'flex items-center justify-center py-10 lg:px-0 sm:px-6 px-4'}
      itemClass={
        'text-sm font-medium leading-none cursor-pointer text-gray-600 hover:text-indigo-700 border-t border-transparent pt-3 mr-4 px-2'
      }
      linkClass={'text-sm'}
      activeClass={
        'text-sm font-medium leading-none cursor-pointer text-indigo-700 border-t border-indigo-400 pt-3 mr-4 px-2'
      }
    />
  );
};
