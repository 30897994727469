import React, { useEffect, useState } from 'react';
import { ChevronDoubleRightIcon } from '@heroicons/react/20/solid';
import { SubjectDto } from '@dto';

import { TailSpin } from 'react-loader-spinner';
import { format } from 'date-fns';

interface CourseAttendanceProp {
  readonly subject?: SubjectDto | undefined;
  readonly subjects: SubjectDto[];
  readonly onSelect: (id: number, completed: boolean) => void;
}

interface ItemProp extends SubjectDto {
  selected?: boolean;
  onClick: (id: number | undefined) => void;
}

const Item: React.FC<ItemProp> = ({
  id,
  name,
  startDate,
  attendDate,
  onClick,
  selected = false,
}) => {
  const handleClick = () => {
    onClick(id);
  };

  return (
    <div
      className={`group grid grid-cols-12 md:h-12 text-sm px-4 cursor-pointer hover:bg-primary/70 ${selected ? 'bg-primary' : 'bg-white'
        }`}
      onClick={handleClick}>
      <div
        className={`col-span-5 flex flex-col justify-center py-2 md:py-0 group-hover:text-white ${selected && 'text-white'
          }`}>
        <div className="truncate">{name}</div>
        <div
          className={`text-xs text-gray-500 group-hover:text-white ${selected && 'text-white'}`}>
          [{startDate || 'asd'}]
        </div>
      </div>
      <div
        className={`col-span-5 flex items-center justify-center group-hover:text-white ${selected && 'text-white'
          }`}>
        {attendDate && format(new Date(attendDate), 'yyyy-MM-dd')}
        {/* {completeDate && format(completeDate, 'yyyy.MM.dd')} */}
      </div>
      <div className="col-span-2 flex items-center justify-end">
        <ChevronDoubleRightIcon
          className={`w-4 h-4 fill-gray-500 group-hover:fill-white ${selected && 'fill-white'}`}
        />
      </div>
    </div>
  );
};

export const CourseAttendance: React.FC<CourseAttendanceProp> = ({
  subject,
  subjects,
  onSelect,
}: CourseAttendanceProp) => {
  const [data, setData] = useState<SubjectDto[]>([]);
  const [selectId, setSelectId] = useState<number | undefined>();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    setData([...subjects]);
    // setSelectId(undefined);
    setLoading(false);
  }, [subjects]);

  useEffect(() => {
    setSelectId(subject?.id);
  }, [subject]);

  const handleClickItem = (id: number | undefined) => {
    setSelectId(id);

    const subject: SubjectDto | undefined = [...data].find(
      (subject: SubjectDto) => subject.id === id,
    );

    if (onSelect) {
      const completed: boolean = subject?.attendDate ? true : false;
      onSelect(id as number, completed);
    }
  };

  return (
    <div
      className={`relative divide-y border rounded-lg h-72 lg:h-[calc(100vh-296px)] overflow-y-auto`}>
      <div className="sticky top-0 grid grid-cols-12 h-12 text-sm text-black/60 border-b bg-slate-50 font-medium z-10">
        <div className="col-span-5 flex items-center justify-center">제목</div>
        <div className="col-span-5 flex items-center justify-center">출석 체크 일자</div>
        <div className="col-span-2 flex items-center justify-center"></div>
      </div>
      <div className="relative h-[calc(100%-48px)]">
        {loading && (
          <div className="absolute w-full h-full flex items-center justify-center bg-white/75">
            <TailSpin
              height="48"
              width="48"
              color="#ff9e18"
              ariaLabel="tail-spin-loading"
              radius="1"
              visible={true}
            />
          </div>
        )}
        {data.length === 0 && (
          <div className="grid grid-cols-1 text-sm h-full bg-white text-center">
            <div className="flex items-center justify-center">
              <span className="text-gray-500">반과 커리큘럼을 먼저 선택해주세요.</span>
            </div>
          </div>
        )}
        {data.length > 0 &&
          data.map((subject: SubjectDto, index) => {
            return (
              <Item
                key={index}
                id={subject.id}
                name={subject.title}
                onClick={handleClickItem}
                attendDate={subject.attendDate}
                startDate={subject.startDate}
                selected={selectId === subject.id}
              />
            );
          })}
      </div>
    </div>
  );
};
