import React, { useState, useEffect, useCallback } from 'react';

import * as courseApi from '@api/course';

import { useAppDispatch } from '@store/hook';
import { open as loadingOpen, close as loadingClose } from '@store/loading';

import { LectureItem, Pagination, ClassForm, Button, ClassSelect } from '@components';
import { COURSE_TYPE } from '@common';
import { CourseDto, SearchDto } from '@dto';
import { PlusIcon } from '@heroicons/react/20/solid';

export const Lecture = () => {
  const rows: number = 10;
  const [data, setData] = useState<any[]>([]);
  const [activePage, setActivePage] = useState<number>(1);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [formOpen, setFormOpen] = useState<boolean>(false);
  const [classId, setClassId] = useState<number>();
  const [count, setCount] = useState<number>(0);
  const [keyword, setKeyword] = useState<string>('');
  const [filterClassId, setFilterClassId] = useState<number | undefined>();
  const [search, setSearch] = useState<SearchDto>({
    page: 1,
    limit: rows,
    search: {
      type: COURSE_TYPE.SUBJECT,
    },
  });

  const dispatch = useAppDispatch();

  const fetchData = useCallback(
    async () => {
      try {
        dispatch(loadingOpen());
        const { count, data } = await courseApi.list(search);
        setCount(count);
        setData([...data]);
      } catch (error) {
      } finally {
        dispatch(loadingClose());
      }
    },
    // eslint-disable-next-line
    [search],
  );

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handlePageChange = (pageNumber: number) => {
    setActivePage(pageNumber);
    const newSearch: SearchDto = {
      ...search,
      page: pageNumber
    };
    setSearch(newSearch);
  };

  const handleItemClick = (id: number) => {
    setClassId(id);
    setIsOpen(true);
  };

  /**
   * Filter 이벤트
   */
  const handleFilterSubmit = () => {
    setActivePage(1);
    const newSearch: SearchDto = {
      page: 1,
      limit: rows,
      search: {
        type: COURSE_TYPE.SUBJECT,
      },
    };

    if (keyword && keyword.trim().length > 0) {
      newSearch.search.title = keyword;
    }

    if (filterClassId) {
      newSearch.search.class = {
        id: filterClassId,
      };
    }

    setSearch(newSearch);
  };

  /**
   * FILTER 초기화
   */
  const handleFilterReset = () => {
    setKeyword('');
    setActivePage(1);
    setFilterClassId(undefined);

    setActivePage(1);
    const newSearch: SearchDto = {
      page: 1,
      limit: rows,
      search: {
        type: COURSE_TYPE.SUBJECT,
      },
    };
    setSearch(newSearch);
  };

  const handleChangeClass = (id: number) => {
    setFilterClassId(id)
    setActivePage(1);
    const newSearch: SearchDto = {
      page: 1,
      limit: rows,
      search: { ...search.search }
    };

    newSearch.search.class = {
      id: id
    };

    setSearch(newSearch);
  }

  return (
    <>
      <div className="w-full">
        <h3 className="mb-4 text-3xl font-semibold tracking-wider">수업관리</h3>
        <div className="p-4 bg-white rounded-lg shadow-lg">
          <div className="w-full text-right">
            <Button
              color="primary"
              className="text-white hover:!text-white/75 font-normal"
              onClick={() => {
                setFormOpen(true);
              }}>
              <PlusIcon className="w-5 h-5 -ml-1 mr-1 fill-white" />
              <span className="text-white font-normal">수업 등록</span>
            </Button>
          </div>
          {/* filter */}
          <div className="grid grid-cols-12 gap-4 mb-4">
            {/* 반 */}
            <ClassSelect
              value={filterClassId}
              onChange={handleChangeClass}
              className="col-span-12 md:col-span-4 lg:col-span-3"
              selectClassName="min-h-[2.25rem] h-[2.25rem] max-h-[2.25rem]"
            />
            {/* 검색어 */}
            <div className="form-control col-span-12 md:col-span-4 lg:col-span-3">
              <label className="label">
                <span className="label-text">검색어</span>
              </label>
              <input
                type="type"
                value={keyword}
                className="w-full text-sm input input-bordered h-[2.25rem]"
                placeholder="검색어를 입력해주세요"
                onChange={(e) => setKeyword(e.target.value)}
              />
            </div>
            <div className="flex col-span-12 md:col-span-4 lg:col-span-3 items-end mb-1 space-x-2">
              {/* </div> */}
              <Button color="secondary" onClick={handleFilterSubmit}>
                <span className="text-white font-light">검색</span>
              </Button>
              <Button
                color="error"
                className="bg-danger border-danger hover:bg-danger/80 hover:border-danger/80 focus-visible:outline-danger"
                onClick={handleFilterReset}>
                <span className="text-white font-light">초기화</span>
              </Button>
            </div>
          </div>
          <div className="overflow-x-auto w-full">
            <table className="table w-full mb-4">
              <colgroup>
                <col width="20%" />
                <col width="*" />
                <col width="20%" />
                <col width="20%" />
              </colgroup>
              <thead>
                <tr>
                  <th className="h-12 text-sm font-medium border-b text-black/60 lg:indent-12 bg-slate-50 !relative">
                    선생님
                  </th>
                  <th className="h-12 text-sm font-medium border-b text-black/60 lg:indent-12 bg-slate-50">
                    커리큘럼
                  </th>
                  <th className="h-12 text-sm font-medium text-center border-b text-black/60 bg-slate-50">
                    참여인원
                  </th>
                  <th className="h-12 text-sm font-medium text-center border-b text-black/60 bg-slate-50"></th>
                </tr>
              </thead>
              <tbody className="bg-white">
                {data.length === 0 && (
                  <tr>
                    <td colSpan={4} className="h-80">
                      <p className="m-auto text-sm text-center text-gray-500">
                        데이터를 찾을 수 없습니다.
                      </p>
                    </td>
                  </tr>
                )}
                {data.map((value: CourseDto, index) => (
                  <LectureItem
                    key={index}
                    id={value.id || index}
                    userName={value.user?.userName || ''}
                    title={value.title || ''}
                    subjectCount={value.subjects?.length || 0}
                    startDate={value.startDate}
                    endDate={value.endDate}
                    membersCount={value?.members?.length}
                    alias={value?.alias}
                    onClick={handleItemClick}
                  />
                ))}
              </tbody>
            </table>
            {count > 0 && (
              <Pagination
                activePage={activePage}
                setActivePage={setActivePage}
                itemsCountPerPage={rows}
                totalItemsCount={count}
                pageRangeDisplayed={5}
                onChange={handlePageChange}
              />
            )}
          </div>
        </div>
      </div>
      <ClassForm
        isOpen={formOpen}
        setIsOpen={setFormOpen}
        onCreated={() => {
          fetchData();
        }}
      />
      <ClassForm
        classId={classId}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        onRemoved={() => {
          fetchData();
        }}
        onUpdated={() => {
          fetchData();
        }}
      />
    </>
  );
};
