import React, { useState } from 'react';
import Drawer from 'react-modern-drawer';
import 'react-modern-drawer/dist/index.css';
import { Bars3Icon, ChevronLeftIcon } from '@heroicons/react/20/solid';
import { UserDropdown, Sidebar } from '@components';
import { ReactComponent as CreamoLogo } from '../../assets/creamo_eng_long.svg';
import { Link, } from 'react-router-dom';
import { selectUser } from '@store/user';
import { useAppSelector } from '@store/hook';

interface IProp {
  readonly auth?: boolean;
  readonly prev?: string;
}

export const PageHeader: React.FC<IProp> = ({ auth = false, prev }: IProp) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { user } = useAppSelector(selectUser);
  const toggleDrawer = () => {
    setIsOpen((prevState: boolean) => !prevState);
  };

  // 현재 페이지가 로그인 페이지일 경우
  if (auth) {
    return (
      <header className="sticky top-0 z-20 flex w-full min-h-[4rem] h-16 bg-white shadow items-center px-4">
        {prev && (
          <Link to={prev}>
            <ChevronLeftIcon className="fill-gray-500 h-8 w-8" />
          </Link>
        )}
        <div className="w-full flex items-center justify-center">
          <CreamoLogo className={`px-2 h-8 ${prev && '-ml-8'}`} />
        </div>
      </header>
    );
  } else {
    return (
      <>
        <header className="sticky top-0 z-20 flex w-full min-h-[4rem] h-16 bg-white shadow items-center px-4">
          {/* LOGO */}
          <div className="w-60 max-w-[15rem] overflow-hidden flex items-center">
            <Bars3Icon className="w-6 h-6 cursor-pointer mr-4 lg:hidden" onClick={toggleDrawer} />
            <CreamoLogo className="px-2 h-8" />
          </div>
          {/* Company Info */}
          <div className="hidden md:flex space-x-8 lg:pl-4">
            <span>
              <a href="https://www.creamo.co.kr" target={'_blank'} rel="noopener noreferrer">
                회사소개
              </a>
            </span>
            <span>
              <a
                href="https://www.creamo.co.kr/products"
                target={'_blank'}
                rel="noopener noreferrer">
                이용안내
              </a>
            </span>

            <span>
              <a
                href="https://smartstore.naver.com/creamo_mall"
                target={'_blank'}
                rel="noopener noreferrer">
                학습교구 정보
              </a>
            </span>

          </div>
          {/* Icons */}
          <div className="flex ml-auto space-x-4 items-center ">

            {/* 유치원명. */}
            <b>{(user?.org?.title) ? user?.org?.title : user?.org?.name}</b>


            {/* <button
              className="mb-2 w-full h-9 border border-secondary rounded-lg text-secondary text-sm hover:ring-2"
              onClick={() => {
                navigate('/selectorg');

              }}>
              {user?.org?.title}
            </button> */}
            {/* <AlarmDropdown userId={0} /> */}
            <UserDropdown />
          </div>
        </header >
        {/* TODO window 크기 변화를 감지한 뒤 일정 사이즈 이상일 경우 강제로 닫는다. */}
        < Drawer open={isOpen} onClose={toggleDrawer} direction="left" className="!w-64" >
          <Sidebar onClick={toggleDrawer} />
        </Drawer >
      </>
    );
  }
};
