import React, { useCallback, useEffect, useState } from 'react';
import { format } from 'date-fns';
import InfiniteScroll from 'react-infinite-scroll-component';
import { PlusIcon } from '@heroicons/react/20/solid';
// import DateRangePicker from '@wojtekmaj/react-daterange-picker';

import * as albumApi from '@api/album';

import { SearchDto, SubjectAlbumDto, UserDto } from '@dto';
import { AlbumCreateModal, Button, CourseSelect, ClassSelect, AlbumModal, Tabs } from '@components';

import { useAppDispatch, useAppSelector } from '@store/hook';
import { selectUser } from '@store/user';
import { open as modalOpen } from '@store/modal';
import { ROLE } from '@common';

export const Album = () => {
  // const [isOpen, setIsOpen] = useState<boolean>(false);
  // const [date, setDate] = useState<Date[] | null[]>([null, null]);
  const [search, setSearch] = useState<SearchDto>({ page: 1, limit: 10, search: {} });
  const [classId, setClassId] = useState<number | undefined>();
  const [courseId, setCourseId] = useState<number | undefined>();
  const [keyword, setKeyword] = useState<string>('');
  const [isOpenCreate, setIsOpenCreate] = useState<boolean>(false);
  const [isOpenDetail, setIsOpenDetail] = useState<boolean>(false);
  const [albums, setAlbums] = useState<SubjectAlbumDto[]>([]);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [albumId, setAlbumId] = useState<number | undefined>();
  const [children, setChildren] = useState<UserDto[]>([]);
  const [childId, setChildId] = useState<number | undefined>();

  const dispatch = useAppDispatch();

  const { user } = useAppSelector(selectUser);

  const fetchData = useCallback(
    async () => {
      try {
        if (!search) {
          throw new Error('올바르지 않은 요청입니다');
        }

        if (!hasMore) {
          return;
        }

        const newSearch: SearchDto = { ...search };
        // FILTER

        const { data, hasNextPage } = await albumApi.list(newSearch);
        setAlbums([...albums, ...data]);
        setHasMore(hasNextPage);
        newSearch.page += 1;
        setSearch(newSearch);
      } catch (error: any) {
        setHasMore(false);
        dispatch(
          modalOpen({
            type: 'alert',
            isOpen: true,
            title: '오류',
            message: error.message
              ? error.message
              : `오류가 발생하였습니다. [오류코드 ${error.status}]`,
          }),
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [search],
  );

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    if (user?.role?.id === ROLE.MEM_PARENT) {
      if (user.childs) {
        setChildren([...user.childs]);
        setChildId(user.childs[0].id);
      }
    }
  }, [user]);

  const handleChangeClass = (id: number) => {
    setClassId(id);
    const newSearch: SearchDto = { ...search };
    newSearch.page = 1;
    newSearch.search.class = {
      id,
    };
    setHasMore(true);
    setAlbums([]);
    setSearch(newSearch);
  };

  const handleChangeCourse = (id: number) => {
    setCourseId(id);
    const newSearch: SearchDto = { ...search };
    newSearch.page = 1;
    newSearch.search.course = {
      id,
    };
    setHasMore(true);
    setAlbums([]);
    setSearch(newSearch);
  };

  // const handleChangeDate = (date: Date[] | null) => {
  //   if (date === null) {
  //     setDate([null, null]);
  //   } else {
  //     setDate([...date]);
  //   }
  // };

  /**
   * FILTER
   */
  const handleSubmitFilter = () => {
    const newSearch: SearchDto = {
      page: 1,
      limit: 10,
      search: {},
    };

    if (keyword && keyword.trim().length > 0) {
      newSearch.search.users = {
        userName: keyword,
      };
    }

    if (courseId) {
      newSearch.search.course = {
        id: courseId,
      };
    }

    if (classId) {
      newSearch.search.class = {
        id: classId,
      };
    }

    setHasMore(true);
    setAlbums([]);
    setSearch(newSearch);
  };

  const handleResetFilter = () => {
    const newSearch: SearchDto = {
      page: 1,
      limit: 10,
      search: {},
    };

    setKeyword('');
    // setDate([null, null]);
    setCourseId(undefined);
    setClassId(undefined);

    setHasMore(true);
    setAlbums([]);
    setSearch(newSearch);
  };

  const handleClickCreate = () => {
    setIsOpenCreate(true);
  };

  const handleCreated = () => {
    setHasMore(true);
    setAlbums([]);
    setSearch({
      page: 1,
      limit: 10,
      search: {},
    });
    fetchData();
  };

  const handleClickAlbum = (id: number) => {
    setAlbumId(id);
    setIsOpenDetail(true);
  };

  const handleChangeTab = (id: any) => {
    setChildId(id as number);
    setHasMore(true);
    setAlbums([]);
    setSearch({
      page: 1,
      limit: 10,
      search: {
        users: {
          id: id,
        },
      },
    });
  };

  return (
    <>
      <div className="w-full h-[calc(100vh-112px)]">
        <h3 className="mb-4 text-3xl font-semibold tracking-wider">앨범</h3>
        <div className="flex flex-col bg-white w-full lg:h-[calc(100%-52px)] overflow-y-hidden rounded-lg shadow-xl p-4">
          {user && (
            <>
              {/* 앨범 등록 (선생님만 가능) */}
              <div className="flex w-full justify-end">
                {user.role?.id === ROLE.MEM_TEACHER && (
                  <Button color="primary" onClick={handleClickCreate}>
                    <PlusIcon className="w-5 h-5 -ml-1 mr-1 fill-white" />
                    <span className="text-white font-normal">앨범 등록</span>
                  </Button>
                )}
              </div>
              {/* 필터 */}
              <div className="flex flex-col lg:flex-row lg:space-x-4 w-full mb-4">
                {/* 원장 선생님일 경우 */}
                {user.role?.id === ROLE.MEM_MANAGER && (
                  <>
                    <div className="2xl:min-w-[240px] 2xl:max-w-[240px]">
                      <ClassSelect
                        value={classId}
                        onChange={handleChangeClass}
                        selectClassName="min-h-[2.25rem] h-[2.25rem] max-h-[2.25rem]"
                      />
                    </div>
                    <div className="2xl:min-w-[240px] 2xl:max-w-[240px]">
                      <CourseSelect
                        requiredClassId
                        classId={classId}
                        value={courseId}
                        onChange={handleChangeCourse}
                        selectClassName="min-h-[2.25rem] h-[2.25rem] max-h-[2.25rem]"
                      />
                    </div>
                  </>
                )}
                {/* 선생님일 경우 */}
                {user.role?.id === ROLE.MEM_TEACHER && (
                  <>
                    <div className="">
                      <CourseSelect
                        value={courseId}
                        onChange={handleChangeCourse}
                        className=" 2xl:max-w-[320px]"
                        selectClassName="min-h-[2.25rem] h-[2.25rem] max-h-[2.25rem]"
                      />
                    </div>
                  </>
                )}
                {/* 부모님일 경우 */}
                {user.role?.id === ROLE.MEM_PARENT && (
                  <div className="mt-auto mb-1">
                    <Tabs
                      data={children}
                      value={childId}
                      labelName={'userName'}
                      valueName={'id'}
                      onChange={handleChangeTab}
                    />
                  </div>
                )}
                {(user.role?.id === ROLE.MEM_TEACHER || user.role?.id === ROLE.MEM_MANAGER) && (
                  <div className="form-control 2xl:min-w-[240px] mb-4 lg:mb-0">
                    <label className="label">
                      <span className="label-text">검색어</span>
                    </label>
                    <input
                      type="type"
                      className="w-full text-sm input input-bordered h-[2.25rem]"
                      placeholder="검색어를 입력해주세요"
                      value={keyword}
                      onChange={(e) => setKeyword(e.target.value)}
                    />
                  </div>
                )}
                {/* <div className="form-control min-w-[320px] mb-4 lg:mb-0">
                  <label className="label">
                    <span className="label-text">기간</span>
                  </label>
                  <DateRangePicker
                    onChange={handleChangeDate}
                    className="text-sm input input-bordered flex items-center"
                    calendarClassName={'text-sm rounded-lg bg-white shadow-lg border-0'}
                    dayAriaLabel="Day"
                    monthAriaLabel="Month"
                    calendarAriaLabel="Toggle calendar"
                    clearAriaLabel="Clear value"
                    yearAriaLabel="Year"
                    nativeInputAriaLabel="Date"
                    value={date}
                    locale="ko"
                    maxDate={new Date()}
                    format="y-MM-dd"
                    openCalendarOnFocus={false}
                    rangeDivider={'~'}
                    required
                    formatDay={(locale: any, date: Date) => format(date, 'd')}
                    calendarType={'US'}
                  />
                </div> */}
                {user.role?.id !== ROLE.MEM_PARENT && (
                  <div className="col-span-12 lg:col-span-3 flex items-end space-x-2 mb-1">
                    <Button color="secondary" onClick={handleSubmitFilter}>
                      <span className="text-white font-light">검색</span>
                    </Button>
                    <Button
                      color="error"
                      onClick={handleResetFilter}
                      className="bg-danger border-danger hover:bg-danger/80 hover:border-danger/80 focus-visible:outline-danger">
                      <span className="text-white font-light">초기화</span>
                    </Button>
                  </div>
                )}
              </div>
              <div
                className="w-full h-[445px] max-h-[445px] lg:h-[calc(100vh-100px)] lg:max-h-[calc(100vh-100px)] overflow-auto bg-gray-200 rounded-lg flex flex-col"
                id="album-list">
                {albums.length === 0 && (
                  <p className="text-sm text-gray-500 m-auto">저장된 앨범이 없습니다</p>
                )}
                {albums.length > 0 && (
                  <InfiniteScroll
                    dataLength={albums.length}
                    next={fetchData}
                    loader={
                      <h4 className="col-span-1 md:col-span-2 lg:col-span-4 4xl:grid-cols-6 text-center">
                        불러오는 중
                      </h4>
                    }
                    scrollableTarget={'album-list'}
                    hasMore={hasMore}
                    className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 4xl:grid-cols-6 gap-8 p-4">
                    {albums.map((value) => (
                      <div
                        key={value.id}
                        className="w-full bg-white rounded-lg shadow cursor-pointer hover:outline hover:outline-primary"
                        onClick={() => handleClickAlbum(value.id as number)}>
                        <div className="w-full h-64 p-2 rounded-lg bg-gray-50/75 mb-4">
                          {(!value.files || value.files.length < 1) && (
                            <div className="w-full h-full bg-gray-200 rounded-lg"></div>
                          )}
                          {value.files && value.files.length > 0 && (
                            <img
                              src={value.files[0].path || ''}
                              alt="album"
                              className="w-full h-full rounded-lg object-cover"
                            />
                          )}
                        </div>
                        <p className="px-4 text-xs text-gray-500 truncate">{value.course?.title}</p>
                        <h5 className="px-4 pb-4 font-semibold truncate">
                          [{value.subject.title}]
                        </h5>
                        <div className="px-4 text-xs text-gray-400 pb-4">
                          {value.subject.class?.title}
                          {' · '}
                          {format(new Date(value.createdAt || ''), 'yyyy년 MM월 dd일')}
                        </div>
                        <div className="text-sm tracking-wider border-t">
                          <div className="p-4">
                            {value.users.map((value) => `#${value.userName}`).join(' ')}
                          </div>
                        </div>
                      </div>
                    ))}
                  </InfiniteScroll>
                )}
              </div>
            </>
          )}
        </div>
      </div>
      <AlbumCreateModal
        isOpen={isOpenCreate}
        setIsOpen={setIsOpenCreate}
        onCreated={handleCreated}
      />
      <AlbumModal
        albumId={albumId}
        isOpen={isOpenDetail}
        setIsOpen={setIsOpenDetail}
        onUpdated={handleCreated}
      />
    </>
  );
};
