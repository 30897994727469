import React, { useState } from 'react';

interface IProp {
  readonly children: Array<{
    id: number | string;
    name: string;
  }>;
  readonly onChange?: (id: number | string) => void;
}

export const ChildTabs = ({ children, onChange }: IProp) => {
  const [activeId, setActiveId] = useState(children[0].id);

  const onClickChild = (id: number | string) => {
    setActiveId(id);
    if (onChange) {
      onChange(id);
    }
  };

  return (
    <div className="tabs tabs-boxed tab-md bg-transparent">
      {children.map((value, index) => (
        <div
          className={`tab rounded ${activeId === value.id ? 'bg-secondary text-white' : 'text-secondary'
            }`}
          key={`child-${index}`}
          onClick={() => onClickChild(value.id)}>
          {value.name}
        </div>
      ))}
    </div>
  );
};
