import React, { useCallback, useEffect, useState } from 'react';

import * as userApi from '@api/user';

// import { useAppDispatch } from '@store/hook';
// import { open as modalOpen } from '@store/modal';

import { Pagination, TeacherItem } from '@components';
import { TeacherDetail } from '@components/TeacherDetail';
import { UserDto } from '@dto';

export const TeacherList: React.FC = () => {
  const rows: number = 10;
  const [activePage, setActivePage] = useState<number>(1);
  const [count, setCount] = useState<number>(0);
  const [list, setList] = useState<UserDto[]>([]);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [teacherId, setTeacherId] = useState<number>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  // const dispatch = useAppDispatch();

  /**
   * 데이터를 불러온다.
   */
  const fetchData = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await userApi.teacherList(activePage, rows);
      setCount(response.count);
      setList(response.data);
    } catch (error: any) {
      // TODO 오류 발생시 페이지 넘기기 혹은 리스트에서 뿌려준다.
      // dispatch(
      //   modalOpen({
      //     type: 'alert',
      //     isOpen: true,
      //     title: '오류',
      //     message: `${
      //       error.message ? error.message : `오류가 발생하였습니다 오류 코드[${error.status}]`
      //     }`,
      //     onOk() {
      //     },
      //   }),
      // );
    } finally {
      setIsLoading(false);
    }
  }, [activePage, setIsLoading]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleClick = (id: number) => {
    setTeacherId(id);
    setIsOpen(true);
  };

  const handlePageChange = (pageNumber: number) => {
    setActivePage(pageNumber);
  };

  const handleRemove = () => {
    fetchData();
  };

  const handleUpdated = () => {
    fetchData();
  };

  return (
    <>
      <div className="w-full">
        <h3 className="text-3xl font-semibold tracking-wider mb-4">교사 관리</h3>
        <div className="bg-white p-4 shadow-lg rounded-lg overflow-x-auto">
          <table className="table w-full mb-4 border-y">
            <colgroup>
              <col width={'28.3333%'} />
              <col width={'28.3333%'} />
              <col width={'28.3333%'} />
              <col width={'15%'} />
            </colgroup>
            <thead>
              <tr>
                <th className="h-12 text-sm text-black/60 border-b bg-slate-50 font-semibold lg:indent-12 !relative">
                  이름
                </th>
                <th className="h-12 text-sm text-black/60 border-b bg-slate-50 font-semibold lg:indent-12">
                  이메일
                </th>
                <th className="h-12 text-sm text-black/60 border-b bg-slate-50 font-semibold lg:indent-12">
                  전화번호
                </th>
                <th className="h-12 text-sm text-black/60 border-b bg-slate-50"></th>
              </tr>
            </thead>
            <tbody className="bg-white">
              {isLoading &&
                Array.from({ length: rows }).map((_, index: number) => {
                  return (
                    <tr key={index} className="animate-pulse">
                      <td className="flex items-center">
                        <div className="w-12 h-12 bg-slate-200 rounded-full mr-2"></div>
                        <div className="w-32 h-3 bg-slate-200 rounded"></div>
                      </td>
                      <td>
                        <div className="w-48 h-3 bg-slate-200 rounded lg:ml-10"></div>
                      </td>
                      <td>
                        <div className="w-48 h-3 bg-slate-200 rounded lg:ml-10"></div>
                      </td>
                      <td></td>
                    </tr>
                  );
                })}
              {list.length === 0 && (
                <tr>
                  <td colSpan={4} className="h-80">
                    <p className="m-auto text-sm text-center text-gray-500">
                      데이터를 찾을 수 없습니다.
                    </p>
                  </td>
                </tr>
              )}
              {list.map((data: UserDto, index: number) => (
                <TeacherItem
                  key={index}
                  id={data.id || index}
                  name={data.userName || ''}
                  email={data.email || ''}
                  phone={data.telno || ''}
                  onClick={handleClick}
                  photo={data.photo}
                />
              ))}
            </tbody>
          </table>
          {list.length > 0 && (
            <Pagination
              activePage={activePage}
              setActivePage={setActivePage}
              itemsCountPerPage={rows}
              totalItemsCount={count}
              pageRangeDisplayed={5}
              onChange={handlePageChange}
            />
          )}
        </div>
      </div>
      <TeacherDetail
        id={teacherId}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        onRemoved={handleRemove}
        onUpdated={handleUpdated}
      />
    </>
  );
};;
