import React, { useEffect, useState } from 'react';
import { Dialog } from '@headlessui/react';
import { format, getDay } from 'date-fns';
import DatePicker from 'react-date-picker';

import { useAppDispatch } from '@store/hook';
import { open as modalOpen } from '@store/modal';

import { Card, Button, CourseSelector } from '@components';
import { CourseDto, SubjectDto } from '@dto';

interface CourseAddProp {
  readonly refCourse?: CourseDto;
  readonly isOpen: boolean;
  readonly setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  readonly onAdd: (refCourse: CourseDto) => void;
  readonly disabledCourse?: boolean;
}

const durations: { name: string; value: 0 | 1 | 2 | 3 | 4 | 5 | 6 }[] = [
  { name: '일', value: 0 },
  { name: '월', value: 1 },
  { name: '화', value: 2 },
  { name: '수', value: 3 },
  { name: '목', value: 4 },
  { name: '금', value: 5 },
  { name: '토', value: 6 },
];

export const CourseAdd: React.FC<CourseAddProp> = ({
  refCourse: _refCourse,
  isOpen,
  setIsOpen,
  onAdd,
  disabledCourse: _disabledCourse = false,
}) => {
  const [selectorOpen, setSelectorOpen] = useState<boolean>(false);
  const [refCourse, setRefCourse] = useState<CourseDto | undefined>();
  const [duration, setDuration] = useState<0 | 1 | 2 | 3 | 4 | 5 | 6>(0);
  const [date, setDate] = useState<Date>(new Date());
  const [disabledCourse, setDisabledCourse] = useState<boolean>(false);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!isOpen) {
      setRefCourse(undefined);
      setDuration(0);
      setDate(new Date());
    }
  }, [isOpen]);

  useEffect(() => {
    if (isOpen && _refCourse) {
      setRefCourse(_refCourse);
      setDuration(_refCourse.duration as 0 | 1 | 2 | 3 | 4 | 5 | 6);
      setDate(new Date(_refCourse.startDate as string));
    }
  }, [isOpen, _refCourse]);

  useEffect(() => {
    setDisabledCourse(_disabledCourse);
  }, [_disabledCourse]);

  const handleCourseSelect = (
    id: number,
    title: string,
    content: string,
    subjects?: SubjectDto[],
  ) => {
    setRefCourse({
      id,
      title,
      subjects,
    });
  };

  const showAlert = (message: string) => {
    dispatch(
      modalOpen({
        type: 'alert',
        message,
        isOpen: true,
      }),
    );
  };

  const handleAdd = () => {
    if (!refCourse) {
      showAlert('커리큘럼을 선택해주세요');
      return;
    }

    if (!date) {
      showAlert('기간을 선택해주세요');
      return;
    }

    if (duration === undefined) {
      showAlert('반복주기를 선택해주세요');
      return;
    }

    refCourse.duration = duration;
    refCourse.startDate = format(date, 'yyyy-MM-dd');
    onAdd(refCourse);
    setIsOpen(false);
  };

  const handleChangeDate = (date: Date | null) => {
    if (date === null) {
      setDate(new Date());
    } else {
      setDate(date);
    }

    setDuration(getDay(date || new Date()));
  };

  return (
    <>
      <Dialog className="relative z-[1010]" open={isOpen} onClose={() => { }}>
        {/* BACKDROP */}
        <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
        <div className="fixed inset-0 flex items-center justify-center min-h-full">
          <Dialog.Panel className="w-full lg:max-w-lg">
            <Card
              title={'커리큘럼 추가'}
              actions={
                <>
                  <Button plain className="font-normal" onClick={() => setIsOpen(false)}>
                    취소
                  </Button>
                  <Button
                    type="submit"
                    plain
                    color="secondary"
                    onClick={handleAdd}
                    className="font-normal">
                    확인
                  </Button>
                </>
              }>
              <>
                <div className="mb-4 form-control">
                  <label className="label">
                    <span className="label-text">커리큘럼</span>
                  </label>
                  <div
                    className="cursor-pointer select select-bordered"
                    onClick={() => {
                      if (disabledCourse) {
                        return false;
                      }
                      setSelectorOpen(true);
                    }}>
                    <span className="my-auto text-sm font-normal">
                      {refCourse && `${refCourse.title} (${refCourse.subjects?.length}강)`}
                      {!refCourse && `커리큘럼을 선택해주세요`}
                    </span>
                  </div>
                </div>
                <div className="mb-4 form-control">
                  <label className="label">
                    <span className="label-text">시작날짜</span>
                  </label>
                  <DatePicker
                    onChange={handleChangeDate}
                    className="text-sm input input-bordered flex items-center"
                    calendarClassName={'text-sm rounded-lg bg-white shadow-lg border-0'}
                    dayAriaLabel="Day"
                    monthAriaLabel="Month"
                    calendarAriaLabel="Toggle calendar"
                    clearAriaLabel="Clear value"
                    yearAriaLabel="Year"
                    nativeInputAriaLabel="Date"
                    value={date}
                    locale="ko"
                    // minDate={new Date()}
                    format="y-MM-dd"
                    openCalendarOnFocus={false}
                    required
                    formatDay={(locale: any, date: Date) => format(date, 'd')}
                    calendarType={'US'}
                  />
                </div>
                <div className="mb-4 form-control">
                  <label className="label">
                    <span className="label-text">반복주기</span>
                  </label>
                  <div className="grid grid-cols-7 gap-2">
                    {durations.map((value, index) => {
                      return (
                        <button
                          key={index}
                          className={`inline-flex items-center justify-center border border-primary h-9 rounded-lg text-white ${duration === value.value ? 'bg-primary text-white' : 'text-primary'
                            } disabled:opacity-50`}
                          onClick={() => {
                            setDuration(value.value);
                          }}>
                          {value.name}
                        </button>
                      );
                    })}
                  </div>
                </div>
              </>
            </Card>
          </Dialog.Panel>
        </div>
      </Dialog>
      <CourseSelector
        mode="custom"
        isOpen={selectorOpen}
        setIsOpen={setSelectorOpen}
        onSelect={handleCourseSelect}
        defaultId={refCourse?.id}
      />
    </>
  );
};
