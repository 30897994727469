import axiosInstance from './index';
import { OrgDto, SearchDto } from '@dto';
import { ORG } from '@common';

/**
 * 반을 신규 등록한다
 * @param title - 제목
 * @param type - ORG 타입
 * @returns OrgDto
 */
export const create = (title: string, type: ORG): Promise<OrgDto> =>
  axiosInstance.post('/org', { title, type, name: title });

/**
 * 반 목록을 가져온다
 * @param search SearchDto - 검색 항목
 * @returns { count: number, data: OrgDto[] }
 */
export const list = (search: SearchDto): Promise<{ count: number; data: OrgDto[] }> =>
  axiosInstance.post('/org/list', search);

/**
 * 반을 삭제한다
 * @param id
 * @returns { count: number }
 */
export const remove = (id: number): Promise<{ count: number }> => axiosInstance.delete(`/org/${id}`);

/**
 * 업데이트를 한다
 * @param org
 * @returns OrgDto
 */
export const updateById = (org: OrgDto): Promise<OrgDto> =>
  axiosInstance.patch(`/org/${org.id}`, org);

  /**
   * org id로 상세를 가져온다
   * @param id
   * @returns
   */
export const getById = (id: number): Promise<OrgDto> => axiosInstance.get(`/org/${id}`);