import { format, isAfter, isBefore } from 'date-fns';
import React from 'react';
import { Chip } from '../Chip';

interface SurveyItemProp {
  readonly id: number;
  readonly header: boolean;
  readonly code?: '10' | '20' | '30';
  readonly voteYn?: string;
  readonly title: string;
  readonly createdAt?: string;
  readonly startDate?: string;
  readonly endDate?: string;
  readonly onClick?: (id: number) => void;
}

export const SurveyItem: React.FC<SurveyItemProp> = ({
  id,
  header = true,
  code = '10',
  voteYn = 'N',
  title,
  startDate,
  endDate,
  createdAt,
  onClick,
}: SurveyItemProp) => {
  const handleClick = () => {
    if (onClick) {
      onClick(id);
    }
  };

  const voteType = () => {
    // startDate, endDate가 없다면 무조건 종료로 친다.
    const defaultClassName: string = 'mr-2 w-20';

    if (voteYn === 'Y') {
      return (
        <Chip label className={`${defaultClassName} bg-blue-500`}>
          참여완료
        </Chip>
      );
    } else {
      if (
        startDate &&
        endDate &&
        (isBefore(new Date(), new Date(startDate)) || isAfter(new Date(), new Date(endDate)))
      ) {
        if (isBefore(new Date(), new Date(startDate))) {
          return (
            <Chip label className={`${defaultClassName} bg-amber-500`}>
              기간이전
            </Chip>
          );
        }

        if (isAfter(new Date(), new Date(endDate))) {
          return (
            <Chip label className={`${defaultClassName} bg-red-500`}>
              기간종료
            </Chip>
          );
        }
      }

      return (
        <Chip label className={`${defaultClassName} bg-green-500`}>
          참여하기
        </Chip>
      );
    }
  };

  return (
    <>
      <tr className="cursor-pointer group" onClick={handleClick}>
        <td className="border-b text-sm text-black/80 lg:hidden">
          {voteType()}
          <p className="whitespace-normal break-words mt-1 mb-2">{title}</p>
          <p className="text-gray-400">{`${startDate} ~ ${endDate}`}</p>
        </td>
        <td className="border-b text-black/80 text-sm h-14 px-4 py-0 leading-normal truncate max-w-xs group-hover:bg-slate-50 hidden lg:table-cell">
          {voteType()}
          {title}
        </td>
        {header && (
          <td className="border-b text-black/80 text-sm h-14 px-4 py-0 leading-normal group-hover:bg-slate-50 hidden lg:table-cell">
            {`${startDate} ~ ${endDate}`}
          </td>
        )}
        <td className="border-b text-black/80 text-sm h-14 px-4 py-0 leading-normal group-hover:bg-slate-50 hidden lg:table-cell">
          {format(new Date(createdAt as string), 'yyyy-MM-dd')}
        </td>
      </tr>
    </>
  );
};
