/**
 * 출석부
 */
import React, { useState, useEffect, useCallback } from 'react';
import { countBy } from 'lodash';

import * as subjectApi from '@api/subject';

import { useAppDispatch } from '@store/hook';
import { open as modalOpen } from '@store/modal';
import { open as loadingOpen, close as loadingClose } from '@store/loading';

import { AttendDto, SearchDto, SubjectAttendDto, SubjectDto, UserDto } from '@dto';
import { Button } from '@components';

import DatePicker from 'react-date-picker';
import { TailSpin } from 'react-loader-spinner';
import { format } from 'date-fns';

interface ChildAttendanceProp {
  subject?: SubjectDto | undefined;
  members?: UserDto[] | undefined;
  onCompleted: () => void;
}


interface ItemProp {
  userId: number;
  userName: string;
  value?: 'Y' | 'N' | undefined;
  disabled?: boolean;
  onChange: (userId: number, value: 'Y' | 'N') => void;
}

const Item: React.FC<ItemProp> = ({
  userId,
  userName,
  value: _value,
  disabled = false,
  onChange,
}) => {
  const [value, setValue] = useState<'Y' | 'N' | undefined>();

  useEffect(() => {
    setValue(_value);
  }, [_value]);

  const handleChange = (e: any) => {
    setValue(e.target.value);
    onChange(userId, e.target.value);
  };

  return (
    <div className={`group grid grid-cols-12 h-12 text-sm px-4 bg-white`}>
      <div className="col-span-6 flex items-center">{userName}</div>
      <div className="col-span-3 flex items-center justify-center">
        <input
          type="radio"
          className="radio radio-sm"
          name={`${userId}`}
          checked={value === 'Y'}
          disabled={disabled}
          value={'Y'}
          onChange={handleChange}
        />
      </div>
      <div className="col-span-3 flex items-center justify-center">
        <input
          type="radio"
          className="radio radio-sm"
          name={`${userId}`}
          checked={value === 'N'}
          disabled={disabled}
          value={'N'}
          onChange={handleChange}
        />
      </div>
    </div>
  );
};

export const ChildAttendance: React.FC<ChildAttendanceProp> = ({
  subject: _subject,
  members: _members,
  onCompleted,
}) => {
  // 상태변경
  const [subject, setSubject] = useState<SubjectDto | undefined>();
  const [completed, setCompleted] = useState<boolean>(false);
  const [countY, setCountY] = useState<number>(0);
  const [countN, setCountN] = useState<number>(0);
  const [members, setMembers] = useState<UserDto[]>([]);
  const [attends, setAttends] = useState<AttendDto[]>([]);
  const [prevAttends, setPrevAttends] = useState<AttendDto[]>([]);
  const [allCheck, setAllCheck] = useState<'Y' | 'N' | undefined>();
  const [loading, setLoading] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const [date, setDate] = useState<Date>();

  const handleChangeDate = (date: Date | null) => {
    if (date === null) {
      if (subject?.attendDate) { //출석이 되어 있다면...
        setDate(new Date("" + subject?.attendDate))
      } else {
        setDate(new Date("" + subject?.startDate))
      }
    } else {
      setDate(date);
    }

  };
  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      if (!subject) {
        setAttends([]);
        setPrevAttends([]);
        return;
      }
      const search: SearchDto = {
        page: 1,
        limit: 100,
        search: {
          subject: {
            id: subject.id,
          },
        },
      };
      if (subject.attendDate) { //출석이 되어 있다면...
        setDate(new Date("" + subject.attendDate))
      } else {
        setDate(new Date("" + subject.startDate))
      }

      const { count, data } = await subjectApi.attendList(search);
      const countY: number = [...data].filter(
        (subjectAttend: AttendDto) => subjectAttend.check === 'Y',
      ).length;
      setCountY(countY);
      setCountN(count - countY);
      const newAttends: AttendDto[] = [...members].map((member: UserDto) => {
        const newAttend: AttendDto | undefined = data.find(
          (a: AttendDto) => a.user?.id === member.id,
        );

        if (newAttend) {
          return { ...newAttend };
        } else {
          return {
            user: { ...member },
            check: undefined,
          };
        }
      });

      let yCount: number = 0;
      let nCount: number = 0;

      newAttends.forEach(({ check }: AttendDto) => {
        if (check) {
          if (check === 'Y') yCount++;
          else nCount++;
        }
      });

      let allCheck: 'Y' | 'N' | undefined = undefined;

      if (newAttends.length === yCount) {
        allCheck = 'Y';
      } else if (newAttends.length === nCount) {
        allCheck = 'N';
      }

      setAttends([...newAttends]);
      setPrevAttends(JSON.parse(JSON.stringify(newAttends)));

      setAllCheck(allCheck);
    } catch (error: any) {
      console.log(error)
      dispatch(
        modalOpen({
          type: 'alert',
          isOpen: true,
          title: '오류',
          message: error.message
            ? error.message
            : `오류가 발생하였습니다. [오류코드 ${error.status}]`,
        }),
      );
    } finally {
      setIsEdit(false);
      setLoading(false);
    }
    // eslint-disable-next-line
  }, [subject]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    if (subject?.attendDate) {
      setCompleted(true);
    } else {
      setCompleted(false);
    }
  }, [subject?.attendDate]);

  useEffect(() => {
    setSubject(_subject);
  }, [_subject]);

  useEffect(() => {
    if (_members) {
      setMembers([..._members]);
      const attends: AttendDto[] = [..._members].map((user: UserDto) => ({ user }));
      setAttends([...attends]);
      setPrevAttends([...attends]);
    }
  }, [_members]);

  const handleCheckAll = (e: any) => {
    setAllCheck(e.target.value);
    const newAttends: AttendDto[] = [...attends].map((attend: AttendDto) => {
      attend.check = e.target.value;
      return attend;
    });
    setAttends(newAttends);
  };
  /**
   * 출석부 수정
   * @param e 
   * @returns 
   */
  const handleSubmit = (e: any) => {
    e.preventDefault();
    const checkedAttends: AttendDto[] = [...attends].filter((attend: AttendDto) => attend.check);

    if (checkedAttends.length !== attends.length) {
      dispatch(
        modalOpen({
          type: 'alert',
          isOpen: true,
          message: '모든 원생의 출석을 완료해야 합니다',
        }),
      );
      return;
    }

    dispatch(
      modalOpen({
        isOpen: true,
        type: 'confirm',
        message: '출석을 진행하시겠습니까?',
        onOk: async () => {
          dispatch(loadingOpen());
          try {
            const subjectAttend: SubjectAttendDto = {
              subject: {
                id: subject?.id as number,
                attendDate: date?.toISOString(),
              },
              attends: checkedAttends,
            };
            const result: SubjectAttendDto[] = await subjectApi.attendBulk(subjectAttend);
            if (result.length === 0) {
              throw new Error('출석 처리에 실패하였습니다');
            }
            onCompleted();
            setCompleted(true);
            fetchData();
          } catch (error: any) {
            dispatch(
              modalOpen({
                type: 'alert',
                isOpen: true,
                title: '오류',
                message: error.message
                  ? error.message
                  : `오류가 발생하였습니다. [오류코드 ${error.status}]`,
              }),
            );
          } finally {
            setIsEdit(false);
            dispatch(loadingClose());
          }
        },
        onCancel: () => { },
      }),
    );
  };

  /**
   * 변경 감지
   * @param id
   * @param value
   */
  const handleChange = (id: number, value: 'Y' | 'N') => {
    const newAttends: AttendDto[] = [...attends].map((attend: AttendDto) => {
      if (attend.user.id === id) {
        attend.check = value;
      }
      return attend;
    });
    setCheckAllYn([...newAttends]);
    setAttends([...newAttends]);
  };

  const setCheckAllYn = (newAttends: AttendDto[]) => {
    const count: { Y?: number; N?: number } = countBy(newAttends, (attends) => attends.check);

    if (count.Y === members.length) {
      setAllCheck('Y');
    } else if (count.N === members.length) {
      setAllCheck('N');
    } else {
      setAllCheck(undefined);
    }
  };

  const toggleEdit = () => {
    setIsEdit(!isEdit);
  };

  const handleCancel = () => {
    // 스프레드 연산자로 깊은 복사가 제대로 되지 않아 JSON 문자열을 만들고
    // JSON 파싱을 다시 하는 방법으로 깊은 복사 실행
    setAttends(JSON.parse(JSON.stringify(prevAttends)));

    const yCount = prevAttends.filter((a) => a.check === 'Y').length;
    const nCount = prevAttends.length - yCount;

    let allCheck: 'Y' | 'N' | undefined = undefined;

    if (prevAttends.length === yCount) {
      allCheck = 'Y';
    } else if (prevAttends.length === nCount) {
      allCheck = 'N';
    }

    setAllCheck(allCheck);
    toggleEdit();
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        {subject?.id && (
          <>
            {completed && (
              <div className="flex justify-between items-end">
                <div className="text-sm mb-2 px-2 lg:mt-[76px] flex flex-col md:flex-row">
                  &nbsp;
                  {!isEdit && (
                    <>
                      <div>출석 인원 {countY}명 / 결석 인원 {countN}명</div>
                      <div className="hidden md:block">- 총 인원 {members.length}명</div>
                    </>
                  )}
                  {isEdit && (

                    <DatePicker
                      onChange={handleChangeDate}
                      className="text-xs input   flex items-center h-auto z-50"
                      calendarClassName={'text-xs  shadow-lg '}
                      dayAriaLabel="Day"
                      monthAriaLabel="Month"
                      calendarAriaLabel="Toggle calendar"
                      clearAriaLabel="Clear value"
                      yearAriaLabel="Year"
                      nativeInputAriaLabel="Date"
                      value={date}
                      locale="ko"
                      format="y-MM-dd"
                      openCalendarOnFocus={false}
                      required
                      formatDay={(locale: any, date: Date) => format(date, 'd')}
                      calendarType={'US'}

                    />

                  )}
                </div>
                {isEdit && (

                  <div className="space-x-2 mb-2 flex">

                    <Button
                      color="error"
                      className="bg-danger border-danger hover:bg-danger/80 hover:border-danger/80 focus-visible:outline-danger"
                      onClick={handleCancel}>
                      <span className="font-normal text-white">취소</span>
                    </Button>
                    <Button color="secondary" type="submit">
                      <span className="font-normal text-white">수정</span>
                    </Button>
                  </div>
                )}
                {!isEdit && (
                  <div className="space-x-2 mb-2 flex">
                    <Button color="secondary" onClick={toggleEdit}>
                      <span className="font-normal text-white">수정</span>
                    </Button>
                  </div>
                )}
              </div>
            )}
            {!completed && (

              <div className="w-full flex justify-end items-center mb-2 px-2 lg:mt-[60px]">

                <DatePicker

                  onChange={handleChangeDate}
                  className="text-xs input   flex items-center h-auto z-50"
                  calendarClassName={'text-xs  shadow-lg '}
                  dayAriaLabel="Day"
                  monthAriaLabel="Month"
                  calendarAriaLabel="Toggle calendar"
                  clearAriaLabel="Clear value"
                  yearAriaLabel="Year"
                  nativeInputAriaLabel="Date"
                  value={date}
                  locale="ko"
                  format="y-MM-dd"
                  openCalendarOnFocus={false}
                  required
                  formatDay={(locale: any, date: Date) => format(date, 'd')}
                  calendarType={'US'}
                />
                <Button type="submit" color="secondary">
                  <span className="font-normal text-white">저장</span>
                </Button>
              </div>
            )}
          </>
        )}
        <div
          className={`relative divide-y border rounded-lg h-96 lg:h-[calc(100vh-296px)] overflow-y-auto ${subject?.id ? '' : 'lg:mt-[104px]'
            }`}>
          <div className="sticky top-0 grid grid-cols-12 h-12 text-sm text-black/60 border-b bg-slate-50 font-medium z-10">
            <div className="col-span-6 flex items-center justify-center">이름</div>
            <div className="col-span-3 flex items-center justify-center">출석</div>
            <div className="col-span-3 flex items-center justify-center">결석</div>
          </div>
          {loading && (
            <div className="absolute w-full lg:h-[calc(100%-48px)] flex items-center justify-center bg-white/75">
              <TailSpin
                height="48"
                width="48"
                color="#ff9e18"
                ariaLabel="tail-spin-loading"
                radius="1"
                visible={true}
              />
            </div>
          )}
          {((completed && isEdit) || !completed) && subject?.id && members.length > 0 && (
            <div className="sticky top-0 grid grid-cols-12 h-12 text-sm text-black/60 bg-white px-4">

              <div className="col-span-6 flex items-center">

              </div>
              <div className="col-span-3 flex items-center justify-center">
                <label className="label">
                  <input
                    type="radio"
                    name="all"
                    className="radio radio-sm md:mr-2"
                    value="Y"
                    checked={allCheck === 'Y'}
                    onChange={handleCheckAll}
                  />
                  <span className="hidden md:block label-text font-normal">전체 출석</span>
                </label>
              </div>
              <div className="col-span-3 flex items-center justify-center">
                <label className="label px-0">
                  <input
                    type="radio"
                    name="all"
                    className="radio radio-sm md:mr-2"
                    value="N"
                    checked={allCheck === 'N'}
                    onChange={handleCheckAll}
                  />
                  <span className="hidden md:block label-text font-normal">전체 결석</span>
                </label>
              </div>
            </div>
          )}
          {subject?.id === undefined && (
            <div className="grid grid-cols-1 text-sm bg-white h-[calc(100%-48px)] text-center">
              <div className="flex items-center justify-center">
                <span className="text-gray-500">
                  반과 커리큘럼을 선택한 뒤 과정을 선택해주세요.
                </span>
              </div>
            </div>
          )}
          {subject?.id &&
            attends.length > 0 &&
            attends.map((attend: AttendDto, index: number) => {
              return (

                <Item
                  key={index}
                  userId={attend.user.id as number}
                  userName={attend.user.userName as string}
                  value={attend.check}
                  disabled={completed && !isEdit}
                  onChange={handleChange}
                />
              );
            })}
        </div>
      </form>
    </>
  );
};
