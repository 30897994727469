import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import * as authApi from '@api/auth';

import { PageHeader } from '@components';
import { useAppDispatch } from '@store/hook';
import { open as modalOpen } from '@store/modal';

export const ChangePassword = () => {
  const { hash } = useParams();
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const schema = yup.object().shape({
    newPassword: yup
      .string()
      .min(6, '변경하실 비밀번호를 6자 이상 입력해주세요')
      .max(16)
      .required('변경하실 비밀번호를 입력해주세요'),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref('newPassword'), null], '변경하실 비밀번호와 동일해야 합니다.')
      .min(6, '비밀번호 확인을 6자 이상 입력해주세요')
      .max(16)
      .required('비밀번호 확인을 입력해주세요.'),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    defaultValues: {
      newPassword: '',
      confirmPassword: '',
    },
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data: any) => {
    try {
      setLoading(true);
      if (!hash) {
        throw new Error('데이터가 올바르지 않습니다');
      }

      const result = await authApi.resetPassword(hash, data.newPassword);

      if (!result) {
        throw new Error('비밀번호 변경에 실패하였습니다');
      }

      dispatch(
        modalOpen({
          type: 'alert',
          message: '비밀번호 변경에 성공하였습니다. 로그인을 해주세요',
          onOk() {
            navigate('/signin');
          },
        }),
      );
    } catch (error: any) {
      dispatch(
        modalOpen({
          type: 'alert',
          isOpen: true,
          title: '오류',
          message: error.message
            ? error.message
            : `오류가 발생하였습니다. [오류코드 ${error.status}]`,
          onOk() {
            navigate('/signin');
          },
        }),
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="w-full h-screen flex flex-col items-center overflow-y-auto">
      <PageHeader auth prev={'/signin'} />
      <div className="container flex items-center">
        <div className="w-full max-w-md pt-8 lg:pt-24 pb-24 mx-auto px-4">
          <h3 className="mb-4 text-3xl font-semibold tracking-wider">비밀번호 변경하기</h3>
          {/* {error.show && (
        <div className="alert alert-error text-sm text-white mb-4 font-light tracking-wider">
          {error.message}
        </div>
      )} */}
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-6">
              <div className="form-control w-full mb-4">
                <label className="label">
                  <span className={`label-text text-sm ${errors.newPassword && 'text-error'}`}>
                    변경할 비밀번호
                  </span>
                </label>
                <input
                  type="password"
                  placeholder="변경하실 비밀번호를 입력해주세요"
                  className={`input input-bordered w-full text-sm ${
                    errors.newPassword && 'input-error'
                  }`}
                  {...register('newPassword')}
                  disabled={loading}
                />
                {errors.newPassword && (
                  <div className="mt-1 text-sm text-error">{errors.newPassword.message}</div>
                )}
              </div>
              <div className="form-control w-full mb-4">
                <label className="label">
                  <span className={`label-text text-sm ${errors.confirmPassword && 'text-error'}`}>
                    비밀번호 확인
                  </span>
                </label>
                <input
                  type="password"
                  placeholder="변경하실 비밀번호 확인을 입력해주세요"
                  className={`input input-bordered w-full text-sm ${
                    errors.confirmPassword && 'input-error'
                  }`}
                  {...register('confirmPassword')}
                  disabled={loading}
                />
                {errors.confirmPassword && (
                  <div className="mt-1 text-sm text-error">{errors.confirmPassword.message}</div>
                )}
              </div>
            </div>
            <div className="pb-6">
              <button
                type="submit"
                className={`btn btn-secondary w-full font-normal text-white ${
                  loading && 'loading'
                }`}
                disabled={loading}>
                {!loading && '비밀번호 변경'}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
