import React, { useCallback, useEffect, useState } from 'react';
import { Button, Pagination, ChildProfile, ChildCreate } from '@components';
import { ClassSelect } from '@components/ClassSelect';
import { PlusIcon, UserCircleIcon } from '@heroicons/react/20/solid';
import { toast } from 'react-toastify';

import { useAppDispatch } from '@store/hook';
import { open as modalOpen } from '@store/modal';
import { open as loadingOpen, close as loadingClose } from '@store/loading';

import * as userApi from '@api/user';
import { SearchDto, UserDto } from '@dto';
import { USER_STATE } from '@common';

export const Child = () => {
  const rows: number = 10;
  const dispatch = useAppDispatch();

  const [totalCount, setTotalCount] = useState<number>(0);
  const [list, setList] = useState<UserDto[]>([]);
  const [activePage, setActivePage] = useState<number>(1);
  const [form, setForm] = useState<boolean>(false);
  const [profile, setProfile] = useState<boolean>(false);
  const [userName, setUserName] = useState<string>('');
  const [userId, setUserId] = useState<number | string>();
  const [orgId, setOrgId] = useState<number | undefined>(-1);
  const [search, setSearch] = useState<SearchDto>({
    page: 1,
    limit: rows,
    search: {
      status: USER_STATE.ACTIVATE,
    },
  });

  /**
   *
   */
  const fetchData = useCallback(async () => {
    try {
      const response: any = await userApi.childrenList(search);

      setTotalCount(response.count);
      setList([...response.data]);
    } catch (error) {
    } finally {
    }
  }, [search]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handlePageChange = (pageNumber: number) => {
    setActivePage(pageNumber);
    const newSearch: SearchDto = {
      ...search,
      page: pageNumber,
    };

    setSearch(newSearch);
  };

  /**
   * 삭제
   * @param id
   */
  const handleRemove = (id: number | undefined) => {
    dispatch(
      modalOpen({
        title: '삭제',
        isOpen: true,
        type: 'confirm',
        message: '해당 원생을 삭제하시겠습니까?',
        onOk: async () => {
          try {
            dispatch(loadingOpen());
            if (!id) {
              throw new Error('원생의 ID가 없습니다.');
            }
            await userApi.removeUser(id);
            fetchData();
            toast.info('원생을 삭제하였습니다');
          } catch (error: any) {
            dispatch(
              modalOpen({
                title: '오류',
                isOpen: true,
                type: 'alert',
                message: `오류가 발생하였습니다. ${error.message}`,
              }),
            );
          } finally {
            dispatch(loadingClose());
          }
        },
      }),
    );
  };

  /**
   * 검색
   */
  const handleSearch = () => {
    setActivePage(1);
    const search: SearchDto = {
      page: 1,
      limit: rows,
      search: {},
    };

    if (userName && userName.trim() !== '') {
      search.search = {
        userName,
      };
    }

    if (orgId && orgId > -1) {
      search.search.class = { id: orgId };
    }

    setSearch(search);
  };

  /**
   * 검색 리셋
   */
  const handleReset = () => {
    setUserName('');
    setActivePage(1);
    setOrgId(-1);

    const search: SearchDto = {
      page: 1,
      limit: rows,
      search: {},
    };

    setSearch(search);
  };

  const handleChangeClass = (id: number) => {
    setOrgId(id);
    const newSearch: SearchDto = { ...search };
    newSearch.page = 1;
    newSearch.search.class = { id };
    setActivePage(1);
    setSearch(newSearch);
  };

  return (
    <>
      <div className="w-full min-h-[calc(100vh-112px)]">
        <h3 className="text-3xl font-semibold tracking-wider mb-4">원생 관리</h3>
        <div className="flex flex-col bg-white w-full overflow-y-hidden rounded-lg shadow-xl p-4">
          {/* ADD BUTTON */}
          <div className="w-full text-right mb-4">
            <Button
              color="primary"
              className="text-white hover:!text-white"
              onClick={() => setForm(true)}>
              <PlusIcon className="w-4 h-6 fill-white" />
              <span className="ml-1 text-sm text-white font-normal">원생 등록</span>
            </Button>
          </div>
          <div className="grid grid-cols-12 mb-4 w-full gap-4">
            <div className="col-span-3">
              <ClassSelect
                value={orgId}
                selectClassName="min-h-[2.25rem] h-[2.25rem] max-h-[2.25rem]"
                onChange={handleChangeClass}
              />
            </div>
            <div className="col-span-3">
              <div className="form-control">
                <label className="label">
                  <span className="label-text">이름</span>
                </label>
                <input
                  type="text"
                  className="input input-bordered text-sm h-[2.25rem]"
                  placeholder="이름을 입력해주세요"
                  value={userName}
                  onChange={(e) => {
                    setUserName(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="col-span-3 flex items-end mb-1 space-x-2">
              <Button color="secondary" onClick={handleSearch}>
                <span className="text-white font-light">검색</span>
              </Button>
              <Button
                color="error"
                className="bg-danger border-danger hover:bg-danger/80 hover:border-danger/80 focus-visible:outline-danger"
                onClick={handleReset}>
                <span className="text-white font-light">초기화</span>
              </Button>
            </div>
          </div>
          <div className="w-full overflow-x-auto">
            <table className="table w-full mb-4 border-y">
              <colgroup>
                <col width={'20%'} />
                <col width={'*'} />
                <col width={'30%'} />
                <col width={'15%'} />
              </colgroup>
              <thead>
                <tr>
                  <th className="h-12 text-sm text-black/60 border-b bg-slate-50 font-medium lg:indent-12">
                    반
                  </th>
                  <th className="h-12 text-sm text-black/60 border-b bg-slate-50 font-medium lg:indent-12">
                    이름
                  </th>
                  <th className="h-12 text-sm text-black/60 border-b bg-slate-50 font-medium lg:indent-12">
                    부모님
                  </th>
                  <th className="h-12 text-sm text-black/60 border-b bg-slate-50 font-medium"></th>
                </tr>
              </thead>
              <tbody>
                {list.length === 0 && (
                  <tr>
                    <td colSpan={5} className="h-80">
                      <p className="m-auto text-sm text-center text-gray-500">
                        데이터를 찾을 수 없습니다.
                      </p>
                    </td>
                  </tr>
                )}
                {list.map((value, index) => (
                  <tr key={index}>
                    <td className="text-sm lg:indent-12 p-2">
                      {value.class?.length ? value.class[0]?.title : ''}
                    </td>
                    <td className="text-sm p-2">
                      <div className="flex items-center">
                        <div className="mr-2">
                          {!value.photo && (
                            <UserCircleIcon className="w-12 h-12 fill-slate-400/75" />
                          )}
                          {value.photo && (
                            <div className="w-12 h-12 flex items-center justify-center">
                              <img
                                src={value.photo.path}
                                alt="profile"
                                className="w-[38px] h-[38px] rounded-full"
                              />
                            </div>
                          )}
                        </div>
                        <div>{value.userName}</div>
                      </div>
                    </td>
                    <td className="text-sm lg:indent-12 py-2">
                      {value.parent && (
                        <>
                          <div className="mb-1">{value.parent?.userName}</div>
                          <div className="text-gray-500 text-xs">({value.parent?.email})</div>
                        </>
                      )}
                    </td>
                    <td className="text-sm text-center p-2">
                      <Button
                        color="primary"
                        size="sm"
                        className="mr-2 text-xs hover:!text-white"
                        outlined
                        onClick={() => {
                          setUserId(value.id);
                          setProfile(true);
                        }}>
                        상세보기
                      </Button>
                      <Button
                        color="error"
                        size="sm"
                        className="text-xs hover:!text-white"
                        outlined
                        onClick={() => handleRemove(value.id)}>
                        삭제
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {totalCount > 0 && (
              <Pagination
                activePage={activePage}
                setActivePage={setActivePage}
                itemsCountPerPage={rows}
                totalItemsCount={totalCount}
                pageRangeDisplayed={5}
                onChange={handlePageChange}
              />
            )}
          </div>
        </div>
      </div>
      <ChildCreate
        isOpen={form}
        setIsOpen={setForm}
        onCreated={() => {
          fetchData();
        }}
      />
      <ChildProfile
        userId={userId as number}
        isOpen={profile}
        setIsOpen={setProfile}
        onRemoved={() => {
          fetchData();
        }}
        onUpdated={() => {
          fetchData();
        }}
      />
    </>
  );
};;
