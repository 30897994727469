import React, { useCallback, useEffect, useState } from 'react';
import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/20/solid';

import * as subjectApi from '@api/subject';
import { SubjectDto } from '@dto';
import { TailSpin } from 'react-loader-spinner';

interface RatingAttendanceProp {
  readonly courseId?: number | undefined;
  readonly childId?: number | undefined;
  readonly onSelect: (id: number, isAttend: boolean) => void;
}

interface AttendanceDto {
  id: number;
  name: string;
  mark: boolean;
}

interface ItemProp extends AttendanceDto {
  selected?: boolean;
  onClick?: (id: number) => void;
}

const Item: React.FC<ItemProp> = ({ id, name, mark, onClick, selected = false }) => {
  const handleClick = () => {
    if (onClick) {
      onClick(id);
    }
  };

  return (
    <div
      className={`group grid grid-cols-12 h-12 text-sm px-4 cursor-pointer hover:bg-primary/70 ${
        selected ? 'bg-primary' : 'bg-white'
      }`}
      onClick={handleClick}>
      <div
        className={`col-span-10 flex items-center group-hover:text-white ${
          selected && 'text-white'
        }`}>
        {name}
      </div>
      <div
        className={`col-span-2 flex items-center justify-center group-hover:text-white ${
          selected && 'text-white'
        }`}>
        {mark && (
          <CheckCircleIcon
            className={`w-6 h-6 group-hover:fill-white ${
              selected ? 'fill-white' : 'fill-gray-500'
            }`}
          />
        )}
        {!mark && (
          <XCircleIcon
            className={`w-6 h-6 group-hover:fill-white ${
              selected ? 'fill-white' : 'fill-gray-500'
            }`}
          />
        )}
      </div>
    </div>
  );
};

export const RatingAttendance: React.FC<RatingAttendanceProp> = ({
  courseId,
  childId,
  onSelect,
}: RatingAttendanceProp) => {
  const [data, setData] = useState<SubjectDto[]>([]);
  const [selectId, setSelectId] = useState<number | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const fetchData = useCallback(async () => {
    try {
      if (!courseId) {
        setData([]);
        return;
      }
      setIsLoading(true);
      setSelectId(undefined);

      const { data } = await subjectApi.list({
        page: 1,
        limit: 100,
        search: {
          course: {
            id: courseId,
          },
          attends: {
            user: {
              id: childId,
            },
          },
        },
      });

      setData([...data]);
    } catch (error: any) {
    } finally {
      setIsLoading(false);
    }
  }, [courseId, childId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleClickItem = (id: number, isAttend: boolean) => {
    setSelectId(id);
    onSelect(id, isAttend);
  };

  const isAttend = (subject: SubjectDto) => {
    if (subject.attends) {
      const attend = subject.attends[0];
      return attend?.check === 'Y';
    } else {
      return false;
    }
  };

  return (
    <div
      className={`relative divide-y border rounded-lg h-72 lg:h-[calc(100vh-372px)] overflow-y-auto`}>
      {isLoading && (
        <div className="absolute w-full h-full bg-white/50 rounded-lg flex items-center justify-center">
          <TailSpin
            height="48"
            width="48"
            color="#ff9e18"
            ariaLabel="tail-spin-loading"
            radius="1"
            visible={true}
          />
        </div>
      )}
      <div className="sticky top-0 grid grid-cols-12 h-12 text-sm text-black/60 border-b bg-slate-50 font-medium">
        <div className="col-span-10 flex items-center justify-center">제목</div>
        <div className="col-span-2 flex items-center justify-center">출석</div>
      </div>
      {!courseId && data.length === 0 && (
        <div className="grid grid-cols-1 text-sm bg-white h-[calc(100%-48px)] text-center">
          <div className="flex items-center justify-center">
            <span className="text-gray-500">커리큘럼을 먼저 선택해주세요.</span>
          </div>
        </div>
      )}
      {courseId && data.length === 0 && (
        <div className="grid grid-cols-1 text-sm bg-white h-[calc(100%-48px)] text-center">
          <div className="flex items-center justify-center">
            <span className="text-gray-500">출석한 주제가 없습니다</span>
          </div>
        </div>
      )}
      {data.length > 0 &&
        data.map((value, index) => {
          return (
            <Item
              key={index}
              id={value.id}
              name={value.title as string}
              mark={isAttend(value)}
              onClick={() => {
                handleClickItem(value.id, isAttend(value));
              }}
              selected={selectId === value.id}
            />
          );
        })}
    </div>
  );
};
