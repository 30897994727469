import React from 'react';
import { useLocation, useMatch, useNavigate } from 'react-router-dom';
import { ChevronDoubleRightIcon } from '@heroicons/react/20/solid';

type Menu = {
  id: number;
  title: string;
  path: string;
  selected: boolean;
};

const Menus = {
  parents: [
    { id: 1, title: '대시보드', path: '/parents', selected: true },
    { id: 2, title: '평가보기', path: '/parents/rating', selected: false },
    { id: 3, title: '앨범', path: '/parents/album', selected: false },
    { id: 10, title: '공지사항', path: '/parents/notice', selected: false },
    { id: 11, title: '설문조사', path: '/parents/survey', selected: false },
  ],
  teacher: [
    { id: 1, title: '대시보드', path: '/teacher', selected: false },
    { id: 2, title: '출석부', path: '/teacher/attendance', selected: false },
    { id: 3, title: '평가하기', path: '/teacher/ratingcreate', selected: false },
    { id: 4, title: '평가보기', path: '/teacher/rating', selected: true },
    { id: 5, title: '수업 관리', path: '/teacher/lecture', selected: false },
    { id: 13, title: '원생 관리', path: '/teacher/child', selected: false },
    { id: 5, title: '앨범', path: '/teacher/album', selected: false },
    { id: 10, title: '공지사항', path: '/teacher/notice', selected: false },
    { id: 11, title: '설문조사', path: '/teacher/survey', selected: false },
  ],
  director: [
    { id: 1, title: '대시보드', path: '/director', selected: false },
    { id: 2, title: '커리큘럼 관리', path: '/director/course', selected: false },
    { id: 3, title: '교사 관리', path: '/director/teacher', selected: false },
    { id: 4, title: '반 관리', path: '/director/org', selected: false },
    { id: 5, title: '수업 관리', path: '/director/lecture', selected: false },
    { id: 6, title: '어린이집 관리', path: '/director/preschool', selected: true },
    { id: 7, title: '원생 평가', path: '/director/rating', selected: false },
    { id: 8, title: '앨범', path: '/director/album', selected: false },
    { id: 9, title: '승인 / 초대', path: '/director/invite', selected: false },
    { id: 12, title: '학부모 관리', path: '/director/parent', selected: false },
    { id: 13, title: '원생 관리', path: '/director/child', selected: false },
    { id: 10, title: '공지사항', path: '/director/notice', selected: false },
    { id: 11, title: '설문조사', path: '/director/survey', selected: false },
  ],
};

interface ISideMenuItemProp {
  readonly id?: number;
  readonly title: string;
  readonly path?: string;
  readonly onClick?: () => void;
}

/**
 * 메뉴의 아이템 항목
 * @param ISideMenuItemProp
 * @returns
 */
const SideMenuItem: React.FC<ISideMenuItemProp> = ({
  title,
  path = '',
  id,
  onClick,
}: ISideMenuItemProp) => {
  const navigate = useNavigate();
  let selected: boolean = false;
  const match = useMatch(path);

  if (match) {
    selected = true;
  }

  const handleClick = () => {
    if (path) {
      navigate(path);
    }

    if (onClick) {
      onClick();
    }
  };

  return (
    <li
      className={`
      px-4 h-12 border-l border-gray-300 flex justify-between items-center cursor-pointer hover:border-secondary hover:text-secondary hover:font-semibold
      ${selected ? 'text-secondary font-semibold border-secondary' : ''}
      `}
      onClick={handleClick}>
      <span>{title}</span>
      <ChevronDoubleRightIcon className="w-4 h-4" />
    </li>
  );
};

interface SidebarProp {
  onClick?: () => void;
}

export const Sidebar: React.FC<SidebarProp> = ({ onClick }) => {
  const { pathname } = useLocation();
  let type: 'director' | 'parents' | 'teacher' = 'director';

  if (/\/parents/g.test(pathname)) {
    type = 'parents';
  } else if (/\/director/g.test(pathname)) {
    type = 'director';
  } else if (/\/teacher/g.test(pathname)) {
    type = 'teacher';
  }

  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <nav className="w-full max-h-screen px-4 py-6 overflow-y-auto bg-white lg:w-64">
      <div className="mb-4 text-sm font-semibold tracking-wide md:hidden text-fontColor/75">
        메뉴
      </div>
      <ul className="mb-8">
        {Menus[type].map((menu: Menu, index: number) => {
          return (
            <SideMenuItem
              key={index}
              title={menu.title}
              path={menu.path}
              id={menu.id}
              onClick={handleClick}
            />
          );
        })}
      </ul>
      <div className="md:hidden">
        <div className="mb-4 text-sm font-semibold tracking-wide text-fontColor/75">
          CREAMO 소개
        </div>
        <ul>
          <SideMenuItem title="회사소개" />
          <SideMenuItem title="이용안내" />
          <SideMenuItem title="학습교구 정보" />
        </ul>
      </div>
    </nav>
  );
};
