import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import { Card, CardTitle, CardBody } from '@components/common';
import { Progress } from '@components/common/Progress';
import { Grade } from '@components/director/Grade';
import { NoticeList, SurveyList } from '@components';
import * as dashApi from '@api/dash';
import { DashDto } from '../../../dto/DashDto';
import { CourseDto, } from '../../../dto';
import { useAppDispatch } from '@store/hook';
import { open as loadingOpen, close as loadingClose } from '@store/loading';

interface ICardDescProps {
  readonly children: ReactNode;
  readonly className?: string;
}

const CardDesc = ({ children, className }: ICardDescProps) => {
  return <div className={`text-3xl font-semibold my-2 leading-10 ${className}`}>{children}</div>;
};

interface IUnitProps {
  readonly label?: string;
  readonly className?: string;
}

const Unit = ({ label, className }: IUnitProps) => {
  return <span className={`text-sm font-normal ml-1 ${className}`}>{label}</span>;
};

export const Dashboard = () => {
  const [activeId, setActiveId] = useState<number>();
  const [course, setCourse] = useState<CourseDto[]>();
  const [info, setInfo] = useState<DashDto>();
  // const [subject, setSubject] = useState<SubjectDto[]>();

  const dispatch = useAppDispatch();

  const fetchData = useCallback(
    async () => {
      try {
        dispatch(loadingOpen());
        const response: any = await dashApi.dash();

        const info = response.result;
        info.classList?.forEach((sClass: any) => {
          let courseAr = new Array<CourseDto>()
          info.courseList?.forEach((course: any) => {
            const u = course.members.find((m: any) =>
              sClass.members.find((m1: any) => m.id === m1.id))
            if (u) {
              courseAr.push(course)
            }
          })
          sClass.classCourse = courseAr
        })

        setInfo(info);

        setActiveCourse(0); //
        setCourse(info.classList[0].classCourse);
        // if (response?.result?.classList?.length > 0) {
        //   // setCourse(response?.result?.classList[0]?.classCourse);
        // }
      } catch (error) {
        console.log('error in Dashboard direct', error);
      } finally {
        dispatch(loadingClose());
      }
    },
    // eslint-disable-next-line
    [],
  );

  const setActiveCourse = (index: number) => {
    setActiveId(index);
    if (!info?.classList) {
      return;
    } else {
      setCourse(info?.classList[index]?.classCourse);
    }
  };
  const getPercent = (course: CourseDto) => {
    const c = info?.courseList.find((a) => {
      return a.id === course.id;
    });
    const slist = c?.subjects?.filter((a) => {
      return a.submitComplete === 'Y' || (a.memberCnt || 0) === ((a.submitCnt || 0) + (a.attendNotCnt || 0));
    });
    const xx = c?.subjects?.length || 0;
    const yy = slist?.length || 0;

    // 둘 중 하나라도 0이면 0%로 표기하게 한다
    if (xx === 0 || yy === 0) {
      return 0;
    }

    return Math.round((yy / xx) * 100);
  };
  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <div className="grid grid-cols-12 gap-4">
      <div className="col-span-12 lg:col-span-4 order-1">
        <Card className="lg:h-full">
          <CardBody>
            <CardTitle>승인 대기 교사</CardTitle>
            <CardDesc className="lg:text-5xl self-end mt-auto">
              {info?.wait_teacher}
              <Unit label="명" />
            </CardDesc>
          </CardBody>
        </Card>
      </div>
      <div className="col-span-12 lg:col-span-4 order-2 lg:order-3">
        <Card className="lg:h-full">
          <CardBody className="flex flex-col">
            <CardTitle>승인 대기 부모님</CardTitle>
            <CardDesc className="lg:text-5xl self-end mt-auto">
              {info?.wait_parent || 0}
              <Unit label="명" />
            </CardDesc>
          </CardBody>
        </Card>
      </div>
      <div className="col-span-12 lg:col-span-8 lg:row-span-2 order-3 lg:order-2">
        <Card className="lg:h-full">
          <CardBody>
            <CardTitle>반별 커리큘럼 진행률</CardTitle>
            <div className="tabs tabs-boxed tab-md bg-white h-auto">
              {info?.classList.map((obj, index: number) => {
                return (
                  <div
                    key={index}
                    onClick={() => {
                      setActiveCourse(index);
                    }}
                    className={`tab rounded text-secondary ${activeId === index ? 'bg-secondary text-white' : 'text-secondary'
                      }`}>
                    {obj.title}
                  </div>
                );
              })}
              {/* 
              <div className={`tab rounded text-secondary`}>2022년 B반</div>
              <div className={`tab rounded text-secondary`}>2022년 C반</div>
              <div className={`tab rounded text-secondary`}>2022년 D반</div> */}
            </div>
            <div className="overflow-y-auto h-56 lg:h-56 mb-2 lg:mt-2 flex w-full flex-col">
              {(!course || course.length === 0) && (
                <p className="text-center text-gray-500 text-sm my-auto">
                  할당된 커리큘럼이 없습니다
                </p>
              )}
              {course?.map((obj, index: number) => {
                return (
                  <div className="grid grid-cols-12 mx-2 mb-2 h-12" key={index}>
                    <div className="col-span-4 lg:col-span-3 w-full my-auto">
                      <div className="truncate text-sm">{obj.title}</div>
                      {obj.alias && (
                        <div className="truncate text-xs text-gray-500">[{obj.alias}]</div>
                      )}
                    </div>
                    <Progress
                      className="col-span-8 lg:col-span-9"
                      theme="success"
                      value={getPercent(obj)}
                      max={100}
                    />
                  </div>
                );
              })}
            </div>
          </CardBody>
        </Card>
      </div>
      <div className="col-span-12 order-4">
        <Card>
          <CardBody>
            <CardTitle>반 현황</CardTitle>
            <div className="flex flex-nowrap w-full max-w-full overflow-y-hidden overflow-x-auto my-2">
              {info?.classList.map((obj, index: number) => {
                return (
                  <Grade
                    key={`grade-${index}`}
                    name={obj?.title || ''}
                    // teacher="AAA 선생님"
                    students={obj?.members || []}
                  />
                );
              })}
            </div>
          </CardBody>
        </Card>
      </div>
      <div className="col-span-12 lg:col-span-6 order-5">
        <Card className="h-[361px]">
          <CardBody>
            <CardTitle>공지사항</CardTitle>
            <NoticeList header={false} pagination={false} rows={5} />
          </CardBody>
        </Card>
      </div>
      <div className="col-span-12 lg:col-span-6 order-6">
        <Card className="h-[361px]">
          <CardBody>
            <CardTitle>설문조사</CardTitle>
            <SurveyList header={false} pagination={false} rows={5} useDashboard />
          </CardBody>
        </Card>
      </div>
    </div>
  );
};
