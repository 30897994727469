import React, { useCallback, useEffect, useState } from 'react';
import { Dialog } from '@headlessui/react';

import * as subjectApi from '@api/subject';

import { useAppDispatch } from '@store/hook';
import { open as modalOpen } from '@store/modal';

import { Card, Button, ChildItem } from '@components';
import { Progress } from '@components/common/Progress';
import { RatingModal } from '@components/RatingModal';
import { RatingCreateModal } from '@components/RatingCreateModal';
import { AttendDto, SubjectDto, UserDto } from '@dto';
import { TailSpin } from 'react-loader-spinner';
import { SubjectSubmitDto } from '@dto/SubjectSubmitDto';
import { useNavigate } from 'react-router-dom';
interface GradeListProp {
  id?: number | undefined;
  readonly isOpen: boolean;
  readonly setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  readonly onUpdated: () => void;
  readonly onCreated?: () => void;
}

export const GradeList: React.FC<GradeListProp> = ({
  id,
  isOpen,
  setIsOpen,
  onUpdated,
  onCreated,
}) => {
  const [isDetail, setIsDetail] = useState<boolean>(false);
  const [isCreate, setIsCreate] = useState<boolean>(false);
  const [subject, setSubject] = useState<SubjectDto | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [attendCheckYCount, setAttendCheckYCount] = useState<number>(0);
  const [submitCount, setSubmitCount] = useState<number>(0);
  const [attends, setAttends] = useState<AttendDto[]>([]);
  const [submits, setSubmits] = useState<any>([]);
  const [keyword, setKeyword] = useState<string>('');
  const [child, setChild] = useState<UserDto | undefined>();
  const [subjectSubmitId, setSubjectSubmitId] = useState<string | undefined>();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const fetchData = useCallback(async () => {
    try {
      if (!id || !isOpen) {
        return;
      }

      setIsLoading(true);

      const subject: SubjectDto = await subjectApi.itemById(id);

      if (subject.submits) {
        setSubmitCount(subject.submits.length);
        setSubmits([...subject.submits]);
      }

      if (subject.attends) {
        const newAttends: AttendDto[] = [...subject.attends];
        // .filter(
        //   (attend: AttendDto) => attend.check === 'Y',
        // );
        const countY: number = newAttends.length;
        setAttendCheckYCount(countY);
        setAttends(newAttends);
      }

      setSubject({ ...subject });
    } catch (error: any) {
      dispatch(
        modalOpen({
          type: 'alert',
          isOpen: true,
          title: '오류',
          message: error.message
            ? error.message
            : `오류가 발생하였습니다. [오류코드 ${error.status}]`,
          onOk: () => {
            setIsOpen(false);
          },
        }),
      );
    } finally {
      setIsLoading(false);
    }
  }, [id, isOpen, dispatch, setIsOpen]);

  /**
   * 전체 리셋
   * @param isForce - 강제로 리셋 시킨다.
   */
  const reset = useCallback(
    (isForce: boolean = false) => {
      if (!isForce && isOpen) {
        return;
      }

      setSubject(undefined);
      setAttendCheckYCount(0);
      setSubmitCount(0);
      setAttends([]);
      setSubmits([]);
      setKeyword('');
    },
    [isOpen],
  );

  useEffect(() => {
    fetchData();
    reset();
  }, [fetchData, reset]);

  /**
   * 아이를 클릭
   * @param id
   * @returns
   */
  const handleClickItem = (id: number, isComplete: boolean = false) => {
    if (!subject?.attends || subject?.attends.length < 1) {
      return;
    }

    const attend: AttendDto | undefined = [...subject.attends].find(
      (attend: AttendDto) => attend?.user?.id === id,
    );

    if (!attend) {
      dispatch(
        modalOpen({
          type: 'alert',
          isOpen: true,
          title: '오류',
          message: '출석 데이터를 찾을 수 없습니다',
        }),
      );
      return;
    }

    const user: UserDto | undefined = [...subject.attends].find(
      (attend: AttendDto) => attend.user?.id === id,
    )?.user;

    if (!user) {
      dispatch(
        modalOpen({
          type: 'alert',
          isOpen: true,
          title: '오류',
          message: '원생 데이터를 찾을 수 없습니다',
        }),
      );
      return;
    }

    setChild({ ...user });
    // console.log(`isComplete ${isComplete}`);
    if (isComplete) {
      // TODO 상세를 넣어준다.
      const submit: SubjectSubmitDto | undefined = [...submits].find(
        (submit: SubjectSubmitDto) => submit.targetUser.id === id,
      );

      if (!submit) {
        return;
      }

      setSubjectSubmitId((submit.id as number) + '');
      setIsCreate(false);
      setIsDetail(true);
    } else {
      setIsDetail(false);
      setIsCreate(true);
    }
  };

  const handleAttendsSearch = () => {
    if (!subject) {
      return;
    }

    const newAttends: AttendDto[] = [...(subject?.attends as AttendDto[])]
      // .filter((attend: AttendDto) => attend.check === 'Y')
      .filter((attend: AttendDto) => (attend.user.userName || '').indexOf(keyword) > -1);

    setAttends(newAttends);
  };

  const handleAttendsReset = () => {
    setKeyword('');
    const newAttends: AttendDto[] = [...(subject?.attends as AttendDto[])];
    // .filter(
    //   (attend: AttendDto) => attend.check === 'Y',
    // );
    setAttends(newAttends);
  };

  /**
   * submits에 데이터가 있을 경우, 완료항목으로 변경한다.
   * @param targetId
   * @returns
   */
  const completeSubmit = (targetId: number): boolean => {
    const submit: SubjectSubmitDto | undefined = [...submits].find(
      (submit: SubjectSubmitDto) => submit.targetUser.id === targetId,
    );

    if (submit) {
      return true;
    } else {
      return false;
    }
  };

  const handleSubmitted = (id: number) => {
    fetchData();
    setSubjectSubmitId(id + '');
    setIsDetail(true);
    if (onCreated) {
      onCreated();
    }
  };

  const handleUpdated = () => {
    onUpdated();
  };

  const handleClickAttendance = () => {
    navigate(`/teacher/attendance?cId=${subject?.course?.id as number}&id=${subject?.id}`);
  };

  return (
    <>
      <Dialog className="relative z-[1001]" open={isOpen} onClose={() => { }}>
        <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
        {/* container */}
        <div className="fixed flex inset-0 w-full max-h-full items-center justify-center overflow-y-auto">
          <Dialog.Panel className="w-full max-w-full max-h-full lg:max-w-2xl xl:max-w-4xl">
            <div className={`relative w-full h-full p-4`}>
              {isLoading && (
                <div className="absolute w-[calc(100%-2rem)] h-[calc(100%-2rem)] bg-white/75 rounded-xl flex items-center justify-center z-10">
                  <TailSpin
                    height="48"
                    width="48"
                    color="#ff9e18"
                    ariaLabel="tail-spin-loading"
                    radius="1"
                    visible={true}
                    wrapperClass={'mx-auto my-auto'}
                  />
                </div>
              )}
              <Card
                title={
                  <>
                    <h3 className="mb-1">{subject?.title}</h3>
                    <p className="text-sm font-normal text-gray-500">({subject?.course?.title})</p>
                  </>
                }
                actions={
                  <Button
                    color="secondary"
                    plain
                    className="text-sm font-normal hover:!bg-secondary/20"
                    onClick={() => setIsOpen(false)}>
                    확인
                  </Button>
                }>
                <div className="grid grid-cols-12 gap-4 mb-4">
                  <div className="form-control w-full col-span-12 md:col-span-4">
                    <label className="label">
                      <span className="label-text">평가완료</span>
                    </label>
                    {subject && (
                      <Progress
                        max={attendCheckYCount}
                        theme="success"
                        value={submitCount}
                        className="mt-auto"
                        textType="value"
                      />
                    )}
                  </div>
                  <div className="form-control w-full col-span-12 md:col-span-4">
                    <label className="label">
                      <span className="label-text">원생</span>
                    </label>
                    <input
                      type="text"
                      className="input input-bordered text-sm input-sm"
                      placeholder="이름을 입력해주세요"
                      value={keyword}
                      onChange={(e) => setKeyword(e.target.value)}
                    />
                  </div>
                  <div className="col-span-12 md:col-span-4 flex items-center justify-end space-x-2">
                    <Button color="secondary" className="mt-auto" onClick={handleAttendsSearch}>
                      <span className="text-white font-light">검색</span>
                    </Button>
                    <Button
                      color="error"
                      className="bg-danger border-danger hover:bg-danger/80 hover:border-danger/80 focus-visible:outline-danger mt-auto"
                      onClick={handleAttendsReset}>
                      <span className="text-white font-light">초기화</span>
                    </Button>
                  </div>
                </div>
                <div className="grid grid-cols-12 gap-4 h-96 overflow-y-auto p-4 bg-gray-100 rounded-xl">
                  {attends.length === 0 && (
                    <div className="col-span-12 flex items-center justify-center">
                      <div className="text-center">
                        <p className="mb-4">수업에 참여한 학생이 없습니다. 출석을 완료해주세요.</p>
                        <Button color="primary" onClick={handleClickAttendance}>
                          <span className="text-sm text-white font-light">출석부로 바로가기</span>
                        </Button>
                      </div>
                    </div>
                  )}
                  {attends &&
                    [...attends]
                      .filter((attend: AttendDto) => {
                        if (attend.user === null) {
                          return false;
                        }
                        return true;
                      })
                      .map((attend: AttendDto, index: number) => {
                        return (
                          <div key={index} className="col-span-12 sm:col-span-6 md:col-span-4 lg:col-span-3">
                            <ChildItem
                              id={attend.user.id as number}
                              name={attend.user.userName as string}
                              org={subject?.course?.class?.title as string}
                              profile={attend.user.photo?.path as string}
                              gradeComplete={completeSubmit(attend.user.id as number)}
                              onClick={handleClickItem}
                              checked={attend.check === 'Y'}
                            />
                          </div>
                        );
                      })}
                </div>
              </Card>
            </div>
          </Dialog.Panel>
        </div>
      </Dialog>
      <RatingModal
        id={subjectSubmitId}
        isOpen={isDetail}
        setIsOpen={setIsDetail}
        onUpdated={handleUpdated}
      />
      <RatingCreateModal
        subjectId={subject?.id}
        child={child}
        evals={subject?.evals}
        isOpen={isCreate}
        setIsOpen={setIsCreate}
        onSubmitted={handleSubmitted}
      />
    </>
  );
};
