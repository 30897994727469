import axiosInstance from '@api';
import { format } from 'date-fns';
/**
 * 공지사항 목록을 가져온다.
 * @param search
 * @returns
 */

export const exportEval = async (classId?: number) => {
  axiosInstance.get(`/subject/eval/exportEvals/${classId || -1}`, {
    responseType: 'blob',
    headers: { 'Content-Type': 'application/octet-stream' }
  }).then((response: any) => {
    const datestr = format(new Date(), 'yyyy-MM-dd')
    const outputFilename = `${datestr}${(classId && classId > 0) ? '_' + classId : ''}.xlsx`;

    const url = URL.createObjectURL(new Blob([response]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', outputFilename);
    document.body.appendChild(link);
    link.click();
  })


}

// console.log('res]', res)
// const blob = new Blob([res], { type: 'xlsx' })
// const fileObjectUrl = window.URL.createObjectURL(blob);

// const link = document.createElement("a");
// link.href = fileObjectUrl;

// link.style.display = "none";
// link.download = 'addi.xls'



// document.body.appendChild(link);
// link.click();
// link.remove();
// window.URL.revokeObjectURL(fileObjectUrl);