import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import { ChevronUpIcon, ChevronLeftIcon } from '@heroicons/react/20/solid';

import * as subjectApi from '@api/subject';
import { SubjectDto, CopyCourseDto } from '@dto';

import { Button, CourseSelector, SubjectList } from '@components';

import { open } from '@store/modal';
import { selectUser } from '@store/user';
import { open as modalOpen } from '@store/modal';
import { open as loadingOpen, close as loadingClose } from '@store/loading';
import { useAppDispatch, useAppSelector } from '@store/hook';
import { copy } from '@api/course';

export const CourseCreate = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const userSelector = useAppSelector(selectUser);

  const [isOpenSelector, setIsOpenSelector] = useState<boolean>(false);
  const [subjects, setSubjects] = useState<SubjectDto[]>([]);
  const [selectSubjects, setSelectSubjects] = useState<Set<number>>(new Set());
  const [copySubjects, setCopySubjects] = useState<SubjectDto[]>([]);

  const schema = yup.object().shape({
    title: yup.string().required('커리큘럼의 이름을 입력해주세요.'),
    content: yup.string().required('커리큘럼의 설명을 입력해주세요.'),
    refCourseId: yup.number(),
    refCourseTitle: yup.string(),
  });

  const {
    register,
    handleSubmit: onSubmit,
    formState: { errors },
    reset: formReset,
    setValue,
  } = useForm({
    defaultValues: {
      title: '',
      content: '',
      refCourseId: 0,
      refCourseTitle: '',
    },
    resolver: yupResolver(schema),
  });

  // SUBMIT
  const handleSubmit = async (data: any) => {
    try {
      if (copySubjects.length < 1) {
        dispatch(
          open({
            isOpen: true,
            type: 'alert',
            message: '선택된 강의가 없습니다.',
          }),
        );
        return;
      }

      dispatch(loadingOpen());

      const org = userSelector.user?.org;

      if (!org) {
        return;
      }

      const c: CopyCourseDto = {
        refCourse: {
          id: data.refCourseId,
        },
        title: data.title,
        content: data.content,
        subjects: [...copySubjects],
        org,
      };

      await copy(c);
      toast.info('커리큘럼을 생성하였습니다');
      navigate('/director/course');
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(loadingClose());
    }
  };

  // RESET
  const handleReset = () => {
    formReset();
    setSubjects([]);
    setSelectSubjects(new Set());
    setCopySubjects([]);
  };

  // 제공 커리큘럼이 선택이 될 때 value값을 변경한다.
  const handleSelectedCourse = async (id: number, title: string, content: string) => {
    try {
      dispatch(loadingOpen());

      const { data } = await subjectApi.list({ page: 1, limit: 100, search: { course: { id } } });
      const newSubjects: SubjectDto[] = [...data].sort((a, b) => (a.order || 0) - (b.order || 0));

      setSubjects(newSubjects);
      setSelectSubjects(new Set());
      setValue('refCourseId', id);
      setValue('refCourseTitle', title);
      setValue('title', title);
      setValue('content', content);
    } catch (error: any) {
      dispatch(
        modalOpen({
          type: 'alert',
          isOpen: true,
          title: '오류',
          message: error.message
            ? error.message
            : `오류가 발생하였습니다. [오류코드 ${error.status}]`,
          onOk () {
            navigate('/director/course');
          },
        }),
      );
    } finally {
      dispatch(loadingClose());
    }
  };

  // 커리큘럼을 복사한다.
  const handleCopy = () => {
    let order: number = 0;

    if (copySubjects.length > 0) {
      order = copySubjects[copySubjects.length - 1].order || 0;
    }

    const newList: SubjectDto[] = [...subjects]
      .filter((subject: SubjectDto) => selectSubjects.has(subject.id))
      .filter((subject: SubjectDto) => {
        return !copySubjects.find(({ id }: SubjectDto) => id === subject.id);
      })
      .map((subject: SubjectDto, index: number) => {
        subject.order = order + index + 1;
        return {
          ...subject,
        };
      });

    setCopySubjects([...copySubjects, ...newList]);
    setSelectSubjects(new Set());
  };

  // 순서 변경을 한다.
  const handleChangeOrder = (subjects: SubjectDto[]) => {
    const newList: SubjectDto[] = [...subjects].map((subject: SubjectDto, index: number) => {
      subject.order = index + 1;
      return subject;
    });

    setCopySubjects(newList);
  };

  return (
    <>
      <div className="w-full lg:h-[calc(100vh-112px)]">
        <h3 className="mb-4 text-3xl font-semibold tracking-wider">커리큘럼</h3>
        <form
          onSubmit={onSubmit(handleSubmit)}
          className="w-full h-[calc(100%-52px)] p-4 bg-white rounded-lg shadow-lg">
          {/* Buttons */}
          <div className="flex items-center justify-between mb-4">
            <Button
              outlined
              color="primary"
              className="hover:!text-white"
              onClick={() => {
                navigate('/director/course');
              }}>
              목록으로
            </Button>
            <div className="flex space-x-2">
              <Button
                type="reset"
                color="error"
                className="bg-danger border-danger hover:bg-danger/80 hover:border-danger/80 focus-visible:outline-danger"
                onClick={handleReset}>
                <span className="text-white font-light">취소</span>
              </Button>
              <Button type="submit" color="secondary">
                <span className="text-white font-light">등록</span>
              </Button>
            </div>
          </div>
          {/* Title, Description */}
          <div className="grid grid-cols-12 gap-4">
            <div className="col-span-12 xl:col-span-3 xl:mt-7">
              <div className="mb-4 form-control">
                <label className="label">
                  <span className={`label-text ${errors.title && 'text-error'}`}>이름</span>
                </label>
                <input
                  type="text"
                  className={`text-sm input input-bordered ${errors.title && 'input-error'}`}
                  placeholder="커리큘럼 이름을 입력해주세요"
                  {...register('title')}
                />
                <span className="text-xs mt-2 text-danger tracking-wide">
                  {errors.title?.message}
                </span>
              </div>
              <div className="px-2 mb-4 text-sm">총 {copySubjects.length}강</div>
              <div className="form-control">
                <label className="label">
                  <span className={`label-text ${errors.content && 'text-error'}`}>설명</span>
                </label>
                <textarea
                  className={`h-full py-2 text-sm resize-none textarea textarea-bordered ${errors.content && 'textarea-error'
                    }`}
                  rows={12}
                  placeholder="설명을 입력해주세요"
                  {...register('content')}
                />
                <span className="text-xs mt-2 text-danger tracking-wide">
                  {errors.content?.message}
                </span>
              </div>
            </div>
            <div className="col-span-12 xl:col-span-4 xl:h-[calc(100vh-300px)] xl:max-h-[calc(100vh-300px)]">
              <div className="relative overflow-x-hidden overflow-y-auto h-[calc(100vh-312px)] lg:h-[calc(100vh-312px)] xl:mt-[4rem] rounded-lg border">
                <SubjectList
                  data={copySubjects}
                  useDnD={true}
                  header={true}
                  useRemove={true}
                  onChange={handleChangeOrder}
                  selectType={'multi'}
                />
              </div>
            </div>
            {/* Center */}
            <div className="col-span-12 xl:col-span-1 xl:h-[calc(100vh-300px)] xl:max-h-[calc(100vh-300px)]">
              <div className="flex items-center justify-center w-full h-full xl:mt-12">
                <Button
                  color="primary"
                  className="text-white hover:!text-white"
                  onClick={handleCopy}>
                  <ChevronUpIcon className="w-6 h-6 -ml-1 fill-white xl:hidden" />
                  <ChevronLeftIcon className="hidden w-6 h-6 -ml-1 fill-white xl:block" />
                  <span>복사</span>
                </Button>
              </div>
            </div>
            {/* Right */}
            <div className="col-span-12 xl:col-span-4 xl:h-[calc(100vh-300px)] xl:max-h-[calc(100vh-300px)]">
              <div className="mb-4 form-control">
                <input
                  type="text"
                  className="text-sm cursor-pointer select select-bordered font-normal"
                  placeholder="커리큘럼을 선택해주세요"
                  readOnly
                  {...register('refCourseTitle')}
                  onClick={() => setIsOpenSelector(true)}
                />
              </div>
              <div className="relative overflow-auto h-[calc(100vh-312px)] lg:h-[calc(100vh-312px)] rounded-lg border">
                <SubjectList
                  data={subjects}
                  header
                  selectType={'multi'}
                  selectData={selectSubjects}
                  onChange={(data) => {
                    console.log('onChange');
                    setSubjects([...data]);
                    setSelectSubjects(new Set());
                  }}
                  onSelect={(data) => {
                    setSelectSubjects(new Set(data));
                  }}
                />
              </div>
            </div>
          </div>
        </form>
        {/* 코스 선택 팝업 */}
        <CourseSelector
          mode="creamo"
          isOpen={isOpenSelector}
          setIsOpen={setIsOpenSelector}
          onSelect={handleSelectedCourse}
        />
      </div>
    </>
  );
};;
