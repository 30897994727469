import React, { useEffect, useState } from 'react';

interface TabsProp {
  data: any[];
  value: any;
  valueName?: string;
  labelName?: string;
  onChange: (value: any) => void;
}

export const Tabs: React.FC<TabsProp> = ({
  data,
  value: _value,
  labelName = 'label',
  valueName = 'value',
  onChange,
}) => {
  const [tabData, setTabData] = useState<any[]>([]);
  const [value, setValue] = useState<any>();

  useEffect(() => {
    setTabData([...data]);
  }, [data]);

  useEffect(() => {
    setValue(_value);
  }, [_value]);

  const handleChange = (value: any) => {
    setValue(value);
    onChange(value);
  };

  return (
    <div className="tabs tabs-boxed tab-md bg-transparent">
      {tabData.map((tab) => {
        return (
          <div
            className={`tab rounded
              ${value === tab[valueName] ? 'bg-secondary text-white' : 'text-secondary'}
            `}
            key={tab[valueName]}
            onClick={() => {
              handleChange(tab[valueName]);
            }}>
            {tab[labelName]}
          </div>
        );
      })}
    </div>
  );
};
