import React, { useCallback, useEffect, useState } from 'react';
import { Dialog } from '@headlessui/react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import * as userApi from '@api/user';

import { Card, Button } from '@components';
import { useAppDispatch } from '@store/hook';
import { open as modalOpen } from '@store/modal';
import { TailSpin } from 'react-loader-spinner';
import { UserDto } from '@dto';
import { ChildInfo } from '@components/parent';
import { TelInput } from '@components/common/TelInput';

interface ParentDetailProp {
  userId: number | undefined;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onUpdated: () => void;
}

export const ParentDetail: React.FC<ParentDetailProp> = ({
  userId,
  isOpen,
  setIsOpen,
  onUpdated,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  // eslint-disable-next-line
  const [children, setChildren] = useState<any[]>([]);
  const [user, setUser] = useState<UserDto>();
  const dispatch = useAppDispatch();

  const scheme = yup.object().shape({
    userName: yup.string(),
    birthDay: yup.string(),
    email: yup.string(),
    telno: yup
      .string()
      .required('전화번호를 입력해주세요')
      .matches(/^01([0|1|6|7|8|9])-([0-9]{3,4})-([0-9]{4})$/, '전화번호 양식에 맞게 입력해주세요.'),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      userName: '',
      birthDay: '',
      email: '',
      telno: '',
    },
    resolver: yupResolver(scheme),
  });

  const fetchData = useCallback(async () => {
    try {
      setIsLoading(true);
      if (!userId) {
        throw new Error('대상을 찾을 수 없습니다');
      }

      const data = await userApi.itemById(userId);
      console.log(data);

      setValue('userName', data.userName || '');
      setValue('email', data.email || '');
      setValue('birthDay', data.birthDay || '');
      setValue('telno', data.telno || '');

      setUser({ ...data });
    } catch (error: any) {
      dispatch(
        modalOpen({
          isOpen: true,
          type: 'alert',
          title: '오류',
          message: error.message
            ? error.message
            : `오류가 발생하였습니다 [오류코드: ${error.status}]`,
          onOk: () => {
            setIsOpen(false);
          },
        }),
      );
    } finally {
      setIsLoading(false);
    }
    // eslint-disable-next-line
  }, [userId]);

  useEffect(() => {
    if (userId) {
      fetchData();
    }
  }, [userId, fetchData]);

  /**
   * Form 리셋
   */
  const handleReset = () => {
    reset({
      userName: user?.userName || '',
      email: user?.email || '',
      birthDay: user?.birthDay || '',
      telno: user?.telno || '',
    });
    setIsEdit(false);
  };

  const onSubmit = async (data: any) => {
    // TODO Alert 띄운다
    try {
      setIsLoading(true);
      if (!user) {
        throw new Error('업데이트 할 대상을 찾을 수 없습니다');
      }

      const updateUser: UserDto = Object.assign(
        { ...user },
        { userName: data.userName, birthDay: data.birthDay, telno: data.telno },
      );

      const result = await userApi.updateById(userId as number, updateUser);

      if (!result) {
        throw new Error('수정에 실패하였습니다');
      }

      setValue('telno', result.telno || '');
      setValue('birthDay', result.birthDay || '');
      onUpdated();
    } catch (error: any) {
      dispatch(
        modalOpen({
          isOpen: true,
          type: 'alert',
          title: '오류',
          message: error.message
            ? error.message
            : `오류가 발생하였습니다 [오류코드: ${error.status}]`,
          onOk: () => {
            setIsOpen(false);
          },
        }),
      );
    } finally {
      setIsLoading(false);
      setIsEdit(false);
    }
  };

  const handleRemove = () => {};

  return (
    <Dialog className="relative z-[1001]" open={isOpen} onClose={() => {}}>
      <div className="fixed inset-0 bg-black/75" aria-hidden="true" />
      <div className="fixed inset-0 flex items-center justify-center">
        <Dialog.Panel className="w-full md:max-w-md bg-white rounded-lg">
          <form onSubmit={handleSubmit(onSubmit)}>
            <Card
              title={
                <div className="flex items-center justify-between">
                  <span>{'상세정보'}</span>
                  {!isEdit && (
                    <div>
                      {user?.childs && user?.childs.length === 0 && (
                        <Button plain color="error" className="font-normal" onClick={handleRemove}>
                          삭제
                        </Button>
                      )}
                      <Button
                        plain
                        color="secondary"
                        className="font-normal"
                        onClick={() => setIsEdit(true)}>
                        수정
                      </Button>
                    </div>
                  )}
                </div>
              }
              actions={
                <>
                  {!isEdit && (
                    <Button plain onClick={() => setIsOpen(false)} className="font-normal">
                      확인
                    </Button>
                  )}
                  {isEdit && (
                    <>
                      <Button plain onClick={handleReset} className="font-normal">
                        취소
                      </Button>
                      <Button type="submit" plain color="secondary" className="font-normal">
                        수정
                      </Button>
                    </>
                  )}
                </>
              }>
              <div className="relative w-full">
                {isLoading && (
                  <div className="w-full h-full absolute bg-white/50 flex items-center justify-center">
                    <TailSpin
                      height="80"
                      width="80"
                      color="#ff9e18"
                      ariaLabel="tail-spin-loading"
                      radius="1"
                      visible={true}
                    />
                  </div>
                )}
                <div className="px-4 pb-4 form-control">
                  <label className="label">
                    <span className={`label-text`}>이메일</span>
                  </label>
                  <input
                    type="text"
                    className={`input input-bordered text-sm`}
                    {...register('email')}
                    disabled
                  />
                </div>
                <div className="px-4 pb-4 form-control">
                  <label className="label">
                    <span className={`label-text`}>이름</span>
                  </label>
                  <input
                    type="text"
                    className={`input input-bordered text-sm`}
                    {...register('userName')}
                    disabled
                  />
                </div>
                <div className="px-4 pb-4 form-control">
                  <label className="label">
                    <span className={`label-text`}>생년월일</span>
                  </label>
                  <input
                    type="text"
                    className={`input input-bordered text-sm`}
                    {...register('birthDay')}
                    disabled
                  />
                </div>
                <div className="px-4 pb-4 form-control">
                  <label className="label">
                    <span className={`label-text ${errors.telno && 'text-error'}`}>연락처</span>
                  </label>
                  <TelInput
                    register={{ ...register('telno') }}
                    className={`${errors.telno && 'input-error'}`}
                    disabled={!isEdit}
                  />
                  {/* <input
                    type="text"
                    className={`input input-bordered text-sm ${errors.telno && 'input-error'}`}
                    {...register('telno')}
                    disabled={!isEdit}
                  /> */}
                  <span className="text-xs mt-2 text-danger tracking-wide">
                    {errors.telno && errors.telno.message}
                  </span>
                </div>
                <div className="px-4 pb-4">
                  <label className="label border-b">
                    <span className={`label-text`}>자녀</span>
                  </label>
                  {!user?.childs ||
                    (user?.childs.length === 0 && (
                      <p className="text-sm">
                        등록된 자녀가 없습니다. '원생 관리'에서 등록해주세요.
                      </p>
                    ))}
                  <div className="max-h-32 overflow-y-auto">
                    {user?.childs &&
                      user.childs.map((value, index) => (
                        <ChildInfo
                          key={index}
                          name={value.userName || ''}
                          profile={value.photo?.path || ''}
                          orgName={
                            (value?.class && value?.class.length > 0 && value.class[0].title) || ''
                          }
                        />
                      ))}
                  </div>
                </div>
              </div>
            </Card>
          </form>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
};;
