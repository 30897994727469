import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

export const NotFound: React.FC = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const handleClickHome = () => {
    if (/director/.test(pathname.slice(1))) {
      navigate('/director');
    } else if (/teacher/.test(pathname.slice(1))) {
      navigate('/teacher');
    } else if (/parents/.test(pathname.slice(1))) {
      navigate('/parents');
    } else {
      navigate('/signin');
    }
  };

  return (
    <section className="flex items-center h-full p-16">
      <div className="container flex flex-col items-center justify-center px-4 mx-auto my-8">
        <div className="max-w-lg text-center">
          <h2 className="mb-8 font-extrabold text-9xl">
            <span className="sr-only">Error</span>404
          </h2>
          <p className="text-2xl font-normal md:text-3xl tracking-wide">
            원하시는 페이지를 찾을 수 없습니다.
          </p>
          <p className="mt-4 text-gray-500 font-light">
            찾으려는 페이지의 주소가 잘못 입력되었거나,
          </p>
          <p className="text-gray-500 font-light">
            주소의 변경 혹은 삭제로 인해 사용하실 수 없습니다.
          </p>
          <p className="mb-8 text-gray-500 font-light">
            입력하신 페이지의 주소가 정확한지 다시 한번 확인해주세요.
          </p>
          <button
            className="px-8 py-3 font-normal rounded-lg bg-primary text-white"
            onClick={handleClickHome}>
            홈 가기
          </button>
        </div>
      </div>
    </section>
  );
};
