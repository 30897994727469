import React, { useEffect, useState } from 'react';
import { ChevronRightIcon } from '@heroicons/react/24/solid';

import * as authApi from '@api/auth';

import { PageHeader, Button } from '@components';
import { useAppDispatch, useAppSelector } from '@store/hook';
import { selectUser, removeUser } from '@store/user';
import { open as modalOpen } from '@store/modal';
import { open as loadingOpen, close as loadingClose } from '@store/loading';
import { setUser } from '@store/user';
import { OrgDto, } from '@dto';
import { useNavigate } from 'react-router-dom';
import { ROLE, TOKEN_KEY } from '@common';

interface OrgItemProp {
  orgId: number;
  title: string;
  type: string;
  onClick: (id: number) => void;
}

const OrgItem: React.FC<OrgItemProp> = ({ type, title, orgId, onClick }) => {
  const handleClick = () => {
    onClick(orgId);
  };

  return (
    <div
      className="w-full bg-white h-16 rounded-lg hover:ring-2 hover:ring-primary focus-visible:outline-2 focus-visible:outline-primary cursor-pointer flex items-center"
      onClick={handleClick}>
      <div className="px-4">
        <h3 className="text-sm">{title} </h3>
      </div>

      <div className="ml-auto px-4 flex">
        <h3 className="text-sm">[{type === '10' ? '유치원' : '반'}]</h3>
        <ChevronRightIcon className="w-4 h-4 text-gray-500" />
      </div>
    </div>
  );
};

export const SelectOrg: React.FC = () => {
  const navigate = useNavigate();

  const { user } = useAppSelector(selectUser);
  const dispatch = useAppDispatch();
  const [orgs, setOrgs] = useState<OrgDto[]>([]);
  const [role, setRole] = useState<number>(ROLE.MEM_TEACHER);

  useEffect(

    () => {

      try {
        dispatch(loadingOpen());
        if (!user) {
          return;
        }
        if ((user?.roleId || 50) < ROLE.MEM_MANAGER) {
          setRole(ROLE.MEM_MANAGER)
        } else {
          setRole(user?.roleId as ROLE)
        }

        // 만약 user.orgs가 1개이고 user.org랑 같다면,
        // 자동으로 페이지를 넘겨 버린다.

        if (!user.orgs || user.orgs.length === 0) {
          if (user?.role?.id === ROLE.MEM_TEACHER) {
            navigate('/teacher');
          } else {
            navigate('/parents');
          }
          return;
        }

        if (user.orgs.length === 1) {
          if (user.org?.id === user.orgs[0].id) {
            if (user?.role?.id === ROLE.MEM_TEACHER) {
              navigate('/teacher');
            } else {
              navigate('/parents');
            }
            return;
          } else {
            throw new Error('조직 오류입니다');
          }
        }

        setOrgs([...user.orgs]);
      } catch (error: any) {
      } finally {
        dispatch(loadingClose());
      }
    },
    // eslint-disable-next-line
    [user],
  );

  const handleClickItem = async (id: number) => {
    // TODO org/change로 작업을 진행하도록 한다.
    try {
      dispatch(loadingOpen());
      if (!user) {
        throw new Error('사용자 정보를 가져올 수 없습니다');
      }

      const org: OrgDto | undefined = orgs.find((org: OrgDto) => org.id === id);

      if (!org) {
        throw new Error('대상을 찾을 수 없습니다');
      }

      const { result } = await authApi.changeOrg(org.id as number, role);

      dispatch(setUser(Object.assign({}, user, result.user)));
      localStorage.setItem(TOKEN_KEY, JSON.stringify(result.token));

      // 관리자...

      if (user?.role?.id && user?.role?.id < ROLE.MEM_TEACHER) {
        navigate('/director');

      }
      else if (user?.role?.id === ROLE.MEM_TEACHER) {
        navigate('/teacher');

      } else {
        if (!result.user.childs || result.user.childs.length === 0) {
          throw new Error(
            `${result.user.org?.title}에 등록된 자녀가 없습니다. 원장 선생님께 자녀 등록을 요청하세요.`,
          );
        }

        navigate('/parents');
      }
    } catch (error: any) {
      dispatch(
        modalOpen({
          type: 'alert',
          isOpen: true,
          title: '오류',
          message: error.message
            ? error.message
            : `오류가 발생하였습니다. [오류코드 ${error.status}]`,
          // onOk() {
          //   navigate('/signin');
          // },
        }),
      );
    } finally {
      dispatch(loadingClose());
    }
  };

  const handleLogout = async () => {
    try {
      dispatch(loadingOpen());
      await authApi.logout();
    } finally {
      // 에러는 무시한다.
      dispatch(loadingClose());
      dispatch(removeUser());
      localStorage.removeItem(TOKEN_KEY);
      navigate('/signin');
    }
  };

  return (
    <div className="flex flex-col items-center w-full h-screen overflow-y-auto">
      <PageHeader auth />
      <div className="container flex items-center">
        <div className="w-full max-w-md px-4 pt-8 pb-24 mx-auto lg:pt-24">
          <h3 className="mb-6 text-3xl font-semibold tracking-wider">유치원 선택</h3>
          {
            user?.roleId && user.roleId < ROLE.MEM_TEACHER
            && (<div className='flex'>
              <div className="col-span-3 flex items-center justify-center">
                <label className="label">
                  <input
                    type="radio"
                    name="all"
                    className="radio radio-sm md:mr-2"
                    checked={role === ROLE.MEM_MANAGER}
                    value={ROLE.MEM_MANAGER}
                    onChange={() => setRole(ROLE.MEM_MANAGER)}
                  />
                  <span className="hidden md:block label-text font-normal">원장</span>
                </label>
              </div>
              <div className="col-span-3 flex items-center justify-center">
                <label className="label px-0">
                  <input
                    type="radio"
                    name="all"
                    className="radio radio-sm md:mr-2"
                    value={ROLE.MEM_TEACHER}
                    checked={role === ROLE.MEM_TEACHER}
                    onChange={() => setRole(ROLE.MEM_TEACHER)}
                  />
                  <span className="hidden md:block label-text font-normal">선생님</span>
                </label>
              </div>
            </div>)
          }
          <div className="mb-6 space-y-4">
            {orgs.map((org: OrgDto) => (
              <OrgItem
                key={org.id}
                orgId={org.id as number}
                type={org.type as string}
                title={org.title as string}
                onClick={handleClickItem}
              />
            ))}
          </div>
          <div className="pb-6">
            <Button color="secondary" className="h-12" block onClick={handleLogout}>
              <span className="text-white font-light">로그아웃</span>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
