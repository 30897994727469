import React, { useState, useEffect, useCallback } from 'react';
import * as orgApi from '@api/org';
import { OrgDto, SearchDto } from '@dto';
import type { FieldError } from 'react-hook-form';
import { ORG, ORG_STATE } from '@common';
interface IProp {
  readonly value?: number | undefined;
  readonly disabled?: boolean;
  readonly className?: string;
  readonly selectClassName?: string;
  readonly onChange?: (id: number) => void;
  readonly error?: FieldError | undefined;
  readonly readonly?: boolean;
  readonly defaultDisabled?: boolean;
  readonly hideLabel?: boolean;
}

export const ClassSelect: React.FC<IProp> = ({
  value: _value = -1,
  disabled: _disabled = false,
  className,
  selectClassName,
  onChange,
  error: _error,
  readonly: _readonly = false,
  defaultDisabled: _defaultDisabled = true,
  hideLabel = false,
}: IProp) => {
  const [options, setOptions] = useState<OrgDto[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [value, setValue] = useState<number | undefined>(_value);
  const [disabled, setDisabled] = useState<boolean>(_disabled);
  const [defaultDisabled, setDefaultDisabled] = useState<boolean>();
  const [readonly, setReadonly] = useState<boolean>(false);
  const [error, setError] = useState<FieldError | undefined>();

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      const search: SearchDto = {
        page: 1,
        limit: 100,
        search: {
          type: ORG.CLASS,
          status: ORG_STATE.USED,
        },
      };

      const { data } = await orgApi.list(search);
      setOptions([...data]);
      setValue(-1);
    } catch (error: any) {
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    if (value !== _value) {
      setValue(_value);
    }
  }, [_value, value]);

  useEffect(() => {
    if (_disabled !== disabled) {
      setDisabled(_disabled);
    }
  }, [_disabled, disabled]);

  useEffect(() => {
    setReadonly(_readonly);
  }, [_readonly]);

  useEffect(() => {
    setError(_error);
  }, [_error, setError]);

  useEffect(() => {
    setDefaultDisabled(_defaultDisabled);
  }, [_defaultDisabled]);

  const handleChange = (e: any) => {
    const value = Number(e.target.value);
    setValue(value);
    if (onChange) {
      onChange(value);
    }
  };

  return (
    <div className={`w-full form-control ${className && className}`}>
      {!hideLabel && (
        <label className="label">
          <span className={`label-text ${error && 'text-error'}`}>반</span>
        </label>
      )}
      <select
        className={`select select-bordered ${loading && 'loading'} text-sm w-full font-normal
        ${selectClassName ? selectClassName : ''}
        ${loading ? 'loading' : ''}
        ${error ? 'select-error' : ''}
        ${readonly ? 'disabled:bg-white disabled:!border-[hsla(0,0%,20%,0.2)]' : ''}`}
        disabled={disabled || readonly}
        onChange={handleChange}
        value={value}>
        <option value={-1} disabled={defaultDisabled}>
          반 선택해주세요
        </option>
        {options.map((value, index) => (
          <option value={value.id} key={index}>
            {value.title}
          </option>
        ))}
      </select>
      <span className="mt-1 text-xs text-error">{error?.message}</span>
    </div>
  );
};
