import React from 'react';

interface NoticeItemProp {
  readonly id: number;
  readonly title: string;
  readonly createdAt: string;
  readonly useHeader?: boolean;
  readonly onClick?: (id: number) => void;
}

export const NoticeItem: React.FC<NoticeItemProp> = ({
  id,
  title,
  createdAt,
  useHeader = true,
  onClick,
}: NoticeItemProp) => {
  const handleClick = () => {
    if (onClick) {
      onClick(id);
    }
  };

  const classes: string = `border-b text-black/80 text-sm h-14 px-4 py-0 leading-normal truncate max-w-xs group-hover:bg-slate-50 hidden lg:table-cell ${
    useHeader ? 'lg:indent-12' : ''
  }`;

  return (
    <>
      <tr className="cursor-pointer group" onClick={handleClick}>
        {/* <td className={`border-b text-black/80 text-sm`}>
          <div className="whitespace-normal break-words mb-2">{title}</div>
          <div className="text-gray-400 text-xs">{createdAt}</div>
        </td> */}
        <td className={classes}>{title}</td>
        <td className={classes}>{createdAt}</td>
      </tr>
    </>
  );
};
