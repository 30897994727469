import React, { useState } from 'react';
import tw, { styled } from 'twin.macro';

import { SurveyModal } from '@components/SurveyModal';

interface ITypeBadgeProps {
  readonly type: number;
}

const TitleTd = styled.td(() => [tw`truncate`]);
const DateTd = styled.td(() => [tw`text-slate-500 text-sm text-right`]);
const TypeTd = styled.td(() => [tw`p-0 text-center`]);
const TypeBadge = styled.span<ITypeBadgeProps>(() => [
  tw`badge px-4 h-6 text-xs rounded-lg w-16 truncate`,
  ({ type }) => {
    if (type === 0) {
      return tw`badge-success`;
    } else if (type === 2) {
      return tw`badge-error`;
    } else if (type === 1) {
      return tw`badge-secondary`;
    }
  },
]);

interface IProps {
  /**
   * 페이징 처리를 한다
   */
  readonly pagination?: boolean;
  /**
   * 페이징 처리시 페이징을 몇 페이지까지 보여줄지 결정한다.
   */
  readonly pageCount?: number;
  /**
   * 열을 결정한다.
   */
  readonly columnCount?: number;
  /**
   * 헤더를 보여줄지 결정한다.
   */
  readonly showHeader?: boolean;
}

const getDummyData = (columnCount = 5) => {
  return Array.from({ length: columnCount }).map((_, index) => ({
    id: index,
    type: Math.floor(Math.random() * 3),
    title:
      '설문조사 제목 설문조사 제목 설문조사 제목 설문조사 제목설문조사 제목 설문조사 제목 설문조사 제목 설문조사 제목',
    date: '2022.12.31',
  }));
};

export const Survey = ({
  pagination = true,
  pageCount = 5,
  columnCount = 10,
  showHeader = true,
}: IProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  /**
   * 타입별로 나타나게 한다
   * @param type
   * @returns
   */
  const getTypeBadge = (type: number) => {
    let label: string = '종료';

    if (type === 0) {
      label = '진행중';
    } else if (type === 1) {
      label = '참여완료';
    }

    return <TypeBadge type={type}>{label}</TypeBadge>;
  };

  /**
   * 타이틀을 클릭시 모달을 띄운다.
   * @param type
   * @param id
   */
  const onClickDetail = (type: number, id: number) => {
    setIsOpen(true);
  };

  return (
    <>
      <table className="table table-fixed w-full max-w-full">
        <colgroup>
          <col width="10%" />
          <col width="*" />
          <col width="20%" />
        </colgroup>
        {showHeader && (
          <thead>
            <tr>
              <th></th>
              <th>제목</th>
              <th>작성날짜</th>
            </tr>
          </thead>
        )}
        <tbody>
          {getDummyData(columnCount).map((value) => (
            <tr key={value.id}>
              <TypeTd>{getTypeBadge(value.type)}</TypeTd>
              <TitleTd>
                <label
                  className="hover:underline hover:cursor-pointer"
                  onClick={() => {
                    onClickDetail(value.type, value.id);
                  }}>
                  {value.title}
                </label>
              </TitleTd>
              <DateTd>{value.date}</DateTd>
            </tr>
          ))}
        </tbody>
      </table>
      <SurveyModal surveyId={1} isOpen={isOpen} setIsOpen={setIsOpen} />
    </>
  );
};
