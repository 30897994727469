import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { login } from '@api/auth';
import { PageHeader } from '@components';
import { UserDto } from '@dto/UserDto';
import { TokenDto } from '@dto/TokenDto';
import { LoginDto } from '@dto/LoginDto';
import { ROLE, USER_STATE } from '@common/code';
import { TOKEN_KEY } from '@common/env';
import { useAppDispatch } from '@store/hook';
import { setUser } from '@store/user';
import { open as modalOpen } from '@store/modal';

export const Signin: React.FC = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const e = searchParams.get('e');

  const scheme = yup.object().shape({
    email: yup
      .string()
      .email('올바른 이메일 형식이 아닙니다.')
      .required('아이디(이메일)을 입력해주세요.'),
    password: yup
      .string()
      .min(6, '비밀번호는 최소 6글자 이상을 입력하셔야합니다.')
      .required('비밀번호를 입력해주세요.'),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    defaultValues: {
      email: '',
      password: '',
    },
    resolver: yupResolver(scheme),
  });

  useEffect(() => {
    if (localStorage.getItem(TOKEN_KEY)) {
      localStorage.removeItem(TOKEN_KEY);
    }

    if (e) {
      dispatch(
        modalOpen({
          isOpen: true,
          type: 'alert',
          message: '로그인이 필요합니다',
        }),
      );

      searchParams.delete('e');
      setSearchParams(searchParams);
    }
    // eslint-disable-next-line
  }, []);

  /**
   * 로그인 처리
   */
  const onSubmit = async (data: any) => {
    try {
      setLoading(true);
      const response: any = await login(data as LoginDto);

      const user: UserDto = response.user as UserDto;
      const token: TokenDto = response.token as TokenDto;

      // 현재 상태에 따라 접근을 막는다.
      if (user.status !== USER_STATE.ACTIVATE) {
        if (user.status === USER_STATE.WAIT) {
          setErrorMessage('현재 승인을 기다리는 중입니다.');
        } else if (user.status === USER_STATE.REMOVE || user.status === USER_STATE.STOP) {
          setErrorMessage('삭제되었거나 정지된 계정입니다.');
        }

        setShowAlert(true);
        return;
      }

      // TOKEN을 저장한다
      localStorage.setItem(TOKEN_KEY, JSON.stringify(token));
      dispatch(setUser(user));

      const id = user.role?.id;

      if (id && id <= ROLE.MEM_MANAGER) { // 관리자 보다 작으면, 운영사 회원포함한다.
        // navigate('/director');
        if (!user.orgs || user.orgs.length <= 1) {
          navigate('/director');
        } else {
          navigate('/selectorg');
        }
      } else if (id === ROLE.MEM_TEACHER) {
        // 로그인 후 우선은 selectorg로 보내서 org를 선택하게 한다.
        if (!user.orgs || user.orgs.length <= 1) {
          navigate('/teacher');
        } else {
          navigate('/selectorg');
        }
      } else if (id === ROLE.MEM_PARENT) {
        // 등록된 아이체크는 orgs가 다중이 아닐 경우에는 여기서 처리한다
        if (!user.orgs || user.orgs.length <= 1) {
          if (!user.childs || user.childs.length === 0) {
            throw new Error('등록된 원생이 없습니다. 담당자에게 연락을 하시길 바랍니다.');
          } else {
            navigate('/parents');
          }
        } else {
          // 로그인 후 우선은 selectorg로 보내서 org를 선택하게 한다.
          navigate('/selectorg');
        }
      } else {
        navigate('/error');
      }
    } catch (error: any) {
      if (!error.status) {
        setErrorMessage(error.message);
      } else {
        if (error.status === 422) {
          setErrorMessage('아이디 (이메일) 혹은 비밀번호가 올바르지 않습니다.');
        } else if (error.status === 400) {
          setErrorMessage('잘못된 요청을 하였습니다.');
        } else {
          setErrorMessage(`에러가 발생하였습니다. [${error.status}]`);
        }
      }

      setShowAlert(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex flex-col items-center w-full h-screen overflow-y-auto">
      <PageHeader auth />
      <div className="container flex items-center">
        <div className="w-full max-w-md px-4 pt-8 pb-24 mx-auto lg:pt-24">
          <h3 className="mb-4 text-3xl font-semibold tracking-wider">로그인</h3>
          {showAlert && (
            <div className="mb-4 text-sm font-light tracking-wider text-white alert alert-error">
              {errorMessage}
            </div>
          )}
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-6">
              <div className="w-full mb-4 form-control">
                <label className="label">
                  <span className={`label-text text-sm ${errors.email && 'text-error'}`}>
                    아이디 (이메일)
                  </span>
                </label>
                <input
                  type="email"
                  placeholder="아이디(이메일)을 입력해주세요"
                  className={`input input-bordered w-full text-sm ${errors.email && 'input-error'}`}
                  {...register('email')}
                  disabled={loading}
                />
                {errors.email && (
                  <div className="mt-1 text-sm text-error">{errors.email.message}</div>
                )}
              </div>
              <div className="w-full form-control">
                <label className="label">
                  <span className={`label-text text-sm ${errors.email && 'text-error'}`}>
                    비밀번호
                  </span>
                </label>
                <input
                  type="password"
                  placeholder="비밀번호를 입력해주세요"
                  className={`input input-bordered w-full text-sm ${errors.password && 'input-error'
                    }`}
                  {...register('password')}
                  disabled={loading}
                />
                {errors.password && (
                  <div className="mt-1 text-sm text-error">{errors.password.message}</div>
                )}
              </div>
            </div>
            <div className="mb-4 text-right">
              <Link
                to="/findpassword"
                className="px-0 font-normal text-gray-500 no-underline btn btn-link">
                로그인 정보를 잊으셨나요?
              </Link>
            </div>
            <div className="pb-6">
              <button
                type="submit"
                className={`btn btn-secondary w-full font-normal text-white ${loading && 'loading'
                  }`}
                disabled={loading}>
                {!loading && '로그인'}
              </button>
            </div>
          </form>
          {/* <div className="sticky bottom-0 z-10 font-normal text-sm text-center bg-[#e7edf3] h-16">
            <span>계정이 없으신 경우</span>
            <Link to="/agreement" className="px-1 font-normal btn btn-link text-secondary">
              회원가입
            </Link>
          </div> */}
        </div>
      </div>
    </div>
  );
};
