import React, { useState, useEffect } from 'react';
import RangeSlider from 'react-rangeslider';
import 'react-rangeslider/lib/index.css';

interface IProp {
  readonly min?: number;
  readonly max?: number;
  readonly step?: number;
  readonly value?: number;
  readonly label?: string;
  readonly content?: string;
  readonly onChange: (value: number) => void;
}

export const Slider: React.FC<IProp> = ({
  min = 1,
  max = 10,
  step = 1,
  value: _value = 1,
  label,
  content,
  onChange,
}) => {
  const [value, setValue] = useState<number>(_value);

  useEffect(() => {
    setValue(_value);
  }, [_value]);

  const handleChange = (value: any) => {
    setValue(value);
    onChange(value);
  };

  return (
    <div className="items-center  h-16 mr-2">
      <div className='mb-2'>
        {label && (
          <pre className="text-sm w-full  font-sans" title={content ? content : label}>
            {content ? content : label}
          </pre>
        )}
      </div>
      <div className="flex items-center  h-4 mr-2">
        {/* LABEL */}
        <div className="w-44 truncate" >
          {label && (
            <pre className="w-full text-xs font-bold ml-2 mr-2 mt-1 font-sans" title={label}>
              {label}
            </pre>
          )}
        </div>
        <div className="text-center text-sm  text-gray-600 mt-1 font-bold ">{value}</div>
        <div className="w-6" >
        </div>
        {/* <div className="text-sm text-gray-500 mr-2">{min}</div> */}
        <div className="w-full mt-2 mr-2">
          <RangeSlider
            min={min}
            step={step}
            max={max}
            value={value}
            onChange={handleChange}
            tooltip={false}
          />
          <div className="text-center text-sm text-gray-500 mt-1"> </div>
        </div>
        {/* <div className="text-sm text-gray-500">{max}</div> */}
      </div>
    </div>
  );
};
