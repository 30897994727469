import React from 'react';
import { useNavigate } from 'react-router-dom';

export const Error: React.FC = () => {
  const navigate = useNavigate();

  const handleClickPrev = () => {
    navigate(-1);
  };

  return (
    <section className="flex items-center h-full p-16">
      <div className="container flex flex-col items-center justify-center px-4 mx-auto my-8">
        <div className="max-w-lg text-center">
          <h2 className="mb-8 font-extrabold text-9xl">
            <span className="sr-only">Error</span>500
          </h2>
          <p className="text-2xl font-normal md:text-3xl tracking-wide">오류가 발생하였습니다.</p>
          <p className="mt-4 text-gray-500 font-light">이전 페이지로 이동을 하거나,</p>
          <p className="mb-4 text-gray-500 font-light">
            계속된 오류가 발생시 언제 연락을 주십시오.
          </p>
          <p className="mb-8 text-gray-500 font-light">담당 연락처 :</p>
          <button
            className="px-8 py-3 font-normal rounded-lg bg-primary text-white"
            onClick={handleClickPrev}>
            이전 페이지로 이동
          </button>
        </div>
      </div>
    </section>
  );
};
