import React, { useCallback, useEffect, useState } from 'react';
import { Dialog } from '@headlessui/react';
import { Button, Card, RatingAlbum } from '@components';
import { ChildInfo } from '@components/parent';
import { SubjectEval } from '@dto/SubjectEvalDto';
import { PhotoDto, UserDto } from '@dto';

import * as subjectApi from '@api/subject';

import { useAppDispatch, useAppSelector } from '@store/hook';
import { selectUser } from '@store/user';
import { open as modalOpen } from '@store/modal';
import { open as loadingOpen, close as loadingClose } from '@store/loading';
import { Slider } from '@components/Slider';
import { SubjectSubmitDto } from '@dto/SubjectSubmitDto';
import { SubjectSubmitEvalItemDto } from '@dto/SubjectSubmitEvalItemDto';
import { toast } from 'react-toastify';

interface RatingCreateModalProp {
  /**
   * 대상 subject 아이디
   */
  subjectId: number | undefined;
  /**
   * 평가항목
   */
  evals?: SubjectEval[];
  /**
   * 대상 원생
   */
  child?: UserDto;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  /**
   * 작업이 완료 되면 완료된 아이디를 전송해준다.
   * @param id
   * @returns
   */
  onSubmitted: (id: number) => void;
}

export const RatingCreateModal: React.FC<RatingCreateModalProp> = ({
  subjectId,
  child,
  evals,
  isOpen,
  setIsOpen,
  onSubmitted,
}) => {
  const [content, setContent] = useState<string>('');
  const [evalSubmits, setEvalSubmits] = useState<SubjectSubmitEvalItemDto[]>([]);
  const { user } = useAppSelector(selectUser);
  const dispatch = useAppDispatch();
  const [files, setFiles] = useState<PhotoDto[]>([]);

  const checkData = useCallback(() => {
    if (!subjectId || !child || !evals || !user) {
      dispatch(
        modalOpen({
          type: 'alert',
          isOpen: true,
          message: '정상적인 요청이 아닙니다',
          onOk () {
            setIsOpen(false);
          },
        }),
      );
    }

    const evalSubmits: SubjectSubmitEvalItemDto[] | undefined = evals?.map(
      (subjectEval: SubjectEval) => {
        return {
          eval: { ...subjectEval },
          submitVal: subjectEval.evalMin as number,
        };
      },
    );

    if (evalSubmits) {
      setEvalSubmits([...evalSubmits]);
    }
  }, [child, subjectId, evals, dispatch, setIsOpen, user]);

  useEffect(() => {
    if (isOpen) {
      checkData();
    } else {
      setFiles([]);
      setEvalSubmits([]);
      setContent('');
    }
  }, [isOpen, checkData]);

  const handleEvalValue = (id: number, value: number) => {
    const newEvalSubmits = [...evalSubmits];
    const index: number = newEvalSubmits.findIndex(
      (value: SubjectSubmitEvalItemDto) => value.eval.id === id,
    );

    if (index < 0) {
      return;
    }

    newEvalSubmits[index].submitVal = value;
    setEvalSubmits(newEvalSubmits);
  };

  const handleSubmit = async () => {
    // TODO files는 최소 한개는 올려야 한다.
    // if (!files || files.length === 0) {
    //   dispatch(
    //     modalOpen({
    //       type: 'alert',
    //       isOpen: true,
    //       title: '오류',
    //       message: '작품 사진을 적어도 1개는 올리셔야 합니다',
    //     }),
    //   );
    //   return;
    // }

    if (!content || content.trim().length === 0) {
      dispatch(
        modalOpen({
          type: 'alert',
          isOpen: true,
          title: '오류',
          message: '선생님 한마디를 적어주세요',
        }),
      );
      return;
    }

    dispatch(
      modalOpen({
        type: 'confirm',
        isOpen: true,
        message: '평가를 업로드 하시겠습니까?',
        onOk: submitProc,
      }),
    );
  };

  const submitProc = async () => {
    try {
      dispatch(loadingOpen());
      const subjectSubmit: SubjectSubmitDto = {
        subject: {
          id: subjectId as number,
        },
        targetUser: {
          id: child?.id as number,
        },
        adminUser: {
          id: user?.id as number,
        },
        content: content,
        evalSubmits: [...evalSubmits],
        files: [...files],
      };

      const result = await subjectApi.submit(subjectSubmit);
      if (!result) {
        throw new Error('평가 업로드에 실패하였습니다');
      }

      onSubmitted(result.id as number);
      toast.info('평가 제출에 성공하였습니다');
      setIsOpen(false);
    } catch (error: any) {
      dispatch(
        modalOpen({
          type: 'alert',
          isOpen: true,
          title: '오류',
          message: error.message
            ? error.message
            : `오류가 발생하였습니다. [오류코드 ${error.status}]`,
        }),
      );
    } finally {
      dispatch(loadingClose());
    }
  };

  const handleChangeFiles = (files: PhotoDto[]) => {
    setFiles([...files]);
    console.log(files);
  };

  return (
    <Dialog className="relative z-[1002]" open={isOpen} onClose={() => []}>
      <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
      {/* container */}
      <div className="fixed inset-0 flex lg:items-center lg:justify-center p-4 overflow-y-auto">
        <Dialog.Panel className="w-full max-w-full lg:max-w-2xl xl:max-w-4xl rounded-lg bg-white shadow">
          <Card
            title={
              <div className="flex items-center justify-between">
                {child && (
                  <ChildInfo
                    name={child.userName as string}
                    orgName={
                      (child?.class && child?.class.length > 0 && child.class[0].title) || ''
                    }
                    profile={child.photo?.path}
                  />
                )}
              </div>
            }
            actions={
              <>
                <Button plain onClick={() => setIsOpen(false)}>
                  취소
                </Button>
                <Button plain color="secondary" onClick={handleSubmit}>
                  저장
                </Button>
              </>
            }>
            <div className="grid w-full grid-cols-12 gap-4 px-4">
              <div className="col-span-12 md:col-span-6">
                <RatingAlbum
                  isEdit
                  label={`${child?.userName}이(가) 만든 작품`}
                  files={files}
                  onChange={handleChangeFiles}
                />
              </div>
              <div className="col-span-12 md:col-span-6">
                <label className="label">
                  <span className="font-medium label-text">평가점수</span>
                </label>
                <div className="border rounded-xl p-4 w-full h-80 max-h-[20rem] mx-auto my-auto overflow-y-auto">
                  {evalSubmits?.map((evalSubmit: SubjectSubmitEvalItemDto) => (
                    <Slider
                      key={evalSubmit.eval.id}
                      min={evalSubmit.eval.evalMin}
                      max={evalSubmit.eval.evalMax}
                      step={1}
                      label={evalSubmit.eval.title}
                      content={evalSubmit.eval.content}
                      value={evalSubmit.submitVal}
                      onChange={(value: number) => {
                        handleEvalValue(evalSubmit.eval.id, value);
                      }}
                    />
                  ))}
                </div>
              </div>
              <div className="col-span-12">
                <label className="label">
                  <span className="font-medium label-text">선생님의 한마디</span>
                </label>
                <textarea
                  className="w-full py-2 text-sm resize-none textarea textarea-bordered"
                  rows={3}
                  value={content}
                  onChange={(e) => {
                    e.stopPropagation();
                    setContent(e.target.value);
                  }}
                />
              </div>
            </div>
          </Card>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
};
