import { DetailedHTMLProps, FC, HTMLAttributes } from 'react';
import type { UseFormRegisterReturn } from 'react-hook-form';

type ReactInputProps = DetailedHTMLProps<HTMLAttributes<HTMLInputElement>, HTMLInputElement>;

type TelInputProps = ReactInputProps & {
  register?: UseFormRegisterReturn;
  disabled?: boolean;
};

export const TelInput: FC<TelInputProps> = ({
  register,
  className: _className,
  disabled: _disabled = false,
  ...props
}) => {
  const className = ['input', 'input-bordered', 'text-sm', _className].join(' ');

  return (
    <input
      {...props}
      type="text"
      className={className}
      disabled={_disabled}
      maxLength={13}
      {...{
        ...register,
        // 값이 변경될 때 마다 여기서 처리한다
        onChange: (e: any) => {
          if (e.target.value.length === 10) {
            e.target.value = e.target.value.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
          }
          if (e.target.value.length === 13) {
            e.target.value = e.target.value
              .replace(/-/g, '')
              .replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3');
          }
          register?.onChange(e);
        },
      }}
      // {...register}
    />
  );
};
