import React from 'react';
import { Button } from '@components';
import { PhotoDto } from '@dto';
import { UserCircleIcon } from '@heroicons/react/24/solid';

export interface TeacherItemProp {
  readonly id: number;
  readonly photo?: PhotoDto | undefined;
  readonly name: string;
  readonly phone?: string;
  readonly email?: string;
  readonly onClick: (id: number) => void;
}

export const TeacherItem: React.FC<TeacherItemProp> = ({
  id,
  photo,
  name,
  phone = '',
  email = '',
  onClick,
}: TeacherItemProp) => {
  const handleClick = () => {
    onClick(id);
  };

  return (
    <tr>
      <td className="border-b text-black/80 text-sm px-2 leading-normal truncate max-w-xs">
        <div className="flex items-center">
          <div className="w-12 h-12 bg-slate-200 rounded-full ml-2 mr-2">
            {photo && <img src={photo.path} alt="profile" className="w-full h-full rounded-full" />}
            {!photo && <UserCircleIcon className="w-full h-full text-gray-400" />}
          </div>
          <span>{name}</span>
        </div>
      </td>
      <td className="border-b text-black/80 text-sm leading-normal lg:indent-12">
        <span>{email}</span>
      </td>
      <td className="border-b text-black/80 text-sm leading-normal lg:indent-12">{phone}</td>
      <td className="border-b px-2 text-center">
        <Button
          outlined
          color="primary"
          size="sm"
          className="text-xs font-normal group"
          onClick={handleClick}>
          <span className="group-hover:text-white">상세보기</span>
        </Button>
      </td>
    </tr>
  );
};
