import React, { useEffect, useState, useCallback } from 'react';
import { Dialog } from '@headlessui/react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import * as userApi from '@api/user';

import { Card, Button, Profile, ParentSelect, DatePicker } from '@components';
import { useAppDispatch } from '@store/hook';
import { open as modalOpen } from '@store/modal';
import { PhotoDto, UserDto } from '@dto';
import { toast } from 'react-toastify';
import { TailSpin } from 'react-loader-spinner';
import { ClassSelect } from '@components/ClassSelect';
import { format } from 'date-fns';

interface ChildProfileProp {
  userId: number | string;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onRemoved: () => void;
  onUpdated: () => void;
}

export const ChildProfile: React.FC<ChildProfileProp> = ({
  userId,
  isOpen,
  setIsOpen,
  onRemoved,
  onUpdated,
}) => {
  // const [error, setError] = useState<ErrorAlert>({ show: false, message: '' });
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [user, setUser] = useState<UserDto>();
  const [photo, setPhoto] = useState<PhotoDto>();
  const [parentSelect, setParentSelect] = useState<boolean>(false);
  const [parentId, setParentId] = useState<number>();
  const [orgId, setOrgId] = useState<number | undefined>(-1);

  const scheme = yup.object().shape({
    userName: yup.string().required('이름을 입력해주세요'),
    birthDay: yup.date().required('생일을 입력해주세요'),
    orgId: yup.number().min(1, '반을 선택해주세요').required('반을 선택해주세요'),
    parentId: yup.number().min(1, '부모님을 선택해주세요').required('부모님을 선택해주세요'),
    parentName: yup.string(),
  });

  const {
    register,
    handleSubmit: onSubmit,
    formState: { errors },
    reset,
    setValue,
    control,
  } = useForm({
    defaultValues: {
      userName: '',
      birthDay: new Date(),
      orgId: -1,
      parentId: -1,
      parentName: '',
    },
    resolver: yupResolver(scheme),
  });

  const fetchData = useCallback(async () => {
    try {
      setIsLoading(true);
      if (!userId) {
        throw new Error('찾고자 하는 KEY값이 존재하지 않습니다.');
      }
      const data: UserDto = await userApi.itemById(userId);

      if (!data) {
        throw new Error('데이터를 찾지 못했습니다.');
      }

      if (data.photo) {
        setPhoto(data.photo);
      }
      setUser((user) => Object.assign({}, user, data));
      setValue('userName', data.userName || '');
      setValue('birthDay', data.birthDay ? new Date(data.birthDay) : new Date());
      setValue('orgId', data.org?.id || -1);
      setValue('parentId', data.parent?.id || -1);
      setValue('parentName', data.parent?.userName || '');
      setParentId(data.parent?.id);
      if (data.class && data.class.length > 0) {
        setOrgId(data.class[0].id || -1);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  useEffect(() => {
    if (isOpen) {
      fetchData();
    } else {
      reset();
      setParentId(undefined);
      setOrgId(undefined);
      setPhoto(undefined);
      setUser(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchData, isOpen]);

  /**
   * 업데이트를 처리한다.
   * @param data - 전달할 데이터
   * @param callback - 완료 후 처리해야 할 콜백 함수
   */
  const updateProc = async (data: UserDto, callback: () => void) => {
    try {
      setIsLoading(true);
      if (!data.id || !user) {
        throw new Error('사용자 ID가 없습니다.');
      }

      const newUser: UserDto = {
        id: data.id,
      };

      if (user.class && user.class.length > 0) {
        newUser.srcOrg = {
          id: user.class[0].id,
        };
      }

      await userApi.updateById(data.id, data);

      if (orgId !== undefined && orgId > 0) {
        await userApi.joinUserOrg({
          org: {
            id: orgId,
          },
          users: [newUser],
        });
      }
      setIsEdit(false);
      callback();
    } catch (error: any) {
      dispatch(
        modalOpen({
          type: 'alert',
          isOpen: true,
          message: error.message ? error.message : `오류가 발생하였습니다 ${error.message}`,
        }),
      );
      handleReset();
    } finally {
      setIsLoading(false);
    }
  };

  const handleReset = () => {
    reset({
      userName: user?.userName || '',
      birthDay: user?.birthDay ? new Date(user.birthDay) : new Date(),
      orgId: user?.org?.id || -1,
      parentId: user?.parent?.id || -1,
      parentName: user?.parent?.userName || '',
    });
    setOrgId(user?.org?.id || -1);
    setParentId(user?.parent?.id);
    setPhoto(user?.photo);
    setIsEdit(false);
  };

  const handleSubmit = (data: any) => {
    dispatch(
      modalOpen({
        type: 'confirm',
        isOpen: true,
        title: '수정',
        message: `${user?.userName}을 수정하시겠습니까?`,
        onOk: () => {
          const d: UserDto = Object.assign({}, user, {
            userName: data.userName,
            birthDay: format(data.birthDay, 'y-MM-dd'),
            org: {
              id: orgId,
            },
            parent: {
              id: parentId,
            },
            photo: photo,
          });
          updateProc(d, async () => {
            fetchData();
            toast.info(`수정이 되었습니다`);
            onUpdated();
          });
        },
      }),
    );
  };

  const handleRemove = () => {
    dispatch(
      modalOpen({
        type: 'confirm',
        isOpen: true,
        title: '삭제',
        message: `${user?.userName}을 삭제하시겠습니까?`,
        onOk: removeProc,
      }),
    );
  };

  /**
   * 삭제 처리
   */
  const removeProc = async () => {
    try {
      setIsLoading(true);
      if (!user) {
        throw new Error('대상을 찾을 수가 없습니다');
      }

      await userApi.removeUser(user.id as number);
      onRemoved();
      setIsOpen(false);
      toast.info('원생을 삭제하였습니다');
    } catch (error: any) {
      dispatch(
        modalOpen({
          type: 'alert',
          isOpen: true,
          message: error.message ? error.message : `오류가 발생하였습니다 ${error.message}`,
        }),
      );
      handleReset();
    } finally {
      setIsLoading(false);
    }
  };

  const handlePhotoUpload = (file: PhotoDto | undefined) => {
    setPhoto(file);
  };

  const handleChangeParent = (id: number, userName: string) => {
    setParentId(id);
    setValue('parentId', id);
    setValue('parentName', userName);
  };

  return (
    <>
      <Dialog className="relative z-[1001]" open={isOpen} onClose={() => {}}>
        <div className="fixed inset-0 bg-black/75" aria-hidden="true" />
        <div className="fixed inset-0 flex items-center justify-center">
          <Dialog.Panel className="w-full md:max-w-md bg-white rounded-lg relative">
            {isLoading && (
              <div className="w-full h-full absolute bg-white flex items-center justify-center">
                <TailSpin
                  height="80"
                  width="80"
                  color="#ff9e18"
                  ariaLabel="tail-spin-loading"
                  radius="1"
                  visible={true}
                />
              </div>
            )}
            <form onSubmit={onSubmit(handleSubmit)} className="w-full">
              <Card
                title={
                  <div className="flex items-center justify-between">
                    <span>{'상세정보'}</span>
                    {!isEdit && (
                      <div>
                        <Button plain color="error" className="font-normal" onClick={handleRemove}>
                          삭제
                        </Button>
                        <Button
                          plain
                          color="secondary"
                          className="font-normal"
                          onClick={() => setIsEdit(true)}>
                          수정
                        </Button>
                      </div>
                    )}
                  </div>
                }
                actions={
                  <>
                    {!isEdit && (
                      <Button plain onClick={() => setIsOpen(false)} className="font-normal">
                        확인
                      </Button>
                    )}
                    {isEdit && (
                      <>
                        <Button plain onClick={handleReset} className="font-normal">
                          취소
                        </Button>
                        <Button type="submit" plain color="secondary" className="font-normal">
                          수정
                        </Button>
                      </>
                    )}
                  </>
                }>
                <div className="relative w-full">
                  {/* {error.show && (
                  <div className="p-4">
                    <div className="alert alert-error rounded-lg text-white">{error.message}</div>
                  </div>
                )}
                <div className={`${error.show ? 'px-4 pb-4' : 'p-4'}`}></div> */}
                  <div className="form-control mb-4 items-center">
                    <Profile photo={photo} onChange={handlePhotoUpload} isEdit={isEdit} />
                  </div>
                  <div className="px-4 pb-4 form-control">
                    <label className="label">
                      <span className={`label-text ${errors.parentId && 'text-error'}`}>
                        부모님
                      </span>
                    </label>
                    <input
                      className={`select select-bordered font-normal text-sm cursor-pointer ${
                        errors.parentId && 'select-error'
                      }`}
                      onClick={() => setParentSelect(true)}
                      placeholder="부모님을 선택해주세요"
                      readOnly
                      {...register('parentName')}
                      disabled={!isEdit}
                    />
                    <span className="mt-1 text-xs text-error">
                      {errors.parentId && errors.parentId.message}
                    </span>
                  </div>
                  <div className="px-4 pb-4 form-control">
                    <label className="label">
                      <span className={`label-text ${errors.userName && 'text-error'}`}>이름</span>
                    </label>
                    <input
                      type="text"
                      className={`input input-bordered text-sm ${errors.userName && 'input-error'}`}
                      {...register('userName')}
                      disabled={!isEdit}
                    />
                    <span className="text-xs mt-2 text-danger tracking-wide">
                      {errors.userName && errors.userName.message}
                    </span>
                  </div>
                  <div className="px-4 pb-4 form-control">
                    <label className="label">
                      <span className={`label-text ${errors.birthDay && 'text-error'}`}>
                        생년월일
                      </span>
                    </label>
                    <DatePicker
                      control={control}
                      name={'birthDay'}
                      className={`${errors.birthDay && 'input-error'}`}
                      disabled={!isEdit}
                      maxDate={new Date()}
                    />
                    <span className="text-xs mt-2 text-danger tracking-wide">
                      {errors.birthDay && errors.birthDay.message}
                    </span>
                  </div>
                  <ClassSelect
                    value={orgId}
                    onChange={(id: number) => {
                      setOrgId(id);
                      setValue('orgId', id);
                    }}
                    className="mb-4 px-4"
                    error={errors.orgId}
                    disabled={!isEdit}
                  />
                </div>
              </Card>
            </form>
          </Dialog.Panel>
        </div>
      </Dialog>
      <ParentSelect
        isOpen={parentSelect}
        setIsOpen={setParentSelect}
        value={parentId}
        onChange={handleChangeParent}
      />
    </>
  );
};
