import React from 'react';
import { Button } from '@components';

interface LectureItemProp {
  id: number;
  userName: string;
  title: string;
  subjectCount: number;
  startDate?: string;
  endDate?: string;
  membersCount?: number;
  alias?: string;
  onClick?: (id: number) => void;
}

export const LectureItem: React.FC<LectureItemProp> = ({
  id,
  userName,
  title,
  subjectCount,
  startDate,
  endDate,
  membersCount,
  alias,
  onClick,
}: LectureItemProp) => {
  const handleClick = () => {
    if (onClick) {
      onClick(id);
    }
  };

  return (
    <tr>
      <td className="border-b text-black/80 text-sm p-4 leading-normal truncate max-w-xs lg:indent-12">
        {userName}
      </td>
      <td className="border-b text-black/80 text-sm p-4 leading-normal truncate max-w-xs lg:indent-12">
        <p className="mb-1">
          {title} ({subjectCount}주){' '}
          <span className="text-xs text-gray-500">{alias && `- ${alias}`}</span>
        </p>
        <p className="text-xs">
          {startDate && endDate && (
            <>
              ({startDate} ~ {endDate})
            </>
          )}
        </p>
      </td>
      <td className="border-b text-black/80 text-sm p-4 leading-normal truncate max-w-xs text-center">
        {membersCount !== undefined && <span>{membersCount} 명</span>}
      </td>
      <td className="border-b text-black/80 text-sm p-4 leading-normal truncate max-w-xs text-center">
        <Button
          outlined
          color="primary"
          size="sm"
          className="text-xs font-normal group"
          onClick={handleClick}>
          <span className="group-hover:text-white">상세보기</span>
        </Button>
      </td>
    </tr>
  );
};
