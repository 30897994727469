import React from 'react';
import { Dialog } from '@headlessui/react';
import { Card } from '../Card';
import { Button } from '../Button';
import { useCallback, useEffect, useState } from 'react';
import { format } from 'date-fns';
import * as noticeApi from '@api/notice';
import { NoticeDto } from '../../dto/NoticeDto';
import { useAppDispatch } from '@store/hook';
import { open as modalOpen } from '@store/modal';
import { TailSpin } from 'react-loader-spinner';
import Linkify from 'react-linkify';

interface NoticeDetailProp {
  readonly id: number;
  readonly nextId: number;
  readonly prevId: number;
  readonly isOpen: boolean;
  readonly setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  readonly onClose?: () => void;
}

export const NoticeDetail: React.FC<NoticeDetailProp> = ({ id, isOpen, setIsOpen, onClose }) => {
  const [obj, setObj] = useState<NoticeDto | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  const handleClose = () => {
    if (onClose) {
      onClose();
    }
    setIsOpen(false);
  };
  const fetchData = useCallback(
    async () => {
      try {
        if (!isOpen) {
          return;
        }
        setIsLoading(true);

        if (id < 1) {
          throw new Error('대상을 찾을 수 없습니다');
        }
        const response: any = await noticeApi.detail(id);
        setObj(response);
        if (response.readYn !== 'Y') {
          noticeApi.read(id);
        }
      } catch (error: any) {
        dispatch(
          modalOpen({
            type: 'alert',
            isOpen: true,
            title: '오류',
            message: error.message
              ? error.message
              : `오류가 발생하였습니다. [오류코드 ${error.status}]`,
            onOk() {
              setIsOpen(false);
            },
          }),
        );
      } finally {
        setIsLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [id, isOpen],
  );

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    if (!isOpen) {
      setObj(undefined);
    }
  }, [isOpen]);

  return (
    <Dialog open={isOpen} onClose={() => {}} className="relative z-[1010]">
      {/* BACKDROP */}
      <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
      <div className="fixed inset-0 flex min-h-full items-center justify-center px-4">
        <Dialog.Panel className="w-full lg:max-w-2xl xl:max-w-4xl shadow relative">
          {isLoading && (
          <div className="absolute w-full h-full bg-white/75 rounded-lg flex items-center justify-center">
            <TailSpin
              height="48"
              width="48"
              color="#ff9e18"
              ariaLabel="tail-spin-loading"
              radius="1"
              visible={true}
            />
          </div>
          )}
          <Card
            title={obj?.title}
            subTitle={
              <div className="text-right">
                {obj ? format(new Date(obj.createdAt), 'yyyy-MM-dd HH:mm:ss') : ''}
              </div>
            }
            actions={
              <Button plain color="secondary" onClick={handleClose}>
                확인
              </Button>
            }>
            <>
              <div className="w-full border-y  min-h-[20rem] max-h-[20rem] overflow-y-auto leading-6 py-4 whitespace-pre-wrap">
              <Linkify
                componentDecorator={
                  (decoratedHref: string, decoratedText: string, key: number) => (
                    <a
                      key={key}
                      href={decoratedHref}
                      target="_blank"
                      rel="noopener noreferrer"
                      >
                      {decoratedText}
                    </a>
                  )
                }
              >
                {obj?.content || ''}
              </Linkify>
              </div>
              <div className="w-full pt-2 px-2">
                {/* <div className="flex mb-2 w-full">
                  <div className="font-medium mr-2 w-24 min-w-[6rem]">이전 공지사항</div>
                  <div className="truncate cursor-pointer" onClick={handlePrev}>
                    {
                      "이전"
                    }
                  </div>
                </div>
                <div className="flex w-full">
                  <div className="font-medium mr-2 w-24 min-w-[6rem]">다음 공지사항</div>
                  <div className="truncate cursor-pointer" onClick={handleNext}>
                    {"다음"}
                  </div>
                </div> */}
              </div>
            </>
          </Card>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
};
