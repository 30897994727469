import React from 'react';
import { chunk } from 'lodash';
import { UserDto } from '../../../dto';

interface IProp {
  readonly name: string;
  // readonly teacher: string | Array<string>;
  readonly students: Array<UserDto>;
}

export const Grade = ({ name, students }: IProp) => {
  const chunkStudents: UserDto[][] = chunk(students, 2);

  return (
    <div className="flex flex-col mr-4 last:mr-0 min-w-[calc(33.3333%-1rem)] lg:min-h-[222.5px]">
      <div className="bg-secondary rounded-lg py-2 px-9 text-center font-light text-white">
        <h3 className="w-full truncate">{name}</h3>
        {/* <p className="text-sm">({teacher})</p> */}
      </div>
      <table className="table table-compact mb-2">
        <colgroup>
          <col width={'50%'} />
          <col width={'50%'} />
        </colgroup>
        <tbody>
          {chunkStudents.map((value: UserDto[], index: number) => {
            return (
              <tr className="border-b" key={`row-${index}`}>
                <td className="text-center">{value[0]?.userName}</td>
                <td className="text-center">{value[1]?.userName}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
