import React, { useState, useEffect, useCallback } from 'react';
import { format } from 'date-fns';

import * as subjectApi from '@api/subject';

import { useAppDispatch, useAppSelector } from '@store/hook';
import { selectUser } from '@store/user';
import { open as modalOpen } from '@store/modal';

import { Button, Pagination, GradeList, CourseSelect } from '@components';
import { SearchDto, SubjectDto } from '@dto';
import { SUBJECT_TYPE } from '@common';
import { useSearchParams } from 'react-router-dom';

const statusRadioGroup = [
  { value: 'A', title: '전체보기' },
  { value: 'Y', title: '완료' },
  { value: 'N', title: '미완료' },
];

interface ItemProp extends SubjectDto {
  onClick?: (id: number) => void;
}

const Item: React.FC<ItemProp> = ({
  id,
  title,
  course,
  startDate,
  submitDate,
  submitCnt,
  onClick,
}) => {
  const handleClick = () => {
    if (onClick) {
      onClick(id);
    }
  };

  return (
    <tr>
      <td className="border-b text-sm lg:hidden">
        <p className="mb-1">{title}</p>
        <p className="text-gray-500 text-xs break-words whitespace-normal">
          ({course?.title}
          {course?.alias && ` - ${course.alias}`})
        </p>
        <div className="bg-gray-100/75 rounded-lg w-full py-2 my-4 text-xs">
          <div className="mb-1 grid grid-cols-12">
            <div className="col-span-6 pl-2">수업 진행 예정 일자</div>
            <div className="col-span-6">{startDate && format(new Date(startDate), 'yyyy-MM-dd')}</div>
          </div>
          <div className="mb-1 grid grid-cols-12">
            <div className="col-span-6 pl-2">수업 완료 일자 (인원)</div>
            <div className="col-span-6">{submitDate ? `${format(new Date(submitDate), 'yyyy-MM-dd')} (${submitCnt}명)` : '-'}</div>
          </div>
        </div>
        <div>
          <Button
            outlined
            color="primary"
            size="sm"
            className="text-xs font-normal"
            onClick={handleClick}>
            상세보기
          </Button>
        </div>
      </td>
      <td className="border-b text-black/80 text-sm p-4 leading-normal truncate max-w-xs hidden lg:table-cell">
        <p className="mb-1 lg:indent-12">{title}</p>
        <p className="text-gray-500 text-xs lg:indent-12">
          ({course?.title}
          {course?.alias && ` - ${course.alias}`})
        </p>
      </td>
      <td className="border-b text-black/80 text-sm py-4 leading-normal truncate max-w-xs lg:indent-12  hidden lg:table-cell">
        {startDate && format(new Date(startDate), 'yyyy-MM-dd')}
      </td>
      <td className="border-b text-black/80 text-sm p-4 leading-normal truncate max-w-xs lg:indent-12  hidden lg:table-cell">
        {submitDate && format(new Date(submitDate), 'yyyy-MM-dd')}
      </td>
      <td className="border-b text-black/80 text-sm p-4 leading-normal truncate max-w-xs text-center hidden lg:table-cell">
        {submitCnt}명
      </td>
      <td className="border-b text-black/80 text-sm p-4 leading-normal truncate max-w-xs text-center hidden lg:table-cell">
        <Button
          outlined
          color="primary"
          size="sm"
          className="text-xs font-normal"
          onClick={handleClick}>
          상세보기
        </Button>
      </td>
    </tr>
  );
};

export const RatingCreateList = () => {
  const [subjectId, setSubjectId] = useState<number | undefined>();
  const [courseId, setCourseId] = useState<number | undefined>();
  const [list, setList] = useState<SubjectDto[]>([]);
  const [count, setCount] = useState<number>(0);
  const [status, setStatus] = useState<'Y' | 'N' | 'A'>('A');
  const rows: number = 10;
  const [activePage, setActivePage] = useState<number>(1);
  const [isListOpen, setIsListOpen] = useState<boolean>(false);
  const [search, setSearch] = useState<SearchDto>({
    page: activePage,
    limit: rows,
    search: {
      type: SUBJECT_TYPE.SUBJECT,
    },
  });
  const dispatch = useAppDispatch();

  const { user } = useAppSelector(selectUser);
  const [searchParams] = useSearchParams();
  const id = searchParams.get('id');

  useEffect(() => {
    if (id && !isNaN(Number(id))) {
      setSubjectId(Number(id));
      setIsListOpen(true);
    }
  }, [id]);

  const fetchData = useCallback(async () => {
    try {
      if (!user) {
        return;
      }
      const { count, data } = await subjectApi.list(search);
      setList([...data]);
      setCount(count);
    } catch (error: any) {
      dispatch(
        modalOpen({
          type: 'alert',
          isOpen: true,
          title: '오류',
          message: error.message
            ? error.message
            : `오류가 발생하였습니다. [오류코드 ${error.status}]`,
        }),
      );
    } finally {
    }
  }, [search, user, dispatch]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  /**
   *
   * @param pageNumber
   */
  const handlePageChange = (pageNumber: number) => {
    setActivePage(pageNumber);
    const newSearch: SearchDto = { ...search };
    newSearch.page = pageNumber;
    setSearch(newSearch);
  };

  const handleItemClick = (id: number) => {
    // TODO 해당 Subject가 출석 상태진행이 되지 않았을 경우 ALERT을 띄운다.
    setSubjectId(id);
    setIsListOpen(true);
  };

  const handleChangeCourse = (id: number) => {
    setCourseId(id);
    const newSearch: SearchDto = { ...search };
    newSearch.page = 1;
    newSearch.search.course = { id };
    setActivePage(1);
    setSearch(newSearch);
  };

  /**
   * 평가 상태 변경 이벤트
   * @param e
   */
  const handleChangeStatus = (e: any) => {
    setStatus(e.target.value);
    const newSearch: SearchDto = { ...search };
    newSearch.page = 1;
    newSearch.search.submitComplete = e.target.value;

    if (e.target.value === 'A') {
      delete newSearch.search.submitComplete;
    }

    setActivePage(1);
    setSearch(newSearch);
  };

  const handleResetFilter = () => {
    const newSearch: SearchDto = {
      page: 1,
      limit: search.limit,
      search: {
        type: SUBJECT_TYPE.SUBJECT,
      },
    };
    setActivePage(1);
    setCourseId(undefined);
    setSearch(newSearch);
    setStatus('A');
  };

  return (
    <>
      <div className="w-full">
        <h3 className="text-3xl font-semibold tracking-wider mb-4">평가하기</h3>
        <div className="w-full bg-white p-4 rounded-lg shadow-lg">
          {/* filter */}
          <div className="grid grid-cols-12 items-center gap-4 mb-4">
            <div className="col-span-12 lg:col-span-3">
              <CourseSelect
                value={courseId}
                onChange={handleChangeCourse}
                isTeacher
                selectClassName="min-h-[2.25rem] h-[2.25rem] max-h-[2.25rem]"
              />
            </div>
            <div className="col-span-12 lg:col-span-3 form-control">
              <label className="label">
                <span className="label-text">평가상태</span>
              </label>
              <div className="flex">
                {statusRadioGroup.map(({ value, title }, index: number) => {
                  return (
                    <label className="label" key={index}>
                      <input
                        type="radio"
                        className="radio radio-secondary radio-sm mr-2"
                        name="status"
                        checked={status === value}
                        value={value}
                        onChange={handleChangeStatus}
                      />
                      <span className="label-text">{title}</span>
                    </label>
                  );
                })}
              </div>
            </div>
            <div className="col-span-12 lg:col-span-3 mt-auto mb-1">
              <Button
                color="error"
                className="bg-danger border-danger hover:bg-danger/80 hover:border-danger/80 focus-visible:outline-danger"
                onClick={handleResetFilter}>
                <span className="text-white font-light">초기화</span>
              </Button>
            </div>
          </div>
          {/* list */}
          <div className="w-full overflow-x-auto">
            <table className="table w-full mb-4 border-y">
              <colgroup className="lg:hidden">
                <col width="*" />
              </colgroup>
              <colgroup className="hidden lg:table-column-group">
                <col width="*" />
                <col width="20%" />
                <col width="20%" />
                <col width="10%" />
                <col width="20%" />
              </colgroup>
              <thead className="hidden lg:table-header-group">
                <tr>
                  <th className="h-12 text-sm text-black/60 border-b bg-slate-50 font-medium lg:indent-12 !relative">
                    제목
                  </th>
                  <th className="h-12 text-sm text-black/60 border-b bg-slate-50 font-medium lg:indent-12">
                    수업 진행 예정 일자
                  </th>
                  <th className="h-12 text-sm text-black/60 border-b bg-slate-50 font-medium lg:indent-12">
                    평가일
                  </th>
                  <th className="h-12 text-sm text-black/60 border-b bg-slate-50 font-medium text-center">
                    평가완료 원생수
                  </th>
                  <th className="h-12 text-sm text-black/60 border-b bg-slate-50 font-medium text-center"></th>
                </tr>
              </thead>
              <tbody className="bg-white">
                {list.length === 0 && (
                  <tr>
                    <td colSpan={5} className="h-80">
                      <p className="m-auto text-sm text-center text-gray-500">
                        데이터를 찾을 수 없습니다.
                      </p>
                    </td>
                  </tr>
                )}

                {list.map((value, index) => (
                  <Item key={index} {...value} onClick={handleItemClick} />
                ))}
              </tbody>
            </table>
            <Pagination
              activePage={activePage}
              setActivePage={setActivePage}
              itemsCountPerPage={rows}
              totalItemsCount={count}
              pageRangeDisplayed={5}
              onChange={handlePageChange}
            />
          </div>
        </div>
      </div>
      <GradeList
        id={subjectId}
        isOpen={isListOpen}
        setIsOpen={setIsListOpen}
        onUpdated={() => {
          fetchData();
        }}
        onCreated={() => {
          fetchData();
        }}
      />
    </>
  );
};
