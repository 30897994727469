import React, { useCallback, useEffect, useState } from 'react';
import { format } from 'date-fns';
import { Dialog } from '@headlessui/react';

import * as subjectApi from '@api/subject';

import { useAppDispatch, useAppSelector } from '@store/hook';
import { selectUser } from '@store/user';
import { open as modalOpen } from '@store/modal';

import { Button, Card, RatingAlbum } from '@components';
import { ChildInfo } from '@components/parent';
import { SubjectSubmitDto } from '@dto/SubjectSubmitDto';
import { PhotoDto } from '@dto';
import { SubjectSubmitEvalItemDto } from '@dto/SubjectSubmitEvalItemDto';
import { RatingGraph } from '@components/common';
import { Slider } from '@components/Slider';
import { toast } from 'react-toastify';
import { RatingInfoModal } from './RatingInfoModal';

interface RatingModalProp {
  id: string | undefined;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onUpdated: () => void;
}

export const RatingModal: React.FC<RatingModalProp> = ({ id, isOpen, setIsOpen, onUpdated }) => {
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [isInfo, setIsInfo] = useState<boolean>(false);
  // 원본 데이터
  const [submitData, setSubmitData] = useState<SubjectSubmitDto>();
  const [files, setFiles] = useState<PhotoDto[]>([]);
  const [content, setContent] = useState<string>('');
  const [evalSubmits, setEvalSubmits] = useState<SubjectSubmitEvalItemDto[]>([]);
  const [submitVals, setSubmitVals] = useState<any>([]);
  const [isEditAuthority, setIsEditAuthority] = useState<boolean>(false);
  const [maxValue, setMaxValue] = useState<number | 'auto' | undefined>('auto');
  const dispatch = useAppDispatch();
  const { user } = useAppSelector(selectUser);

  /**
   * 데이터를 불러온다
   */
  const fetchData = useCallback(async () => {
    try {
      if (!id) {
        throw new Error('잘못된 요청입니다');
      }

      const submit: SubjectSubmitDto | undefined = await subjectApi.getSubmitById(id);

      if (!submit) {
        throw new Error('데이터를 불러오지 못했습니다');
      }

      setSubmitData({ ...submit });
      setFiles([...submit.files]);
      setContent(submit.content);
      setSubmitVal([...submit.evalSubmits]);
    } catch (error: any) {
      dispatch(
        modalOpen({
          type: 'alert',
          isOpen: true,
          title: '오류',
          message: error.message
            ? error.message
            : `오류가 발생하였습니다. [오류코드 ${error.status}]`,
        }),
      );
    } finally {
    }
  }, [id, dispatch]);

  useEffect(() => {
    if (isOpen) {
      fetchData();
    }
  }, [isOpen, fetchData]);

  useEffect(() => {
    if (!isOpen) {
      setIsEdit(false);
    }
  }, [isOpen]);

  useEffect(() => {
    // 권한 확인
    // ID가 동일하면, 수정할 권리를 가진다.
    if (user?.id === submitData?.adminUser.id || user?.role?.name === "admin") {
      setIsEditAuthority(true);
    }
  }, [user, submitData]);

  const handleReset = () => {
    // TODO 모든 데이터를 RESET 시킨다.
    // ISEDIT를 FALSE로 돌린다.
    if (!submitData) {
      return;
    }

    setFiles([...submitData?.files]);
    setContent(submitData.content);
    setSubmitVal([...submitData.evalSubmits]);
    setIsEdit(false);
  };

  const handleSubmit = () => {
    dispatch(
      modalOpen({
        type: 'confirm',
        isOpen: true,
        message: `${submitData?.targetUser.userName}원생의 평가를 수정하시겠습니까?`,
        onOk: handleUpdate,
      }),
    );
  };

  const handleUpdate = async () => {
    try {
      if (!submitData) {
        throw new Error('원본 데이터를 찾을 수 없습니다');
      }

      // if (!files || files.length === 0) {
      //   throw new Error('작품 사진을 적어도 1개는 올리셔야 합니다');
      // }

      if (!content || content.trim().length === 0) {
        throw new Error('선생님 한마디를 적어주세요.');
      }

      const newSubmitData: SubjectSubmitDto = {
        ...submitData,
        files: [...files],
        evalSubmits: [...evalSubmits],
        content: content,
      };

      const submit: SubjectSubmitDto = await subjectApi.submit(newSubmitData);
      if (!submit) {
        throw new Error('데이터를 불러오지 못했습니다');
      }

      setSubmitData({ ...submit });
      setFiles([...submit.files]);
      setContent(submit.content);
      setSubmitVal([...submit.evalSubmits]);
      toast.info('평가 수정에 성공하였습니다');
      setIsEdit(false);
      onUpdated();
    } catch (error: any) {
      dispatch(
        modalOpen({
          type: 'alert',
          isOpen: true,
          title: '오류',
          message: error.message
            ? error.message
            : `오류가 발생하였습니다. [오류코드 ${error.status}]`,
        }),
      );
    } finally {
    }
  };

  const handleChangeFiles = (files: PhotoDto[]) => {
    console.log('files...', files);
    setFiles([...files]);
  };

  const handleChangeContent = (e: any) => {
    setContent(e.target.value);
  };

  const setSubmitVal = (evalSubmits: SubjectSubmitEvalItemDto[]) => {
    const evalMaxs: number[] | undefined = [...evalSubmits].map(
      (item: SubjectSubmitEvalItemDto) => item.eval.evalMax || 1,
    );

    if (evalMaxs) {
      const maxValue: number = Math.max(...evalMaxs);
      setMaxValue(maxValue);
    } else {
      setMaxValue('auto');
    }

    const newList: any = [...evalSubmits].map((evalItem: SubjectSubmitEvalItemDto) => {
      return {
        점수: Number(evalItem.submitVal),
        제목: evalItem.eval.title || '',
      };
    });

    const newEvalSubmits: SubjectSubmitEvalItemDto[] = [...evalSubmits].map(
      (evalItem: SubjectSubmitEvalItemDto) => {
        return {
          ...evalItem,
          submitVal: Number(evalItem.submitVal),
        };
      },
    );

    setSubmitVals(newList);
    setEvalSubmits(newEvalSubmits);
  };

  /**
   * evalSubmit 데이터 변경
   * @param id
   * @param value
   * @returns
   */
  const handleEvalValue = (id: number, value: number) => {
    const newEvalSubmits = [...evalSubmits];
    const index: number = newEvalSubmits.findIndex(
      (value: SubjectSubmitEvalItemDto) => value.eval.id === id,
    );

    if (index < 0) {
      return;
    }

    newEvalSubmits[index].submitVal = value;
    setEvalSubmits(newEvalSubmits);
  };

  return (
    <Dialog className="relative z-[1002]" open={isOpen} onClose={() => []}>
      <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
      {/* container */}
      <div className="fixed flex inset-0 w-full max-h-full items-center justify-center overflow-y-auto">
        <Dialog.Panel className="w-full max-w-full max-h-full lg:max-w-2xl xl:max-w-4xl">
          <div className="relative w-full h-full p-4">
            <Card
              title={
                <div className="flex items-center justify-between">
                  {submitData && (
                    <ChildInfo
                      name={submitData.targetUser?.userName as string}
                      orgName={submitData.subject?.class?.title as string}
                      profile={submitData.targetUser?.photo?.path}
                    />
                  )}
                  {isEditAuthority && !isEdit && (
                    <Button color="secondary" plain onClick={() => setIsEdit(true)}>수정</Button>
                  )}
                </div>
              }
              actions={
                <>
                  {isEdit && (
                    <>
                      <Button plain onClick={handleReset}>
                        취소
                      </Button>
                      <Button plain color="secondary" onClick={handleSubmit}>
                        저장
                      </Button>
                    </>
                  )}
                  {!isEdit && (
                    <Button color="secondary" plain onClick={() => setIsOpen(false)}>
                      확인
                    </Button>
                  )}
                </>
              }>
              <>
                <div className="w-full grid grid-cols-12 gap-4 px-4">
                  <div className="col-span-12 lg:col-span-6">
                    <RatingAlbum
                      isEdit={isEdit && isEditAuthority}
                      label={`${submitData?.targetUser.userName}이(가) 만든 작품`}
                      files={files}
                      onChange={handleChangeFiles}
                    />
                  </div>
                  <div className="col-span-12 lg:col-span-6 mt-6 lg:mt-0">
                    <label className="label" onClick={() => { }}>
                      <span className="label-text font-medium">평가 점수 </span>
                      <button className="label-text font-medium" onClick={() => {
                        setIsInfo(true)
                      }}>평가 기준</button>
                    </label>
                    <div
                      className={`border p-3 rounded-xl w-full h-80 max-h-[20rem] mx-auto my-auto ${isEdit && 'overflow-y-auto'
                        }`}>
                      {!isEdit && (
                        <RatingGraph
                          data={submitVals}
                          keys={['점수']}
                          indexBy={'제목'}
                          maxValue={maxValue}
                        />
                      )}
                      {isEdit &&
                        evalSubmits?.map((evalSubmit: SubjectSubmitEvalItemDto) => (
                          <Slider
                            key={evalSubmit.eval.id}
                            min={evalSubmit.eval.evalMin}
                            max={evalSubmit.eval.evalMax}
                            step={1}
                            label={evalSubmit.eval.title}
                            content={evalSubmit.eval.content}
                            value={evalSubmit.submitVal}
                            onChange={(value: number) => {
                              handleEvalValue(evalSubmit.eval.id, value);
                            }}
                          />
                        ))}
                    </div>
                  </div>
                  <div className="col-span-12 mt-8">
                    <label className="label">
                      <span className="label-text font-medium">선생님의 한마디</span>
                    </label>
                    <div className="mb-4 last:mb-0">
                      {/* 수정권한이 없을 경우 작성자의 이름을 보여준다. */}
                      {!isEditAuthority && (
                        <ChildInfo
                          name={submitData?.adminUser.userName as string}
                          orgName={
                            (submitData?.createdAt &&
                              format(new Date(submitData?.createdAt), 'yyyy.MM.dd')) ||
                            ''
                          }
                          profile={submitData?.adminUser.photo?.path}
                        />
                      )}
                      {!isEdit && content.trim().length === 0 && (
                        <>
                          <div className="textarea textarea-bordered h-28">
                            <span className="text-sm text-gray-500">선생님의 한마디가 없습니다</span>
                          </div>
                        </>
                      )}
                      {/* 수정 권한이 있고, 수정 모드일 경우 */}
                      {isEdit && isEditAuthority && (
                        <textarea
                          className="textarea textarea-bordered h-28 w-full resize-none"
                          value={content}
                          onChange={handleChangeContent}
                        />
                      )}
                      {/*  */}
                      {!isEdit && isEditAuthority && (
                        <div className="textarea textarea-bordered h-28 whitespace-pre-wrap overflow-y-auto">
                          <span className="text-sm text-gray-500">{content}</span>
                        </div>
                      )}
                      {/* 수정 권한이 없을 경우 */}
                      {!isEditAuthority && content && (
                        <>
                          <div className="textarea textarea-bordered h-28 whitespace-pre-wrap overflow-y-auto">
                            <span className="text-sm text-gray-500">{content}</span>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </>
            </Card>
          </div>
        </Dialog.Panel>
      </div>
      {submitData?.subject.id &&
        <RatingInfoModal id={submitData?.subject.id} isOpen={isInfo} setIsOpen={setIsInfo} />
      }
    </Dialog>
  );
};
