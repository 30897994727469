import React from 'react';
import classNames from 'classnames';
import { XMarkIcon } from '@heroicons/react/20/solid';

interface ChipProp {
  readonly id?: string;
  readonly className?: string;
  readonly label?: boolean;
  readonly remove?: boolean;
  readonly children?: JSX.Element | JSX.Element[] | string | null;
  readonly onClick?: () => {};
  readonly onRemove?: () => {};
}

export const Chip: React.FC<ChipProp> = ({
  id,
  className,
  label = false,
  remove = false,
  children,
  onClick,
  onRemove,
}) => {
  const chipClass: string = classNames(
    'inline-flex items-center justify-center cursor-default max-w-full overflow-hidden leading-5 relative px-3 whitespace-nowrap text-xs bg-blue-500 text-white h-7',
    {
      'rounded-2xl': !label,
      'rounded-md': label,
    },
    className,
  );

  const handleRemove = () => {};

  return (
    <span id={id} className={chipClass}>
      {remove && (
        <>
          <span>{children}</span>
          <button
            className="w-5 h-5 rounded-full ml-1 -mr-1 hover:opacity-75"
            onClick={handleRemove}>
            <XMarkIcon className="fill-white h-5 w-5" />
          </button>
        </>
      )}
      {!remove && children}
    </span>
  );
};
