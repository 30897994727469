import React from 'react';
import classNames from 'classnames';

export interface ButtonProp {
  readonly id?: string;
  readonly type?: 'button' | 'submit' | 'reset';
  readonly className?: string;
  readonly block?: boolean;
  readonly children?: JSX.Element | JSX.Element[] | string;
  readonly size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  readonly disabled?: boolean;
  readonly outlined?: boolean;
  readonly plain?: boolean;
  readonly color?: 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'light' | 'accent';
  readonly onClick?: () => void;
}

export const Button: React.FC<ButtonProp> = ({
  id,
  type = 'button',
  className,
  block = false,
  children,
  size = 'md',
  disabled = false,
  outlined = false,
  plain = false,
  color = 'light',
  onClick,
}) => {
  const buttonClass = classNames('btn tracking-[0.089em] font-medium', className, {
    [`btn-${color}`]: true,
    'btn-block': block,
    'btn-outline': outlined,
    [`btn-ghost ${`text-${color}`}`]: plain,
    'text-xs min-w-[2.25rem] min-h-[1.25rem] h-5 px-2': size === 'xs',
    'text-sm min-w-[2.25rem] min-h-[1.75rem] h-7 px-3': size === 'sm',
    'text-sm min-w-[4rem] min-h-[2.25rem] h-9 px-4': size === 'md',
    'text-sm min-w-[4.875rem] min-h-[3rem] h-12 px-5': size === 'lg',
    'min-w-[5.75rem] min-h-[3.5rem] h-14 px-6': size === 'xl',
  });

  return (
    <button id={id} type={type} className={buttonClass} onClick={onClick} disabled={disabled}>
      {children}
    </button>
  );
};
