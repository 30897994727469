import React, { useEffect, useState } from 'react';
import { groupBy } from 'lodash';

import * as subjectApi from '@api/subject';

import { ChildInfo } from '@components/parent';
import { RatingAttendance } from '@components/RatingAttendance';
import { RatingGraph } from '@components/common';
import { useAppDispatch, useAppSelector } from '@store/hook';
import { selectUser } from '@store/user';
import { SubjectDto, UserDto } from '@dto';
import { CourseSelect, Tabs } from '@components';
import { SubjectSubmitDto } from '@dto/SubjectSubmitDto';
import { RatingDetail } from '@components/RatingDetail';
import { open as modalOpen } from '@store/modal';
import { SubjectSubmitEvalItemDto } from '@dto/SubjectSubmitEvalItemDto';
import { TailSpin } from 'react-loader-spinner';

export const Rating: React.FC = () => {
  const { user } = useAppSelector(selectUser);
  const [children, setChildren] = useState<UserDto[]>([]);
  const [childId, setChildId] = useState<number | undefined>();
  const [child, setChild] = useState<UserDto>();
  const [courseId, setCourseId] = useState<number | undefined>();
  const [subject, setSubject] = useState<SubjectDto | undefined>();
  const [submit, setSubmit] = useState<SubjectSubmitDto | undefined>();
  const [submitVals, setSubmitVals] = useState<any>([]);
  const [avgMaxValue, setAvgMaxValue] = useState<number | 'auto' | undefined>('auto');
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (user) {
      if (user.childs) {
        // user.childs = user.childs.sort((a, b) => {
        //   if (a.userName === undefined) return 1;
        //   if (b.userName === undefined) return -1;
        //   return a.userName?.localeCompare(b.userName)
        // })
        setChildren([...user.childs]);

        if (user.childs.length > 0) {
          setChildId(user.childs[0].id);
          setChild(user.childs[0]);
        }
      }
    }
  }, [user]);

  const handleChangeTab = (id: any) => {
    setChildId(id);
    const child: UserDto | undefined = children.find((user: UserDto) => user.id === (id as number));
    setChild(child);
    setCourseId(undefined);
    setSubmit(undefined);
    setSubmitVals([]);
  };

  const handleSelectCourse = async (id: number, members?: UserDto[], subjects?: any) => {
    try {
      setCourseId(id);
      setSubject(undefined);
      setSubmitVals([]);

      if (id > 0) {
        const { data } = await subjectApi.submitList({
          page: 1,
          limit: subjects.length,
          search: {
            subject: {
              course: {
                id,
              },
            },
            targetUser: {
              id: childId,
            },
          },
        });

        // 평가 자료가 있는지 확인
        if (data.length > 0) {
          const r: SubjectSubmitEvalItemDto[][] = data.map((subjectSubmit: SubjectSubmitDto) => [
            ...subjectSubmit.evalSubmits,
          ]);
          const submitEvalList: SubjectSubmitEvalItemDto[] = (
            [] as SubjectSubmitEvalItemDto[]
          ).concat(...r);

          const maxValues: number[] | undefined = submitEvalList.map(
            (v: SubjectSubmitEvalItemDto) => v.eval.evalMax || 1,
          );

          if (maxValues) {
            const maxValue: number = Math.max(...maxValues);
            setAvgMaxValue(maxValue);
          } else {
            setAvgMaxValue('auto');
          }

          const evalItems: any = groupBy(
            submitEvalList.map((value: SubjectSubmitEvalItemDto) => ({
              submitVal: Number(value.submitVal),
              title: value.eval.title || '',
            })),
            'title',
          );

          const newList: any = [];

          for (const key in evalItems) {
            const count: number = evalItems[key].length;
            const sum: number = evalItems[key].reduce((acc: number, current: any) => {
              return acc + current.submitVal;
            }, 0);
            const avg: number = Number((sum / count).toFixed(1));
            newList.push({
              평가항목: key,
              평균점수: avg,
              '총 점수': sum,
              '참여 주제수': count,
            });
          }

          setSubmitVals([...newList]);
        } else {
          setSubmitVals([]);
        }
      }
    } catch (error: any) { }
  };

  const handleSelectSubject = async (id: number) => {
    try {
      setIsLoading(true);
      const subject = await subjectApi.itemById(id);

      if (!subject) {
        return;
      }

      setSubject({ ...subject });

      const submit = (subject?.submits || []).find((submit: SubjectSubmitDto) => {
        return submit?.targetUser.id === childId;
      });

      if (!submit) {
        setIsLoading(false);
      }

      setSubmit(submit);
    } catch (error: any) {
      dispatch(
        modalOpen({
          type: 'alert',
          isOpen: true,
          title: '오류',
          message: error.message
            ? error.message
            : `오류가 발생하였습니다. [오류코드 ${error.status}]`,
        }),
      );
    }
  };

  return (
    <div className="w-full h-[calc(100vh-112px)]">
      <h3 className="mb-4 text-3xl font-semibold tracking-wider">평가보기</h3>
      <div className="bg-white rounded-lg shadow-lg w-full h-[calc(100%-52px) p-4">
        <div className="grid grid-cols-12 gap-4">
          <div className="col-span-12 lg:col-span-4">
            <div className="pb-4">
              <Tabs
                data={children}
                valueName={'id'}
                labelName={'userName'}
                value={childId}
                onChange={handleChangeTab}
              />
            </div>
            <div className="pb-4">
              <ChildInfo
                name={child?.userName || ''}
                orgName={(child?.class && child?.class.length > 0 && child.class[0].title) || ''}
                profile={child?.photo?.path}
              />
            </div>
            <div className="pb-4">
              <CourseSelect
                value={courseId}
                hideLabel
                onChange={handleSelectCourse}
                isParent
                childId={childId}
                selectClassName="min-h-[2.25rem] h-[2.25rem] max-h-[2.25rem]"
              />
            </div>
            <RatingAttendance
              courseId={courseId}
              childId={childId}
              onSelect={handleSelectSubject}
            />
          </div>
          {/* R */}
          <div className="flex flex-col col-span-12 lg:col-span-8 relative">
            {isLoading && (
              <div className="w-full col-span-12 flex items-center justify-center h-full absolute bg-white/70 z-[1001]">
                <TailSpin
                  height="48"
                  width="48"
                  color="#ff9e18"
                  ariaLabel="tail-spin-loading"
                  radius="1"
                  visible={true}
                />
              </div>
            )}
            {!courseId && (
              <p className="my-auto text-sm text-center text-gray-500">
                커리큘럼을 먼저 선택해주세요
              </p>
            )}
            {courseId && !subject && submitVals.length > 0 && (
              <div className="w-1/2 mx-auto my-auto h-96 lg:h-1/2">
                <RatingGraph
                  data={submitVals}
                  keys={['평균점수']}
                  indexBy={'평가항목'}
                  maxValue={avgMaxValue}
                />
              </div>
            )}
            {courseId && !subject && submitVals.length === 0 && (
              <p className="my-auto text-sm text-center text-gray-500">참여한 주제가 없습니다</p>
            )}
            {courseId && subject && !submit && (
              <p className="my-auto text-sm text-center text-gray-500">
                아직 평가가 되지 않은 주제입니다
              </p>
            )}
            {submit && subject && (
              <RatingDetail
                submitId={submit.id}
                onLoading={(isLoading) => {
                  if (!isLoading) {
                    setIsLoading(false);
                  }
                }}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
