import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { PlusIcon } from '@heroicons/react/20/solid';

import * as courseApi from '@api/course';
import { CourseDto } from '@dto/CourseDto';

import { useAppDispatch } from '@store/hook';
import { open as loadingOpen, close as loadingClose } from '@store/loading';

import { Button, CourseItem, Pagination } from '@components';
import { SearchDto } from '@dto';
import { COURSE_TYPE } from '@common';

export const CourseList = () => {
  const rows: number = 10;
  const [activePage, setActivePage] = useState<number>(1);
  const [count, setCount] = useState<number>(0);
  const [list, setList] = useState<CourseDto[]>([]);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const fetchData = useCallback(async () => {
    try {
      dispatch(loadingOpen());

      const search: SearchDto = {
        page: activePage,
        limit: rows,
        search: {
          type: COURSE_TYPE.COMPANY,
        },
      };

      const { count, data } = await courseApi.list(search);

      setCount(count);
      setList([...data]);
    } catch (error: any) {
    } finally {
      dispatch(loadingClose());
    }
  }, [activePage, dispatch]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleClick = (id: number) => {
    navigate(`/director/course/${id}`);
  };

  const handlePageChange = (pageNumber: number) => {
    setActivePage(pageNumber);
  };

  return (
    <div className="w-full">
      <h3 className="text-3xl font-semibold tracking-wider mb-4">커리큘럼</h3>
      <div className="w-full bg-white p-4 rounded-lg shadow-xl">
        <div className="text-right mb-4">
          <Button
            color="primary"
            onClick={() => {
              navigate('create');
            }}>
            <PlusIcon className="w-5 h-5 -ml-1 mr-1 fill-white" />
            <span className="text-white font-normal">커리큘럼 등록</span>
          </Button>
        </div>
        <div className="w-full overflow-x-auto">
          <table className="table w-full mb-4 border-y">
            <colgroup>
              <col width={'42.5%'} />
              <col width={'42.5%'} />
              <col width={'15%'} />
            </colgroup>
            <thead>
              <tr>
                <th className="h-12 text-sm text-black/60 border-b bg-slate-50 font-medium lg:indent-12 !relative">
                  제목
                </th>
                <th className="h-12 text-sm text-black/60 border-b bg-slate-50 font-medium text-center">
                  강의수
                </th>
                {/* <th className="h-12 text-sm text-black/60 border-b bg-slate-50 font-medium text-center">
                  상태
                </th> */}
                <th className="h-12 text-sm text-black/60 border-b bg-slate-50 font-medium"></th>
              </tr>
            </thead>
            <tbody className="bg-white">
              {list.length === 0 && (
                <tr>
                  <td colSpan={3} className="py-8 text-center">
                    <p className="text-center text-sm text-gray-500 mb-4">
                      등록된 커리큘럼이 없습니다.
                    </p>
                    <Button
                      outlined
                      color="primary"
                      className="hover:!text-white font-normal"
                      onClick={() => navigate('create')}>
                      <PlusIcon className="w-5 h-5 -ml-1 mr-1" />
                      <span>새로운 커리큘럼 등록</span>
                    </Button>
                  </td>
                </tr>
              )}
              {list.map((course: CourseDto, index: number) => (
                <CourseItem
                  key={index}
                  id={course.id || -1}
                  title={course.title || ''}
                  status={course.status || ''}
                  subjectCount={course.subjects?.length}
                  onClick={handleClick}
                />
              ))}
            </tbody>
          </table>
          {/* TODO PAGINATION */}
          {count > 0 && (
            <Pagination
              activePage={activePage}
              setActivePage={setActivePage}
              itemsCountPerPage={rows}
              totalItemsCount={count}
              pageRangeDisplayed={5}
              onChange={handlePageChange}
            />
          )}
        </div>
      </div>
    </div>
  );
};
