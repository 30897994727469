import React, { useState, useEffect, useCallback } from 'react';
import { Dialog } from '@headlessui/react';
import { UserCircleIcon } from '@heroicons/react/20/solid';
import { TailSpin } from 'react-loader-spinner';

import { useAppDispatch, useAppSelector } from '@store/hook';
import { selectUser } from '@store/user';
import { open as modalOpen } from '@store/modal';
import { open as loadingOpen, close as loadingClose } from '@store/loading';

import * as courseApi from '@api/course';
import { CourseDto, UserDto } from '@dto';

import { Card, Button, CourseAdd, ChildSelector } from '@components';
import { TeacherSelector } from '@components/TeacherSelector';
import { toast } from 'react-toastify';

interface ChildItemProp {
  id: number;
  profile?: string;
  name: string;
  classTitle: string;
}

const ChildItem: React.FC<ChildItemProp> = ({ id, profile, name, classTitle }) => {
  return (
    <div className="flex items-center min-h-[4rem] h-16">
      <div className="ml-2">
        <div className="w-12 h-12 rounded-full">
          {profile && (
            <img
              src={profile}
              alt="profile"
              className="w-[38.4px] h-[38.4px] rounded-full m-auto"
            />
          )}
          {!profile && <UserCircleIcon className={`"w-full h-full fill-gray-400`} />}
        </div>
      </div>
      <div className="ml-4">
        <div className="text-sm">{name}</div>
        <div className={`text-xs truncate`}>{classTitle === '' ? '\u00A0' : classTitle}</div>
      </div>
    </div>
  );
};

interface ClassFormProp {
  readonly classId?: number | undefined;
  readonly isOpen: boolean;
  readonly setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  readonly onCreated?: () => void;
  readonly onRemoved?: () => void;
  readonly onUpdated?: () => void;
}

export const ClassForm: React.FC<ClassFormProp> = ({
  classId: _id,
  isOpen,
  setIsOpen,
  onCreated,
  onRemoved,
  onUpdated,
}) => {
  const [courseAdd, setCourseAdd] = useState<boolean>(false);
  const [showTeacherSelector, setShowTeacherSelector] = useState<boolean>(false);
  const [teacher, setTeacher] = useState<UserDto | undefined>();
  const [members, setMembers] = useState<UserDto[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [refCourse, setRefCourse] = useState<CourseDto>();
  const [course, setCourse] = useState<CourseDto | undefined>();
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [showChildSelector, setShowChildSelector] = useState<boolean>(false);
  const [alias, setAlias] = useState<string>('');

  const dispatch = useAppDispatch();
  const userSelector = useAppSelector(selectUser);

  useEffect(() => {
    if (!isOpen) {
      setTeacher(undefined);
      setMembers([]);
      setRefCourse(undefined);
    }
  }, [isOpen]);

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      const data = await courseApi.itemById(_id as number);
      if (!data) {
        throw new Error('데이터를 불러오는데 실패하였습니다');
      }

      setRefCourse({
        ...data.refCourse,
        duration: data.duration,
        subjects: data.subjects,
        startDate: data.startDate,
      });

      if (data.members) {
        setMembers([...data.members]);
      } else {
        setMembers([]);
      }

      setTeacher(data.user);
      setCourse(data);
      setAlias(data.alias || '');
    } catch (error: any) {
    } finally {
      setLoading(false);
    }
  }, [_id]);

  useEffect(() => {
    if (isOpen && _id !== undefined) {
      fetchData();
    }
  }, [isOpen, _id, fetchData]);

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleCourseAdd = (refCourse: CourseDto) => {
    setRefCourse(refCourse);
  };

  /**
   * Teacher를 변경한다.
   * @param teacher
   */
  const handleChangeTeacher = (teacher: UserDto) => {
    setTeacher({ ...teacher });
  };

  const durationText = (duration: number) => {
    switch (duration) {
      case 0:
        return '일';
      case 1:
        return '월';
      case 2:
        return '화';
      case 3:
        return '수';
      case 4:
        return '목';
      case 5:
        return '금';
      case 6:
        return '토';
    }
  };

  const showAlert = (message: string) => {
    dispatch(
      modalOpen({
        type: 'alert',
        isOpen: true,
        message,
      }),
    );
  };

  const handleCreate = async () => {
    try {
      dispatch(loadingOpen());
      if (!alias || alias.trim().length === 0) {
        showAlert('수업의 별칭을 입력해주세요');
        return;
      }

      if (members === undefined || members.length === 0) {
        showAlert('수업에 참여할 원생을 선택해주세요');
        return;
      }
      if (teacher === undefined) {
        showAlert('선생님을 선택해주세요');
        return;
      }
      if (refCourse === undefined) {
        showAlert('커리큘럼을 선택해주세요');
        return;
      }

      await courseApi.newClass({
        org: {
          id: userSelector.user?.org?.id,
        },
        members: members,
        course: {
          ...refCourse,
          durationUnit: 'w',
          alias,
        },
        teacher: {
          id: teacher?.id,
        },
      });

      toast.info('수업을 생성하였습니다');
      if (onCreated) {
        onCreated();
      }
      setIsOpen(false);
    } catch (error: any) {
    } finally {
      dispatch(loadingClose());
    }
  };

  const handleUpdate = () => {
    dispatch(
      modalOpen({
        type: 'confirm',
        message: '수업을 수정하시겠습니까?',
        onOk: updateProc,
      }),
    );
  };
  const handleRemove = () => {
    dispatch(
      modalOpen({
        type: 'confirm',
        message: '수업을 삭제하시겠습니까?',
        onOk: removeProc,
      }),
    );
  };
  const handleReset = () => {
    if (!course) {
      return;
    }

    setRefCourse({
      ...course.refCourse,
      duration: course.duration,
      subjects: course.subjects,
      startDate: course.startDate,
    });

    if (course.members) {
      setMembers([...course.members]);
    } else {
      setMembers([]);
    }

    setTeacher(course.user);
    setIsEdit(false);
  };

  const updateProc = async () => {
    try {
      dispatch(loadingOpen());
      if (!alias || alias.trim().length === 0) {
        showAlert('수업의 별칭을 입력해주세요');
        return;
      }

      if (members === undefined || members.length === 0) {
        showAlert('수업에 참여할 원생을 선택해주세요');
        return;
      }
      if (teacher === undefined) {
        showAlert('선생님을 선택해주세요');
        return;
      }
      if (refCourse === undefined) {
        showAlert('커리큘럼을 선택해주세요');
        return;
      }

      if (!course) {
        showAlert('대상을 찾을 수가 없습니다');
        return;
      }

      const newCourse: CourseDto = {
        ...course,
        alias: alias,
        duration: refCourse.duration,
        durationUnit: 'w',
        startDate: refCourse.startDate,
        members: members,
        user: {
          id: teacher.id,
        },
      };
      console.log('members', newCourse, members);

      const result = await courseApi.updateById(course?.id as number, newCourse);

      if (!result) {
        throw new Error('수정에 실패하였습니다');
      }

      fetchData();
      setIsEdit(false);
      // TODO 업데이트 확인
      toast.info('수정이 되었습니다');
      if (onUpdated) {
        onUpdated();
      }
    } catch (error: any) {
    } finally {
      dispatch(loadingClose());
    }
  };
  const removeProc = async () => {
    try {
      if (!course) {
        throw new Error('대상을 찾을 수 없습니다');
      }

      await courseApi.removeById(course.id as number);
      toast.info('수업을 삭제하였습니다');
      if (onRemoved) {
        onRemoved();
      }
    } catch (error: any) {
      dispatch(
        modalOpen({
          type: 'alert',
          isOpen: true,
          title: '오류',
          message: error.message
            ? error.message
            : `오류가 발생하였습니다. [오류코드 ${error.status}]`,
        }),
      );
    } finally {
      setIsOpen(false);
    }
  };

  const handleClickChild = () => {
    setShowChildSelector(true);
  };

  const handleChangeChild = (members: UserDto[]) => {
    setMembers([...members]);
  };

  const handleChangeAlias = (e: any) => {
    setAlias(e.target.value);
  };

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={() => {
          return false;
        }}
        className="relative z-[1010]">
        {/* BACKDROP */}
        <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
        <div className="fixed inset-0 flex items-center justify-center min-h-full">
          <Dialog.Panel className="w-full lg:max-w-2xl xl:max-w-4xl">
            <Card
              title={
                <div className="flex justify-between">
                  <span>수업 {course ? (isEdit ? '상세정보 수정' : '상세정보') : '등록'}</span>
                  {course && !isEdit && (
                    <div>
                      <Button plain color="error" onClick={handleRemove}>
                        삭제
                      </Button>
                      <Button plain color="secondary" onClick={() => setIsEdit(true)}>
                        수정
                      </Button>
                    </div>
                  )}
                </div>
              }
              actions={
                <>
                  {course && (
                    <>
                      {/* 수정 모드가 아닐 때 */}
                      {!isEdit && (
                        <Button plain className="text-gray-500" onClick={handleClose}>
                          확인
                        </Button>
                      )}
                      {isEdit && (
                        <>
                          <Button plain className="text-gray-500" onClick={handleReset}>
                            취소
                          </Button>
                          <Button plain color="secondary" onClick={handleUpdate}>
                            수정
                          </Button>
                        </>
                      )}
                    </>
                  )}
                  {!course && (
                    <>
                      <Button plain className="text-gray-500" onClick={handleClose}>
                        취소
                      </Button>
                      <Button plain color="secondary" onClick={handleCreate}>
                        등록
                      </Button>
                    </>
                  )}
                </>
              }>
              <div className="grid grid-cols-12 gap-4 px-4 pt-4">
                {/* L */}
                <div className="col-span-6">
                  <div className="form-control mb-4">
                    <label className="label h-11">
                      <span className="label-text">수업 별칭</span>
                    </label>
                    <input
                      type="text"
                      className="input input-bordered text-sm"
                      placeholder="수업의 별칭을 입력해주세요"
                      value={alias}
                      onChange={handleChangeAlias}
                      readOnly={course && !isEdit}
                    />
                  </div>
                  <div className="form-control mb-4">
                    <label className="label h-11">
                      <span className="label-text">담당 선생님</span>
                    </label>
                    <div
                      className={`${
                        !course
                          ? 'cursor-pointer select select-bordered'
                          : !isEdit
                          ? 'cursor-default input input-bordered flex'
                          : 'cursor-pointer select select-bordered'
                      }`}
                      onClick={() => {
                        if (course && !isEdit) {
                          return;
                        }
                        setShowTeacherSelector(true);
                      }}>
                      {teacher && (
                        <div className="my-auto">
                          <div className="text-sm font-normal">{teacher.userName}</div>
                        </div>
                      )}
                      {!teacher && (
                        <span className="my-auto text-sm font-normal">선생님을 선택해주세요</span>
                      )}
                    </div>
                  </div>
                  <div className="mb-4 form-control">
                    <label className="label h-11">
                      <span className="label-text">커리큘럼</span>
                    </label>
                    <div
                      className={`${
                        !course
                          ? 'cursor-pointer select select-bordered'
                          : !isEdit
                          ? 'cursor-default input input-bordered flex'
                          : 'cursor-pointer select select-bordered'
                      }`}
                      onClick={() => {
                        if (course && !isEdit) {
                          return;
                        }
                        setCourseAdd(true);
                      }}>
                      {refCourse && (
                        <div className="my-auto">
                          <div className="text-sm font-normal">{`${refCourse.title} (${refCourse.subjects?.length}강)`}</div>
                          <div className="text-xs font-normal">
                            {`${refCourse.startDate} 부터 매주 ${durationText(
                              refCourse.duration as number,
                            )} 반복`}
                          </div>
                        </div>
                      )}
                      {!refCourse && (
                        <span className="my-auto text-sm font-normal">커리큘럼을 선택해주세요</span>
                      )}
                    </div>
                  </div>
                </div>
                {/* R */}
                <div className="col-span-6">
                  <div className="flex h-11 justify-between items-center">
                    <label className="label items-center h-11">
                      <span className="label-text">수업 참여중인 원생 목록</span>
                    </label>
                    {(!course || isEdit) && (
                      <Button
                        color="primary"
                        className="hover:!text-white"
                        outlined
                        size="sm"
                        onClick={handleClickChild}>
                        <span className="font-normal text-xs">
                          {isEdit ? '참여 원생 변경' : '참여 원생 추가'}
                        </span>
                      </Button>
                    )}
                  </div>
                  <div className="relative flex flex-col overflow-y-auto border border-[hsl(0,0%,20%)] border-opacity-20 divide-y h-80 rounded-xl">
                    {loading && (
                      <div className="absolute bg-white/75 w-full h-full flex items-center justify-center">
                        <TailSpin
                          height="64"
                          width="64"
                          color="#ff9e18"
                          ariaLabel="tail-spin-loading"
                          radius="1"
                          visible={true}
                        />
                      </div>
                    )}
                    {members.length === 0 && (
                      <p className="my-auto text-sm text-center">반을 선택해주세요</p>
                    )}
                    {members.map((user: UserDto, index: number) => {
                      return (
                        <ChildItem
                          id={user.id as number}
                          name={user.userName as string}
                          profile={user.photo?.path as string}
                          classTitle={
                            (user?.class && user?.class.length > 0 && user.class[0].title) || ''
                          }
                          key={index}
                        />
                      );
                    })}
                  </div>
                </div>
              </div>
            </Card>
          </Dialog.Panel>
        </div>
      </Dialog>
      <CourseAdd
        refCourse={refCourse}
        isOpen={courseAdd}
        setIsOpen={setCourseAdd}
        onAdd={handleCourseAdd}
        disabledCourse={course ? true : false}
      />
      <TeacherSelector
        value={teacher}
        isOpen={showTeacherSelector}
        setIsOpen={setShowTeacherSelector}
        onChange={handleChangeTeacher}
      />
      <ChildSelector
        members={members}
        onChange={handleChangeChild}
        isOpen={showChildSelector}
        setIsOpen={setShowChildSelector}
        isEdit={isEdit}
      />
    </>
  );
};
