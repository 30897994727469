import React, { useCallback, useEffect, useState } from 'react';
// import { useNavigate } from 'react-router-dom';
import { TailSpin } from 'react-loader-spinner';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Dialog } from '@headlessui/react';

import * as userApi from '@api/user';
import { PhotoDto, UserDto } from '@dto';

import { useAppDispatch } from '@store/hook';
import { open as modalOpen } from '@store/modal';

import { Button, Card, Profile } from '@components';
import { toast } from 'react-toastify';
import { TelInput } from '@components/common/TelInput';
import { DatePicker } from '@components/DatePicker';
import { format } from 'date-fns';

interface TeacherDetailProp {
  id?: number;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onUpdated: () => void;
  onRemoved: () => void;
}

export const TeacherDetail: React.FC<TeacherDetailProp> = ({
  id,
  isOpen,
  setIsOpen,
  onUpdated,
  onRemoved,
}: TeacherDetailProp) => {
  // const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [user, setUser] = useState<UserDto>();
  const [photo, setPhoto] = useState<PhotoDto>();

  const scheme = yup.object().shape({
    email: yup.string().email('올바른 이메일 형식이 아닙니다.').required('이메일을 입력해주세요.'),
    userName: yup.string().required('이름을 입력해주세요.'),
    telno: yup
      .string()
      .required('전화번호를 입력해주세요.')
      .matches(/^01([0|1|6|7|8|9])-([0-9]{3,4})-([0-9]{4})$/, '전화번호 양식에 맞게 입력해주세요.'),
    birthDay: yup.date().nullable(false).required('날짜를 선택해주세요'),
  });

  const {
    register,
    handleSubmit: onSubmit,
    formState: { errors },
    reset,
    setValue,
    control,
  } = useForm({
    defaultValues: {
      userName: '',
      email: '',
      telno: '',
      birthDay: '',
    },
    resolver: yupResolver(scheme),
  });

  /**
   * 데이터를 가져온다
   */
  const fetchData = useCallback(async () => {
    try {
      setIsLoading(true);
      if (!id) {
        throw new Error('찾고자 하는 KEY값이 존재하지 않습니다.');
      }
      const data: UserDto = await userApi.itemById(id);

      if (!data) {
        throw new Error('데이터를 찾지 못했습니다.');
      }

      setUser((user) => Object.assign({}, user, data));
      setValue('userName', data.userName || '');
      setValue('email', data.email || '');
      setValue('telno', data.telno || '');
      setValue('birthDay', data.birthDay || '');
      setPhoto(() => data?.photo);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (id && isOpen) {
      fetchData();
    }
  }, [id, isOpen, fetchData]);

  /**
   * 업데이트를 처리한다.
   * @param data - 전달할 데이터
   * @param callback - 완료 후 처리해야 할 콜백 함수
   */
  const updateProc = async (data: UserDto, callback: () => void) => {
    try {
      setIsLoading(true);
      if (!data.id) {
        throw new Error('사용자 ID가 없습니다.');
      }

      await userApi.updateById(data.id, data);
      setIsEdit(false);
      callback();
    } catch (error: any) {
      dispatch(
        modalOpen({
          type: 'alert',
          isOpen: true,
          message: `오류가 발생하였습니다 ${error.message}`,
        }),
      );
      setIsEdit(false);
    } finally {
      setIsLoading(false);
    }
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  /**
   * 삭제 이벤트 핸들러
   */
  const handleRemove = () => {
    dispatch(
      modalOpen({
        type: 'confirm',
        isOpen: true,
        title: '삭제',
        message: `${user?.userName}을 삭제하시겠습니까?`,
        onOk: removeProc,
      }),
    );
  };

  /**
   * 삭제 처리
   */
  const removeProc = async () => {
    try {
      setIsLoading(true);
      if (!user?.id) {
        throw new Error('사용자 ID가 없습니다.');
      }

      await userApi.removeUser(user?.id as number);
      setIsOpen(false);
      onRemoved();
      toast.info('선생님을 삭제하였습니다');
    } catch (error: any) {
      dispatch(
        modalOpen({
          type: 'alert',
          isOpen: true,
          message: `오류가 발생하였습니다 ${error.message}`,
        }),
      );
      setIsEdit(false);
    } finally {
      setIsLoading(false);
    }
  };

  const handleReset = () => {
    reset({
      userName: user?.userName,
      email: user?.email,
      telno: user?.telno,
      birthDay: user?.birthDay,
    });
    setPhoto(user?.photo);
    setIsEdit(false);
  };

  /**
   * 수정 처리
   * @param data
   */
  const handleSubmit = (data: any) => {
    dispatch(
      modalOpen({
        type: 'confirm',
        isOpen: true,
        title: '수정',
        message: `${user?.userName}을 수정하시겠습니까?`,
        onOk: () => {
          const d: UserDto = Object.assign({}, user, data);

          if (photo) {
            d.photo = {
              id: photo.id,
            };
          }

          if (d.birthDay) {
            d.birthDay = format(new Date(d.birthDay), 'y-MM-dd');
          }

          updateProc(d, async () => {
            fetchData();
            onUpdated();
            toast.info(`수정이 되었습니다`);
            handleReset();
          });
        },
      }),
    );
  };

  return (
    <Dialog
      className="relative z-[1001]"
      open={isOpen}
      onClose={() => {
        return false;
      }}>
      <div className="fixed inset-0 bg-black/75" aria-hidden="true" />
      {/* container */}
      <div className="fixed inset-0 flex items-center justify-center">
        <Dialog.Panel className="w-full md:max-w-md bg-white rounded-lg">
          <form className="w-full h-full" onSubmit={onSubmit(handleSubmit)}>
            <Card
              title={
                <div className="flex items-center justify-between">
                  <div>상세정보</div>
                  {!isEdit && (
                    <div>
                      <Button color="error" plain onClick={handleRemove}>
                        삭제
                      </Button>
                      <Button color="secondary" plain onClick={() => setIsEdit(true)}>
                        수정
                      </Button>
                    </div>
                  )}
                </div>
              }
              actions={
                <>
                  {!isEdit && (
                    <Button plain color="secondary" onClick={handleClose}>
                      확인
                    </Button>
                  )}
                  {isEdit && (
                    <>
                      <Button plain onClick={handleReset}>
                        취소
                      </Button>
                      <Button type="submit" plain color="secondary">
                        수정
                      </Button>
                    </>
                  )}
                </>
              }>
              <div className="w-full relative">
                {isLoading && (
                  <div className="w-full h-full absolute bg-white flex items-center justify-center">
                    <TailSpin
                      height="80"
                      width="80"
                      color="#ff9e18"
                      ariaLabel="tail-spin-loading"
                      radius="1"
                      visible={true}
                    />
                  </div>
                )}
                <Profile photo={photo} onChange={(photo) => setPhoto(photo)} isEdit={isEdit} />
                <div className="form-control mb-4">
                  <label className="label">
                    <span className={`label-text ${errors.userName && 'text-error'}`}>이름</span>
                  </label>
                  <input
                    id="name"
                    className={`input input-bordered text-sm ${errors.userName && 'input-error'}`}
                    disabled={!isEdit}
                    {...register('userName')}
                  />
                  <span className="mt-1 text-xs text-error">
                    {errors.userName && errors.userName.message}
                  </span>
                </div>
                <div className="form-control mb-4">
                  <label htmlFor="email" className="label">
                    <span className="label-text">이메일</span>
                  </label>
                  <input
                    id="email"
                    className="input input-bordered text-sm"
                    disabled
                    {...register('email')}
                  />
                </div>
                <div className="form-control mb-4">
                  <label htmlFor="telno" className="label">
                    <span className={`label-text ${errors.telno && 'text-error'}`}>전화번호</span>
                  </label>
                  <TelInput
                    register={register('telno')}
                    className={`${errors.telno && 'input-error'}`}
                    disabled={!isEdit}
                  />
                  <span className="mt-1 text-xs text-error">
                    {errors.telno && errors.telno.message}
                  </span>
                </div>
                <div className="form-control">
                  <label htmlFor="birthDay" className="label">
                    <span className={`label-text ${errors.birthDay && 'text-error'}`}>생일</span>
                  </label>
                  <DatePicker
                    control={control}
                    name={'birthDay'}
                    className={`${errors.birthDay && 'input-error'}`}
                    disabled={!isEdit}
                    maxDate={new Date()}
                  />
                  <span className="mt-1 text-xs text-error">
                    {errors.birthDay && errors.birthDay.message}
                  </span>
                </div>
              </div>
            </Card>
          </form>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
};
