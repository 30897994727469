import React, { ReactNode, useState, useEffect, useCallback } from 'react';
import { format, isToday } from 'date-fns';

import { ChildTabs } from '@components/parent';
import { Card, CardBody, CardTitle, CardSubTitle, Carousel, RatingGraph } from '@components/common';
import { CourseSelect } from '@components';

import * as dashApi from '@api/dash';
import * as subjectApi from '@api/subject';

import { NoticeList } from '@components/NoticeList';
import { AttendDto, CourseDto, UserDto, SubjectDto } from '@dto';
import { SurveyList } from '../../SurveyList';
import { FileUser } from '@dto/DashDto';
import { useAppDispatch, useAppSelector } from '@store/hook';
import { open as modalOpen } from '@store/modal';
import { selectUser } from '@store/user';
import { TailSpin } from 'react-loader-spinner';
import { useNavigate } from 'react-router-dom';
import { EvalSumDto } from '@dto/EvalSumDto';
import { SubjectSubmitEvalItemDto } from '@dto/SubjectSubmitEvalItemDto';
import { SubjectSubmitDto } from '@dto/SubjectSubmitDto';
import { groupBy } from 'lodash';

interface ICardDescProps {
  readonly children: ReactNode;
  readonly className?: string;
}

const CardDesc = ({ children, className }: ICardDescProps) => {
  return <div className={`text-3xl font-semibold my-2 leading-10 ${className}`}>{children}</div>;
};

interface IUnitProps {
  readonly label?: string;
  readonly className?: string;
}

const Unit = ({ label, className }: IUnitProps) => {
  return <span className={`text-sm font-normal ml-1 ${className}`}>{label}</span>;
};

export interface UserData extends UserDto {
  course: CourseDto[];
  files: FileUser[];
  subject?: SubjectDto;
  courses?: CourseDto[];
}

export const Dashboard = () => {
  const [childId, setChildId] = useState<number>();
  const [childs, setChilds] = useState<UserData[]>();
  const [files, setFiles] = useState<FileUser[]>();
  const [totalAttend, setTotalAttend] = useState<number>(0);
  const [countYAttend, setCountYAttend] = useState<number>(0);
  const [attendLoading, setAttendLoading] = useState<boolean>(false);
  const [ratingLoading, setRatingLoading] = useState<boolean>(false);
  const [subject, setSubject] = useState<SubjectDto>();
  const [courseId, setCourseId] = useState<number | undefined>();
  const [submitVals, setSubmitVals] = useState<any>([]);
  const [evalAvgMaxValue, setEvalAvgMaxValue] = useState<number | 'auto' | undefined>('auto');

  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const { user } = useAppSelector(selectUser);

  /**
   * USER를 검사하고, child가 있는지 여부를 판단한다
   */
  useEffect(
    () => {
      try {
        if (!user) {
          return;
        }

        if (!user.childs || user.childs.length === 0) {
          throw new Error('등록된 자녀가 없습니다. 원장 선생님께 문의하세요.');
        }

        setChildId(user.childs[0].id);
      } catch (error: any) {
        dispatch(
          modalOpen({
            type: 'alert',
            isOpen: true,
            title: '오류',
            message: error.message
              ? error.message
              : `오류가 발생하였습니다. [오류코드 ${error.status}]`,
            onOk () {
              navigate('/signin');
            },
          }),
        );
      }
    },
    // eslint-disable-next-line
    [user],
  );

  const fetchData = useCallback(async () => {
    try {
      if (!user) {
        return;
      }

      const response: any = await dashApi.dash();
      // setInfo(response.result);
      const users = response.result.childs;
      const files = response.result.albums;
      const courseList = response.result.courseList;
      users.forEach((u: UserData) => {
        const ff = files.filter((f: FileUser) => {
          return f.users.find((u1: UserDto) => {
            return u1.id === u.id;
          });
        });
        u.files = ff;

        const courses: CourseDto[] = courseList.filter((cl: any) =>
          cl.members.find((m: any) => m.id === u.id),
        );

        // .find((cl: any) => cl.members.find((m: any) => m.id === u.id))
        // .classCourse.map((cc: any) => courseList.find((cl: any) => cl.id === cc.id));

        const ss: SubjectDto[] = courses.map((co: any) => co.subjects);

        const subjects: SubjectDto[] = [];
        const newSubjects: SubjectDto[] = [...subjects]
          .concat(...ss)
          .filter((v: SubjectDto) => isToday(new Date(v.startDate as string)));

        // 당일 대표 1가지만 보여준다.
        const newSubject: SubjectDto = newSubjects[0] || undefined;
        u.subject = newSubject;
        u.courses = courses.sort((a: CourseDto, b: CourseDto) => (b.id || 0) - (a.id || 0));
      });
      setChilds(users);
      if (users?.length > 0) {
        const userData: UserData = users[0];

        setFiles(userData.files);
        setSubject(userData.subject);

        if (userData.courses) {
          setCourseId(userData.courses[0].id);
        }
      }
    } catch (error) {
      console.log('error in Dashboard parent', error);
    } finally {
    }
  }, [user]);

  /**
   * attend 데이터를 가져온다
   * (아이에 따른...)
   */
  const fetchAttend = useCallback(
    async () => {
      try {
        if (!childId) {
          return;
        }

        setAttendLoading(true);

        const { data } = await subjectApi.attendList({
          page: 1,
          limit: 9999,
          search: {
            user: {
              id: childId,
            },
          },
        });

        const countY: number | undefined = [...data].filter(
          (value: AttendDto) => value.check === 'Y',
        ).length;

        setTotalAttend(data.length);
        setCountYAttend(countY);
      } catch (error: any) {
        dispatch(
          modalOpen({
            type: 'alert',
            isOpen: true,
            title: '오류',
            message: error.message
              ? error.message
              : `오류가 발생하였습니다. [오류코드 ${error.status}]`,
          }),
        );
      } finally {
        setAttendLoading(false);
      }
    },
    // eslint-disable-next-line
    [childId],
  );

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    fetchAttend();
  }, [fetchAttend]);

  const onChangeChild = (id: number | undefined) => {
    setChildId(id as number);
    const c = childs?.find((a) => {
      return a.id === id;
    });
    if (c) {
      console.log('files', c.files);
      setFiles(c.files);
      setSubject(c.subject);
      setSubmitVals([]);
      if (c.courses && c.courses.length > 0) {
        setCourseId(c.courses[0].id as number);
      }
    }
  };

  const handleSelectCourse = async (
    id: number,
    members?: UserDto[],
    subjects?: any,
    evalSums?: EvalSumDto[],
  ) => {
    setCourseId(id);
    try {
      if (id > 0 && childId) {
        setRatingLoading(true);
        const { data } = await subjectApi.submitList({
          page: 1,
          limit: subjects.length,
          search: {
            subject: {
              course: {
                id,
              },
            },
            targetUser: {
              id: childId,
            },
          },
        });
        if (data.length > 0) {
          const r: SubjectSubmitEvalItemDto[][] = data.map((subjectSubmit: SubjectSubmitDto) => [
            ...subjectSubmit.evalSubmits,
          ]);
          const submitEvalList: SubjectSubmitEvalItemDto[] = (
            [] as SubjectSubmitEvalItemDto[]
          ).concat(...r);

          const maxValues: number[] | undefined = submitEvalList.map(
            (v: SubjectSubmitEvalItemDto) => v.eval.evalMax || 1,
          );

          if (maxValues) {
            const maxValue: number = Math.max(...maxValues);
            setEvalAvgMaxValue(maxValue);
          } else {
            setEvalAvgMaxValue('auto');
          }

          const evalItems: any = groupBy(
            submitEvalList.map((value: SubjectSubmitEvalItemDto) => ({
              submitVal: Number(value.submitVal),
              title: value.eval.title || '',
            })),
            'title',
          );

          const newList: any = [];

          for (const key in evalItems) {
            const count: number = evalItems[key].length;
            const sum: number = evalItems[key].reduce((acc: number, current: any) => {
              return acc + current.submitVal;
            }, 0);
            const avg: number = Number((sum / count).toFixed(1));
            newList.push({
              평가항목: key,
              평균점수: avg,
              '총 점수': sum,
              '참여 주제수': count,
            });
          }

          setSubmitVals([...newList]);
        } else {
          setSubmitVals([]);
        }
      }
    } catch (error: any) {
      setSubmitVals([]);
    } finally {
      setRatingLoading(false);
    }
  };

  return (
    <>
      <div className="grid grid-cols-12 gap-4">
        <div className="col-span-12">
          {user && <ChildTabs children={user.childs as UserDto[]} onChange={onChangeChild} />}
        </div>
        <div className="col-span-12 lg:col-span-4 xl:col-span-3">
          <Card>
            <CardBody className="relative">
              {attendLoading && (
                <div className="absolute w-full h-full flex items-center justify-center -ml-8 -mt-4 bg-white/50">
                  <TailSpin
                    height="32"
                    width="32"
                    color="#ff9e18"
                    ariaLabel="tail-spin-loading"
                    radius="1"
                    visible={true}
                  />
                </div>
              )}
              <CardTitle>출석정보</CardTitle>
              <CardSubTitle>{format(new Date(), 'yyyy년 MM월 dd일')}까지</CardSubTitle>
              <CardDesc className="ml-auto">
                {countYAttend}
                <Unit label="/" />
                <Unit label={`${totalAttend}회`} className="opacity-80" />
              </CardDesc>
            </CardBody>
          </Card>
        </div>
        <div className="col-span-12 lg:col-span-8 xl:col-span-9">
          <Card>
            <CardBody>
              <CardTitle>
                <span>오늘의 커리큘럼</span>
                {/* <button className="btn btn-xs btn-secondary rounded-full font-light text-white ml-auto hover:ring-2 hover:ring-offset-2 focus:ring-2 focus:ring-offset-2">
                  상세보기
                </button> */}
              </CardTitle>
              <CardSubTitle>{format(new Date(), 'yyyy년 MM월 dd일')}</CardSubTitle>
              <CardDesc className="h-10">
                <>
                  {subject && <span>{subject?.title}</span>}
                  {!subject && (
                    <div className="font-normal w-full h-full text-sm text-gray-500 flex items-center">
                      오늘 참여하는 커리큘럼은 없습니다
                    </div>
                  )}
                </>
              </CardDesc>
            </CardBody>
          </Card>
        </div>
        <div className="col-span-12 lg:col-span-6">
          <Card>
            <CardBody className="relative">
              {ratingLoading && (
                <div className="absolute w-full h-full flex items-center justify-center -ml-8 -mt-4 bg-white/50">
                  <TailSpin
                    height="48"
                    width="48"
                    color="#ff9e18"
                    ariaLabel="tail-spin-loading"
                    radius="1"
                    visible={true}
                  />
                </div>
              )}
              <CardTitle>종합평가</CardTitle>
              {
                <div className="h-96 my-2 flex flex-col">
                  <CourseSelect
                    hideLabel
                    value={courseId}
                    onChange={handleSelectCourse}
                    isParent
                    childId={childId}
                    className="lg:w-1/2"
                    selectClassName="min-h-[2.25rem] h-[2.25rem] max-h-[2.25rem]"
                  />
                  {submitVals.length === 0 && (
                    <p className="text-sm text-gray-500 my-auto text-center">
                      평가 내용이 없습니다
                    </p>
                  )}
                  {submitVals.length > 0 && (
                    <RatingGraph
                      data={submitVals}
                      keys={['평균점수']}
                      indexBy={'평가항목'}
                      maxValue={evalAvgMaxValue}
                    />
                  )}
                </div>
              }
            </CardBody>
          </Card>
        </div>
        <div className="col-span-12 lg:col-span-6">
          <Card>
            <CardBody>
              <CardTitle>활동사진</CardTitle>
              <div className="h-96 my-2 flex flex-col">
                {files && files.length > 0 && <Carousel images={files ? files : []} />}
                {(!files || files.length === 0) && (
                  <p className="text-sm text-gray-500 my-auto text-center">활동사진이 없습니다</p>
                )}
              </div>
            </CardBody>
          </Card>
        </div>
        <div className="col-span-12 lg:col-span-6">
          <Card className="h-[361px]">
            <CardBody>
              <CardTitle>공지사항</CardTitle>
              <NoticeList header={false} rows={5} pagination={false} />
            </CardBody>
          </Card>
        </div>
        <div className="col-span-12 lg:col-span-6">
          <Card className="h-[361px]">
            <CardBody>
              <CardTitle>설문조사</CardTitle>
              <SurveyList header={false} pagination={false} rows={5} useDashboard />
            </CardBody>
          </Card>
        </div>
      </div>
    </>
  );
};
