import { format } from 'date-fns';
import { FC } from 'react';
import ReactDatePicker from 'react-date-picker';
import { Controller } from 'react-hook-form';

type DatePickerProp = {
  control?: any;
  name: string;
  className?: string;
  calendarClassName?: string;
  minDate?: Date;
  maxDate?: Date;
  disabled?: boolean;
};

export const DatePicker: FC<DatePickerProp> = ({
  control,
  name,
  className: _className = '',
  calendarClassName: _calendarClassName = '',
  minDate,
  maxDate,
  disabled = false,
}) => {
  const dateFormat: string = 'y-MM-dd';
  const className: string = [
    'input',
    'input-bordered',
    'text-sm',
    'flex',
    'items-center',
    _className,
  ].join(' ');
  const calendarClassName: string = [
    'text-sm',
    'rounded-lg',
    'bg-white',
    'shadow-lg',
    'border-0',
    _calendarClassName,
  ].join(' ');

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value } }) => (
        <ReactDatePicker
          className={className}
          calendarClassName={calendarClassName}
          format={dateFormat}
          monthAriaLabel="Month"
          calendarAriaLabel="Toggle calendar"
          clearAriaLabel="Clear value"
          yearAriaLabel="Year"
          nativeInputAriaLabel="Date"
          minDate={minDate}
          maxDate={maxDate}
          locale={'ko'}
          openCalendarOnFocus={false}
          calendarType={'US'}
          value={value ? new Date(value) : null}
          formatDay={(locale: any, date: Date) => format(date, 'd')}
          onChange={(date: Date | null) => {
            console.log('EEEEE', date);
            onChange(format(date || new Date(), dateFormat));
          }}
          disabled={disabled}
        />
      )}
    />
  );
};
