import axiosInstance from '@api';
import { ConfirmDto } from '@dto/ConfirmDto';
import { InviteDto } from '@dto/InviteDto';
import { LoginDto } from '@dto/LoginDto';
import { TokenDto } from '@dto/TokenDto';
import { UserDto } from '@dto/UserDto';

/**
 * 로그인을 한다
 * @param data
 * @returns
 */
export const login = async (data: LoginDto) => {
  const response: any = await axiosInstance.post('/auth/email/login', data);
  return response.result as { user: UserDto; token: TokenDto };
};

/**
 * 로그아웃을 한다
 * @returns
 */
export const logout = async () => {
  const response = await axiosInstance.post('/auth/email/logout');
  return response;
};

/**
 * 자기 자신의 정보를 가져온다.
 */
export const me = async () => {
  const response: any = await axiosInstance.get('/auth/me');
  return response.result as UserDto;
};

/**
 * 비밀번호 분실, 이메일 발송
 * @param email
 */
export const forgotPassword = async (email: string) => {
  const response = await axiosInstance.post('/auth/forgot/password', { email });
  return response;
};

/**
 * 비밀번호 변경
 * @param id
 * @param newpassword
 * @param oldpassword
 * @returns
 */
export const changePassword = async (id: number, newpassword: string, oldpassword: string) => {
  const response = await axiosInstance.post('/auth/changepasswd', {
    id,
    newpassword,
    oldpassword,
  });

  return response;
};

/**
 * 이메일 존재 여부 확인
 * @param email
 * @returns
 */
export const existEmail = (
  email: string,
): Promise<{
  result: {
    code: number;
    user?: UserDto;
  };
}> => axiosInstance.get(`/auth/email/existInOrg/${email}`);

/**
 * 초대
 * @param body
 * @returns
 */
export const invite = (body: InviteDto) => axiosInstance.post('/auth/invite', body);

export const inviteByOrg = (email: string): Promise<any> =>
  axiosInstance.post('/auth/inviteByOrg', { email });

/**
 * 초대 재전송
 * @param email
 * @returns
 */
export const resend = (email: string) => axiosInstance.post('/auth/email/resend', { email });

export const confirmInfo = (hash: string): Promise<{ status: number; result: UserDto }> =>
  axiosInstance.get(`/auth/email/confirminfo/${hash}`);

export const emailConfirm = (data: ConfirmDto): Promise<any> =>
  axiosInstance.post('/auth/email/confirm', data);

/**
 * 비밀번호 변경 (이메일을 통한)
 * @param hash
 * @param password
 * @returns
 */
export const resetPassword = (hash: string, password: string): Promise<any> =>
  axiosInstance.post('/auth/reset/password', { hash, password });

/**
 * ORG를 변경한다
 * @param orgId
 * @returns
 */
export const changeOrg = (orgId: number, roleId: number): Promise<{
  status: number, result: {
    user: UserDto,
    token: TokenDto
  }
}> =>
  axiosInstance.get(`/auth/changeOrg/${orgId}/${roleId}`);