import React, { useEffect, useState } from 'react';
import {
  EllipsisVerticalIcon,
  UserCircleIcon,
  XMarkIcon,
  PlusIcon,
  PencilIcon,
  ArrowPathIcon,
} from '@heroicons/react/20/solid';
import { ReactSortable } from 'react-sortablejs';
import { Menu } from '@headlessui/react';
import { OrgDto, OrgUserDto, PhotoDto } from '@dto';

import * as orgApi from '@api/org';

import { useAppDispatch } from '@store/hook';
import { open as modalOpen } from '@store/modal';

interface ClassListProp extends OrgDto {
  /**
   * 신규 생성인지 확인 하는 플래그
   */
  isNew?: boolean;
  onClickChild?: (id: number) => void;
  onClickAdd?: (id: number) => void;
  onRemoved: (id: number) => void;
  onMoveEnd: (orgUserDto: OrgUserDto) => void;
  onLeave: (orgUserDto: OrgUserDto) => void;
}

interface ClassItemProp {
  id: number;
  name: string;
  photo?: PhotoDto | undefined;
  srcOrg?: OrgDto | undefined;
  onClick: (id: number) => void;
  onLeaveClick: (id: number) => void;
}

/**
 * 반에 속하는 아이템
 * @param
 * @returns
 */
export const ClassItem = ({ id, name, photo, srcOrg, onClick, onLeaveClick }: ClassItemProp) => {
  const handleClick = () => {
    onClick(id);
  };

  const handleLeaveClick = (e: any) => {
    e.stopPropagation();
    onLeaveClick(id);
  };

  return (
    <div
      className="classItem group w-full p-2 bg-white rounded shadow flex items-center cursor-pointer h-12 mb-2 hover:outline hover:outline-primary"
      id={`${id}`}
      data-src-org={srcOrg?.id}
      onClick={handleClick}>
      <span className="handle"></span>
      <div className="w-8 h-8 fill-gray-400 mr-1 rounded-full flex items-center justify-center">
        {photo && (
          <img src={photo.path} alt={'profile'} className="w-[25.6px] h-[25.6px] rounded-full" />
        )}
        {!photo && <UserCircleIcon className="w-8 h-8 fill-gray-400" />}
      </div>
      <div className="text-sm">{name}</div>
      <button className="ml-auto hidden group-hover:block" onClick={handleLeaveClick}>
        <XMarkIcon className="w-6 h-6 fill-gray-400" />
      </button>
    </div>
  );
};

export const ClassList: React.FC<ClassListProp> = ({
  id,
  title,
  members,
  onClickChild,
  onClickAdd,
  onRemoved,
  onMoveEnd,
  onLeave,
}) => {
  const [list, setList] = useState<any[]>([]);
  const [orgName, setOrgName] = useState<string>('');
  const [editName, setEditName] = useState<boolean>(true);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (members) {
      setList([...members]);
    }
  }, [members]);

  useEffect(() => {
    if (title) {
      setOrgName(title);
      setEditName(false);
    }
  }, [title]);

  /**
   * orgName을 업데이트 처리한다.
   */
  const handleUpdateOrgName = async () => {
    try {
      const result = await orgApi.updateById(Object.assign({ id }, { title: orgName }));
      console.log(result);
      setOrgName(result.title || '');
    } catch (error: any) {
    } finally {
    }
    setEditName(false);
  };

  const handleAdd = () => {
    if (onClickAdd) {
      onClickAdd(id as number);
    }
  };

  const handleItemClick = (id: number) => {
    console.log('item click', id);
    if (onClickChild) {
      onClickChild(id);
    }
  };

  const handleEditName = () => {
    // 업데이트 폼을 보여준다
    setEditName(true);
  };

  const handleRemove = () => {
    dispatch(
      modalOpen({
        type: 'confirm',
        isOpen: true,
        message: `해당 반을 삭제하시겠습니까?`,
        onOk: async () => {
          try {
            if (!id || id < 1) {
              throw new Error('대상 ID가 존재하지 않습니다');
            }

            const { count } = await orgApi.remove(id);

            if (count < 1) {
              throw new Error('삭제에 실패하였습니다');
            }

            onRemoved(id);
          } catch (error: any) {
            dispatch(
              modalOpen({
                type: 'alert',
                isOpen: true,
                title: '오류',
                message: error.message
                  ? error.message
                  : `오류가 발생하였습니다. [오류코드 ${error.status}]`,
              }),
            );
          }
        },
      }),
    );
  };

  const handleDragEnd = (evt: any) => {
    const toOrgId: number = Number(evt.to.id.slice(4));
    const fromOrgId: number = Number(evt.from.id.slice(4));

    if (toOrgId === fromOrgId) {
      return;
    }

    const targetItemId: number = Number(evt.item.id);
    const srcOrgId: number = Number(evt.item.dataset.srcOrg);
    const orgUserDto: OrgUserDto = {
      org: {
        id: toOrgId,
      },
      users: [{ id: targetItemId, srcOrg: { id: srcOrgId } }],
    };
    onMoveEnd(orgUserDto);
    console.log(list, id);
  };

  const handleLeaveClick = (userId: number) => {
    dispatch(
      modalOpen({
        type: 'confirm',
        isOpen: true,
        message: `해당 원생을 반에서 떠나게 하시겠습니까?`,
        onOk: () => {
          const orgUserDto: OrgUserDto = {
            org: {
              id,
            },
            users: [{ id: userId }],
          };
          onLeave(orgUserDto);
        },
      }),
    );
  };

  return (
    <div className="h-full">
      <div className="inline-block box-border bg-gray-200 border border-gray-300 rounded-md w-80 whitespace-nowrap">
        {/* HEADER */}
        <div className="text-sm flex items-center px-4 h-14">
          {!editName && (
            <>
              <div className="w-64 mr-1">
                <h2 className="text-sm font-medium min-h-[24px] mt-1 break-words truncate">
                  {orgName}
                </h2>
                <div className="text-sm text-gray-500 min-h-[24px] -mt-1">
                  {/* {teachers?.map((value) => value.userName).join(', ')} */}
                </div>
              </div>
              <Menu as="div" className="relative inline-block text-left">
                <Menu.Button className="rounded-full p-2 border border-transparent hover:bg-black/10">
                  <EllipsisVerticalIcon className="w-4 h-5 fill-gray-500" />
                </Menu.Button>
                <Menu.Items className="absolute right-0 mt-2 w-56 origin-top-right divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none p-2">
                  <Menu.Item
                    as="div"
                    className="flex h-9 rounded group cursor-pointer items-center hover:bg-primary"
                    onClick={handleEditName}>
                    <span className="text-sm group-hover:text-white px-2">반 수정</span>
                  </Menu.Item>
                  {(!members || members.length < 1) && (
                    <Menu.Item
                      as="div"
                      className="flex h-9 rounded group cursor-pointer items-center hover:bg-primary"
                      onClick={handleRemove}>
                      <span className="text-sm group-hover:text-white px-2">반 삭제</span>
                    </Menu.Item>
                  )}
                </Menu.Items>
              </Menu>
            </>
          )}
          {editName && (
            <>
              <input
                type="text"
                className="w-full min-h-[24px] py-2 rounded border border-secondary text-sm font-medium mr-1 focus-visible:outline-secondary"
                value={orgName}
                onChange={(e) => setOrgName(e.target.value)}
              />
              <button
                className="inline-flex bg-primary p-2 min-h-[24px] items-center rounded mr-1 border border-primary hover:bg-opacity-75"
                onClick={handleUpdateOrgName}>
                <PencilIcon className="w-4 h-5 fill-white" />
              </button>
              <button
                className="group inline-flex border border-error p-2 min-h-[24px] items-center rounded hover:bg-error"
                onClick={() => {}}>
                <ArrowPathIcon className="w-4 h-5 fill-error group-hover:fill-white" />
              </button>
            </>
          )}
        </div>
        <ReactSortable
          swap={list.length > 0}
          group="children"
          list={list}
          setList={setList}
          swapClass="bg-gray-200 rounded"
          fallbackTolerance={3}
          animation={150}
          scroll
          scrollSensitivity={100}
          scrollSpeed={100}
          draggable={'.classItem'}
          className="p-2 space-y-2 overflow-x-hidden overflow-y-auto max-h-[calc(100vh-365px)] min-h-12"
          onEnd={handleDragEnd}
          id={`org-${id}`}>
          <>
            {list.length === 0 && (
              <div className="w-full h-12 outline outline-dashed rounded-md outline-primary outline-2 flex items-center justify-center bg-white placeholder">
                <p className="text-sm text-primary">원생을 드래그 이동해주세요</p>
              </div>
            )}
            {list.map((value) => {
              return (
                <ClassItem
                  key={value.id}
                  id={value.id as number}
                  name={value.userName as string}
                  photo={value.photo as PhotoDto}
                  srcOrg={value.srcOrg as OrgDto}
                  onClick={handleItemClick}
                  onLeaveClick={handleLeaveClick}
                />
              );
            })}
          </>
        </ReactSortable>
        {/* CONTENTS */}
        <div className="text-sm flex items-center px-4">
          <button className="inline-flex w-full text-sm h-10 items-center" onClick={handleAdd}>
            <PlusIcon className="w-4 h-4 fill-gray-500 mr-1" />
            원생 추가
          </button>
        </div>
      </div>
    </div>
  );
};
