import React, { useCallback, useEffect, useState } from 'react';
import { Pagination } from '@components/Pagination';
import { NoticeDetail } from '@components/NoticeDetail';
import { NoticeItem } from '@components/NoticeItem';
import { SearchDto } from '../../dto';
import { format } from 'date-fns';
import * as noticeApi from '@api/notice';
import { NoticeDto } from '../../dto/NoticeDto';
// import {
//   ChevronLeftIcon,
//   ChevronDoubleLeftIcon,
//   ChevronRightIcon,
//   ChevronDoubleRightIcon,
// } from '@heroicons/react/20/solid';

interface NoticeListProp {
  readonly header?: boolean;
  readonly rows?: number;
  readonly pagination?: boolean;
}

export const NoticeList: React.FC<NoticeListProp> = ({
  header = true,
  rows = 10,
  pagination = true,
}: NoticeListProp) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [id, setId] = useState<number>(-1);

  const [totalCount, setTotalCount] = useState<number>(0);
  const [list, setList] = useState<NoticeDto[]>([]);
  const [activePage, setActivePage] = useState<number>(1);

  const handleRowClick = (id: number) => {
    setId(id);
    setIsOpen(true);
  };

  const fetchData = useCallback(
    async () => {
      try {
        const search: SearchDto = {
          page: activePage,
          limit: rows,
          search: {},
        };

        const response: any = await noticeApi.list(search);

        setTotalCount(response.count);
        setList([...response.data]);
      } catch (error) {
      } finally {
      }
    },
    // eslint-disable-next-line
    [activePage, rows],
  );

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handlePageChange = (pageNumber: number) => {
    setActivePage(pageNumber);
  };

  return (
    <>
      <table className="table w-full mb-4 border-y">
        <colgroup>
          <col width={'*'} />
          <col width={'15%'} className="hidden lg:table-column"/>
        </colgroup>
        {header && (
          <thead className="hidden lg:table-header-group">
            <tr>
              <th className="h-12 text-sm text-black/60 border-b bg-slate-50 font-medium lg:indent-12">
                제목
              </th>
              <th className="h-12 text-sm text-black/60 border-b bg-slate-50 font-medium lg:indent-12 ">
                작성날짜
              </th>
            </tr>
          </thead>
        )}
        <tbody className="bg-white">
          {list.length === 0 && (
            <tr>
              <td className="h-[280px] text-center text-sm text-gray-500" colSpan={3}>
                등록된 공지사항이 없습니다
              </td>
            </tr>
          )}
          {list.map((value, index) => (
            <NoticeItem
              id={value.id || 0}
              title={value.title || ''}
              createdAt={format(new Date(value.createdAt), 'yyyy-MM-dd')}
              // createdAt={value.createdAt}
              onClick={handleRowClick}
              key={index}
              useHeader={header}
            />
          ))}
        </tbody>
      </table>
      {pagination && (
        <Pagination
          activePage={activePage}
          setActivePage={setActivePage}
          itemsCountPerPage={10}
          totalItemsCount={totalCount}
          pageRangeDisplayed={5}
          onChange={handlePageChange}
        />
      )}
      <NoticeDetail id={id} nextId={id} prevId={id} isOpen={isOpen} setIsOpen={setIsOpen} />
    </>
  );
};
