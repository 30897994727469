import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Dialog } from '@headlessui/react';
import { debounce } from 'lodash';

import * as userApi from '@api/user';

import { useAppDispatch } from '@store/hook';
import { open as modalOpen } from '@store/modal';

import { Card, Button } from '@components';
import { SearchDto, UserDto } from '@dto';
import { ROLE, USER_STATE } from '@common';
import { TailSpin } from 'react-loader-spinner';

interface ParentSelectProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  value: number | undefined;
  onChange: (id: number, userName: string) => void;
}

export const ParentSelect: React.FC<ParentSelectProps> = ({
  isOpen,
  setIsOpen,
  value: _value,
  onChange,
}) => {
  const [userName, setUserName] = useState<string>();
  const [userList, setUserList] = useState<UserDto[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [userId, setUserId] = useState<number | undefined>(_value);
  const dispatch = useAppDispatch();

  const fetchData = useCallback(
    async (userName: string | undefined) => {
      try {
        setIsLoading(true);
        const search: SearchDto = {
          page: 1,
          limit: 100,
          search: {
            status: USER_STATE.ACTIVATE,
            role: {
              id: ROLE.MEM_PARENT,
            },
          },
        };

        if (userName) {
          search.search.userName = userName;
        }

        const { data } = await userApi.list(search);

        setUserList([...data]);
      } catch (error: any) {
        dispatch(
          modalOpen({
            isOpen: true,
            type: 'alert',
            title: '오류',
            message: error.message
              ? error.message
              : `오류가 발생하였습니다 [오류코드: ${error.status}]`,
            onOk: () => {
              setIsOpen(false);
            },
          }),
        );
      } finally {
        setIsLoading(false);
      }
    },
    [setIsOpen, dispatch],
  );

  const debounceFetchData = useMemo(
    () =>
      debounce((userName: string) => {
        fetchData(userName);
      }, 200),
    [fetchData],
  );

  useEffect(() => {
    if (isOpen) {
      fetchData(userName);
    } else {
      setUserList([]);
    }
  }, [isOpen, fetchData, userName]);

  useEffect(() => {
    setUserId(_value);
  }, [_value]);

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleSelect = (id: number, userName: string) => {
    setUserId(id);
    onChange(id, userName);
    setIsOpen(false);
  };

  const handleUserName = (e: any) => {
    setUserName(e.target.value);
    debounceFetchData(e.target.value);
  };

  return (
    <Dialog
      as="div"
      className="relative z-[1010]"
      open={isOpen}
      onClose={() => {
        return false;
      }}>
      <div className="fixed inset-0 bg-black/75" aria-hidden="true" />
      <div className="fixed inset-0 overflow-y-auto">
        <div className="flex items-center justify-center min-h-full">
          <Dialog.Panel className="w-full lg:max-w-lg">
            <Card
              title={'부모님 선택'}
              actions={
                <Button plain className="font-normal" onClick={handleClose}>
                  취소
                </Button>
              }>
              <div className="pb-4 form-control">
                <input
                  type="text"
                  className="text-sm input input-bordered"
                  placeholder="검색할 부모님 이름을 입력해주세요"
                  value={userName}
                  onChange={handleUserName}
                />
              </div>
              <div className="flex flex-col overflow-y-auto border divide-y rounded-lg h-80">
                {isLoading && (
                  <TailSpin
                    height="48"
                    width="48"
                    color="#ff9e18"
                    ariaLabel="tail-spin-loading"
                    radius="1"
                    visible={true}
                    wrapperClass={'mx-auto my-auto'}
                  />
                )}
                {userList.length === 0 && (
                  <div className="m-auto text-sm text-gray-500">등록된 부모님이 없습니다</div>
                )}
                {userList.map((user: UserDto, index: number) => {
                  return (
                    <div
                      key={index}
                      className={`group min-h-[4rem] px-4 flex flex-col justify-center cursor-pointer hover:bg-primary/75 ${
                        userId === user.id && 'bg-primary/75'
                      }`}
                      onClick={() => {
                        handleSelect(user.id as number, user.userName as string);
                      }}>
                      <div
                        className={`text-sm mb-1 group-hover:text-white ${
                          userId === user.id && 'text-white'
                        }`}>
                        {user.userName}
                      </div>
                      <div
                        className={`text-xs group-hover:text-white/75 ${
                          userId === user.id && 'text-white'
                        }`}>
                        {user.email} {user.telno && `(${user.telno})`}
                      </div>
                    </div>
                  );
                })}
              </div>
            </Card>
          </Dialog.Panel>
        </div>
      </div>
    </Dialog>
  );
};
