import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Popover } from '@headlessui/react';
import { UserCircleIcon } from '@heroicons/react/24/outline';

import { useAppDispatch, useAppSelector } from '@store/hook';
import { selectUser, removeUser } from '@store/user';
import { open as loadingOpen, close as loadingClose } from '@store/loading';

import { logout } from '@api/auth';
import { TOKEN_KEY } from '@common/env';

interface UserDropdownProp {
  readonly userId?: number;
  readonly src?: string;
}

export const UserDropdown: React.FC<UserDropdownProp> = ({ userId, src }: UserDropdownProp) => {
  const navigate = useNavigate();
  const { user } = useAppSelector(selectUser);
  const dispatch = useAppDispatch();

  /**
   * 로그아웃 처리
   */
  const handleLogout = async () => {
    try {
      dispatch(loadingOpen());
      await logout();
    } finally {
      // 에러는 무시한다.
      dispatch(loadingClose());
      dispatch(removeUser());
      localStorage.removeItem(TOKEN_KEY);
      navigate('/signin');
    }
  };

  /**
   * Account 페이지로 이동
   */
  const handleClickAccount = () => {
    navigate('account');
  };

  const avatarClassName: string = 'rounded-full w-24 h-24 mx-auto mb-4';

  return (
    <Popover className="relative h-8">
      <Popover.Button className="w-8 h-8 rounded-full">
        {user?.photo && (
          <img src={user?.photo.path} alt="avatar" className="w-8 h-8 rounded-full" />
        )}
        {!user?.photo && <UserCircleIcon className="text-slate-500" />}
      </Popover.Button>
      <Popover.Panel className="absolute z-10 right-0 w-80 bg-white rounded-lg shadow p-4">
        {({ close }) => (
          <>
            {user?.photo && <img src={user?.photo.path} alt="avatar" className={avatarClassName} />}
            {!user?.photo && <UserCircleIcon className={`text-slate-500 ${avatarClassName}`} />}
            <h3 className="font-semibold text-center">{user && user.userName}</h3>
            <div className="text-sm text-slate-400 text-center mb-4">{user && user.org?.title}</div>
            {//(user?.role?.id === ROLE.MEM_ADMIN || user?.role?.id === ROLE.MEM_PARENT) && (user?.orgs && user?.orgs.length > 1) && }
            }
            {
              (user?.orgs && user?.orgs.length > 1) &&
              (
                <button
                  className="mb-2 w-full h-9 border border-secondary rounded-lg text-secondary text-sm hover:ring-2"
                  onClick={() => {
                    navigate('/selectorg');
                    close();
                  }}>
                  유치원 변경
                </button>
              )}
            <button
              className="mb-2 w-full h-9 border border-secondary rounded-lg text-secondary text-sm hover:ring-2"
              onClick={() => {
                handleClickAccount();
                close();
              }}>
              계정관리
            </button>
            <button
              className="w-full h-9 bg-secondary rounded-lg text-white text-sm"
              onClick={() => {
                handleLogout();
                close();
              }}>
              로그아웃
            </button>
          </>
        )}
      </Popover.Panel>
    </Popover>
  );
};
