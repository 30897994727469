import React, { useCallback, useState } from 'react';
import { Dialog } from '@headlessui/react';
import { toast } from 'react-toastify';
import { TailSpin } from 'react-loader-spinner';
import debounce from 'debounce-promise';

import { Card, Button } from '@components';
import { ROLE, USER_EXIST } from '@common';

import * as authApi from '@api/auth';
import { InviteDto } from '@dto/InviteDto';
import { selectUser } from '@store/user';
import { open as modalOpen } from '@store/modal';
import { useAppDispatch, useAppSelector } from '@store/hook';


interface InviteCreateProp {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onAddComplete: () => void;
}

interface Errors {
  email?: {
    message?: string;
  };
  userName?: {
    message?: string;
  };
  role?: {
    message?: string;
  };
}

export const InviteCreate: React.FC<InviteCreateProp> = ({ isOpen, setIsOpen, onAddComplete }) => {
  const userSelector = useAppSelector(selectUser);
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [code, setCode] = useState<number>(USER_EXIST.NOT_FOUND);
  const [email, setEmail] = useState<string>('');
  const [userName, setUserName] = useState<string>('');
  const [role, setRole] = useState<ROLE>(ROLE.MEM_TEACHER);
  const [errors, setErrors] = useState<Errors>({});

  // eslint-disable-next-line
  const debounceExistEmail = useCallback(
    debounce(
      async (email: string) => {
        try {
          const { result } = await authApi.existEmail(email);
          return Promise.resolve(result);
        } catch (error: any) {
          return Promise.resolve();
        }
      },
      500,
      { leading: true },
    ),
    [],
  );

  const inviteOrgProc = async () => {
    try {
      setIsLoading(true);
      const result = await authApi.inviteByOrg(email);
      if (result.status === 503) {
        throw new Error('해당 사용자는 이미 어린이집에 승인된 사용자입니다');
      }

      toast.info(`승인이 완료되었습니다`);
      setIsOpen(false);
      handleReset();
      onAddComplete();
    } catch (error: any) {
      dispatch(
        modalOpen({
          type: 'alert',
          title: '오류',
          message: error.message
            ? error.message
            : `오류가 발생하였습니다. [오류코드 ${error.status}]`,
        }),
      );
    } finally {
      setIsLoading(false);
    }
  };
  /**
   * 입력 처리
   */
  const inviteProc = async () => {
    try {
      setIsLoading(true);
      const d: InviteDto = {
        userName: userName,
        email: email,
        role: {
          id: role,
        },
        org: {
          id: userSelector.user?.org?.id,
        },
      };
      await authApi.invite(d);
      toast.info(`${email}로 초대 메일을 전송하였습니다.`);
      setIsOpen(false);
      handleReset();
      onAddComplete();
    } catch (error: any) {
      dispatch(
        modalOpen({
          type: 'alert',
          title: '오류',
          message: error.message
            ? error.message
            : `오류가 발생하였습니다. [오류코드 ${error.status}]`,
        }),
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();

    if (code === USER_EXIST.EQUAL_ORG) {
      return;
    } else if (code === USER_EXIST.NOT_FOUND) {
      if (!validateEmail(email) || !validateUserName(userName)) {
        return false;
      }

      dispatch(
        modalOpen({
          type: 'confirm',
          message: '초대하시겠습니까?',
          onOk: inviteProc,
        }),
      );
    } else {
      if (!validateEmail(email)) {
        return false;
      }

      dispatch(
        modalOpen({
          type: 'confirm',
          message:
            '다른 어린이집에서 활동하고 있는 회원입니다. 본 어린이집에 초대하시면 승인 없이 활동 가능합니다. 초대하시겠습니까?',
          onOk: inviteOrgProc,
        }),
      );
    }
  };

  const handleReset = () => {
    setEmail('');
    setUserName('');
    setRole(ROLE.MEM_TEACHER);
    setCode(USER_EXIST.NOT_FOUND);
    setIsOpen(false);
  };

  const handleEmail = (e: any) => {
    setEmail(e.target.value);
    validateEmail(e.target.value);
  };

  /**
   * 이메일 에러 체크
   * @param email
   * @returns
   */
  const validateEmail = async (email: string) => {
    if (!email || email.trim().length === 0) {
      setCode(USER_EXIST.NOT_FOUND);
      const newErrors = { ...errors };
      newErrors.email = {
        message: '이메일을 입력해주세요',
      };
      setErrors(newErrors);
      return false;
    }

    if (!/^[a-z0-9!#$%&'*+/=?^_`{|}~.-]+@[a-z0-9-]+(\.[a-z0-9-]+)*$/.test(email)) {
      setCode(USER_EXIST.NOT_FOUND);
      const newErrors = { ...errors };
      newErrors.email = {
        message: '올바른 이메일 형식이 아닙니다',
      };
      setErrors(newErrors);
      return false;
    }

    const result = await debounceExistEmail(email);

    if (!result || result.code === USER_EXIST.EQUAL_ORG) {
      setCode(USER_EXIST.EQUAL_ORG);
      const newErrors = { ...errors };
      newErrors.email = {
        message: '이미 존재하는 이메일입니다',
      };
      setErrors(newErrors);
      return false;
    }

    setCode(result.code);

    if (result.code === USER_EXIST.NOT_FOUND) {
      setRole(ROLE.MEM_TEACHER);
      const newErrors = { ...errors };
      delete newErrors.email;
      setErrors(newErrors);
      return true;
    }

    if (result.code === USER_EXIST.NOT_EQUAL_ORG) {
      if (!result.user) {
        setCode(USER_EXIST.NOT_FOUND);
        const newErrors = { ...errors };
        newErrors.email = {
          message: '이미 존재하는 이메일입니다',
        };
        setErrors(newErrors);
        return false;
      } else {
        setRole(result.user.roleId as number);
        const newErrors = { ...errors };
        delete newErrors.email;
        setErrors(newErrors);
        return true;
      }
    }

    return false;
  };

  const handleUserName = (e: any) => {
    setUserName(e.target.value);
    validateUserName(e.target.value);
  };

  /**
   * 이름을 수정한다
   * @param userName
   * @returns
   */
  const validateUserName = (userName: string): boolean => {
    if (!userName || userName.trim().length === 0) {
      const newErrors = { ...errors };
      newErrors.userName = {
        message: '이름을 입력해주세요',
      };
      setErrors(newErrors);
      return false;
    }

    const newErrors = { ...errors };
    delete newErrors.userName;
    setErrors(newErrors);
    return true;
  };

  const handleChangeRole = (e: any) => {
    setRole(Number(e.target.value));
  };

  return (
    <Dialog className="relative z-[1001]" open={isOpen} onClose={() => { }}>
      <div className="fixed inset-0 bg-black/75" aria-hidden="true" />
      <div className="fixed inset-0 flex items-center justify-center">
        <Dialog.Panel className="relative w-full md:max-w-md bg-white rounded-lg">
          {isLoading && (
            <div className="w-full h-full absolute bg-white/50 flex items-center justify-center">
              <TailSpin
                height="80"
                width="80"
                color="#ff9e18"
                ariaLabel="tail-spin-loading"
                radius="1"
                visible={true}
              />
            </div>
          )}

          <form onSubmit={handleSubmit}>
            <Card
              title={'초대하기'}
              actions={
                <>
                  <Button plain onClick={handleReset} className="font-normal">
                    취소
                  </Button>
                  <Button type="submit" plain color="secondary" className="font-normal">
                    확인
                  </Button>
                </>
              }>
              <>
                <div className="w-full">
                  <div className="px-4 pb-4 form-control">
                    <label className="label">
                      <span className={`label-text ${errors.email && 'text-error'}`}>이메일</span>
                    </label>
                    <input
                      type="text"
                      className={`input input-bordered text-sm ${errors.email && 'input-error'}`}
                      placeholder="이메일을 입력해주세요"
                      value={email}
                      onChange={handleEmail}
                    />
                    <span className="text-xs mt-2 text-danger tracking-wide">
                      {errors.email && errors.email.message}
                    </span>
                  </div>
                  {code !== USER_EXIST.NOT_EQUAL_ORG && (
                    <div className="px-4 pb-4 form-control">
                      <label className="label">
                        <span className={`label-text ${errors.userName && 'text-error'}`}>
                          이름
                        </span>
                      </label>
                      <input
                        type="text"
                        className={`input input-bordered text-sm ${errors.userName && 'input-error'
                          }`}
                        placeholder="이름을 입력해주세요"
                        value={userName}
                        onChange={handleUserName}
                      />
                      <span className="text-xs mt-2 text-danger tracking-wide">
                        {errors.userName && errors.userName.message}
                      </span>
                    </div>
                  )}
                  <div className="px-4 pb-4 form-control">
                    <label className="label">
                      <span className={`label-text ${errors.role && 'text-error'}`}>역할</span>
                    </label>
                    <div className="flex space-x-2">
                      <label className="label">
                        <input
                          type="radio"
                          className="radio radio-sm mr-2"
                          value={ROLE.MEM_TEACHER}
                          checked={role === ROLE.MEM_TEACHER}
                          onChange={handleChangeRole}
                          disabled={code === USER_EXIST.NOT_EQUAL_ORG}
                        />
                        <span className={`label-text ${errors.role ? 'text-error' : ''}`}>
                          선생님
                        </span>
                      </label>
                      <label className="label">
                        <input
                          type="radio"
                          className="radio radio-sm mr-2"
                          value={ROLE.MEM_PARENT}
                          checked={role === ROLE.MEM_PARENT}
                          onChange={handleChangeRole}
                          disabled={code === USER_EXIST.NOT_EQUAL_ORG}
                        />
                        <span className={`label-text ${errors.role ? 'text-error' : ''}`}>
                          부모님
                        </span>
                      </label>
                    </div>
                    <span className="text-xs mt-2 text-danger tracking-wide">
                      {errors.role && errors.role.message}
                    </span>
                  </div>
                </div>
              </>
            </Card>
          </form>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
};
